import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Pagination} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Pagination, {
            'total': 200,
            'value': this.get('value1'),
            'ev-$change:value': this._fetch1
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Pagination, {
            'total': 200,
            'ev-change': this._fetch2,
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2'),
            'ev-$model:limit': function($v) {
                $this.set('limit', $v);
            },
            'limit': $this.get('limit')
        })
    ], 4 /* HasNonKeyedChildren */);
};