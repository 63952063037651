import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    map as _$ma,
} from 'intact';
import {Tags, Tag, Split, Tooltip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Split, {
        'style': 'width: 600px; background: #e1e1e1;',
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['first'] = function($super) {
                        return (
                            _$cc(Tags, {
                                'children': (
                                    _$ma(this.get('tags1'), function($value, $key) {
                                        return _$cc(Tag, {
                                            'key': $value,
                                            'type': $value,
                                            'closable': true,
                                            'ev-close': (e) => this.remove('tags1', $value, e),
                                            'children': $value
                                        }, $value);
                                    }, $this)
                                )
                            })
                        );
                    }),
                    (__$blocks['first'] = function($super, data) {
                        var block = $blocks['first'];
                        var callBlock = function() {
                            return _$blocks['first'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                (
                    (_$blocks['last'] = function($super) {
                        return (
                            _$cc(Tags, {
                                'nowrap': true,
                                'children': [
                                    _$ma(this.get('tags2'), function($value, $key) {
                                        return _$cc(Tag, {
                                            'key': $value,
                                            'type': $value,
                                            'closable': true,
                                            'ev-close': (e) => this.remove('tags2', $value, e),
                                            'children': $value
                                        }, $value);
                                    }, $this),
                                    _$cc(Tooltip, {
                                        'content': 'test',
                                        'key': 'test',
                                        'children': (
                                            _$cc(Tag, {
                                                'children': 'with tooltip'
                                            })
                                        )
                                    }, 'test')
                                ]
                            })
                        );
                    }),
                    (__$blocks['last'] = function($super, data) {
                        var block = $blocks['last'];
                        var callBlock = function() {
                            return _$blocks['last'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    });
};