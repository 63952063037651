import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': [
            {name: 'Javey', sex: 'male'},
            {name: 'Kanily', sex: 'female'}
        ],
        'stickHeader': '64',
        'resizable': true,
        'children': [
            _$cc(TableColumn, {
                'title': '姓名',
                'key': 'name',
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['template'] = function($super, [data]) {
                                return data.name;
                            }),
                            (__$blocks['template'] = function($super, data) {
                                var block = $blocks['template'];
                                var callBlock = function() {
                                    return _$blocks['template'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, 'name'),
            _$cc(TableColumn, {
                'title': '性别',
                'key': 'sex',
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['template'] = function($super, [data]) {
                                return data.sex;
                            }),
                            (__$blocks['template'] = function($super, data) {
                                var block = $blocks['template'];
                                var callBlock = function() {
                                    return _$blocks['template'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, 'sex')
        ]
    });
};