import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    createTextVNode as _$ct,
} from 'intact';
import {Button, ButtonGroup} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'children': (
                _$ma(this.get('types'), function($value, $key) {
                    return _$cc(Button, {
                        'ev-click': this.showDialog.bind(this, $value),
                        'children': [
                            _$ct('Show '),
                            $value[0].toUpperCase() + $value.substring(1),
                            _$ct(' Dialog')
                        ]
                    });
                }, $this)
            )
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(ButtonGroup, {
            'children': (
                _$ma(this.get('types'), function($value, $key) {
                    return _$cc(Button, {
                        'ev-click': this.showDialogWithTitle.bind(this, $value),
                        'children': [
                            _$ct('Show '),
                            $value[0].toUpperCase() + $value.substring(1),
                            _$ct(' Dialog with Title')
                        ]
                    });
                }, $this)
            )
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Button, {
            'ev-click': this.showAsyncCloseConfirm,
            'children': '异步关闭confirm弹层'
        })
    ], 4 /* HasNonKeyedChildren */);
};