import {
    createElementVNode as _$ce,
    className as _$cn,
    createVNode as _$cv,
} from 'intact';
import {directClone} from 'intact';
import {mapChildren, getRestProps} from '../utils';
import {Menu} from '../menu';
import {makeAsideStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        className, children, collapse,
        fixed, theme,
    } = this.get();
    const { k } = this.config;
    
    const vNodes = mapChildren(children, vNode => {
        if (vNode.tag === Menu) {
            let vNodeClone = directClone(vNode);
            vNodeClone.props = {...vNode.props, collapse};
            return _$ce(2, 'div', vNodeClone, 0 /* UnknownChildren */, _$cn(`${k}-layout-wrapper`))
        }
        return vNode
    });
    
    const classNameObj = {
        [`${k}-layout-aside`]: true,
        [`${k}-collapsed`]: collapse,
        [`${k}-fixed`]: fixed,
        [`${k}-${theme === 'dark' ? 'dark' : 'light'}`]: true,
        [className]: className,
        [makeAsideStyles(k)]: true,
    };
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'style': this.getStyles(k)
    }, vNodes);
};