import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Progress, Button, ButtonGroup} from 'kpc';

var _$tmp0 = {
    'style': 'font-size: 14px; line-height: 20px;'
};
var _$tmp1 = {
    'style': 'font-size: 14px; line-height: 20px; color: #4db500;'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Progress, {
            'percent': this.get('percent'),
            'type': 'circle',
            'children': (
                (this.get('percent') !== 100) ?
                    _$ce(2, 'div', [
                        _$ct('\n            正在上传'),
                        _$ce(2, 'br'),
                        this.get('percent'),
                        _$ct('%\n        ')
                    ], 0 /* UnknownChildren */, null, _$tmp0) :
                    _$ce(2, 'div', '\n            上传完成\n        ', 16 /* HasTextChildren */, null, _$tmp1)
            )
        }),
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'size': 'mini',
                    'icon': true,
                    'ev-click': this.minus,
                    'children': '-'
                }),
                _$cc(Button, {
                    'size': 'mini',
                    'icon': true,
                    'ev-click': this.add,
                    'children': '+'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};