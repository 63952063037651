import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Cascader} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Cascader, {
        'data': this.get('data'),
        'loadData': this.loadData
    });
};