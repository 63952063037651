import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Input} from 'kpc';

var _$tmp0 = {
    'placeholder': '内联文字',
    'inline': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Input, _$tmp0);
};