import {
    noop as _$no,
    createElementVNode as _$ce,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
} from 'intact';
import {DropdownMenu} from '../dropdown';
import {getRestProps, findChildren, isComponentVNode} from '../utils';
import {isDropdown} from './useDropdown';
import {makeMenuStyles, makeTitleStyles} from './styles';
import {Icon} from '../icon';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {children, className} = this.get();
    const {collapse, theme, type, size, showCollapseArrow} = (this.rootMenu || this).get();
    const isRootMenu = !this.rootMenu;
    const isDropdownMenu = !isRootMenu && isDropdown(this.rootMenu);
    const { k } = this.config;
    const classNameObj = {
        [`${k}-menu`]: true,
        [className]: className,
        [`${k}-${theme === 'dark' ? 'dark' : 'light'}`]: (isRootMenu || isDropdownMenu) && theme,
        [`${k}-${size}`]: isRootMenu && size !== 'default',
        [`${k}-collapsed`]: isRootMenu && collapse,
        [`${k}-collapsed-arrow`]: isRootMenu && showCollapseArrow && collapse,
        [`${k}-${type}`]: isRootMenu && type,
        [makeMenuStyles(k)]: true,
    };
    
    const header = (
        (_$blocks['header'] = function($super) {
            return null;
        }),
        (__$blocks['header'] = function($super, data) {
            var block = $blocks['header'];
            var callBlock = function() {
                return _$blocks['header'].call($this, $super, data);
            };
            return block ?
                block.call($this, callBlock, data) :
                callBlock();
        }),
        __$blocks['header'](_$no)
    )
    const content = (
        [
            ($blocks.header) ?
                _$ce(2, 'div', (
                    (!collapse) ?
                        header :
                        findChildren(header, vNode => isComponentVNode(vNode, Icon))
                ), 0 /* UnknownChildren */, _$cn({
                    [`${k}-menu-header`]: true,
                    [makeTitleStyles(k)]: true
                })) :
                undefined,
            (isRootMenu && showCollapseArrow && type !== 'horizontal') ?
                _$ce(2, 'div', (
                    _$cc(Icon, {
                        'className': _$cn(`${k}-menu-arrow ion-arrow-left-b`)
                    })
                ), 2 /* HasVNodeChildren */, _$cn(`${k}-menu-arrow-box`), {
                    'ev-click': this.onClick.bind(this)
                }) :
                undefined,
            _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-menu-body`))
        ]
    );
    
    return (
        (!isDropdownMenu) ?
            _$cv('div', {
                ...getRestProps(this),
                'className': _$cn(classNameObj)
            }, content) :
            _$cc(DropdownMenu, {
                ...getRestProps(this),
                'className': _$cn(classNameObj),
                'children': content
            })
    );
};