export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message} from 'kpc';
import {bind} from 'kpc';

export default class extends Component {
    static template = template;
    
    @bind
    showMessage1() {
        Message.info('close after 10s', 1000000);
    }

    @bind
    showMessage2() {
        Message.success('stick message', 0);
    }
}