import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'odd rows are uncheckable'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$cc(Table, {
        'data': [{a: '1'}, {a: '2'}, {a: '3'}, {a: '4'}, {a: '5'}],
        'disableRow': (data, index) => index % 2 === 0,
        'rowKey': data => data.a,
        'ref': _$ref___test,
        'children': (
            _$cc(TableColumn, _$tmp0, 'a')
        ),
        'ev-$model:checkedKeys': function($v) {
            $this.set('checkedKeys', $v);
        },
        'checkedKeys': $this.get('checkedKeys')
    }, null, _$ref___test);
};