import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Switch} from 'kpc';

var _$tmp0 = {
    'on': '开启',
    'off': '关闭',
    'width': '62'
};
var _$tmp1 = {
    'height': '22'
};
var _$tmp2 = {
    'on': '开启',
    'off': '关闭',
    'width': '100',
    'height': '30'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Switch, _$tmp0),
        _$cc(Switch, _$tmp1),
        _$cc(Switch, _$tmp2)
    ], 4 /* HasNonKeyedChildren */);
};