import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'tagName': 'div',
            'type': 'primary',
            'children': 'div元素按钮'
        }),
        _$cc(Button, {
            'tagName': 'div',
            'disabled': true,
            'children': 'disabled div button'
        }),
        _$cc(Button, {
            'href': 'https://www.ksyun.com',
            'target': '_blank',
            'children': '超链接按钮'
        })
    ], 4 /* HasNonKeyedChildren */);
};