import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Spinner} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Spinner, {
        'step': {
            // 小于-5
            '-5': 5,
            // 大于-5，小于5
            '5': 1,
            // 大于5
            '$': 5
        },
        'ev-$model:value': function($v) {
            $this.set('value1', $v);
        },
        'value': $this.get('value1')
    });
};