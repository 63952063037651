export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data1: [{a: 'type', b: 'border'}],
            data2: [{a: 'type', b: 'grid'}] 
        }
    }
}