export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            expandedKeys: [],
            selectedKey: '3-1',
            menuList: [
                {
                    key: '1',
                    title: 'menu 1'
                },
                {
                    key: '2',
                    title: 'menu 2'
                },
                {
                    key: '3',
                    title: 'menu 3',
                    children: [
                        {
                            key: '3-1',
                            title: 'sub menu 1'
                        },
                        {
                            key: '3-2',
                            title: 'sub menu 2'
                        },
                        {
                            key: '3-3',
                            title: 'sub menu 3'
                        },
                        {
                            key: '3-4',
                            title: 'sub menu 4',
                            children: [
                                {
                                    key: '3-4-1',
                                    title: 'option 1'
                                },
                                {
                                    key: '3-4-2',
                                    title: 'option 2'
                                }
                            ]
                        }
                    ]
                },
                {
                    key: '4',
                    title: 'menu 4'
                },
            ]
        };
    }
}