import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    superCall as _$su,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    map as _$ma,
    createTextVNode as _$ct,
} from 'intact';
import {Button, Badge, Aside} from 'kpc';
import {makeStyles} from './styles';
import {Link} from '../../components/link';
import {Catalogue} from '../../components/catalogue';

var _$tmp0 = {
    'id': 'article-title'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const Article = this.get('Article');
    
    if (!Article) return _$ce(2, 'div', 'Loading...', 16 /* HasTextChildren */);
    
    let currentNav = {};
    
    const Catalog = (props) => {
        let isEmpty = true;
    
        const lis = props.value.map((value) => {
            if (!value.disabled) isEmpty = false;
            return (!value.disabled) ?
                _$ce(2, 'li', (
                    _$cc(Link, {
                        'href': `/${value.path}/`,
                        'children': (() => {
                            const matches = value.path.match(/components\/([^\/]+)/);
                            if (matches) {
                                const name = matches[1];
                                return [
                                    name[0].toUpperCase() + name.substring(1),
                                    _$ce(2, 'span', value.title, 0 /* UnknownChildren */)
                                ];
                            } else {
                                if (value.title === '更新日志') {
                                    return _$cc(Badge, {
                                        'disabled': !!this.get('hasRead'),
                                        'children': value.title
                                    })
                                } else {
                                    return value.title;
                                }
                            }
                        })()
                    })
                ), 2 /* HasVNodeChildren */, _$cn({
                    active: (() => {
                        const path = this.path.slice(1);
                        if (path === value.path) {
                            currentNav = value;
                            return true;
                        }
                    })()
                })) :
                undefined
        });
        if (isEmpty) return;
        return _$ce(2, 'div', [
            (props.key !== undefined) ?
                _$ce(2, 'h5', props.key, 0 /* UnknownChildren */) :
                undefined,
            _$ce(2, 'ul', lis, 0 /* UnknownChildren */)
        ], 0 /* UnknownChildren */, 'catalog-section')
    }
    
    const sidebar = Article.sidebar;
    const _sidebar = [];
    if (sidebar) {
        if (sidebar.undefined) {
            _sidebar.push({
                key: undefined,
                value: sidebar.undefined
            });
        }
        for (let key in sidebar) {
            if (key !== 'undefined') {
                _sidebar.push({
                    key,
                    value: key !== '组件' ? 
                        sidebar[key] :
                        sidebar[key].sort((a, b) => {
                            return a.path > b.path ? 1 : -1;
                        })
                });
            }
        }
    }
    
    return _$su.call($this, {
        'navIndex': 'document',
        ...$props,
        'className': _$cn({
            "document-page fixed-aside": true,
            [$props.className]: $props.className,
            [makeStyles(this.config.k)]: true,
        })
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['aside'] = function($super) {
                    return (
                        _$cc(Aside, {
                            'className': _$cn({expanded: this.get('expanded')}),
                            'fixed': true,
                            'width': '260px',
                            'theme': 'white',
                            'children': [
                                _$ce(2, 'div', [
                                    _$ma(_sidebar, function($value, $key) {
                                        return Catalog($value);
                                    }, $this),
                                    _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'aside-border transition')
                                ], 0 /* UnknownChildren */, 'aside-wrapper'),
                                _$cc(Button, {
                                    'icon': true,
                                    'ev-click': () => this.set('expanded', !this.get('expanded')),
                                    'children': (
                                        _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'k-icon ion-android-menu')
                                    )
                                })
                            ]
                        })
                    );
                }),
                (__$blocks['aside'] = function($super, data) {
                    var block = $blocks['aside'];
                    var callBlock = function() {
                        return _$blocks['aside'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            (
                (_$blocks['content'] = function($super) {
                    return (
                        _$ce(2, 'article', [
                            _$ce(2, 'div', [
                                _$ce(2, 'div', currentNav.title, 0 /* UnknownChildren */, 'title', _$tmp0),
                                _$ce(2, 'div', [
                                    _$ct('\n                    如果你发现文档有问题，请帮忙在\n                    '),
                                    _$ce(2, 'a', 'github', 16 /* HasTextChildren */, null, {
                                        'target': '_blank',
                                        'href': `https://github.com/ksc-fe/kpc/blob/master/${currentNav.path}`
                                    }),
                                    _$ct('\n                    上修正该文档\n                ')
                                ], 4 /* HasNonKeyedChildren */, 'edit-link')
                            ], 4 /* HasNonKeyedChildren */, 'article-head'),
                            _$cc(Article, {
                                'ref': this.articleRef
                            }, null, this.articleRef),
                            _$cc(Catalogue, {
                                'data': this.get('catalogue'),
                                'ev-$model:value': function($v) {
                                    $this.set('catalogueId', $v);
                                },
                                'value': $this.get('catalogueId')
                            })
                        ], 4 /* HasNonKeyedChildren */)
                    );
                }),
                (__$blocks['content'] = function($super, data) {
                    var block = $blocks['content'];
                    var callBlock = function() {
                        return _$blocks['content'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};