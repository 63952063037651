import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'h4', '居左 start', 16 /* HasTextChildren */),
        _$cc(Row, {
            'justify': 'start',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '居右 end', 16 /* HasTextChildren */),
        _$cc(Row, {
            'justify': 'end',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '居中 center', 16 /* HasTextChildren */),
        _$cc(Row, {
            'justify': 'center',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '平均分布，顶边 between', 16 /* HasTextChildren */),
        _$cc(Row, {
            'justify': 'between',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '平均分布，与边界的距离是两个子元素的一半 around', 16 /* HasTextChildren */),
        _$cc(Row, {
            'justify': 'around',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '平均分布，间距（包括边界）相等 evenly', 16 /* HasTextChildren */),
        _$cc(Row, {
            'justify': 'evenly',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};