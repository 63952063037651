import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-search'
};
var _$tmp1 = {
    'className': 'k-icon-search'
};
var _$tmp2 = {
    'className': 'k-icon-search'
};
var _$tmp3 = {
    'className': 'k-icon-search'
};
var _$tmp4 = {
    'className': 'k-icon-search',
    'size': 'large'
};
var _$tmp5 = {
    'className': 'k-icon-search',
    'size': 'small'
};
var _$tmp6 = {
    'className': 'k-icon-search',
    'size': 'small'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'icon': true,
            'children': (
                _$cc(Icon, _$tmp0)
            )
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'children': (
                _$cc(Icon, _$tmp1)
            )
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'type': 'none',
            'children': (
                _$cc(Icon, _$tmp2)
            )
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'type': 'flat',
            'children': (
                _$cc(Icon, _$tmp3)
            )
        }),
        _$cc(Button, {
            'type': 'danger',
            'size': 'large',
            'icon': true,
            'circle': true,
            'children': (
                _$cc(Icon, _$tmp4)
            )
        }),
        _$cc(Button, {
            'type': 'primary',
            'children': [
                _$cc(Icon, _$tmp5),
                _$ct('搜索')
            ]
        }),
        _$cc(Button, {
            'circle': true,
            'type': 'primary',
            'children': [
                _$cc(Icon, _$tmp6),
                _$ct('搜索')
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};