export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    day?: string | null
    days?: string[]
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            day: null,
            days: []
        } as Props;
    };
}