import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Popover, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_popover1 = function(i) {_$refs['popover1'] = i};
    var _$ref_popover2 = function(i) {_$refs['popover2'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Popover, {
            'title': '确定删除？',
            'ev-ok': this.ok,
            'ev-cancel': this.cancel,
            'ref': _$ref_popover1,
            'size': 'small',
            'children': (
                _$cc(Button, {
                    'children': '删除'
                })
            )
        }, null, _$ref_popover1),
        ' ',
        _$cc(Popover, {
            'title': '确定删除？',
            'content': '这是一段描述文字',
            'ev-ok': this.ok,
            'ev-cancel': this.cancel,
            'ref': _$ref_popover2,
            'children': (
                _$cc(Button, {
                    'children': '删除'
                })
            )
        }, null, _$ref_popover2)
    ], 0 /* UnknownChildren */);
};