import {
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {getRestProps} from '../utils';
import {makeItemStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        children, disabled, className, hideOnSelect,
    
        _isFocus,
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-dropdown-item`]: true,
        [`${k}-disabled`]: disabled,
        [className]: className,
        [`${k}-hover`]: this.keyboard.isFocus.value,
        [makeItemStyles(k)]: true,
    }
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'ev-click': this.onClick,
        'ev-mouseenter': this.keyboard.onMouseEnter,
        'ev-mouseleave': this.keyboard.onMouseLeave
    }, children);
};