import {
    createElementVNode as _$ce,
    className as _$cn,
    createVNode as _$cv,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import { makeStyles } from './styles';
import {Tooltip} from '../tooltip';
import {getRestProps, addStyle} from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        children, className, maxLines, 
        disabled, position, theme, style,
        hoverable
    } = this.get();
    const { k } = this.config;
    
    const { showTooltip, ellipsisRef } = this.ellipsis;
    const tooltip = showTooltip.value && !disabled;
    const wrapper = _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn({
        'c-ellipsis': true,
        [`${k}-ellipsis-wrapper`]: true,
        [className]: className && !tooltip,
    }));
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn({
            [`${k}-ellipsis`]: true,
            [`${k}-multiline`]: maxLines,
            [makeStyles(k, maxLines)]: true,
        }),
        'ref': ellipsisRef
    }, (
        (tooltip) ?
            _$cc(Tooltip, {
                'position': position,
                'theme': theme,
                'className': _$cn(className),
                'hoverable': hoverable,
                'children': wrapper,
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['content'] = function($super) {
                                return children;
                            }),
                            (__$blocks['content'] = function($super, data) {
                                var block = $blocks['content'];
                                var callBlock = function() {
                                    return _$blocks['content'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }) :
            wrapper
    ));
};