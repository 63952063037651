import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Slider, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Slider, {
            'min': this.get('min'),
            'max': this.get('max'),
            'unit': 'MB',
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }),
        _$cc(Slider, {
            'min': 50,
            'max': 500,
            'ref': _$ref___test,
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        }, null, _$ref___test),
        _$cc(Button, {
            'ev-click': this._changeRange,
            'children': 'Change Range Randomly'
        })
    ], 4 /* HasNonKeyedChildren */);
};