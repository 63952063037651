export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {MenuItem} from 'kpc';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            expandedKeys: ['3'],
            selectedKey: '3-1',
        };
    }

    onSelect(item: MenuItem) {
        console.log('key', item.get('key'));
    }
}