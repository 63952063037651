import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
} from 'intact';
import {Tooltip} from '../tooltip';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {ColorpickerPanel} from './panel';
import {Dropdown, DropdownMenu} from '../dropdown';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        className, transition, presets,
        value, size, disabled, container,
        position,
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-colorpicker`]: true,
        [className]: className,
        [`${k}-${size}`]: size !== 'default',
        [`${k}-disabled`]: disabled,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, (
        _$cc(Dropdown, {
            'position': position,
            'trigger': 'click',
            'disabled': disabled,
            'container': container,
            'children': [
                _$ce(2, 'div', (
                    _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-colorpicker-inner`), {
                        'style': {backgroundColor: value}
                    })
                ), 2 /* HasVNodeChildren */, _$cn(`${k}-colorpicker-color`)),
                _$cc(DropdownMenu, {
                    'className': _$cn(`${k}-colorpicker-content`),
                    'children': (
                        _$cc(ColorpickerPanel, {
                            'presets': presets,
                            'ev-$model:value': function($v) {
                                $this.set('value', $v);
                            },
                            'value': $this.get('value')
                        })
                    )
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        })
    ));
};