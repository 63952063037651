import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {ButtonGroup, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(ButtonGroup, {
        'children': [
            _$cc(Button, {
                'ev-click': this.showMessage1,
                'children': 'close after 10s'
            }),
            _$cc(Button, {
                'ev-click': this.showMessage2,
                'children': 'stick message'
            })
        ]
    });
};