import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Switch} from 'kpc';

var _$tmp0 = {
    'style': 'margin-bottom: 20px'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$cc(Switch, {
                'ev-$model:value': function($v) {
                    $this.set('value1', $v);
                },
                'value': $this.get('value1')
            }),
            JSON.stringify(this.get('value1'))
        ], 0 /* UnknownChildren */, null, _$tmp0),
        _$ce(2, 'div', [
            _$cc(Switch, {
                'trueValue': 1,
                'falseValue': '1',
                'ev-$model:value': function($v) {
                    $this.set('value2', $v);
                },
                'value': $this.get('value2')
            }),
            JSON.stringify(this.get('value2'))
        ], 0 /* UnknownChildren */)
    ], 4 /* HasNonKeyedChildren */);
};