import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Dropdown, DropdownMenu, DropdownItem, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-down'
};
var _$tmp1 = {
    'className': 'k-icon-down'
};
var _$tmp2 = {
    'className': 'k-icon-down'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Dropdown, {
            'position': {my: 'left top', at: 'left bottom+5'},
            'children': [
                _$cc(Button, {
                    'type': 'primary',
                    'children': [
                        _$ct('\n            left '),
                        _$cc(Icon, _$tmp0)
                    ]
                }),
                _$cc(DropdownMenu, {
                    'children': [
                        _$cc(DropdownItem, {
                            'children': 'item 1'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 2'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 3'
                        })
                    ]
                })
            ]
        }),
        _$cc(Dropdown, {
            'position': {my: 'right top', at: 'right bottom+5'},
            'children': [
                _$cc(Button, {
                    'type': 'primary',
                    'children': [
                        _$ct('\n            right '),
                        _$cc(Icon, _$tmp1)
                    ]
                }),
                _$cc(DropdownMenu, {
                    'children': [
                        _$cc(DropdownItem, {
                            'children': 'item 1'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 2'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 3'
                        })
                    ]
                })
            ]
        }),
        _$cc(Dropdown, {
            'position': 'top',
            'children': [
                _$cc(Button, {
                    'type': 'primary',
                    'children': [
                        _$ct('\n            top center '),
                        _$cc(Icon, _$tmp2)
                    ]
                }),
                _$cc(DropdownMenu, {
                    'children': [
                        _$cc(DropdownItem, {
                            'children': 'item 1'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 2'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 3'
                        })
                    ]
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};