import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {makeStyles} from './styles';
import {Footer} from '../../components/footer/';
import {ImgBox} from '../../components/ImgBox/';
import {Tooltip} from 'kpc';
import tpl_1 from '../../imgs/tpl_1.png';
import tpl_2 from '../../imgs/tpl_2.png';
import tpl_3 from '../../imgs/tpl_3.png';
import tpl_4 from '../../imgs/tpl_4.png';
import tpl_5 from '../../imgs/tpl_5.png';
import tpl_6 from '../../imgs/tpl_6.png';
import tpl_7 from '../../imgs/tpl_7.png';
import tpl_8 from '../../imgs/tpl_8.png';
import tpl_9 from '../../imgs/tpl_9.png';
import tpl_10 from '../../imgs/tpl_10.png';
import form_1 from '../../imgs/common/form_1.png';
import form_2 from '../../imgs/common/form_2.png';
import login_1 from '../../imgs/common/login_1.png';
import login_2 from '../../imgs/common/login_2.png';
import personal_1 from '../../imgs/common/personal_1.png';
import list_1 from '../../imgs/common/list_1.png';
import detail_1 from '../../imgs/common/detail_1.png';
import overview_1 from '../../imgs/common/overview_1.png';
import overview_2 from '../../imgs/common/overview_2.png';
import edu_1 from '../../imgs/industry/edu_1.png';
import edu_2 from '../../imgs/industry/edu_2.png';
import edu_3 from '../../imgs/industry/edu_3.png';
import iot_1 from '../../imgs/industry/iot_1.png';
import iot_2 from '../../imgs/industry/iot_2.png';
import iot_3 from '../../imgs/industry/iot_3.png';
import iot_4 from '../../imgs/industry/iot_4.png';
import ai_1 from '../../imgs/industry/ai_1.png';
import ai_2 from '../../imgs/industry/ai_2.png';
import ai_3 from '../../imgs/industry/ai_3.png';
import ai_4 from '../../imgs/industry/ai_4.png';
import ob_1 from '../../imgs/industry/ob_1.png';
import ob_2 from '../../imgs/industry/ob_2.png';
import ob_3 from '../../imgs/industry/ob_3.png';
import ob_4 from '../../imgs/industry/ob_4.png';
import sketch_transparent from '../../imgs/sketch_transparent.png';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const solutions = [
        {
            title: '通用解决方案',
            list: [
                {
                    name: '通用-表单',
                    img: tpl_1,
                    templateList: [form_1, form_2],
                    downloadFileName: '表单页.sketch',
                    desc: '清晰的表单设计，体验更加高效'
                },
                {
                    name: '通用-登录',
                    img: tpl_2,
                    templateList: [login_1, login_2],
                    downloadFileName: '登录页.sketch',
                    desc: '多种登录样式，满足不同业务场景'
                },
                {
                    name: '通用-个人中心',
                    img: tpl_3,
                    templateList: [personal_1],
                    downloadFileName: '个人中心页.sketch',
                    desc: '卡片式聚合设计，全面展示个人信息'
                },
                {
                    name: '通用-列表',
                    img: tpl_4,
                    templateList: [list_1],
                    downloadFileName: '列表页.sketch',
                    desc: '统一的列表样式，浏览更加高效'
                },
                {
                    name: '通用-详情',
                    img: tpl_5,
                    templateList: [detail_1],
                    downloadFileName: '详情页.sketch',
                    desc: '全页面布局产品信息，页面更加丰富'
                },
                {
                    name: '通用-总览',
                    img: tpl_6,
                    templateList: [overview_1, overview_2],
                    downloadFileName: '总览页.sketch',
                    desc: '组件化核心内容展示，方便业务扩展'
                }
            ]
        },
        {
            title: '行业解决方案',
            list: [
                {
                    name: '行业-监控',
                    img: tpl_7,
                    templateList: [ob_1, ob_2, ob_3, ob_4],
                    downloadFileName: '监控.sketch',
                    desc: '服务器的数据安全监控与统计'
                },
                {
                    name: '行业-教育',
                    img: tpl_8,
                    templateList: [edu_1, edu_2, edu_3],
                    downloadFileName: 'AI教育平台.sketch',
                    desc: 'AI教育材料的管理与配置'
                },
                {
                    name: '行业-人工智能',
                    img: tpl_9,
                    templateList: [ai_1, ai_2, ai_3, ai_4],
                    downloadFileName: '人工智能平台.sketch',
                    desc: '人工智能的计算模型在线设置'
                },
                {
                    name: '行业-IOT',
                    img: tpl_10,
                    templateList: [iot_1, iot_2, iot_3, iot_4],
                    downloadFileName: 'IOT.sketch',
                    desc: '智能家居设备的设置，统计和管理'
                }
            ]
        }
    ]
    
    const {showImgBox, curImgList} = this.get();
    
    return _$su.call($this, {
        'className': _$cn(makeStyles()),
        'navIndex': 'solution'
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['content'] = function($super) {
                    return [
                        _$ce(2, 'div', (
                            _$ce(2, 'div', (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', '解决方案', 16 /* HasTextChildren */),
                                    _$ce(2, 'div', '提供多行业的设计模板', 16 /* HasTextChildren */)
                                ], 4 /* HasNonKeyedChildren */, 'nav-title')
                            ), 2 /* HasVNodeChildren */, 'content')
                        ), 2 /* HasVNodeChildren */, 'solution-nav'),
                        _$ce(2, 'div', (
                            _$ce(2, 'div', (
                                _$ma(solutions, function($value, $key) {
                                    return _$ce(2, 'div', [
                                        _$ce(2, 'div', $value.title, 0 /* UnknownChildren */, 'solution-title'),
                                        _$ce(2, 'div', (
                                            _$ma($value.list, function($value, $key) {
                                                return _$ce(2, 'div', [
                                                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                        'src': $value.img,
                                                        'ev-click': this.handleImgClick.bind(this, $value)
                                                    }),
                                                    _$ce(2, 'div', [
                                                        _$ce(2, 'div', $value.name, 0 /* UnknownChildren */),
                                                        _$ce(2, 'div', [
                                                            _$ce(2, 'div', $value.desc, 0 /* UnknownChildren */, 'solution-desc'),
                                                            _$ce(2, 'div', (
                                                                _$cc(Tooltip, {
                                                                    'content': '下载sketch模板',
                                                                    'children': (
                                                                        _$ce(2, 'div', (
                                                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                                'ev-click': this.downloadSketch.bind(this, $value),
                                                                                'src': sketch_transparent
                                                                            })
                                                                        ), 2 /* HasVNodeChildren */, 'sketch-logo')
                                                                    )
                                                                })
                                                            ), 2 /* HasVNodeChildren */)
                                                        ], 4 /* HasNonKeyedChildren */)
                                                    ], 4 /* HasNonKeyedChildren */, 'solution-info')
                                                ], 4 /* HasNonKeyedChildren */, 'solution-item');
                                            }, $this)
                                        ), 4 /* HasNonKeyedChildren */, 'solution-box')
                                    ], 4 /* HasNonKeyedChildren */, 'solutions');
                                }, $this)
                            ), 4 /* HasNonKeyedChildren */)
                        ), 2 /* HasVNodeChildren */, 'solution-content'),
                        _$cc(Footer),
                        _$cc(ImgBox, {
                            'show': showImgBox,
                            'imgList': curImgList,
                            'ev-closebox': this.handleBoxClose.bind(this)
                        })
                    ];
                }),
                (__$blocks['content'] = function($super, data) {
                    var block = $blocks['content'];
                    var callBlock = function() {
                        return _$blocks['content'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};