import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Upload} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Upload, {
        'multiple': true,
        'type': 'drag',
        'beforeRemove': this.beforeRemove,
        'action': '//fakestoreapi.com/products'
    });
};