export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {TagProps} from 'kpc';

interface Props {
    tags1: Required<TagProps>['type'][]
    tags2: Required<TagProps>['type'][]
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            tags1: ['default', 'primary', 'success', 'warning', 'danger'],
            tags2: ['default', 'primary', 'success', 'warning', 'danger'],
        } as Props;
    }

    remove(name: keyof Props, type: Required<TagProps>['type'], e: MouseEvent) {
        e.preventDefault();

        const tags = this.get(name).filter((tag) => tag !== type);
        this.set(name, tags); 
    }
}