import {
    createElementVNode as _$ce,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    superCall as _$su,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Icon} from '../icon';
import {DropdownMenu} from '../dropdown';
import {makePanelStyles} from './styles';
import {DatepickerTime} from '../datepicker/time';
import {_$} from '../../i18n';
import {Button} from '../button';
import {PanelFlags} from '../datepicker/usePanel';
import {last} from '../datepicker/helpers';
import {ScrollSelect} from '../scrollSelect';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, range, multiple} = this.get();
    const {
        value: {value},
        onConfirm,
        onChangeTime,
        onChangeTimeByStep,
        getTimeValue,
    } = this.value;
    const lastValue = last(value);
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-time-content`]: true,
        [`${k}-range`]: range,
        [className]: className,
        [makePanelStyles(k)]: true,
    };
    const options = this.step.options.value;
    const {isDisabledTime, isDisabledTimeByStep, isDisabledConfirm} = this.disabled;
    const {getValueFormat, getValueString} = this.formats;
    
    const generatePanel = flag => {
        const timeValue = getTimeValue(flag); 
        return (
            _$ce(2, 'div', [
                (range) ?
                    _$ce(2, 'div', flag === PanelFlags.Start ? _$('开始时间') : _$('结束时间'), 0 /* UnknownChildren */, _$cn(`${k}-timepicker-title`)) :
                    undefined,
                (!options) ?
                    _$cc(DatepickerTime, {
                        'value': timeValue,
                        'ev-$change:value': v => onChangeTime(v, flag),
                        'format': getValueFormat(),
                        'flag': flag,
                        'isDisabledTime': isDisabledTime
                    }) :
                    _$ce(2, 'div', (
                        _$cc(ScrollSelect, {
                            'value': timeValue ? getValueString(timeValue) : timeValue,
                            'ev-$change:value': v => onChangeTimeByStep(v, flag),
                            'data': options,
                            'disable': v => isDisabledTimeByStep(v, flag)
                        })
                    ), 2 /* HasVNodeChildren */, _$cn(`${k}-datepicker-time`))
            ], 0 /* UnknownChildren */, _$cn(`${k}-datepicker-calendar-wrapper`))
        );
    };
    
    return _$su.call($this, {
        'className': _$cn(`${k}-timepicker`)
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['base-menu'] = function($super) {
                    return (
                        _$cc(DropdownMenu, {
                            'className': _$cn(classNameObj),
                            'children': (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', [
                                        generatePanel(PanelFlags.Start),
                                        _$ct('\n                    '),
                                        range ? generatePanel(PanelFlags.End) : null
                                    ], 0 /* UnknownChildren */, _$cn(`${k}-datepicker-calendars`)),
                                    _$ce(2, 'div', (
                                        _$cc(Button, {
                                            'type': 'primary',
                                            'size': 'small',
                                            'disabled': isDisabledConfirm(),
                                            'ev-click': onConfirm,
                                            'children': _$('确定')
                                        })
                                    ), 2 /* HasVNodeChildren */, _$cn(`${k}-datepicker-footer`))
                                ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-datepicker-wrapper`))
                            )
                        })
                    );
                }),
                (__$blocks['base-menu'] = function($super, data) {
                    var block = $blocks['base-menu'];
                    var callBlock = function() {
                        return _$blocks['base-menu'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            (
                (_$blocks['suffix'] = function($super) {
                    return (
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-time`)
                        })
                    );
                }),
                (__$blocks['suffix'] = function($super, data) {
                    var block = $blocks['suffix'];
                    var callBlock = function() {
                        return _$blocks['suffix'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};