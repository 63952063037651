import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Drawer, Button, ButtonGroup, Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___demoOne = function(i) {_$refs['__demoOne'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.set.bind(this, 'show', true),
            'type': 'primary',
            'children': 'Show Drawer'
        }),
        _$cc(Drawer, {
            'title': 'Drawer Title',
            'ref': _$ref___demoOne,
            'children': [
                _$cc(Select, {
                    'children': [
                        _$cc(Option, {
                            'value': '1',
                            'children': 'Option 1'
                        }),
                        _$cc(Option, {
                            'value': '2',
                            'children': 'Option 2'
                        })
                    ]
                }),
                _$ct('\n        Drawer Body \n    ')
            ],
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        }, null, _$ref___demoOne)
    ], 4 /* HasNonKeyedChildren */);
};