import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {makeStyles} from './styles';
import {Footer} from '../../components/footer/';
import {Icon} from 'kpc';
import sketch_img from '../../imgs/sketch_transparent.png';
import rectangle from '../../imgs/rectangle.png';
import kingvision_icon from '../../imgs/kingvision_icon.png';
import axure_icon from '../../imgs/axure_icon.png';
import iconFonts from '../../imgs/icon-fonts.png';
import iconSize from '../../imgs/icon-size.png';
import {WorkflowCard} from '../../components/WorkflowCard/';
import {Link} from '../../components/link';
import github_icon from '../../imgs/github_icon.png';
import {getAxure, getSketch, getFigma, getWeights} from './index';

var _$tmp0 = {
    'style': 'padding-left: 14px;'
};
var _$tmp1 = {
    'className': 'icon ion-chevron-right'
};
var _$tmp2 = {
    'bgColor': '#FAFAFA'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const pricipleList = [
        {
            title: 'KingDesign设计原则',
            link: '/docs/design/principle/'
        },
        {
            title: '可访问性设计',
            link: '/docs/design/guide-6/'
        },
        {
            title: '体验度量',
            link: '/docs/design/model/'
        },
        {
            title: '字体和排版',
            link: '/docs/design/guide-4/'
        },
        {
            title: '使用适合间距',
            link: '/docs/design/guide-3/'
        },
    ];
    
    return _$su.call($this, {
        'className': _$cn(makeStyles()),
        'navIndex': 'resource'
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['content'] = function($super) {
                    return [
                        _$ce(2, 'div', (
                            _$ce(2, 'div', (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', '资源', 16 /* HasTextChildren */),
                                    _$ce(2, 'div', '生态设计工具与研发资源，快速支持业务', 16 /* HasTextChildren */)
                                ], 4 /* HasNonKeyedChildren */, 'nav-title')
                            ), 2 /* HasVNodeChildren */, 'content')
                        ), 2 /* HasVNodeChildren */, 'source-nav'),
                        _$ce(2, 'div', (
                            _$ce(2, 'div', [
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', '产品，设计，开发，测试，全链路加速产品开发', 16 /* HasTextChildren */, 'main-title'),
                                    _$ce(2, 'div', '构建专业的企业级数字产品', 16 /* HasTextChildren */, 'secondary-title'),
                                    _$ce(2, 'div', [
                                        _$cc(WorkflowCard, {
                                            'title': '原型设计',
                                            'children': (
                                                _$ce(2, 'div', (
                                                    _$ce(2, 'div', [
                                                        _$ce(2, 'div', '组件库', 16 /* HasTextChildren */, 'card-title'),
                                                        _$ce(2, 'div', [
                                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                'src': axure_icon
                                                            }),
                                                            _$ce(2, 'div', 'Axure组件库', 16 /* HasTextChildren */)
                                                        ], 4 /* HasNonKeyedChildren */, 'card-item', {
                                                            'ev-click': getAxure
                                                        })
                                                    ], 4 /* HasNonKeyedChildren */)
                                                ), 2 /* HasVNodeChildren */, 'card-content-box')
                                            )
                                        }),
                                        _$cc(WorkflowCard, {
                                            'title': 'UI/UE设计',
                                            'children': (
                                                _$ce(2, 'div', [
                                                    _$ce(2, 'div', [
                                                        _$ce(2, 'div', '组件库', 16 /* HasTextChildren */, 'card-title'),
                                                        _$ce(2, 'div', [
                                                            _$ce(2, 'div', (
                                                                _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                    'src': sketch_img
                                                                })
                                                            ), 2 /* HasVNodeChildren */, 'sketch-img-box'),
                                                            _$ce(2, 'div', 'Sketch组件库', 16 /* HasTextChildren */)
                                                        ], 4 /* HasNonKeyedChildren */, 'card-item', {
                                                            'ev-click': getSketch
                                                        }),
                                                        _$ce(2, 'div', [
                                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                'src': rectangle
                                                            }),
                                                            _$ce(2, 'div', 'Figma组件库', 16 /* HasTextChildren */)
                                                        ], 4 /* HasNonKeyedChildren */, 'card-item', {
                                                            'ev-click': getFigma
                                                        })
                                                    ], 4 /* HasNonKeyedChildren */),
                                                    _$ce(2, 'div', [
                                                        _$ce(2, 'div', '更多KD资源', 16 /* HasTextChildren */, 'card-title'),
                                                        _$ce(2, 'div', (
                                                            _$cc(Link, {
                                                                'href': 'http://vision.ksyun.com/#/icon-reposition',
                                                                'children': (
                                                                    _$ce(2, 'div', [
                                                                        _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                            'src': kingvision_icon
                                                                        }),
                                                                        _$ce(2, 'div', 'KingVision素材库', 16 /* HasTextChildren */, null, _$tmp0)
                                                                    ], 4 /* HasNonKeyedChildren */, 'code-resource')
                                                                )
                                                            })
                                                        ), 2 /* HasVNodeChildren */, 'card-item'),
                                                        _$ce(2, 'div', (
                                                            _$cc(Link, {
                                                                'href': 'https://js.design/resourceDetails?id=62c6c0834835c771da15dfe0&source=ksd&plan=web',
                                                                'children': (
                                                                    _$ce(2, 'div', [
                                                                        _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                            'src': iconFonts
                                                                        }),
                                                                        _$ce(2, 'div', '即时设计', 16 /* HasTextChildren */)
                                                                    ], 4 /* HasNonKeyedChildren */, 'code-resource')
                                                                )
                                                            })
                                                        ), 2 /* HasVNodeChildren */, 'card-item')
                                                    ], 4 /* HasNonKeyedChildren */)
                                                ], 4 /* HasNonKeyedChildren */, 'card-content-box')
                                            )
                                        }),
                                        _$cc(WorkflowCard, {
                                            'title': '开发阶段',
                                            'children': (
                                                _$ce(2, 'div', (
                                                    _$ce(2, 'div', [
                                                        _$ce(2, 'div', '组件套件', 16 /* HasTextChildren */, 'card-title'),
                                                        _$ce(2, 'div', (
                                                            _$cc(Link, {
                                                                'href': 'https://github.com/ksc-fe/kpc',
                                                                'children': (
                                                                    _$ce(2, 'div', [
                                                                        _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                            'src': github_icon
                                                                        }),
                                                                        _$ce(2, 'div', '代码资源', 16 /* HasTextChildren */)
                                                                    ], 4 /* HasNonKeyedChildren */, 'code-resource')
                                                                )
                                                            })
                                                        ), 2 /* HasVNodeChildren */, 'card-item')
                                                    ], 4 /* HasNonKeyedChildren */)
                                                ), 2 /* HasVNodeChildren */, 'card-content-box')
                                            )
                                        }),
                                        _$cc(WorkflowCard, {
                                            'title': '测试阶段',
                                            'children': (
                                                _$ce(2, 'div', (
                                                    _$ce(2, 'div', [
                                                        _$ce(2, 'div', '产品可用性度量', 16 /* HasTextChildren */, 'card-title'),
                                                        _$ce(2, 'div', [
                                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                'src': iconSize
                                                            }),
                                                            _$ce(2, 'div', '度量衡工具', 16 /* HasTextChildren */)
                                                        ], 4 /* HasNonKeyedChildren */, 'card-item', {
                                                            'ev-click': getWeights
                                                        })
                                                    ], 4 /* HasNonKeyedChildren */)
                                                ), 2 /* HasVNodeChildren */, 'card-content-box')
                                            )
                                        })
                                    ], 4 /* HasNonKeyedChildren */, 'workflow-box'),
                                    _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'line')
                                ], 4 /* HasNonKeyedChildren */, 'content-top'),
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', '工具', 16 /* HasTextChildren */, 'title'),
                                            _$ce(2, 'div', '更多的设计资源创造更高的产品价值', 16 /* HasTextChildren */)
                                        ], 4 /* HasNonKeyedChildren */),
                                        _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'tool-item process-box'),
                                        _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'tool-item expect-box')
                                    ], 4 /* HasNonKeyedChildren */, 'tool-box'),
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', '原则与方法论', 16 /* HasTextChildren */, 'title'),
                                            _$ce(2, 'div', '以人机界面设计指南为设计核心', 16 /* HasTextChildren */)
                                        ], 4 /* HasNonKeyedChildren */),
                                        _$ce(2, 'div', (
                                            _$ma(pricipleList, function($value, $key) {
                                                return _$ce(2, 'div', [
                                                    _$ce(2, 'span', $value.title, 0 /* UnknownChildren */),
                                                    _$cc(Icon, _$tmp1)
                                                ], 4 /* HasNonKeyedChildren */, 'priciple-item', {
                                                    'ev-click': this.goDoc.bind(this, $value.link)
                                                });
                                            }, $this)
                                        ), 4 /* HasNonKeyedChildren */, 'priciples')
                                    ], 4 /* HasNonKeyedChildren */, 'principle')
                                ], 4 /* HasNonKeyedChildren */, 'content-bottom')
                            ], 4 /* HasNonKeyedChildren */)
                        ), 2 /* HasVNodeChildren */, 'source-content'),
                        _$cc(Footer, _$tmp2)
                    ];
                }),
                (__$blocks['content'] = function($super, data) {
                    var block = $blocks['content'];
                    var callBlock = function() {
                        return _$blocks['content'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};