import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Badge, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Badge, {
            'text': 'new',
            'children': 'Message'
        }),
        _$cc(Badge, {
            'text': 6,
            'children': (
                _$cc(Button, {
                    'children': 'Message'
                })
            )
        }),
        _$cc(Badge, {
            'text': 19,
            'children': (
                _$cc(Button, {
                    'children': 'Message'
                })
            )
        }),
        _$cc(Badge, {
            'text': 100,
            'max': 99,
            'children': (
                _$cc(Button, {
                    'children': 'Message'
                })
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};