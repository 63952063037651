import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tooltip, ButtonGroup, Button, Input} from 'kpc';

var _$tmp0 = {
    'placeholder': 'focus'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(ButtonGroup, {
        'children': [
            _$cc(Tooltip, {
                'content': 'hover',
                'children': (
                    _$cc(Button, {
                        'children': 'hover'
                    })
                )
            }),
            _$cc(Tooltip, {
                'trigger': 'click',
                'content': 'click',
                'children': (
                    _$cc(Button, {
                        'children': 'click'
                    })
                )
            }),
            _$cc(Tooltip, {
                'hoverable': true,
                'content': 'the text can be hovered',
                'children': (
                    _$cc(Button, {
                        'children': 'hoverable'
                    })
                )
            }),
            _$cc(Tooltip, {
                'trigger': 'focus',
                'content': 'foucs',
                'children': (
                    _$cc(Input, _$tmp0)
                )
            })
        ]
    });
};