import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Slider} from 'kpc';

var _$tmp0 = {
    'value': 45,
    'step': 5,
    'points': true,
    'showInput': false
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Slider, _$tmp0),
        _$cc(Slider, {
            'value': [0.3, 0.6],
            'min': 0,
            'max': 1,
            'step': 0.1,
            'range': true,
            'points': true
        }),
        _$cc(Slider, {
            'value': 45,
            'step': 5,
            'showInput': false,
            'points': [0, 35, 95, 100]
        })
    ], 4 /* HasNonKeyedChildren */);
};