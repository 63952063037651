import {
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {getRestProps, eachChildren} from '../utils';
import {Step} from './step';
import {makeStyles} from './styles';
import {context as StepsContext} from './context';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        children, className, value,
        status, type, clickable, vertical,
    } = this.get();
    
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-steps`]: true,
        [className]: className,
        [`${k}-${type}`]: true,
        [`${k}-clickable`]: clickable,
        [`${k}-vertical`]: vertical,
        [makeStyles(k)]: true,
    };
    
    let stepIndex = 0;
    const steps = [];
    eachChildren(children, vNode => {
        if (vNode.tag === Step) {
            vNode.props = {...vNode.props, index: stepIndex};
            steps.push(vNode);
            stepIndex++;
        }
    });
    
    // add width
    /* const width = (100 / stepIndex) + '%';  */
    
    return _$cc(StepsContext.Provider, {
        'value': {
            value,
            status,
            /* width, */
            type,
            onChange: this.changeIndex 
        },
        'children': (
            _$cv('div', {
                'className': _$cn(classNameObj),
                ...getRestProps(this)
            }, steps)
        )
    });
};