import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Upload, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_instance = function(i) {_$refs['instance'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Upload, {
            'ref': _$ref_instance,
            'multiple': true,
            'action': '//fakestoreapi.com/products',
            'autoUpload': false,
            'children': (
                _$cc(Button, {
                    'type': 'primary',
                    'children': '选择文件'
                })
            )
        }, null, _$ref_instance),
        _$cc(Button, {
            'ev-click': this.upload,
            'children': '开始上传'
        })
    ], 4 /* HasNonKeyedChildren */);
};