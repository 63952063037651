import {
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {linkEvent} from 'intact';
import {getRestProps, addStyle} from '../utils';
import {context as StepsContext} from './context';
import {Icon} from '../icon';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, title, index, className, style} = this.get();
    const { k } = this.config;
    
    return _$cc(StepsContext.Consumer, {
        'children': ({value, status, onChange, type}) => {
            const selected = value === index;
            const error = selected && status === 'error';
            const done = value > index;
            const classNameObj = {
                [`${k}-step`]: true,
                [`${k}-active`]: selected,
                [`${k}-done`]: done,
                [`${k}-error`]: error,
                [className]: className,
            };
        
            return _$cv('div', {
                ...getRestProps(this),
                'className': _$cn(classNameObj),
                'style': style
            }, [
                _$ce(2, 'div', [
                    _$ce(2, 'div', (
                        (error && type !== 'simple') ?
                            _$cc(Icon, {
                                'className': _$cn(`${k}-icon-close-bold`),
                                'color': 'danger'
                            }) :
                            done && (type === 'line' || type === 'line-compact') ?
                                _$cc(Icon, {
                                    'className': _$cn(`${k}-icon-check-bold`),
                                    'color': 'primary'
                                }) :
                                type !== 'simple' ?
                                    _$ce(2, 'span', index + 1, 0 /* UnknownChildren */) :
                                    undefined
                    ), 0 /* UnknownChildren */, _$cn(`${k}-step-mark`)),
                    _$ce(2, 'div', [
                        _$ce(2, 'div', title, 0 /* UnknownChildren */, _$cn(`${k}-step-title`)),
                        _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-step-content`))
                    ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-step-main`))
                ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-step-wrapper`), {
                    'ev-click': linkEvent(index, onChange)
                }),
                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-step-tail`))
            ])
        }
    });
};