import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    noop as _$no,
    className as _$cn,
} from 'intact';
import {Transition} from 'intact';
import {makeMenuStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {value, trigger, container} = this.dropdown.get();
    const {children, className} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-dropdown-menu`]: true,
        [className]: className,
        [$props.className]: $props.className,
        [makeMenuStyles(k)]: true,
    };
    
    const transition = $props.transition || {css: false, ...this.transition};
    
    return _$cc(Transition, {
        'show': 'value' in $props ? $props.value : !!value,
        'appear': true,
        ...transition,
        'children': (
            _$ce(2, 'div', (
                (
                    (_$blocks['children'] = function($super) {
                        return children;
                    }),
                    (__$blocks['children'] = function($super, data) {
                        var block = $blocks['children'];
                        var callBlock = function() {
                            return _$blocks['children'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['children'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(classNameObj), {
                'ev-mouseenter': this.onMouseEnter,
                'ev-mouseleave': trigger === 'hover' ? this.onMouseLeave : null
            }, null, this.elementRef)
        )
    });
};