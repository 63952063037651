import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Slider} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Slider, {
            'min': 50,
            'max': 500,
            'showTooltip': true,
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }),
        _$cc(Slider, {
            'range': true,
            'showTooltip': true,
            'ev-$model:value': function($v) {
                $this.set('values', $v);
            },
            'value': $this.get('values')
        }),
        _$cc(Slider, {
            'min': 1,
            'max': 12,
            'showInput': false,
            'marks': this.get('marks'),
            'showTooltip': true,
            'always': true,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['tooltip'] = function($super, value) {
                            return (
                                (value > 9) ?
                                    _$ce(2, 'span', [
                                        _$ct('满'),
                                        value - 9,
                                        _$ct('年，优惠'),
                                        (value - 9) * 2,
                                        _$ct('个月')
                                    ], 0 /* UnknownChildren */) :
                                    undefined
                            );
                        }),
                        (__$blocks['tooltip'] = function($super, data) {
                            var block = $blocks['tooltip'];
                            var callBlock = function() {
                                return _$blocks['tooltip'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        }),
        _$cc(Slider, {
            'min': 50,
            'max': 500,
            'showTooltip': true,
            'always': true,
            'tooltipProps': {'theme': 'light', 'size': 'small'},
            'ev-$model:value': function($v) {
                $this.set('value3', $v);
            },
            'value': $this.get('value3')
        })
    ], 4 /* HasNonKeyedChildren */);
};