import {
    createElementVNode as _$ce,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    noop as _$no,
    createVNode as _$cv,
    createTextVNode as _$ct,
} from 'intact';
import {Icon} from '../icon';
import {addStyle, isTextBlock, getRestProps, stopPropagation} from '../utils';
import {makeStyles} from './styles';
import {noop, isNullOrUndefined, isStringOrNumber} from 'intact-shared';
import {Wave} from '../wave';
import {context as ErrorContext} from '../form/useError';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        className, style, type, value, defaultValue, placeholder,
        clearable, disabled, size, rows, autoWidth, fluid,
        width, stackClearIcon, frozenOnInput, readonly, inline,
        waveDisabled, resize, flat, showCount, maxlength,
        
        'ev-click': click,
        'ev-mounseenter': mouseenter,
        'ev-mouseleave': mouseleave,
    
        ...rest
    } = this.get();
    
    const {
        inputing: {value: inputing},
        originalValue: {value: originalValue},
        startInput, onInput, endInput
    } = this.frozen;
    const { isFocus, focusInputOnClick }= this.focusHook;
    
    const isNotAutoRows = isStringOrNumber(rows) && rows !== 'auto';
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-input`]: true,
        [`${k}-${size}`]: size !== 'default',
        [`${k}-group`]: $blocks.prepend || $blocks.append,
        [`${k}-disabled`]: disabled,
        [`${k}-clearable`]: clearable,
        [`${k}-auto-width`]: autoWidth,
        [`${k}-fluid`]: fluid,
        [`${k}-stack-clear`]: stackClearIcon,
        [`${k}-inline`]: inline,
        [`${k}-flat`]: flat,
        [`${k}-type-textarea`]: type === 'textarea',
        [`${k}-focus`]: isFocus.value,
        [`${k}-resize-${resize}`]: type === 'textarea' && isNotAutoRows,
        [`${k}-resize-none`]: type === 'textarea' && !isNotAutoRows,
        [className]: className,
        [makeStyles(k)]: true,
    }
    
    const {
        isShow: isShowPassword,
        toggleShow: toggleShowPassword,
        type: showPasswordType,
        showIcon: showPasswordIcon,
    } = this.showPassword;
    
    const {fakeRef, width: {value: fakeWidth}} = this.autoWidth;
    const height = this.autoRows;
    
    const inputValue = frozenOnInput && inputing ? originalValue : value;
    const hasInputValue = !isNullOrUndefined(inputValue);
    const hasValue = hasInputValue && inputValue !== '';
    const inputProps = {
        ...getRestProps(this, rest),
        'ev-focus': startInput,
        'ev-blur': endInput,
        'ev-input': onInput,
        defaultValue,
        readOnly: readonly,
        placeholder,
        disabled,
        maxLength: maxlength,
        ref: this.inputRef,
        style: autoWidth && fakeWidth ? {width: fakeWidth + 'px'} : undefined,
    };
    // if we pass value to input element, the input is controlled and the
    // defaultValue does not work
    if (hasInputValue) {
        inputProps.value = inputValue;
    }
    return _$ce(2, 'div', [
        do {
            if ($blocks.prepend) {
                // if the children of this block is text node
                // we add a padding to the wrapper node
                // so we handle this block manually
                const children = $blocks.prepend(noop);
                _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn({
                    [`${k}-input-prepend`]: true, 
                    [`${k}-input-padding`]: isTextBlock(children)
                }))
            }
        },
        _$cc(ErrorContext.Consumer, {
            'defaultValue': false,
            'children': isInvalid => {
                return _$cc(Wave, {
                    'disabled': waveDisabled || disabled || isInvalid,
                    'inset': '-2px',
                    'children': (
                        _$ce(2, 'div', [
                            ($blocks.prefix) ?
                                _$ce(2, 'div', (
                                    (
                                        (_$blocks['prefix'] = function($super) {
                                            return null;
                                        }),
                                        (__$blocks['prefix'] = function($super, data) {
                                            var block = $blocks['prefix'];
                                            var callBlock = function() {
                                                return _$blocks['prefix'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        }),
                                        __$blocks['prefix'](_$no)
                                    )
                                ), 0 /* UnknownChildren */, _$cn(`${k}-input-prefix`)) :
                                undefined,
                            (type !== 'textarea') ?
                                _$cv('input', {
                                    ...inputProps,
                                    'className': _$cn(`${k}-input-inner`),
                                    'type': showPasswordType.value
                                }) :
                                _$cv('textarea', {
                                    ...inputProps,
                                    'className': _$cn(`${k}-input-inner ${k}-textarea`),
                                    'rows': isNotAutoRows ? rows : 1,
                                    'style': height.value ? addStyle(inputProps.style, { height: height.value + 'px' }) : inputProps.style
                                }),
                            ($blocks.suffix || clearable && !disabled || showPasswordIcon.value || showCount) ?
                                _$ce(2, 'div', [
                                    (showPasswordIcon.value) ?
                                        [
                                            _$cc(Icon, {
                                                'hoverable': true,
                                                'className': _$cn({
                                                    [`${k}-input-show-password`]: true,
                                                    [`${k}-icon-hidden`]: !isShowPassword.value,
                                                    [`${k}-icon-visible`]: isShowPassword.value,
                                                }),
                                                'ev-click': toggleShowPassword
                                            }),
                                            ' '
                                        ] :
                                        undefined,
                                    (clearable && !disabled) ?
                                        _$cc(Icon, {
                                            'className': _$cn({
                                                [`${k}-input-clear ${k}-icon-error-fill`]: true, 
                                                [`${k}-input-show`]: hasValue,
                                            }),
                                            'ev-click': this.clear,
                                            'hoverable': true
                                        }) :
                                        undefined,
                                    ' ',
                                    (showCount) ?
                                        _$ce(2, 'span', [
                                            hasValue ? value.length : 0,
                                            (maxlength) ?
                                                _$ce(2, 'span', [
                                                    _$ct(' / '),
                                                    maxlength
                                                ], 0 /* UnknownChildren */) :
                                                undefined
                                        ], 0 /* UnknownChildren */, _$cn(`${k}-input-count`)) :
                                        undefined,
                                    (
                                        (_$blocks['suffix'] = function($super) {
                                            return null;
                                        }),
                                        (__$blocks['suffix'] = function($super, data) {
                                            var block = $blocks['suffix'];
                                            var callBlock = function() {
                                                return _$blocks['suffix'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        }),
                                        __$blocks['suffix'](_$no)
                                    )
                                ], 0 /* UnknownChildren */, _$cn(`${k}-input-suffix`), {
                                    'ev-click': stopPropagation
                                }) :
                                undefined,
                            (autoWidth) ?
                                _$ce(2, 'div', (
                                    _$ce(2, 'span', !hasValue ? (hasInputValue || !defaultValue ? placeholder : defaultValue) : inputValue, 0 /* UnknownChildren */, null, null, null, fakeRef)
                                ), 2 /* HasVNodeChildren */, _$cn(`${k}-input-fake`)) :
                                undefined
                        ], 0 /* UnknownChildren */, _$cn(`${k}-input-wrapper`), {
                            'ev-click': focusInputOnClick
                        })
                    )
                })
            }
        }),
        do {
            if ($blocks.append) {
                const children = $blocks.append(noop);
                _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn({
                    [`${k}-input-append`]: true, 
                    [`${k}-input-padding`]: isTextBlock(children)
                }))
            }
        }
    ], 0 /* UnknownChildren */, _$cn(classNameObj), {
        'style': width != null ? addStyle(style, {width: `${width}px`}) : style,
        'ev-click': click,
        'ev-mouseenter': mouseenter,
        'ev-mouseleave': mouseleave
    });
};