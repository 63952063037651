export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind, TreeProps, TreeNode} from 'kpc';

interface Props {
    data: NonNullable<TreeProps['data']>
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    label: 'parent',
                    children: [
                        {label: 'first child', children: []},
                        {label: 'last child', children: [], loaded: true},
                    ],
                },
            ],
        }
    }

    @bind
    loadData(node: TreeNode<never>) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                node.data.children = [
                    {label: 'child1', children: []},
                    {label: 'child2'},
                ];
                this.set('data', [...this.get('data')]);

                resolve();
            }, 1000);
        });
    }
}