import {
    createUnknownComponentVNode as _$cc,
    noop as _$no,
    createElementVNode as _$ce,
    className as _$cn,
    createTextVNode as _$ct,
    createVNode as _$cv,
} from 'intact';
import {linkEvent} from 'intact';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {Icon} from '../icon';

var _$tmp0 = {
    'className': 'ion-android-star'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {className, count, disabled, half} = this.get();
    const {activeValue, onClick, onMouseEnter, onMouseLeaveComponent} = this.activeValue;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-rate`]: true,
        [`${k}-disabled`]: disabled,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    const icon = (
        (_$blocks['icon'] = function($super) {
            return (
                _$cc(Icon, _$tmp0)
            );
        }),
        (__$blocks['icon'] = function($super, data) {
            var block = $blocks['icon'];
            var callBlock = function() {
                return _$blocks['icon'].call($this, $super, data);
            };
            return block ?
                block.call($this, callBlock, data) :
                callBlock();
        }),
        __$blocks['icon'](_$no)
    )
    const rateIcon = function(className, step) {
        return (
            _$ce(2, 'div', icon, 0 /* UnknownChildren */, _$cn(className), {
                'ev-click': linkEvent(step, onClick),
                'ev-mouseenter': linkEvent(step, onMouseEnter)
            })
        );
    };
    
    const items = [];
    for(let i = 0; i < count; i++) {
        const className = activeValue.value >= i + 1 ? 
            [`${k}-full`] : activeValue.value === i + 0.5 ? 
                [`${k}-half`] : null;
        const halfStep = half ? i + 0.5 : i + 1;
        const item = (
            _$ce(2, 'div', [
                rateIcon(`${k}-rate-first`, halfStep),
                _$ct('\n            '),
                rateIcon(`${k}-rate-second`, i + 1)
            ], 0 /* UnknownChildren */, _$cn({[`${k}-rate-item`]: true, [className]: className}))
        );
        items.push(item);
    }
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'ev-mouseleave': onMouseLeaveComponent
    }, items);
};