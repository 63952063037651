export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, TableRowKey} from 'kpc';

interface Props {
    expandedKeys: TableRowKey[]
    data: DataItem[]
}

type DataItem = {
    name: string
    email: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            expandedKeys: [],
            data: [
                {name: 'Javey', email: 'jiawei23716@sina.com'}, 
                {name: 'Jiawei', email: 'zoujiawei@gmail.com'}
            ],
        } as Props;
    }

    @bind
    toggleExpand(data: DataItem, index: number) {
        const expandedKeys = this.get('expandedKeys').slice(0);
        const i = expandedKeys.indexOf(index);
        if (i > -1) {
            expandedKeys.splice(i, 1);
        } else {
            expandedKeys.push(index);
        }
        this.set('expandedKeys', expandedKeys);
    }
}