import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Affix, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Affix, {
            'top': 87,
            'children': (
                _$cc(Button, {
                    'children': 'fix at 87px from the top'
                })
            )
        }),
        _$cc(Affix, {
            'bottom': this.get('bottom'),
            'children': (
                _$cc(Button, {
                    'children': 'fix at the bottom'
                })
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};