import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Descriptions, DescriptionItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Descriptions, {
            'title': '基础信息',
            'vertical': true,
            'children': [
                _$cc(DescriptionItem, {
                    'label': '测试1',
                    'children': '测试1'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试2',
                    'children': '测试2'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试3',
                    'children': '测试3'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试4',
                    'children': '测试4'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试5',
                    'children': '测试5'
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};