import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Transfer, Input, Select, Option, Tag} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Transfer, {
            'data': this.get('data'),
            'filterable': true,
            'keyName': 'name',
            'ref': _$ref___test,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['filter'] = function($super, type) {
                            return (
                                (type === 'left') ?
                                    _$cc(Input, {
                                        'size': 'small',
                                        'placeholder': '请输入',
                                        'clearable': true,
                                        '$blocks': function($blocks) {
                                            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                            return (
                                                (
                                                    (_$blocks['prepend'] = function($super) {
                                                        return (
                                                            _$cc(Select, {
                                                                'width': '100',
                                                                'size': 'small',
                                                                'children': [
                                                                    _$cc(Option, {
                                                                        'value': 'all',
                                                                        'children': '全部策略'
                                                                    }),
                                                                    _$cc(Option, {
                                                                        'value': 'system',
                                                                        'children': '系统策略'
                                                                    }),
                                                                    _$cc(Option, {
                                                                        'value': 'common',
                                                                        'children': '通用策略'
                                                                    })
                                                                ],
                                                                'ev-$model:value': function($v) {
                                                                    $this.set('policy', $v);
                                                                },
                                                                'value': $this.get('policy')
                                                            })
                                                        );
                                                    }),
                                                    (__$blocks['prepend'] = function($super, data) {
                                                        var block = $blocks['prepend'];
                                                        var callBlock = function() {
                                                            return _$blocks['prepend'].call($this, $super, data);
                                                        };
                                                        return block ?
                                                            block.call($this, callBlock, data) :
                                                            callBlock();
                                                    })
                                                ),
                                                __$blocks
                                            );
                                        }.call($this, _$em),
                                        'ev-$model:value': function($v) {
                                            $this.set('keywords', $v);
                                        },
                                        'value': $this.get('keywords')
                                    }) :
                                    undefined
                            );
                        }),
                        (__$blocks['filter'] = function($super, data) {
                            var block = $blocks['filter'];
                            var callBlock = function() {
                                return _$blocks['filter'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['label'] = function($super, [data, index, type]) {
                            return (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', [
                                        data.name,
                                        (data.policy === 'system') ?
                                            _$cc(Tag, {
                                                'type': 'warning',
                                                'size': 'mini',
                                                'children': '系统策略'
                                            }) :
                                            undefined
                                    ], 0 /* UnknownChildren */),
                                    _$ce(2, 'p', [
                                        data.members,
                                        _$ct('人 / '),
                                        data.desc
                                    ], 0 /* UnknownChildren */)
                                ], 4 /* HasNonKeyedChildren */)
                            );
                        }),
                        (__$blocks['label'] = function($super, data) {
                            var block = $blocks['label'];
                            var callBlock = function() {
                                return _$blocks['label'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }, null, _$ref___test),
        _$ce(2, 'p', [
            _$ct('You selected: '),
            JSON.stringify(this.get('value'))
        ], 0 /* UnknownChildren */)
    ], 4 /* HasNonKeyedChildren */);
};