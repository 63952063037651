import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Form, FormItem, Input, Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_form = function(i) {_$refs['form'] = i};
    
    return _$cc(Form, {
        'ref': _$ref_form,
        'children': (
            _$cc(FormItem, {
                'label': '姓名',
                'children': (
                    _$cc(Row, {
                        'gutter': '16',
                        'children': [
                            _$cc(Col, {
                                'span': '12',
                                'children': (
                                    _$cc(FormItem, {
                                        'hideLabel': true,
                                        'value': this.get('firstName'),
                                        'rules': {
                                            // 该规则取值，随着lastName的存在与否而改变
                                            required: this.get('lastName')
                                        },
                                        'children': (
                                            _$cc(Input, {
                                                'ev-$model:value': function($v) {
                                                    $this.set('firstName', $v);
                                                },
                                                'value': $this.get('firstName')
                                            })
                                        )
                                    })
                                )
                            }),
                            _$cc(Col, {
                                'span': '12',
                                'children': (
                                    _$cc(FormItem, {
                                        'hideLabel': true,
                                        'value': this.get("lastName"),
                                        'rules': {
                                            // 该规则取值，随着firstName的存在与否而改变
                                            required: this.get('firstName')
                                        },
                                        'children': (
                                            _$cc(Input, {
                                                'ev-$model:value': function($v) {
                                                    $this.set('lastName', $v);
                                                },
                                                'value': $this.get('lastName')
                                            })
                                        )
                                    })
                                )
                            })
                        ]
                    })
                )
            })
        )
    }, null, _$ref_form);
};