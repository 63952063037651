import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Timepicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Timepicker, {
            'min': '01:34:56',
            'max': '15:02:59',
            'ev-$model:value': function($v) {
                $this.set('time', $v);
            },
            'value': $this.get('time')
        }),
        _$ct('\n    You selected: '),
        this.get('time'),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Timepicker, {
            'multiple': true,
            'clearable': true,
            'ev-$model:value': function($v) {
                $this.set('timeArray', $v);
            },
            'value': $this.get('timeArray')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('timeArray'))
    ], 0 /* UnknownChildren */);
};