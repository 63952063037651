import {
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$ma(this.get('types'), function($value, $key) {
            return _$cc(Tip, {
                'type': $value,
                'children': $value
            });
        }, $this)
    ), 4 /* HasNonKeyedChildren */);
};