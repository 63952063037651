import {
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Icon} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ma(['default', 'primary', 'warning', 'danger', 'success', 'red', '#0000ea', 'rgb(183, 18, 193)'], function($value, $key) {
            return _$cc(Icon, {
                'className': 'ion-happy-outline',
                'size': 'large',
                'color': $value
            });
        }, $this),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$ma(['default', 'primary', 'warning', 'danger', 'success', 'red', '#0000ea', 'rgb(183, 18, 193)'], function($value, $key) {
            return _$cc(Icon, {
                'className': 'ion-happy-outline',
                'size': 'large',
                'color': $value,
                'hoverable': true
            });
        }, $this)
    ], 0 /* UnknownChildren */);
};