import {
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {getRestProps} from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, style} = this.get();
    const { k } = this.config;
    
    return _$cv('div', {
        'className': _$cn(this.getClassNames()),
        'style': this.getStyles ? this.getStyles(k) : style,
        ...getRestProps(this)
    }, children);
};