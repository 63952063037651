import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';
import {Transition} from 'intact';
import {DropdownItem} from '../dropdown';
import {isNullOrUndefined} from 'intact-shared';
import {isEmptyChildren} from '../utils';
import {Icon} from '../icon';
import {Checkbox} from '../checkbox';
import {context} from './useSearchable';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, className, disabled, label} = this.get();
    const {multiple, searchable} = this.select.get();
    const { k } = this.config;
    
    const isActive = this.isActive();
    const classNameObj = {
        [`${k}-select-option`]: true,
        [className]: className,
        [`${k}-active`]: isActive,
    };
    
    let _children = isEmptyChildren(children) ? label : children;
    if (searchable && multiple) {
        const {Consumer} = context;
        const children = _children;
        _children = (
            _$cc(Consumer, {
                'children': value => (
                    _$cc(Checkbox, {
                        'value': value.value,
                        'ev-$change:value': value.set,
                        'trueValue': this.get('value'),
                        'disabled': disabled,
                        'children': children
                    })
                )
            })
        );
    }
    
    return _$cc(DropdownItem, {
        'className': _$cn(classNameObj),
        'ev-select': searchable && multiple ? null : this.onSelect,
        'disabled': disabled,
        'hideOnSelect': !multiple,
        'children': [
            _children,
            (!searchable) ?
                _$cc(Transition, {
                    'name': 'k-scale',
                    'children': (
                        (multiple && isActive) ?
                            _$cc(Icon, {
                                'className': _$cn(`${k}-select-checkmark ion-ios-checkmark-empty`),
                                'color': 'primary'
                            }) :
                            undefined
                    )
                }) :
                undefined
        ]
    });
};