import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Dropdown, DropdownMenu, DropdownItem, Checkbox, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Dropdown, {
            'children': [
                _$cc(Button, {
                    'children': [
                        _$ct('More'),
                        _$cc(Icon, _$tmp0)
                    ]
                }),
                _$cc(DropdownMenu, {
                    'className': 'checkbox-menu',
                    'children': [
                        _$cc(DropdownItem, {
                            'hideOnSelect': false,
                            'children': (
                                _$cc(Checkbox, {
                                    'trueValue': '1',
                                    'children': 'item 1',
                                    'ev-$model:value': function($v) {
                                        $this.set('checked', $v);
                                    },
                                    'value': $this.get('checked')
                                })
                            )
                        }),
                        _$cc(DropdownItem, {
                            'hideOnSelect': false,
                            'children': (
                                _$cc(Checkbox, {
                                    'trueValue': '2',
                                    'children': 'item 2',
                                    'ev-$model:value': function($v) {
                                        $this.set('checked', $v);
                                    },
                                    'value': $this.get('checked')
                                })
                            )
                        }),
                        _$cc(DropdownItem, {
                            'hideOnSelect': false,
                            'children': (
                                _$cc(Checkbox, {
                                    'trueValue': '3',
                                    'children': 'item 3',
                                    'ev-$model:value': function($v) {
                                        $this.set('checked', $v);
                                    },
                                    'value': $this.get('checked')
                                })
                            )
                        })
                    ]
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};