import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {
    Form, FormItem,
    Input,
    Select, Option,
    Checkbox,
    Radio,
    ButtonGroup, Button,
    Switch,
    Slider,
    Datepicker,
} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_form = function(i) {_$refs['form'] = i};
    
    return _$cc(Form, {
        'ev-submit': this.submit,
        'ref': _$ref_form,
        'labelWidth': '200',
        'children': [
            _$cc(FormItem, {
                'label': 'Input',
                'value': this.get('model.input'),
                'rules': {required: true},
                'children': (
                    _$cc(Input, {
                        'ev-$model:value': function($v) {
                            $this.set('model.input', $v);
                        },
                        'value': $this.get('model.input')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Select',
                'value': this.get('model.select'),
                'rules': {required: true},
                'messages': {required: '必须选择'},
                'children': (
                    _$cc(Select, {
                        'children': [
                            _$cc(Option, {
                                'value': 'Javascript',
                                'children': 'Javascript'
                            }),
                            _$cc(Option, {
                                'value': 'PHP',
                                'children': 'PHP'
                            }),
                            _$cc(Option, {
                                'value': 'C++',
                                'children': 'C++'
                            })
                        ],
                        'ev-$model:value': function($v) {
                            $this.set('model.select', $v);
                        },
                        'value': $this.get('model.select')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Checkbox',
                'value': this.get('model.checkbox'),
                'rules': {required: true, maxLength: 2},
                'messages': {required: '必须选择'},
                'children': [
                    _$cc(Checkbox, {
                        'trueValue': 'Javascript',
                        'children': 'Javascript',
                        'ev-$model:value': function($v) {
                            $this.set('model.checkbox', $v);
                        },
                        'value': $this.get('model.checkbox')
                    }),
                    _$cc(Checkbox, {
                        'trueValue': 'PHP',
                        'children': 'PHP',
                        'ev-$model:value': function($v) {
                            $this.set('model.checkbox', $v);
                        },
                        'value': $this.get('model.checkbox')
                    }),
                    _$cc(Checkbox, {
                        'trueValue': 'C++',
                        'children': 'C++',
                        'ev-$model:value': function($v) {
                            $this.set('model.checkbox', $v);
                        },
                        'value': $this.get('model.checkbox')
                    })
                ]
            }),
            _$cc(FormItem, {
                'label': 'Radio',
                'value': this.get('model.radio'),
                'rules': {required: true},
                'messages': {required: '必须选择'},
                'children': [
                    _$cc(Radio, {
                        'trueValue': 'Javascript',
                        'children': 'Javascript',
                        'ev-$model:value': function($v) {
                            $this.set('model.radio', $v);
                        },
                        'value': $this.get('model.radio')
                    }),
                    _$cc(Radio, {
                        'trueValue': 'PHP',
                        'children': 'PHP',
                        'ev-$model:value': function($v) {
                            $this.set('model.radio', $v);
                        },
                        'value': $this.get('model.radio')
                    }),
                    _$cc(Radio, {
                        'trueValue': 'C++',
                        'children': 'C++',
                        'ev-$model:value': function($v) {
                            $this.set('model.radio', $v);
                        },
                        'value': $this.get('model.radio')
                    })
                ]
            }),
            _$cc(FormItem, {
                'label': 'Radio ButtonGroup',
                'value': this.get("model.buttonGroup"),
                'rules': {required: true},
                'messages': {required: '必须选择'},
                'children': (
                    _$cc(ButtonGroup, {
                        'checkType': 'radio',
                        'children': [
                            _$cc(Button, {
                                'value': 'Javascript',
                                'children': 'Javascript'
                            }),
                            _$cc(Button, {
                                'value': 'PHP',
                                'children': 'PHP'
                            }),
                            _$cc(Button, {
                                'value': 'C++',
                                'children': 'C++'
                            })
                        ],
                        'ev-$model:value': function($v) {
                            $this.set('model.buttonGroup', $v);
                        },
                        'value': $this.get('model.buttonGroup')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Switch',
                'value': this.get("model.switch"),
                'children': (
                    _$cc(Switch, {
                        'ev-$model:value': function($v) {
                            $this.set('model.switch', $v);
                        },
                        'value': $this.get('model.switch')
                    })
                )
            }),
            _$cc(FormItem, {
                'value': this.get("model.slider"),
                'rules': {required: true, min: 1},
                'label': 'Slider',
                'children': (
                    _$cc(Slider, {
                        'showInput': false,
                        'ev-$model:value': function($v) {
                            $this.set('model.slider', $v);
                        },
                        'value': $this.get('model.slider')
                    })
                )
            }),
            _$cc(FormItem, {
                'value': this.get("model.date"),
                'rules': {required: true},
                'label': 'Datepicker',
                'children': (
                    _$cc(Datepicker, {
                        'ev-$model:value': function($v) {
                            $this.set('model.date', $v);
                        },
                        'value': $this.get('model.date')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Textarea',
                'value': this.get("model.textarea"),
                'rules': {required: true},
                'children': (
                    _$cc(Input, {
                        'type': 'textarea',
                        'ev-$model:value': function($v) {
                            $this.set('model.textarea', $v);
                        },
                        'value': $this.get('model.textarea')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Password',
                'value': this.get("model.password"),
                'rules': {required: true},
                'children': (
                    _$cc(Input, {
                        'type': 'password',
                        'ev-$model:value': function($v) {
                            $this.set('model.password', $v);
                        },
                        'value': $this.get('model.password')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Confirm Password',
                'value': this.get("model.confirmPassword"),
                'rules': {required: true, equal: this.get('model.password')},
                'children': (
                    _$cc(Input, {
                        'type': 'password',
                        'ev-$model:value': function($v) {
                            $this.set('model.confirmPassword', $v);
                        },
                        'value': $this.get('model.confirmPassword')
                    })
                )
            }),
            _$cc(FormItem, {
                'children': [
                    _$cc(Button, {
                        'type': 'primary',
                        'htmlType': 'submit',
                        'ev-click': this.handleSubmit,
                        'children': '提交'
                    }),
                    _$cc(Button, {
                        'style': 'margin-left: 8px',
                        'ev-click': this.reset,
                        'children': '重置'
                    })
                ]
            })
        ]
    }, null, _$ref_form);
};