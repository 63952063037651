import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    map as _$ma,
    createTextVNode as _$ct,
} from 'intact';
import {Transfer, Checkbox} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Transfer, {
            'enableAdd': this.enableAdd,
            'enableRemove': this.enableRemove,
            'ev-add': this.onAdd,
            'ev-remove': this.onRemove,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['header'] = function($super, type) {
                            return (
                                (type === 'left') ?
                                    _$ce(2, 'div', '未分配', 16 /* HasTextChildren */) :
                                    _$ce(2, 'div', '标准双人间', 16 /* HasTextChildren */)
                            );
                        }),
                        (__$blocks['header'] = function($super, data) {
                            var block = $blocks['header'];
                            var callBlock = function() {
                                return _$blocks['header'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['list'] = function($super, type) {
                            return (
                                (type === 'left') ?
                                    (
                                        _$ma(this.get('users'), function($value, $key) {
                                            return _$ce(2, 'div', (
                                                _$cc(Checkbox, {
                                                    'trueValue': $value,
                                                    'disabled': !!$value.room,
                                                    'children': [
                                                        $value.name,
                                                        ($value.room) ?
                                                            _$ce(2, 'span', [
                                                                _$ct('（'),
                                                                $value.room.name,
                                                                _$ct('）')
                                                            ], 0 /* UnknownChildren */) :
                                                            undefined
                                                    ],
                                                    'ev-$model:value': function($v) {
                                                        $this.set('checkedUsers', $v);
                                                    },
                                                    'value': $this.get('checkedUsers')
                                                })
                                            ), 2 /* HasVNodeChildren */, 'k-transfer-item');
                                        }, $this)
                                    ) :
                                    (
                                        _$ma(this.get('rooms'), function($value, $key) {
                                            return _$ce(2, 'div', (
                                                _$cc(Checkbox, {
                                                    'trueValue': $value,
                                                    'children': [
                                                        $value.name,
                                                        _$ce(2, 'span', [
                                                            _$ct('（当前人数：'),
                                                            $value.users.length,
                                                            _$ct('）')
                                                        ], 0 /* UnknownChildren */)
                                                    ],
                                                    'ev-$model:value': function($v) {
                                                        $this.set('checkedRooms', $v);
                                                    },
                                                    'value': $this.get('checkedRooms')
                                                })
                                            ), 2 /* HasVNodeChildren */, 'k-transfer-item');
                                        }, $this)
                                    )
                            );
                        }),
                        (__$blocks['list'] = function($super, data) {
                            var block = $blocks['list'];
                            var callBlock = function() {
                                return _$blocks['list'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ), 2 /* HasVNodeChildren */);
};