import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Type'
};
var _$tmp1 = {
    'key': 'b',
    'title': 'Value'
};
var _$tmp2 = {
    'key': 'a',
    'title': 'Type'
};
var _$tmp3 = {
    'key': 'b',
    'title': 'Value'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data1'),
            'type': 'border',
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        }),
        _$cc(Table, {
            'data': this.get('data2'),
            'type': 'grid',
            'children': [
                _$cc(TableColumn, _$tmp2, 'a'),
                _$cc(TableColumn, _$tmp3, 'b')
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};