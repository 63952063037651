import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
} from 'intact';
import {Collapse, CollapseItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Collapse, {
        'accordion': true,
        'children': [
            _$cc(CollapseItem, {
                'title': '设计语言',
                'children': [
                    _$ce(2, 'p', '语言是合作的基础。在团队中工作，没有共同的语言，一群人不能共同有效地合作。同理，因为每个人都会有不同的心智模式去达成各自的目的，如果没有共同的设计语言，对界面元素的共同理解，那么我们很难通过协作输出协调一致并具备高可用性的产品。', 16 /* HasTextChildren */),
                    _$ce(2, 'p', '拥有通用语言意味着我们有相同的方法来命名界面元素和定义设计模式，或者在设计文件和前端框架中使用相同的名称。通用设计语言让团队成员达成了相互理解，基于共同的理解，参与产品创建的每个人都知道这个元素是什么: 它的名称和目的, 为什么这样设计, 以及如何及何时使用它。', 16 /* HasTextChildren */),
                    _$ce(2, 'p', '当设计语言是通用知识时，它就成为一个强大的设计和协作工具，产品创建过程中便可停止专注于界面样式本身，而更多地关注用户。', 16 /* HasTextChildren */)
                ]
            }),
            _$cc(CollapseItem, {
                'title': 'KDL的价值',
                'children': (
                    _$ce(2, 'ol', [
                        _$ce(2, 'li', '节省设计和构建模块的时间；', 16 /* HasTextChildren */),
                        _$ce(2, 'li', '网站变更时节省时间，复用的模式更新时将在任何使用的地方自动更新，重复工作浪费的时间越少，就有 更多时间做有价值的事；', 16 /* HasTextChildren */),
                        _$ce(2, 'li', '更快的发布产品，现成的模式库缩短了新功能设计研发上线时间；', 16 /* HasTextChildren */),
                        _$ce(2, 'li', '品牌统一性、视觉一致性、提升团队协作效率，不用每次重新创造。', 16 /* HasTextChildren */)
                    ], 4 /* HasNonKeyedChildren */)
                )
            }),
            _$cc(CollapseItem, {
                'title': '设计原则',
                'children': '\n        原则顺序必须按照给定的顺序执行。也就是说，美不可以建立在清晰和效率之上，效率也不可以妥协于一致性之下，清晰永远都是第一优先级。在制定任何设计决策时都应按照设计原则，考虑优先级。\n        设计由设计者的选择而决定，设计原则为设计者提供了选择依据，从众多存在矛盾或价值冲突的设计决策中找到平衡。\n    '
            })
        ],
        'ev-$model:value': function($v) {
            $this.set('value', $v);
        },
        'value': $this.get('value')
    });
};