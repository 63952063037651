export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    text?: string
    text2?: string
    text3?: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            text: 'editable text auto',
            text2: 'editable text for 5 rows',
            text3: 'editable text for many rows',
        };
    }
}