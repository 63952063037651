import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    className as _$cn,
} from 'intact';
import {getClassAndStyleForFixed} from './useFixedColumns';
import {get, noop} from 'intact-shared';
import {Button} from '../button';
import {Icon} from '../icon';
import {getTextByChildren} from '../utils';
import {Ellipsis} from '../ellipsis';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        props, rowIndex, columnIndex, offset,
        data, checkType, indent, grid,
        colspan, rowspan, onClickArrow, hasChildren, loaded
    } = this.get();
    const { k } = this.config;
    
    const blocks = props.$blocks; 
    let children = get(data, props.key);
    if (blocks) {
        const template = blocks.template || blocks.default;
        if (template) {
            children = template(noop, [data, rowIndex]);
        }
    }
    
    if (props.ellipsis) {
        children = _$cc(Ellipsis, {
            'children': children
        })
    }
    
    let {className, style} = getClassAndStyleForFixed(props, offset, k, checkType);
    if (columnIndex === 0 && indent) {
        style || (style = {});
        style.paddingLeft = `${indent}px`;
    }
    
    const classNameObj = {
        [`${k}-hidden`]: props.hidden,
        [className]: className
    }
    
    return _$ce(2, 'td', [
        (columnIndex === 0 && hasChildren) ?
            _$cc(Button, {
                'type': 'none',
                'icon': true,
                'circle': true,
                'size': 'mini',
                'className': _$cn(`${k}-table-arrow`),
                'ev-click': onClickArrow,
                'disabled': loaded === false,
                'children': (
                    (loaded !== false) ?
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-right`),
                            'size': 'small'
                        }) :
                        _$cc(Icon, {
                            'className': _$cn(`${k}-tree-icon ion-load-c`),
                            'rotate': true
                        })
                )
            }) :
            undefined,
        children
    ], 0 /* UnknownChildren */, _$cn(classNameObj), {
        'style': style,
        'title': getTextByChildren(children),
        'rowspan': rowspan,
        'colspan': colspan
    });
};