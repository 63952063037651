export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind, TreeSelectProps, TreeNode} from 'kpc';

interface Props {
    data: NonNullable<TreeSelectProps['data']>
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    label: 'parent',
                    key: '1', 
                    children: [
                        {label: 'first child', children: [], key: '1.1'},
                        {label: 'last child', children: [], loaded: true, key: '1.2'},
                    ],
                },
            ],
        }
    }

    @bind
    loadData(node: TreeNode<string>) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                node.data.children = [
                    {label: `child ${node.key}.1`, children: [], key: `${node.key}.1`},
                    {label: `child ${node.key}.2`, key: `${node.key}.2`},
                ];
                this.set('data', [...this.get('data')]);

                resolve();
            }, 1000);
        });
    }
}
