export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {Dialog, bind, UploadFile} from 'kpc';

export default class extends Component {
    static template = template;

    @bind
    beforeRemove(file: UploadFile) {
        return new Promise<boolean>((resolve, reject) => {
            Dialog.confirm({content: `确认删除文件：${file.name}?`}).then(
                () => resolve(true),
                () => resolve(false),
            );
        });
    }
}