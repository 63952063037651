import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {getRestProps} from '../utils';
import {makeRowStyles} from './styles';
import {context as GutterContext, gutterStyle} from './useGutter';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, children, style, justify, align} = this.get();
    const { k } = this.config;
    const classNameObj = {
        [`${k}-row`]: true,
        [`${k}-justify-${justify}`]: justify,
        [`${k}-align-${align}`]: align,
        [className]: className,
        [makeRowStyles(k)]: true,
    };
    const gutter = this.gutter.getGutter();
    const newStyle = gutter ? gutterStyle(gutter, style, 'margin') : style; 
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this),
        'style': newStyle
    }, (
        _$cc(GutterContext.Provider, {
            'value': gutter,
            'children': children
        })
    ));
};