import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn, Button, ButtonGroup} from 'kpc';

var _$tmp0 = {
    'title': 'Name',
    'key': 'name',
    'fixed': 'left'
};
var _$tmp1 = {
    'title': 'IP',
    'key': 'ip'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_table = function(i) {_$refs['table'] = i};
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'ev-click': this.scrollToRowByIndex,
                    'children': 'Scroll to the 20th row'
                }),
                _$cc(Button, {
                    'ev-click': this.scrollToRowByKey,
                    'children': 'Scroll to the \"name 25\" row'
                })
            ]
        }),
        _$cc(Table, {
            'fixHeader': '300',
            'data': this.get('data'),
            'ref': _$ref_table,
            'rowKey': item => item.name,
            'children': [
                _$cc(TableColumn, _$tmp0, 'name'),
                _$cc(TableColumn, _$tmp1, 'ip'),
                _$cc(TableColumn, {
                    'title': 'Operation',
                    'key': 'op',
                    'fixed': 'right',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return (
                                        _$ce(2, 'a', 'Remove', 16 /* HasTextChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'op')
            ]
        }, null, _$ref_table)
    ], 4 /* HasNonKeyedChildren */, 'wrapper');
};