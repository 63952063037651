import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Title 1',
    'minWidth': 200
};
var _$tmp1 = {
    'key': 'b',
    'title': 'Title 2',
    'minWidth': 300,
    'hidden': true
};
var _$tmp2 = {
    'key': 'c',
    'title': 'Title 3'
};
var _$tmp3 = {
    'key': 'a',
    'title': 'Title 1'
};
var _$tmp4 = {
    'key': 'b',
    'title': 'Title 2',
    'hidden': true
};
var _$tmp5 = {
    'key': 'c',
    'title': 'Title 3'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'resizable': true,
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b'),
                _$cc(TableColumn, _$tmp2, 'c')
            ]
        }),
        _$ce(2, 'p', '无数据展示', 16 /* HasTextChildren */),
        _$cc(Table, {
            'data': this.get('data2'),
            'resizable': true,
            'children': [
                _$cc(TableColumn, _$tmp3, 'a'),
                _$cc(TableColumn, _$tmp4, 'b'),
                _$cc(TableColumn, _$tmp5, 'c')
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};