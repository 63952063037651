import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';
import {Affix, Tabs, Tab} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Affix, {
            'top': 100,
            'children': (
                _$cc(Tabs, {
                    'vertical': true,
                    'size': 'small',
                    'children': (
                        _$ma(this.get('data'), function($value, $key) {
                            return _$cc(Tab, {
                                'className': _$cn({
                                    'item': true,
                                    'senior': $value.level === 1,
                                }),
                                'ev-click': this.scrollTo.bind(this, $value),
                                'style': {paddingLeft: $value.level * 16 + 'px'},
                                'value': $value.id,
                                'children': (
                                    _$ce(2, 'span', null, 1 /* HasInvalidChildren */, null, {
                                        'innerHTML': $value.text
                                    })
                                )
                            });
                        }, $this)
                    ),
                    'ev-$model:value': function($v) {
                        $this.set('value', $v);
                    },
                    'value': $this.get('value')
                })
            )
        })
    ), 2 /* HasVNodeChildren */, _$cn({catalogue: true, [makeStyles()]: true}));
};