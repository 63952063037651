import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tabs, Tab} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tabs, {
            'beforeChange': this.beforeChange,
            'children': [
                _$cc(Tab, {
                    'value': 'rulein',
                    'children': '入站规则'
                }),
                _$cc(Tab, {
                    'value': 'ruleout',
                    'children': '出站规则'
                }),
                _$cc(Tab, {
                    'value': 'relatedVM',
                    'children': '关联云主机'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('tab', $v);
            },
            'value': $this.get('tab')
        }),
        _$ce(2, 'div', (
            (this.get('tab') === 'rulein') ?
                _$ce(2, 'div', '入站规则', 16 /* HasTextChildren */) :
                this.get('tab') === 'ruleout' ?
                    _$ce(2, 'div', '出站规则', 16 /* HasTextChildren */) :
                    _$ce(2, 'div', '关联云主机', 16 /* HasTextChildren */)
        ), 2 /* HasVNodeChildren */, 'content')
    ], 4 /* HasNonKeyedChildren */);
};