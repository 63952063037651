import {
    map as _$ma,
    createElementVNode as _$ce,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Transition} from 'intact';
import {Button} from '../button';
import {Icon} from '../icon';
import {_$} from '../../i18n';
import {makeCalendarStyles} from './styles';
import {getNowDate} from './helpers';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const type = this.type.value;
    const isYear = type === 'year';
    const isYearOrMonth = isYear || type === 'month';
    const { k } = this.config;
    const classNameObj = {
        [`${k}-datepicker-calendar`]: true,
        [makeCalendarStyles(k)]: true,
    };
    const now = getNowDate();
    const {focusDate, reset: resetFocusDate} = this.datepicker.focusDate;
    const getItems = (data, onClick) => {
        return _$ma(data, function($value, $key) {
            return _$ce(2, 'div', (
                _$ce(2, 'div', $value.label, 0 /* UnknownChildren */, _$cn(`${k}-value`))
            ), 2 /* HasVNodeChildren */, _$cn({
                [`${k}-calendar-item`]: true,
                [`${k}-exceed`]: $value.isExceed,
                [`${k}-active`]: $value.isActive,
                [`${k}-today`]: $value.isToday,
                [`${k}-disabled`]: $value.isDisabled, 
                [`${k}-hover`]: $value.isHover,
                [`${k}-in-range`]: $value.isInRange,
            }), {
                'ev-click': !$value.isDisabled ? e => {
                    e._ignore = true;
                    onClick($value.value);
                } : null,
                'ev-mouseenter': () => focusDate.set($value.value)
            });
        }, $this)
    };
    
    const {getDays, onClick: onClickDay} = this.days;
    const {
        getDateLabel,
        prevMonth, nextMonth,
        prevYear, nextYear,
        prevTenYears, nextTenYears
    } = this.showDate;
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$ce(2, 'div', [
                _$cc(Button, {
                    'icon': true,
                    'type': 'none',
                    'size': 'small',
                    'className': _$cn(`${k}-prev`),
                    'tagName': 'div',
                    'ev-click': isYear ? prevTenYears : prevYear,
                    'children': [
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-left`),
                            'size': 'small'
                        }),
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-left`),
                            'size': 'small'
                        })
                    ]
                }),
                (!isYearOrMonth) ?
                    _$cc(Button, {
                        'icon': true,
                        'type': 'none',
                        'size': 'small',
                        'className': _$cn(`${k}-prev`),
                        'tagName': 'div',
                        'ev-click': prevMonth,
                        'children': (
                            _$cc(Icon, {
                                'className': _$cn(`${k}-icon-left`),
                                'size': 'small'
                            })
                        )
                    }) :
                    undefined
            ], 0 /* UnknownChildren */, _$cn(`${k}-left-btns`)),
            _$ce(2, 'div', (
                (!isYear) ?
                    (
                        _$ma(getDateLabel(), function($value, $key) {
                            return _$ce(2, 'span', $value.value, 0 /* UnknownChildren */, _$cn(`${k}-month-value`), {
                                'ev-click': $value.onClick
                            });
                        }, $this)
                    ) :
                    _$ce(2, 'span', this.years.getYearLabel(), 0 /* UnknownChildren */, _$cn(`${k}-month-value`))
            ), 0 /* UnknownChildren */, _$cn(`${k}-month-values`)),
            _$ce(2, 'div', [
                (!isYearOrMonth) ?
                    _$cc(Button, {
                        'icon': true,
                        'type': 'none',
                        'size': 'small',
                        'className': _$cn(`${k}-next`),
                        'tagName': 'div',
                        'ev-click': nextMonth,
                        'children': (
                            _$cc(Icon, {
                                'className': _$cn(`${k}-icon-right`),
                                'size': 'small'
                            })
                        )
                    }) :
                    undefined,
                _$cc(Button, {
                    'icon': true,
                    'type': 'none',
                    'size': 'small',
                    'className': _$cn(`${k}-next`),
                    'tagName': 'div',
                    'ev-click': isYear ? nextTenYears : nextYear,
                    'children': [
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-right`),
                            'size': 'small'
                        }),
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-right`),
                            'size': 'small'
                        })
                    ]
                })
            ], 0 /* UnknownChildren */, _$cn(`${k}-right-btns`))
        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-datepicker-month`)),
        (!isYearOrMonth) ?
            [
                _$ce(2, 'div', (
                    _$ma([_$('日'), _$('一'), _$('二'), _$('三'), _$('四'), _$('五'), _$('六')], function($value, $key) {
                        return _$ce(2, 'div', $value, 0 /* UnknownChildren */, _$cn(`${k}-weekday`));
                    }, $this)
                ), 4 /* HasNonKeyedChildren */, _$cn(`${k}-weekdays`)),
                _$ce(2, 'div', getItems(getDays(now), onClickDay), 0 /* UnknownChildren */, _$cn(`${k}-days`), {
                    'ev-mouseleave': resetFocusDate
                })
            ] :
            _$ce(2, 'div', isYear ?
                getItems(this.years.getYears(now), this.years.onClick) :
                getItems(this.months.getMonths(now), this.months.onClick), 0 /* UnknownChildren */, _$cn(`${k}-years`), {
                'ev-mouseleave': resetFocusDate
            })
    ], 0 /* UnknownChildren */, _$cn(classNameObj));
};