import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Ellipsis, Split} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Split, {
        'style': 'width: 400px',
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['first'] = function($super) {
                        return [
                            _$cc(Ellipsis, {
                                'className': 'panel',
                                'children': '测试测试测试测试测试测试'
                            }),
                            _$ce(2, 'br'),
                            _$cc(Ellipsis, {
                                'className': 'panel',
                                'maxLines': 2,
                                'children': '最大几行忽略最大几行忽略最大几行忽略'
                            })
                        ];
                    }),
                    (__$blocks['first'] = function($super, data) {
                        var block = $blocks['first'];
                        var callBlock = function() {
                            return _$blocks['first'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                (
                    (_$blocks['last'] = function($super) {
                        return (
                            _$cc(Ellipsis, {
                                'className': 'panel',
                                'disabled': this.get('disabled'),
                                'children': '不展示tooltip不展示tooltip'
                            })
                        );
                    }),
                    (__$blocks['last'] = function($super, data) {
                        var block = $blocks['last'];
                        var callBlock = function() {
                            return _$blocks['last'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    });
};