import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';
import {Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'icon ion-chevron-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, mainTitle, subTitle} = this.get();
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$ce(2, 'div', mainTitle, 0 /* UnknownChildren */),
            _$cc(Button, {
                'type': 'none',
                'icon': true,
                'circle': true,
                'ev-click': this.goHref.bind(this),
                'children': (
                    _$cc(Icon, _$tmp0)
                )
            })
        ], 4 /* HasNonKeyedChildren */, 'title'),
        _$ce(2, 'div', subTitle, 0 /* UnknownChildren */, 'sub-title'),
        _$ce(2, 'div', children, 0 /* UnknownChildren */, 'content-box')
    ], 4 /* HasNonKeyedChildren */, _$cn(makeStyles()));
};