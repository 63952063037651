import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Popover, Button, ButtonGroup} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(ButtonGroup, {
            'children': (
                _$ma(this.get('types'), function($value, $key) {
                    return _$cc(Popover, {
                        'key': $value,
                        'title': '确定删除？',
                        'type': $value,
                        'ev-ok': this.ok,
                        'ev-cancel': this.cancel,
                        'children': (
                            _$cc(Button, {
                                'data-type': $value,
                                'value': $value,
                                'children': $value
                            })
                        )
                    }, $value);
                }, $this)
            )
        })
    ), 2 /* HasVNodeChildren */);
};