export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, Table} from 'kpc';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data: [{a: '第一行', b: '哈哈'}, {a: '第二行', b: '哈哈'}]
        };
    }

    @bind
    onSelectedKeysChanged() {
        console.log((this.refs.__test1 as Table).getSelectedData());
    }
}