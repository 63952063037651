import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Upload} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Upload, {
        'multiple': true,
        'action': '//fakestoreapi.com/products',
        'ev-$model:files': function($v) {
            $this.set('files', $v);
        },
        'files': $this.get('files')
    });
};