import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Icon} from '../icon';
import {Button} from '../button';
import {makeAlertStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        type, content, hideIcon, 
        hideFooter, hideClose, title,
        iconClassName, icon
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-alert-dialog`]: true,
        [`${k}-${type}`]: true,
        [`${k}-with-title`]: title,
        [makeAlertStyles(k)]: true,
    };
    
    return _$su.call($this, {
        'className': _$cn(classNameObj)
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['header'] = function($super) {
                    return null;
                }),
                (__$blocks['header'] = function($super, data) {
                    var block = $blocks['header'];
                    var callBlock = function() {
                        return _$blocks['header'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            (
                (_$blocks['body'] = function($super) {
                    return [
                        _$ce(2, 'div', [
                            (!hideIcon && !icon) ?
                                _$cc(Icon, {
                                    'className': _$cn(iconClassName || {
                                        [`${k}-icon-success-fill`]: type === 'success',
                                        [`${k}-icon-warning-fill`]: type === 'warning' || type === 'confirm',
                                        [`${k}-icon-error-fill`]: type === 'error',
                                    }),
                                    'color': type === 'error' ? 'danger' : type === 'confirm' ? 'warning' : type
                                }) :
                                undefined,
                            icon
                        ], 0 /* UnknownChildren */, _$cn(`${k}-dialog-tip-icon`)),
                        _$ce(2, 'div', [
                            (title) ?
                                _$ce(2, 'div', title, 0 /* UnknownChildren */, _$cn(`${k}-alert-dialog-title`)) :
                                undefined,
                            content
                        ], 0 /* UnknownChildren */, _$cn(`${k}-alert-dialog-wrapper`))
                    ];
                }),
                (__$blocks['body'] = function($super, data) {
                    var block = $blocks['body'];
                    var callBlock = function() {
                        return _$blocks['body'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            (
                (_$blocks['footer'] = function($super) {
                    return (
                        (hideFooter) ?
                            null :
                            $super()
                    );
                }),
                (__$blocks['footer'] = function($super, data) {
                    var block = $blocks['footer'];
                    var callBlock = function() {
                        return _$blocks['footer'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};