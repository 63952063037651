import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
    createElementVNode as _$ce,
} from 'intact';
import {Diagram, DLine, DRectangle, DStackLayout} from 'kpc/components/diagram';
import {Form, FormItem, Button, ButtonGroup, Slider, Checkbox} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const keys = ['A', 'B', 'C'];
    
    return _$cc(Form, {
        'children': [
            _$ma(['startArrow', 'endArrow', 'type', 'strokeStyle'], function($value, $key) {
                return _$cc(FormItem, {
                    'label': $value,
                    'children': (
                        _$cc(ButtonGroup, {
                            'checkType': 'radio',
                            'children': (
                                _$ma(DLine.typeDefs[$value], function($value, $key) {
                                    return _$cc(Button, {
                                        'value': $value,
                                        'children': $value
                                    });
                                }, $this)
                            ),
                            'ev-$model:value': function($v) {
                                $this.set($value, $v);
                            },
                            'value': $this.get($value)
                        })
                    )
                });
            }, $this),
            _$ma(['startPoint', 'endPoint'], function($value, $key) {
                return _$cc(FormItem, {
                    'label': $value,
                    'children': (
                        _$ma([0, 1], function(index, $key) {
                            return [
                                _$cc(Slider, {
                                    'value': this.get(`${$value}.${index}`),
                                    'min': 0,
                                    'max': 1000,
                                    'isShowInput': false,
                                    'showTooltip': true,
                                    'ev-$change:value': this.onSliderChange.bind(this, $value, index),
                                    '$blocks': function($blocks) {
                                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                        return (
                                            (
                                                (_$blocks['tooltip'] = function($super, value) {
                                                    return [
                                                        index === 0 ? 'x: ' : 'y: ',
                                                        _$ct(' '),
                                                        value
                                                    ];
                                                }),
                                                (__$blocks['tooltip'] = function($super, data) {
                                                    var block = $blocks['tooltip'];
                                                    var callBlock = function() {
                                                        return _$blocks['tooltip'].call($this, $super, data);
                                                    };
                                                    return block ?
                                                        block.call($this, callBlock, data) :
                                                        callBlock();
                                                })
                                            ),
                                            __$blocks
                                        );
                                    }.call($this, _$em)
                                }),
                                _$ce(2, 'br')
                            ];
                        }, $this)
                    )
                });
            }, $this),
            _$ma(['from', 'to'], function($value, $key) {
                return _$cc(FormItem, {
                    'label': $value,
                    'children': (
                        _$cc(ButtonGroup, {
                            'checkType': 'radio',
                            'children': [
                                _$ma(keys, function($value, $key) {
                                    return _$cc(Button, {
                                        'value': $value,
                                        'children': $value
                                    });
                                }, $this),
                                _$cc(Button, {
                                    'value': null,
                                    'children': 'None'
                                })
                            ],
                            'ev-$model:value': function($v) {
                                $this.set($value, $v);
                            },
                            'value': $this.get($value)
                        })
                    )
                });
            }, $this),
            _$ma(['exit', 'entry'], function($value, $key) {
                return _$cc(FormItem, {
                    'label': $value,
                    'children': (
                        _$ma([0, 1], function(index, $key) {
                            return [
                                _$cc(Slider, {
                                    'value': this.get(`${$value}.${index}`),
                                    'min': 0,
                                    'max': 1,
                                    'step': 0,
                                    'isShowInput': false,
                                    'showTooltip': true,
                                    'ev-$change:value': this.onSliderChange.bind(this, $value, index),
                                    'disabled': !this.get(`use${$value}`),
                                    '$blocks': function($blocks) {
                                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                        return (
                                            (
                                                (_$blocks['tooltip'] = function($super, value) {
                                                    return [
                                                        index === 0 ? 'x: ' : 'y: ',
                                                        _$ct(' '),
                                                        value
                                                    ];
                                                }),
                                                (__$blocks['tooltip'] = function($super, data) {
                                                    var block = $blocks['tooltip'];
                                                    var callBlock = function() {
                                                        return _$blocks['tooltip'].call($this, $super, data);
                                                    };
                                                    return block ?
                                                        block.call($this, callBlock, data) :
                                                        callBlock();
                                                })
                                            ),
                                            __$blocks
                                        );
                                    }.call($this, _$em)
                                }),
                                _$ce(2, 'br')
                            ];
                        }, $this)
                    ),
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['label'] = function($super) {
                                    return (
                                        _$cc(Checkbox, {
                                            'children': $value,
                                            'ev-$model:value': function($v) {
                                                $this.set(`use${$value}`, $v);
                                            },
                                            'value': $this.get(`use${$value}`)
                                        })
                                    );
                                }),
                                (__$blocks['label'] = function($super, data) {
                                    var block = $blocks['label'];
                                    var callBlock = function() {
                                        return _$blocks['label'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                });
            }, $this),
            _$cc(FormItem, {
                'children': (
                    _$cc(Diagram, {
                        'children': [
                            _$cc(DStackLayout, {
                                'spacing': '50',
                                'border': '20',
                                'children': (
                                    _$ma(keys, function($value, $key) {
                                        return _$cc(DRectangle, {
                                            'key': $value,
                                            'label': $value
                                        }, $value);
                                    }, $this)
                                )
                            }),
                            _$cc(DLine, {
                                'startArrow': this.get('startArrow'),
                                'endArrow': this.get('endArrow'),
                                'startPoint': this.get('startPoint'),
                                'endPoint': this.get('endPoint'),
                                'type': this.get('type'),
                                'strokeStyle': this.get('strokeStyle'),
                                'from': this.get('from'),
                                'to': this.get('to'),
                                'exit': this.get('useexit') ? this.get('exit') : null,
                                'entry': this.get('useentry') ? this.get('entry') : null
                            })
                        ]
                    })
                )
            })
        ]
    });
};