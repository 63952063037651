import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Progress, ButtonGroup, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ct('\n    状态：\n    '),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'value': 'active',
                    'children': 'active'
                }),
                _$cc(Button, {
                    'value': 'success',
                    'children': 'success'
                }),
                _$cc(Button, {
                    'value': 'error',
                    'children': 'error'
                }),
                _$cc(Button, {
                    'value': 'normal',
                    'children': 'normal'
                }),
                _$cc(Button, {
                    'value': 'warning',
                    'children': 'warning'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('status', $v);
            },
            'value': $this.get('status')
        }),
        _$ct('\n\n    进度：\n    '),
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'size': 'mini',
                    'icon': true,
                    'ev-click': this.minus,
                    'children': '-'
                }),
                _$cc(Button, {
                    'size': 'mini',
                    'icon': true,
                    'ev-click': this.add,
                    'children': '+'
                })
            ]
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Progress, {
            'type': 'circle',
            'percent': this.get('percent'),
            'status': this.get('status')
        }),
        _$cc(Progress, {
            'type': 'circle',
            'percent': this.get('percent'),
            'status': this.get('status'),
            'size': 'small'
        }),
        _$cc(Progress, {
            'type': 'circle',
            'percent': this.get('percent'),
            'status': this.get('status'),
            'size': 'mini'
        })
    ], 4 /* HasNonKeyedChildren */);
};