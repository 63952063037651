import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {ButtonGroup, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(ButtonGroup, {
        'children': (
            _$ma(this.get('types'), function($value, $key) {
                return _$cc(Button, {
                    'ev-click': this.showMessage.bind(this, $value),
                    'children': $value
                });
            }, $this)
        )
    });
};