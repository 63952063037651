export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data1: [{a: 'checkbox 1'}, {a: 'checkbox 2'}],
            data2: [{a: 'radio 1'}, {a: 'radio 2'}],
            data3: [{a: 'no check 1'}, {a: 'no check 2'}],
        }
    }
}