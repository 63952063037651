import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '表头1'
};
var _$tmp1 = {
    'key': 'b',
    'title': '表头2'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', (
        _$cc(Table, {
            'data': this.get('data'),
            'resizable': true,
            'ref': _$ref___test,
            'showIndeterminate': false,
            'checkedKeys': [0],
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        }, null, _$ref___test)
    ), 2 /* HasVNodeChildren */);
};