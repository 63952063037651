import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    noop as _$no,
} from 'intact';
import {makeStyles, kls} from './styles';
import { MemoBlock } from './memo';
import { getRestProps } from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {mode} = this.get();
    const {firstSize, lastSize} = this.size;
    const {firstRef, lastRef, elementRef, start, dragging} = this.drag;
    const { k } = this.config;
    const classNameObj = {
        [`${k}-split`]: true,
        [`${k}-${mode}`]: true,
        [makeStyles(k)]: true,
        [[`${k}-first-auto`]]: firstSize.value === 'auto',
        [[`${k}-last-auto`]]: lastSize.value === 'auto',
        [[`${k}-resizing`]]: dragging.value,
    };
    
    const stylePropName = mode === 'horizontal' ? 'width' : 'height';
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        'ref': elementRef,
        ...getRestProps(this)
    }, [
        _$ce(2, 'div', (
            _$cc(MemoBlock, {
                'block': $blocks.first,
                'children': (
                    (
                        (
                            (_$blocks['first'] = function($super) {
                                return null;
                            }),
                            (__$blocks['first'] = function($super, data) {
                                var block = $blocks['first'];
                                var callBlock = function() {
                                    return _$blocks['first'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            }),
                            __$blocks['first'](_$no)
                        )
                    )
                )
            })
        ), 2 /* HasVNodeChildren */, _$cn(kls('first', k)), {
            'style': {[stylePropName]: firstSize.value}
        }, null, firstRef),
        _$ce(2, 'div', (
            (
                (_$blocks['drag'] = function($super) {
                    return (
                        _$ce(2, 'div', (
                            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(kls('drag-lines', k)))
                        ), 2 /* HasVNodeChildren */, _$cn(kls('line', k)))
                    );
                }),
                (__$blocks['drag'] = function($super, data) {
                    var block = $blocks['drag'];
                    var callBlock = function() {
                        return _$blocks['drag'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                }),
                __$blocks['drag'](_$no)
            )
        ), 0 /* UnknownChildren */, _$cn(kls('line-wrapper', k)), {
            'ev-mousedown': start
        }),
        _$ce(2, 'div', (
            _$cc(MemoBlock, {
                'block': $blocks.last,
                'children': (
                    (
                        (
                            (_$blocks['last'] = function($super) {
                                return null;
                            }),
                            (__$blocks['last'] = function($super, data) {
                                var block = $blocks['last'];
                                var callBlock = function() {
                                    return _$blocks['last'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            }),
                            __$blocks['last'](_$no)
                        )
                    )
                )
            })
        ), 2 /* HasVNodeChildren */, _$cn(kls('last', k)), {
            'style': {[stylePropName]: lastSize.value}
        }, null, lastRef)
    ]);
};