import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Editable} from 'kpc';

var _$tmp0 = {
    'href': ''
};
var _$tmp1 = {
    'href': ''
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Editable, {
            'ref': _$ref___test,
            'children': [
                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-ios-location'),
                _$ce(2, 'a', this.get('text'), 0 /* UnknownChildren */, null, _$tmp0)
            ],
            'ev-$model:value': function($v) {
                $this.set('text', $v);
            },
            'value': $this.get('text')
        }, null, _$ref___test),
        _$ce(2, 'br'),
        _$cc(Editable, {
            'value': 'disabled editable text',
            'disabled': true,
            'children': [
                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-ios-location'),
                _$ce(2, 'a', 'disabled editable text', 16 /* HasTextChildren */, null, _$tmp1)
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};