import {
    createElementVNode as _$ce,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, title} = this.get();
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', title, 0 /* UnknownChildren */, 'title'),
        _$ce(2, 'div', children, 0 /* UnknownChildren */, 'content')
    ], 4 /* HasNonKeyedChildren */, _$cn(makeStyles()));
};