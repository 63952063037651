import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button} from '../button';
import {Icon} from '../icon';
import {getRestProps} from '../utils';
import {isNullOrUndefined} from 'intact-shared';

var _$tmp0 = {
    'className': 'ion-ios-close-empty',
    'hoverable': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {value, children, className, disabled, closable} = this.get();
    const {value: currentValue} = this.tabs.get();
    const { k } = this.config;
    const classNameObj = {
        [`${k}-tab`]: true, 
        [`${k}-active`]: !isNullOrUndefined(value) && value === currentValue,
        [className]: className,
        [`${k}-disabled`]: disabled,
    };
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'ev-click': this.onClick
    }, [
        children,
        (isNullOrUndefined(closable) ? this.tabs.get('closable') : closable) ?
            _$cc(Button, {
                'type': 'none',
                'icon': true,
                'disabled': disabled,
                'size': 'mini',
                'className': _$cn(`${k}-tab-close`),
                'ev-click': this.remove,
                'children': (
                    _$cc(Icon, _$tmp0)
                )
            }) :
            undefined
    ]);
};