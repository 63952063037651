import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Steps, Step, Button} from 'kpc';

var _$tmp0 = {
    'title': '设置VPC'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Steps, {
            'type': 'simple',
            'children': [
                _$cc(Step, {
                    'title': '选择配置',
                    'children': '请选择主机的配置信息'
                }),
                _$cc(Step, {
                    'title': '选择弹性IP',
                    'children': '请选择主机弹性IP的配置信息'
                }),
                _$cc(Step, _$tmp0)
            ],
            'ev-$model:value': function($v) {
                $this.set('index', $v);
            },
            'value': $this.get('index')
        }),
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.previous,
            'disabled': this.get('index') === 0,
            'children': 'Previous Step'
        }),
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.next,
            'disabled': this.get('index') === 2,
            'children': 'Next Step'
        })
    ], 4 /* HasNonKeyedChildren */);
};