export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    value1?: number
    value2?: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            value1: 10,
            value2: 10,
        };
    }

    disableBtn(page: number, limit: number) {
        return page > 10;
    }

    disablePage(page: number, limit: number) {
        return page > 10;
    }
}