import {
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Menu, MenuItem, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-heart'
};
var _$tmp1 = {
    'className': 'ion-star'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Menu, {
        'dot': true,
        'theme': 'light',
        'children': [
            _$cc(MenuItem, {
                'key': '1',
                'ev-select': this.onSelect,
                'children': 'menu 1'
            }, '1'),
            _$cc(MenuItem, {
                'key': '2',
                'disabled': true,
                'children': 'menu 2'
            }, '2'),
            _$cc(MenuItem, {
                'key': '3',
                'dot': false,
                'children': [
                    _$cc(Icon, _$tmp0),
                    _$ct('menu 3\n        '),
                    _$cc(Menu, {
                        'children': [
                            _$cc(MenuItem, {
                                'key': '3-1',
                                'ev-select': this.onSelect,
                                'children': 'sub menu 1'
                            }, '3-1'),
                            _$cc(MenuItem, {
                                'key': '3-2',
                                'ev-select': this.onSelect,
                                'children': 'sub menu 2'
                            }, '3-2'),
                            _$cc(MenuItem, {
                                'key': '3-3',
                                'disabled': true,
                                'children': 'sub menu 3'
                            }, '3-3'),
                            _$cc(MenuItem, {
                                'key': '3-4',
                                'children': 'sub menu 4'
                            }, '3-4')
                        ]
                    })
                ]
            }, '3'),
            _$cc(MenuItem, {
                'key': '4',
                'to': '/',
                'children': 'menu 4'
            }, '4')
        ],
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['header'] = function($super) {
                        return [
                            _$cc(Icon, _$tmp1),
                            _$ct('Header')
                        ];
                    }),
                    (__$blocks['header'] = function($super, data) {
                        var block = $blocks['header'];
                        var callBlock = function() {
                            return _$blocks['header'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em),
        'ev-$model:expandedKeys': function($v) {
            $this.set('expandedKeys', $v);
        },
        'expandedKeys': $this.get('expandedKeys'),
        'ev-$model:selectedKey': function($v) {
            $this.set('selectedKey', $v);
        },
        'selectedKey': $this.get('selectedKey')
    });
};