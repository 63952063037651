import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Dropdown, DropdownMenu, DropdownItem, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-down'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Dropdown, {
            'children': [
                _$cc(Button, {
                    'type': 'primary',
                    'children': [
                        _$ct('\n            hover '),
                        _$cc(Icon, _$tmp0)
                    ]
                }),
                _$cc(DropdownMenu, {
                    'children': [
                        _$cc(DropdownItem, {
                            'children': 'item 1'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 2'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 3'
                        })
                    ]
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};