import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {getRestProps} from '../utils';
import {Input} from '../input';
import {Icon} from '../icon';
import {makeStyles} from './styles';
import { Tooltip } from '../tooltip';
import {isNullOrUndefined} from 'intact-shared';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        children, className, editing, 
        value, validate, disabled, 
        tip, invalid, rows
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-editable`]: true,
        [`${k}-editing`]: editing,
        [`${k}-disabled`]: disabled,
        [`${k}-invalid`]: invalid,
        [className]: className,
        [makeStyles(k)]: true
    };
    const isTextArea = !isNullOrUndefined(rows) && rows != '1';
    
    const inputProps = {
        autoWidth: !isTextArea,
        fluid: isTextArea,
        ...(isTextArea && { type: 'textarea' }),
        rows,
        ...((!isTextArea || rows === 'auto') && {size: 'mini'})
    };
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj)
    }, [
        _$ce(2, 'div', (
            (!editing) ?
                children :
                _$cc(Input, {
                    'defaultValue': value,
                    'ev-blur': this.onBlur,
                    'ev-keydown': this.onKeydown,
                    'ref': this.inputRef,
                    'frozenOnInput': true,
                    'ev-$mounted': this.select,
                    ...inputProps
                }, null, this.inputRef)
        ), 0 /* UnknownChildren */, 'c-ellipsis'),
        (!disabled && !editing) ?
            _$cc(Tooltip, {
                'content': tip,
                'children': (
                    _$cc(Icon, {
                        'className': _$cn(`${k}-icon-edit ${k}-editable-icon`),
                        'ev-click': this.edit,
                        'hoverable': true
                    })
                )
            }) :
            undefined
    ]);
};