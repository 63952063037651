export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    value1?: number
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            value1: 0,
        };
    }
}