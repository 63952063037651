import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    map as _$ma,
} from 'intact';
import {
    Layout, Header, Aside, Body, Footer,
    Menu, MenuItem,
    Icon,
    Breadcrumb, BreadcrumbItem
} from 'kpc';

var _$tmp0 = {
    'className': 'ion-flag'
};
var _$tmp1 = {
    'className': 'ion-star'
};
var _$tmp2 = {
    'className': 'ion-heart'
};
var _$tmp3 = {
    'className': 'ion-gear-b'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Layout, {
        'className': 'layout',
        'children': [
            _$cc(Header, {
                'fixed': true,
                'children': [
                    _$ce(2, 'div', 'LOGO', 16 /* HasTextChildren */, 'logo'),
                    _$cc(Menu, {
                        'type': 'horizontal',
                        'children': [
                            _$cc(MenuItem, {
                                'key': '1',
                                'children': 'menu 1'
                            }, '1'),
                            _$cc(MenuItem, {
                                'key': '2',
                                'disabled': true,
                                'children': 'menu 2'
                            }, '2'),
                            _$cc(MenuItem, {
                                'key': '3',
                                'children': [
                                    _$ct('\n                menu 3\n                '),
                                    _$cc(Menu, {
                                        'children': [
                                            _$cc(MenuItem, {
                                                'key': '3-1',
                                                'children': 'sub menu 1'
                                            }, '3-1'),
                                            _$cc(MenuItem, {
                                                'key': '3-2',
                                                'children': 'sub menu 2'
                                            }, '3-2'),
                                            _$cc(MenuItem, {
                                                'key': '3-3',
                                                'disabled': true,
                                                'children': 'sub menu 3'
                                            }, '3-3'),
                                            _$cc(MenuItem, {
                                                'key': '3-4',
                                                'children': [
                                                    _$ct('\n                        sub menu 4\n                        '),
                                                    _$cc(Menu, {
                                                        'children': [
                                                            _$cc(MenuItem, {
                                                                'key': '3-4-1',
                                                                'children': 'option 1'
                                                            }, '3-4-1'),
                                                            _$cc(MenuItem, {
                                                                'key': '3-4-2',
                                                                'children': 'option 2'
                                                            }, '3-4-2')
                                                        ]
                                                    })
                                                ]
                                            }, '3-4')
                                        ]
                                    })
                                ]
                            }, '3'),
                            _$cc(MenuItem, {
                                'key': '4',
                                'children': 'menu 4'
                            }, '4')
                        ]
                    })
                ]
            }),
            _$cc(Layout, {
                'children': [
                    _$cc(Aside, {
                        'fixed': true,
                        'theme': 'light',
                        'children': (
                            _$cc(Menu, {
                                'theme': 'light',
                                'children': [
                                    _$cc(MenuItem, {
                                        'key': '1',
                                        'children': [
                                            _$cc(Icon, _$tmp0),
                                            _$ct('menu 1\n                    '),
                                            _$cc(Menu, {
                                                'children': [
                                                    _$cc(MenuItem, {
                                                        'key': '1-1',
                                                        'children': 'sub menu 1'
                                                    }, '1-1'),
                                                    _$cc(MenuItem, {
                                                        'key': '1-2',
                                                        'children': 'sub menu 2'
                                                    }, '1-2'),
                                                    _$cc(MenuItem, {
                                                        'key': '1-3',
                                                        'disabled': true,
                                                        'children': 'sub menu 3'
                                                    }, '1-3'),
                                                    _$cc(MenuItem, {
                                                        'key': '1-4',
                                                        'children': 'sub menu 4'
                                                    }, '1-4')
                                                ]
                                            })
                                        ]
                                    }, '1'),
                                    _$cc(MenuItem, {
                                        'key': '2',
                                        'disabled': true,
                                        'children': [
                                            _$cc(Icon, _$tmp1),
                                            _$ct('menu 2')
                                        ]
                                    }, '2'),
                                    _$cc(MenuItem, {
                                        'key': '3',
                                        'children': [
                                            _$cc(Icon, _$tmp2),
                                            _$ct('menu 3\n                    '),
                                            _$cc(Menu, {
                                                'children': [
                                                    _$cc(MenuItem, {
                                                        'key': '3-1',
                                                        'children': 'sub menu 1'
                                                    }, '3-1'),
                                                    _$cc(MenuItem, {
                                                        'key': '3-2',
                                                        'children': 'sub menu 2'
                                                    }, '3-2'),
                                                    _$cc(MenuItem, {
                                                        'key': '3-3',
                                                        'disabled': true,
                                                        'children': 'sub menu 3'
                                                    }, '3-3'),
                                                    _$cc(MenuItem, {
                                                        'key': '3-4',
                                                        'children': 'sub menu 4'
                                                    }, '3-4')
                                                ]
                                            })
                                        ]
                                    }, '3'),
                                    _$cc(MenuItem, {
                                        'key': '4',
                                        'children': [
                                            _$cc(Icon, _$tmp3),
                                            _$ct('menu 4')
                                        ]
                                    }, '4')
                                ],
                                'ev-$model:expandedKeys': function($v) {
                                    $this.set('expandedKeys', $v);
                                },
                                'expandedKeys': $this.get('expandedKeys'),
                                'ev-$model:selectedKey': function($v) {
                                    $this.set('selectedKey', $v);
                                },
                                'selectedKey': $this.get('selectedKey')
                            })
                        )
                    }),
                    _$cc(Body, {
                        'children': [
                            _$cc(Breadcrumb, {
                                'children': [
                                    _$cc(BreadcrumbItem, {
                                        'children': 'Home'
                                    }),
                                    _$cc(BreadcrumbItem, {
                                        'children': 'Detail'
                                    })
                                ]
                            }),
                            _$ma(this.get('data'), function($value, $key) {
                                return _$ce(2, 'div', '\n                content\n            ', 16 /* HasTextChildren */);
                            }, $this)
                        ]
                    })
                ]
            })
        ]
    });
};