import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createElementVNode as _$ce,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    map as _$ma,
} from 'intact';
import {Layout, Header, Aside, Menu, MenuItem, Icon, Body, Card} from 'kpc';
import {makeStyles} from './styles';
import KD_logo from '../../../imgs/KD_logo.png';
import colorProLogo from '../../../imgs/color_pro_logo.png';
import colorNewEar from '../../../imgs/color_NewEra.png';
import color_ocean from '../../../imgs/color_ocean.png';
import color_deign62 from '../../../imgs/color_deign62.png';
import color_techno from '../../../imgs/color_techno.png';

var _$tmp0 = {
    'className': 'icon ion-navicon'
};
var _$tmp1 = {
    'clsss': 'menu-header'
};
var _$tmp2 = {
    'className': 'icon ion-navicon'
};
var _$tmp3 = {
    'className': 'icon ion-navicon'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        asideWidth, headerHeight, bgTheme, curBgTheme,
        theme, curTheme
    } = this.get();
    
    const colorMap = {
        'New Era': colorNewEar,
        'Ocean': color_ocean,
        'Techno': color_techno,
        'burset 62': color_deign62,
    }
    
    function getSel(theme) {
        return theme == curTheme;
    }
    
    return _$cc(Layout, {
        'className': _$cn(makeStyles(bgTheme[curBgTheme], theme[curTheme])),
        'children': [
            _$cc(Header, {
                'height': headerHeight,
                'children': [
                    _$ce(2, 'div', (
                        _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                            'src': colorProLogo
                        })
                    ), 2 /* HasVNodeChildren */, 'header-logo'),
                    _$ce(2, 'div', [
                        _$ce(2, 'div', 'Design', 16 /* HasTextChildren */),
                        _$ce(2, 'div', 'Component', 16 /* HasTextChildren */),
                        _$ce(2, 'div', 'DevOps', 16 /* HasTextChildren */),
                        _$ce(2, 'div', 'Resources', 16 /* HasTextChildren */),
                        _$ce(2, 'div', (
                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                'src': KD_logo
                            })
                        ), 2 /* HasVNodeChildren */)
                    ], 4 /* HasNonKeyedChildren */, 'header-menu')
                ]
            }),
            _$cc(Layout, {
                'collapse': true,
                'className': 'cp-body',
                'children': [
                    _$cc(Aside, {
                        'width': asideWidth,
                        'children': [
                            _$cc(Menu, {
                                'selectedKey': '1',
                                'children': [
                                    _$cc(MenuItem, {
                                        'key': '1',
                                        'children': 'Collections'
                                    }, '1'),
                                    _$cc(MenuItem, {
                                        'key': '2',
                                        'children': 'Webflow'
                                    }, '2'),
                                    _$cc(MenuItem, {
                                        'key': '3',
                                        'children': 'Colors'
                                    }, '3'),
                                    _$cc(MenuItem, {
                                        'key': '4',
                                        'children': 'Fonts'
                                    }, '4'),
                                    _$cc(MenuItem, {
                                        'key': '5',
                                        'children': 'Layout'
                                    }, '5'),
                                    _$cc(MenuItem, {
                                        'key': '6',
                                        'children': 'Interaction'
                                    }, '6')
                                ],
                                '$blocks': function($blocks) {
                                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                    return (
                                        (
                                            (_$blocks['header'] = function($super) {
                                                return (
                                                    _$ce(2, 'div', [
                                                        _$ce(2, 'span', 'Design', 16 /* HasTextChildren */),
                                                        _$cc(Icon, _$tmp0)
                                                    ], 4 /* HasNonKeyedChildren */, null, _$tmp1)
                                                );
                                            }),
                                            (__$blocks['header'] = function($super, data) {
                                                var block = $blocks['header'];
                                                var callBlock = function() {
                                                    return _$blocks['header'].call($this, $super, data);
                                                };
                                                return block ?
                                                    block.call($this, callBlock, data) :
                                                    callBlock();
                                            })
                                        ),
                                        __$blocks
                                    );
                                }.call($this, _$em)
                            }),
                            _$ce(2, 'div', (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', 'design.ksyun.com', 16 /* HasTextChildren */),
                                    _$ce(2, 'div', 'COPY', 16 /* HasTextChildren */)
                                ], 4 /* HasNonKeyedChildren */)
                            ), 2 /* HasVNodeChildren */, 'menu-footer')
                        ]
                    }),
                    _$cc(Body, {
                        'className': 'cp-content',
                        'children': [
                            _$ce(2, 'div', (
                                _$ma(colorMap, function($value, $key) {
                                    return _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn({"img-item": true, "img-seld": getSel($key), "img-noseld": !getSel($key)}), {
                                        'style': {backgroundImage: `url(${$value})`}
                                    });
                                }, $this)
                            ), 4 /* HasNonKeyedChildren */, 'img-box'),
                            _$ce(2, 'div', [
                                _$cc(Card, {
                                    'children': [
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', 'Trending', 16 /* HasTextChildren */),
                                            _$cc(Icon, _$tmp2)
                                        ], 4 /* HasNonKeyedChildren */, 'card-title'),
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', [
                                                _$ce(2, 'span', 'A unique design color for your brand. ', 16 /* HasTextChildren */),
                                                _$ce(2, 'span', '2022/01/01', 16 /* HasTextChildren */)
                                            ], 4 /* HasNonKeyedChildren */),
                                            _$ce(2, 'p', 'KD color algorithm tool generate the color scale and auxiliary color automatically', 16 /* HasTextChildren */)
                                        ], 4 /* HasNonKeyedChildren */, 'title-secondary'),
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', [
                                                _$ce(2, 'span', 'Business Thinking for Designers. ', 16 /* HasTextChildren */),
                                                _$ce(2, 'span', '2022/01/01', 16 /* HasTextChildren */)
                                            ], 4 /* HasNonKeyedChildren */),
                                            _$ce(2, 'p', 'Bring a business mind to design, and transform your career and company', 16 /* HasTextChildren */)
                                        ], 4 /* HasNonKeyedChildren */, 'title-secondary')
                                    ]
                                }),
                                _$cc(Card, {
                                    'children': [
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', 'Collections', 16 /* HasTextChildren */),
                                            _$cc(Icon, _$tmp3)
                                        ], 4 /* HasNonKeyedChildren */, 'card-title'),
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', '20', 16 /* HasTextChildren */),
                                                _$ce(2, 'div', 'NEWS', 16 /* HasTextChildren */)
                                            ], 4 /* HasNonKeyedChildren */),
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', '14', 16 /* HasTextChildren */),
                                                _$ce(2, 'div', 'Design Books', 16 /* HasTextChildren */)
                                            ], 4 /* HasNonKeyedChildren */),
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', '230', 16 /* HasTextChildren */),
                                                _$ce(2, 'div', 'Crafts', 16 /* HasTextChildren */)
                                            ], 4 /* HasNonKeyedChildren */)
                                        ], 4 /* HasNonKeyedChildren */, 'collection-box')
                                    ]
                                })
                            ], 4 /* HasNonKeyedChildren */, 'card-box')
                        ]
                    })
                ]
            })
        ]
    });
};