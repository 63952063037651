import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Datepicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Datepicker, {
            'multiple': true,
            'clearable': true,
            'ev-$model:value': function($v) {
                $this.set('date', $v);
            },
            'value': $this.get('date')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('date')),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'multiple': true,
            'type': 'datetime',
            'ev-$model:value': function($v) {
                $this.set('datetime', $v);
            },
            'value': $this.get('datetime')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('datetime')),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'multiple': true,
            'type': 'year',
            'ev-$model:value': function($v) {
                $this.set('year', $v);
            },
            'value': $this.get('year')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('year')),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'multiple': true,
            'type': 'month',
            'ev-$model:value': function($v) {
                $this.set('month', $v);
            },
            'value': $this.get('month')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('month')),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'multiple': true,
            'range': true,
            'ev-$model:value': function($v) {
                $this.set('dateRange', $v);
            },
            'value': $this.get('dateRange')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('dateRange')),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'multiple': true,
            'range': true,
            'type': 'datetime',
            'ev-$model:value': function($v) {
                $this.set('datetimeRange', $v);
            },
            'value': $this.get('datetimeRange')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('datetimeRange')),
        _$ce(2, 'br'),
        _$ce(2, 'br')
    ], 0 /* UnknownChildren */);
};