import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Input} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Input, {
            'type': 'textarea',
            'placeholder': 'please enter',
            'fluid': true,
            'rows': 'auto',
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Input, {
            'type': 'textarea',
            'placeholder': 'please enter',
            'fluid': true,
            'rows': {min: 3, max: 5},
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        })
    ], 4 /* HasNonKeyedChildren */);
};