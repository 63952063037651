import {
    createCommentVNode as _$ccv,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createVNode as _$cv,
    map as _$ma,
} from 'intact';
import {getRestProps, mapChildren} from '../utils';
import {ButtonGroup, Button} from '../button';
import {Select, Option} from '../select';
import {Input} from '../input';
import {Icon} from '../icon';
import {_$} from '../../i18n';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        total, size, flat, simple, 
        className, showTotal, showGoto,
        counts, value, limit, limits,
        showLimits, disableBtn, disablePage
    } = this.get();
    
    if (!total) return _$ccv(' pagination ')
    
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-pagination`]: true,
        [className]: className,
        [`${k}-${size}`]: size !== 'default',
        [`${k}-flat`]: flat,
        [`${k}-simple`]: simple,
        [makeStyles(k)]: true,
    };
    
    const type = flat ? 'flat' : simple ? 'none' : 'default';
    
    const totalPages = (Math.ceil(total / limit)) || 0;
    
    const paginationButton = function(page) {
        let disabled = false;
        if (page !== value) {
            if (disablePage) {
                disabled ||= !!disablePage(page, limit);
            }
            if (disableBtn) {
                disabled ||= !!disableBtn(page, limit);
            }
        }
        return (
            _$cc(Button, {
                'type': type,
                'size': size,
                'icon': true,
                'value': page,
                'disabled': disabled,
                'children': page
            })
        );
    };
    
    const paginationDot = function(callback) {
        return _$cc(Button, {
            'type': type,
            'size': size,
            'icon': true,
            'className': _$cn(`${k}-pagination-ellipsis`),
            'ev-click': callback,
            'children': '...'
        })
    };
    
    let paginationItems = [];
    let minCount = Math.ceil(counts / 2);
    let maxCount = totalPages - minCount;
    let prevPage = value - 1;
    let nextPage = value + 1;
    
     if (!simple) {
        if (totalPages > counts) {
            if (value <= minCount) {
                const max = counts - 2;
                for (let i = 1; i <= max; i++) {
                    paginationItems.push(paginationButton(i));
                }
                paginationItems.push(paginationDot(this.fastNext));
                paginationItems.push(paginationButton(totalPages));
            } else if (value > minCount && value <= maxCount) {
                paginationItems.push(paginationButton(1));
                paginationItems.push(paginationDot(this.fastPrev));
    
                const midCount = counts - 4;
                const mid = Math.floor(midCount / 2);
                const min = value - mid;
                const max = midCount % 2 === 1 ? value + mid : value + mid - 1;
                for (let i = min; i <= max; i++) {
                    paginationItems.push(paginationButton(i));
                }
    
                paginationItems.push(paginationDot(this.fastNext));
                paginationItems.push(paginationButton(totalPages));
            } else {
                paginationItems.push(paginationButton(1));
                paginationItems.push(paginationDot(this.fastPrev));
    
                const min = totalPages - counts + 3;
                for (let i = min; i <= totalPages; i++) {
                    paginationItems.push(paginationButton(i));
                }
            }
        } else {
            for (let i = 1; i <= totalPages; i++) {
                paginationItems.push(paginationButton(i));
            }
        }
    } else {
        paginationItems = [
            _$cc(Input, {
                'size': size === 'mini' || size === 'small' ? 'mini' : 'small',
                'autoWidth': true,
                'value': value,
                'ev-change': this.goto
            }),
            _$ce(2, 'span', [
                _$ct(' / '),
                totalPages
            ], 0 /* UnknownChildren */, _$cn(`${k}-pagination-text`)),
        ];
    }
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        (showTotal && !simple) ?
            _$ce(2, 'div', _$('共 {n} 条', {n: total}), 0 /* UnknownChildren */, _$cn(`${k}-pagination-total`)) :
            undefined,
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'icon': true,
                    'size': size,
                    'type': 'none',
                    'disabled': value <= 1 || !!(disablePage && disablePage(value - 1, limit)),
                    'ev-click': this.prev,
                    'children': (
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-left`)
                        })
                    )
                }),
                paginationItems,
                _$cc(Button, {
                    'icon': true,
                    'size': size,
                    'type': 'none',
                    'disabled': value >= totalPages || !!(disablePage && disablePage(value + 1, limit)),
                    'ev-click': this.next,
                    'children': (
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-right`)
                        })
                    )
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }),
        (!simple) ?
            [
                (showLimits) ?
                    _$ce(2, 'div', (
                        _$cc(Select, {
                            'size': size,
                            'flat': flat,
                            'children': (
                                _$ma(limits, function($value, $key) {
                                    return _$cc(Option, {
                                        'value': $value,
                                        'children': _$('{n} 条 / 页', {n: $value})
                                    });
                                }, $this)
                            ),
                            'ev-$model:value': function($v) {
                                $this.set('limit', $v);
                            },
                            'value': $this.get('limit')
                        })
                    ), 2 /* HasVNodeChildren */, _$cn(`${k}-pagination-limits`)) :
                    undefined,
                (showGoto) ?
                    _$ce(2, 'div', [
                        _$ce(2, 'span', _$('前往'), 0 /* UnknownChildren */, 'c-middle'),
                        _$cc(Input, {
                            'size': size,
                            'value': value,
                            'ev-change': this.goto,
                            'flat': flat
                        }),
                        _$ce(2, 'span', _$('页'), 0 /* UnknownChildren */, 'c-middle')
                    ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-pagination-goto`)) :
                    undefined
            ] :
            undefined
    ]);
};