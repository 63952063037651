import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Pagination} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Pagination, {
            'total': 200,
            'ref': _$ref___test,
            'ev-change': this._onChange,
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }, null, _$ref___test),
        _$cc(Pagination, {
            'total': 200,
            'limit': 20,
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        }),
        _$cc(Pagination, {
            'total': 200,
            'limit': 20,
            'limits': [10, 20, 50, 100],
            'ev-$model:value': function($v) {
                $this.set('value3', $v);
            },
            'value': $this.get('value3')
        })
    ], 4 /* HasNonKeyedChildren */);
};