import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Colorpicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Colorpicker, {
            'ev-$model:value': function($v) {
                $this.set('color', $v);
            },
            'value': $this.get('color')
        }),
        _$ce(2, 'span', '\n        Hello, Colorpicker!\n    ', 16 /* HasTextChildren */, null, {
            'style': {color: this.get('color'), marginLeft: '10px', verticalAlign: 'middle'}
        })
    ], 4 /* HasNonKeyedChildren */);
};