import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    noop as _$no,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {DescriptionItem} from './item';
import {makeStyles} from './styles';
import {getRestProps} from '../utils';



export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {children, className, columns, title, vertical, items} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-descriptions`]: true,
        [`${k}-vertical`]: vertical,
        [className]: className,
        [makeStyles(k, columns)]: true,
    };
    
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj)
    }, [
        ($blocks.title || title) ?
            _$ce(2, 'div', (
                (
                    (_$blocks['title'] = function($super) {
                        return (
                            (title) ?
                                title :
                                undefined
                        );
                    }),
                    (__$blocks['title'] = function($super, data) {
                        var block = $blocks['title'];
                        var callBlock = function() {
                            return _$blocks['title'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['title'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-descriptions-title`)) :
            undefined,
        _$ce(2, 'div', (
            (items) ?
                (
                    _$ma(items, function($value, $key) {
                        return _$cc(DescriptionItem, {
                            'label': $value.label,
                            'content': $value.content,
                            'append': $value.append
                        });
                    }, $this)
                ) :
                children
        ), 0 /* UnknownChildren */, _$cn(`${k}-descriptions-content`))
    ]);
};