export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind, DrawerProps} from 'kpc';

interface Props {
    show?: boolean
    showPosition: DrawerProps['placement'] 
    showList: ListItem[]
    size: DrawerProps['size'] 
}

type ListItem = {
    key: DrawerProps['placement'] 
    value: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            show: false,
            showPosition: 'right',
            size: 'default',
            showList: [
                {
                    key: 'top',
                    value: '从上侧出现'
                },
                {
                    key: 'bottom',
                    value: '从下侧出现'
                },
                {
                    key: 'left',
                    value: '从左侧出现'
                },
                {
                    key: 'right',
                    value: '从右侧出现'
                }
            ]
        } as Props;
    }

    @bind
    showDrawer(value: DrawerProps['placement']) {
        this.set({
            'showPosition': value,
            'show': true
        });
    }
}