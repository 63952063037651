import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Select, {
            'searchable': true,
            'children': [
                _$cc(Option, {
                    'value': 'Monday',
                    'disabled': true,
                    'children': '星期一'
                }),
                _$cc(Option, {
                    'value': 'Tuesday',
                    'children': '星期二'
                }),
                _$cc(Option, {
                    'value': 'Wednesday',
                    'children': '星期三'
                }),
                _$cc(Option, {
                    'value': 'Thursday',
                    'children': '星期四'
                }),
                _$cc(Option, {
                    'value': 'Friday',
                    'children': '星期五'
                }),
                _$cc(Option, {
                    'value': 'Saturday',
                    'children': '星期六'
                }),
                _$cc(Option, {
                    'value': 'Sunday',
                    'children': '星期天'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('day', $v);
            },
            'value': $this.get('day')
        }),
        _$cc(Select, {
            'searchable': true,
            'multiple': true,
            'children': [
                _$cc(Option, {
                    'value': 'Monday',
                    'disabled': true,
                    'children': '星期一'
                }),
                _$cc(Option, {
                    'value': 'Tuesday',
                    'children': '星期二'
                }),
                _$cc(Option, {
                    'value': 'Wednesday',
                    'children': '星期三'
                }),
                _$cc(Option, {
                    'value': 'Thursday',
                    'children': '星期四'
                }),
                _$cc(Option, {
                    'value': 'Friday',
                    'children': '星期五'
                }),
                _$cc(Option, {
                    'value': 'Saturday',
                    'children': '星期六'
                }),
                _$cc(Option, {
                    'value': 'Sunday',
                    'children': '星期天'
                })
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['values'] = function($super, [value]) {
                            return (
                                _$ce(2, 'div', [
                                    _$ct('\n                已选择'),
                                    value.length,
                                    _$ct('项 / 共7项\n            ')
                                ], 0 /* UnknownChildren */, 'k-value')
                            );
                        }),
                        (__$blocks['values'] = function($super, data) {
                            var block = $blocks['values'];
                            var callBlock = function() {
                                return _$blocks['values'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:value': function($v) {
                $this.set('days', $v);
            },
            'value': $this.get('days')
        })
    ], 4 /* HasNonKeyedChildren */);
};