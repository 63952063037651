import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Select, Option} from 'kpc';

var _$tmp0 = {
    'autoDisableArrow': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Select, _$tmp0),
        _$cc(Select, {
            'autoDisableArrow': true,
            'clearable': true,
            'children': (
                _$cc(Option, {
                    'value': 'Monday',
                    'children': '星期一'
                })
            ),
            'ev-$model:value': function($v) {
                $this.set('day', $v);
            },
            'value': $this.get('day')
        })
    ], 4 /* HasNonKeyedChildren */);
};