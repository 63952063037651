import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'title': '姓名',
    'key': 'name',
    'sortable': true
};
var _$tmp1 = {
    'title': '年龄',
    'key': 'age',
    'sortable': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': this.get('data'),
        'sort': this.get('sort'),
        'ev-$change:sort': this._onSort,
        'rowKey': item => item.name,
        'resizable': true,
        'children': [
            _$cc(TableColumn, _$tmp0, 'name'),
            _$cc(TableColumn, _$tmp1, 'age')
        ]
    });
};