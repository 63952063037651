import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Card, Tabs, Tab} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Card, {
            'title': '用户信息',
            'children': [
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */),
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */)
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['extra'] = function($super) {
                            return (
                                _$cc(Tabs, {
                                    'value': '1',
                                    'size': 'small',
                                    'children': [
                                        _$cc(Tab, {
                                            'value': '1',
                                            'children': '数据集成'
                                        }),
                                        _$cc(Tab, {
                                            'value': '2',
                                            'children': '数据采集'
                                        })
                                    ]
                                })
                            );
                        }),
                        (__$blocks['extra'] = function($super, data) {
                            var block = $blocks['extra'];
                            var callBlock = function() {
                                return _$blocks['extra'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$cc(Card, {
            'title': '用户信息',
            'type': 'border',
            'children': [
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */),
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */)
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['extra'] = function($super) {
                            return (
                                _$cc(Tabs, {
                                    'value': '1',
                                    'size': 'small',
                                    'children': [
                                        _$cc(Tab, {
                                            'value': '1',
                                            'children': '数据集成'
                                        }),
                                        _$cc(Tab, {
                                            'value': '2',
                                            'children': '数据采集'
                                        })
                                    ]
                                })
                            );
                        }),
                        (__$blocks['extra'] = function($super, data) {
                            var block = $blocks['extra'];
                            var callBlock = function() {
                                return _$blocks['extra'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */);
};