import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {TreeSelect} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(TreeSelect, {
        'multiple': true,
        'data': this.get('data'),
        'ev-$model:value': function($v) {
            $this.set('values', $v);
        },
        'value': $this.get('values')
    });
};