import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'fixed': 'left',
    'key': 'name',
    'title': 'Name',
    'width': '200',
    'className': 'name'
};
var _$tmp1 = {
    'key': 'column2',
    'title': 'Column2',
    'width': '300'
};
var _$tmp2 = {
    'key': 'column3',
    'title': 'Column3',
    'width': '300'
};
var _$tmp3 = {
    'key': 'column4',
    'title': 'Column4',
    'width': '300'
};
var _$tmp4 = {
    'fixed': 'left',
    'key': 'name',
    'title': 'Name',
    'width': '200'
};
var _$tmp5 = {
    'key': 'column1',
    'title': 'Column1',
    'width': '300'
};
var _$tmp6 = {
    'key': 'column2',
    'title': 'Column2',
    'width': '300'
};
var _$tmp7 = {
    'key': 'column3',
    'title': 'Column3',
    'width': '300'
};
var _$tmp8 = {
    'key': 'column4',
    'title': 'Column4',
    'width': '300',
    'fixed': 'right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'resizable': true,
            'stickHeader': '64',
            'children': [
                _$cc(TableColumn, _$tmp0, 'name'),
                _$cc(TableColumn, {
                    'key': 'column1',
                    'title': 'Column1',
                    'width': '300',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['title'] = function($super) {
                                    return (
                                        _$ce(2, 'div', 'Column1', 16 /* HasTextChildren */)
                                    );
                                }),
                                (__$blocks['title'] = function($super, data) {
                                    var block = $blocks['title'];
                                    var callBlock = function() {
                                        return _$blocks['title'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'column1'),
                _$cc(TableColumn, _$tmp1, 'column2'),
                _$cc(TableColumn, _$tmp2, 'column3'),
                _$cc(TableColumn, _$tmp3, 'column4'),
                _$cc(TableColumn, {
                    'fixed': 'right',
                    'key': 'action',
                    'title': 'Action',
                    'width': '200',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, data) {
                                    return (
                                        _$ce(2, 'a', 'action', 16 /* HasTextChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'action')
            ]
        }),
        _$cc(Table, {
            'data': this.get('data'),
            'fixHeader': '150',
            'resizable': true,
            'ref': _$ref___test,
            'type': 'grid',
            'stickHeader': '64',
            'children': [
                _$cc(TableColumn, _$tmp4, 'name'),
                _$cc(TableColumn, _$tmp5, 'column1'),
                _$cc(TableColumn, _$tmp6, 'column2'),
                _$cc(TableColumn, _$tmp7, 'column3'),
                _$cc(TableColumn, _$tmp8, 'column4'),
                _$cc(TableColumn, {
                    'fixed': 'right',
                    'key': 'action',
                    'title': 'Action',
                    'width': '200',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, data) {
                                    return (
                                        _$ce(2, 'a', 'action', 16 /* HasTextChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'action')
            ]
        }, null, _$ref___test)
    ], 4 /* HasNonKeyedChildren */);
};