export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import tinycolor from 'tinycolor2';
import {bind} from 'kpc';

interface Props {
    value: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            value: '#ddd'
        };
    }

    @bind
    onInput(e: InputEvent) {
        const value = (e.target as HTMLInputElement).value.trim();
        const color = tinycolor(value);
        if (color.isValid()) {
            const newValue = color.toHexString();
            this.set('value', newValue);
        }
    }
}