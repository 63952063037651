import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tooltip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tooltip, {
            'content': 'hello',
            'children': '\n        hover the text\n    '
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tooltip, {
            'content': 'hello',
            'size': 'small',
            'children': '\n        small size\n    '
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tooltip, {
            'children': '\n        don\'t show anything if content is empty \n    '
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tooltip, {
            'content': 'hello',
            'theme': 'light',
            'children': '\n        light theme \n    '
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tooltip, {
            'content': 'hello',
            'disabled': true,
            'children': '\n        disabled\n    '
        })
    ], 4 /* HasNonKeyedChildren */);
};