import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {makeStyles} from './styles';
import {Footer} from '../../components/footer/';
import designer from '../../imgs/font/designer.png';
import initiator from '../../imgs/font/initiator.png'
import media from '../../imgs/font/媒介支持.png';
import cooperation from '../../imgs/font/生态合作.png';
import fontDownLoad from '../../imgs/font/字体下载.png';
import text_h5 from "../../imgs/font/text-h5.png";
import title_web from "../../imgs/font/title-web.png";
import source_nav_h5 from '../../imgs/font/banner-h5.jpg';

var _$tmp0 = {
    'bgColor': 'white'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const designer_list = [
        '宋辉', 
        '李业鹏',
        '刘雨凡',
        '郭嘉琦',
        '王鹏飞', 
        '张立明', 
        '解磊',
        '毛燕妮',
        '冯芷欣',
        '闫佳妮', 
        '张婧雨', 
        '王思雨', 
        '刘淼'
    ];
    
    const iconList = [
        {
            titleImg: initiator,
            icons: [
                {
                    icon: '1',
                    label: '金山软件'
                },
                {
                    icon: '2',
                    label: '金山云'
                }
            ]
        },
        {
            titleImg: cooperation,
            icons: [
                {
                    icon: '3',
                    label: 'WPS Office'
                },
                {
                    icon: '4',
                    label: '即时设计'
                }
            ]
        },
        {
            titleImg: fontDownLoad,
            icons: [
                {
                    icon: '字加',
                    label: '字加'
                },
                {
                    icon: '6',
                    label: '字由'
                },
                {
                    icon: '5',
                    label: '以方·iFont'
                },
                {
                    icon: '8',
                    label: '猫啃网'
                },
                {
                    icon: '9',
                    label: '字库网'
                },
                {
                    icon: '10',
                    label: '字体下载'
                },
            ]
        },
        {
            titleImg: media,
            icons: [
                {
                    icon: '4',
                    label: '即时设计'
                },
                {
                    icon: '11',
                    label: '国际体验设计'
                },
                {
                    icon: '搜狐新闻',
                    label: '搜狐新闻'
                },
                {
                    icon: '网易新闻',
                    label: '网易新闻'
                },
                {
                    icon: '腾讯新闻',
                    label: '腾讯新闻'
                },
                {
                    icon: '凤凰新闻',
                    label: '凤凰新闻'
                },
                {
                    icon: '13',
                    label: 'UI中国'
                },
                {
                    icon: '9',
                    label: '心愿盒子'
                },
                {
                    icon: '14',
                    label: 'ProtoPie'
                },
                {
                    icon: '8',
                    label: '猫啃网'
                },
                {
                    icon: '20',
                    label: '包小盒'
                },
                {
                    icon: '18',
                    label: 'Sketch中文网'
                },
                {
                    icon: '19',
                    label: 'XD 中文网'
                },
                {
                    icon: '21',
                    label: 'OurSketch'
                },
                {
                    icon: '最设计',
                    label: '最设计'
                },
                {
                    icon: 'GrayDesign',
                    label: 'GrayDesign'
                },
                {
                    icon: '23',
                    label: '体验进阶'
                },
                {
                    icon: '24',
                    label: 'UXD笔记'
                },
                {
                    icon: '22',
                    label: 'UI范'
                },
                {
                    icon: '31',
                    label: '功夫体验设计'
                },
                {
                    icon: '应谋鬼计',
                    label: '应谋鬼计'
                },
                {
                    icon: '26',
                    label: '设计达人'
                },
                {
                    icon: '29',
                    label: '庆科字体'
                },
                {
                    icon: '27',
                    label: 'UX实验室'
                },
                {
                    icon: '25',
                    label: '三分设'
                },
                {
                    icon: '30',
                    label: '静design'
                },
                {
                    icon: '金山范儿',
                    label: '金山范儿'
                },
                {
                    icon: '金山荟生活',
                    label: '金山荟生活'
                },
            ]
        },
    ];
    
    return _$su.call($this, {
        'className': _$cn(makeStyles()),
        'navIndex': 'font'
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['content'] = function($super) {
                    return [
                        _$ce(2, 'div', [
                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, 'bg-img', {
                                'src': source_nav_h5
                            }),
                            _$ce(2, 'div', (
                                _$ce(2, 'div', [
                                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                        'src': title_web
                                    }),
                                    _$ce(2, 'div', (
                                        _$ce(2, 'button', '免费下载', 16 /* HasTextChildren */, 'nav-btn-download', {
                                            'ev-click': this.handleDownLoad
                                        })
                                    ), 2 /* HasVNodeChildren */),
                                    _$ce(2, 'div', '已发布V1.0.1版,（修复了个别问题）', 16 /* HasTextChildren */, 'nav-content-notice')
                                ], 4 /* HasNonKeyedChildren */, 'nav-content-img')
                            ), 2 /* HasVNodeChildren */, 'font-nav-content')
                        ], 4 /* HasNonKeyedChildren */, 'font-nav'),
                        _$ce(2, 'div', (
                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                'src': text_h5
                            })
                        ), 2 /* HasVNodeChildren */, 'font-info'),
                        _$ce(2, 'div', (
                            _$ce(2, 'div', (
                                _$ma(iconList, function($value, $key) {
                                    return _$ce(2, 'div', [
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div'),
                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                'src': $value.titleImg
                                            }),
                                            _$ce(2, 'div')
                                        ], 4 /* HasNonKeyedChildren */, 'icon-box-title'),
                                        _$ce(2, 'div', (
                                            _$ma($value.icons, function($value, $key) {
                                                return _$ce(2, 'div', [
                                                    _$ce(2, 'div', (
                                                        _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                            'src': `../../imgs/font/${$value.icon}.png`
                                                        })
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', $value.label, 0 /* UnknownChildren */)
                                                ], 4 /* HasNonKeyedChildren */, 'icon-item');
                                            }, $this)
                                        ), 4 /* HasNonKeyedChildren */, 'icon-wrapper')
                                    ], 4 /* HasNonKeyedChildren */, 'icon-box');
                                }, $this)
                            ), 4 /* HasNonKeyedChildren */, 'icon-list')
                        ), 2 /* HasVNodeChildren */, 'icon-list-box'),
                        null,
                        _$cc(Footer, _$tmp0)
                    ];
                }),
                (__$blocks['content'] = function($super, data) {
                    var block = $blocks['content'];
                    var callBlock = function() {
                        return _$blocks['content'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};