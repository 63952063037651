import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'h4', '顶端对齐 top', 16 /* HasTextChildren */),
        _$cc(Row, {
            'align': 'top',
            'justify': 'center',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 100px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 60px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 80px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 40px',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '底部对齐 bottom', 16 /* HasTextChildren */),
        _$cc(Row, {
            'align': 'bottom',
            'justify': 'center',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 100px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 60px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 80px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 40px',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '居中对齐 middle', 16 /* HasTextChildren */),
        _$cc(Row, {
            'align': 'middle',
            'justify': 'center',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 100px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 60px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 80px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 40px',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '撑开成容器高度 stretch', 16 /* HasTextChildren */),
        _$cc(Row, {
            'align': 'stretch',
            'justify': 'center',
            'style': 'height: 100px;',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                })
            ]
        }),
        _$ce(2, 'h4', '基线对齐 baseline', 16 /* HasTextChildren */),
        _$cc(Row, {
            'align': 'baseline',
            'justify': 'center',
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 100px; font-size: 20px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 60px; font-size: 16px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 80px; font-size: 28px',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '4',
                    'style': 'height: 40px; font-size: 12px',
                    'children': 'col-4'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};