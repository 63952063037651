export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {UploadFileStatus, UploadFile} from 'kpc';

interface Props {
    files?: UploadFile[]
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            files: [
                {name: 'test1.jpg', url: 'http://www.example.com/test1.jpg'},
                {name: 'test2.png', url: 'http://www.example.com/test2.png'},
                {name: 'test3.png', status: UploadFileStatus.Error},
            ]
        } as Props;
    }
}