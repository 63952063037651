export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    value1?: boolean
    value2?: boolean | string 
    value3?: number | object
    checkedValue: object
}
export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            value1: false,
            value2: false,
            value3: 0,
            checkedValue: {
                checked: true
            }
        } as Props;
    };
}