import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Dropdown, DropdownMenu, DropdownItem, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-down'
};
var _$tmp1 = {
    'className': 'k-icon-right'
};
var _$tmp2 = {
    'className': 'k-icon-right'
};
var _$tmp3 = {
    'className': 'k-icon-right'
};
var _$tmp4 = {
    'className': 'k-icon-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Dropdown, {
            'trigger': 'click',
            'children': [
                _$cc(Button, {
                    'type': 'primary',
                    'children': [
                        _$ct('\n            click '),
                        _$cc(Icon, _$tmp0)
                    ]
                }),
                _$cc(DropdownMenu, {
                    'className': 'dropdown-demo',
                    'children': [
                        _$cc(DropdownItem, {
                            'children': 'item 1'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 2'
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 3'
                        }),
                        _$cc(Dropdown, {
                            'position': {my: 'left top', at: 'right top'},
                            'className': 'dropdown-demo',
                            'children': [
                                _$cc(DropdownItem, {
                                    'children': [
                                        _$ce(2, 'span', 'hover', 16 /* HasTextChildren */),
                                        _$cc(Icon, _$tmp1)
                                    ]
                                }),
                                _$cc(DropdownMenu, {
                                    'className': 'dropdown-demo',
                                    'children': [
                                        _$cc(Dropdown, {
                                            'position': {my: 'left top', at: 'right top'},
                                            'children': [
                                                _$cc(DropdownItem, {
                                                    'children': [
                                                        _$ce(2, 'span', 'hover', 16 /* HasTextChildren */),
                                                        _$cc(Icon, _$tmp2)
                                                    ]
                                                }),
                                                _$cc(DropdownMenu, {
                                                    'className': 'dropdown-demo',
                                                    'children': [
                                                        _$cc(DropdownItem, {
                                                            'children': 'item 1'
                                                        }),
                                                        _$cc(DropdownItem, {
                                                            'children': 'item 2'
                                                        })
                                                    ]
                                                })
                                            ]
                                        }),
                                        _$cc(DropdownItem, {
                                            'children': 'item 1'
                                        })
                                    ]
                                })
                            ]
                        }),
                        _$cc(Dropdown, {
                            'trigger': 'click',
                            'position': {my: 'left top', at: 'right top'},
                            'children': [
                                _$cc(DropdownItem, {
                                    'children': [
                                        _$ce(2, 'span', 'click', 16 /* HasTextChildren */),
                                        _$cc(Icon, _$tmp3)
                                    ]
                                }),
                                _$cc(DropdownMenu, {
                                    'className': 'dropdown-demo',
                                    'children': [
                                        _$cc(Dropdown, {
                                            'trigger': 'click',
                                            'position': {my: 'left top', at: 'right top'},
                                            'children': [
                                                _$cc(DropdownItem, {
                                                    'children': [
                                                        _$ce(2, 'span', 'click', 16 /* HasTextChildren */),
                                                        _$cc(Icon, _$tmp4)
                                                    ]
                                                }),
                                                _$cc(DropdownMenu, {
                                                    'className': 'dropdown-demo',
                                                    'children': [
                                                        _$cc(DropdownItem, {
                                                            'children': 'item 1'
                                                        }),
                                                        _$cc(DropdownItem, {
                                                            'children': 'item 2'
                                                        })
                                                    ]
                                                })
                                            ]
                                        }),
                                        _$cc(DropdownItem, {
                                            'children': 'item 1'
                                        })
                                    ]
                                })
                            ]
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 3'
                        })
                    ]
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};