export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import type {MenuProps} from 'kpc';

interface Props extends MenuProps { }

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            expandedKeys: [],
            selectedKey: '2',
            size: 'small',
            theme: 'light',
            showCollapseArrow: true,
            collapse: false,
        } as MenuProps;
    }
}