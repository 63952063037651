import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Row, {
        'children': [
            _$cc(Col, {
                'span': '18',
                'push': '6',
                'children': '1 col-18 push-6'
            }),
            _$cc(Col, {
                'span': '6',
                'pull': '18',
                'children': '2 col-6 push-18'
            })
        ]
    });
};