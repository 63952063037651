import {
    noop as _$no,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createVNode as _$cv,
    map as _$ma,
} from 'intact';
import {linkEvent} from 'intact';
import {Spinner} from '../spinner';
import {Tooltip} from '../tooltip';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        disabled, className, max,
        min, unit, showInput,
        range, step, value,
        spinnerProps, showEnd,
        showTooltip, animate, forceStep
    } = this.get();
    const {
        onStart, trackRef, dragging,
        firstThumbRef, secondThumbRef,
        onFocusin
    } = this.draggable;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-slider`]: true,
        [`${k}-disabled`]: disabled,
        [`${k}-dragging`]: dragging.value || !animate,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    const {barStyle, thumbFirstStyle, thumbSecondStyle} = this.styles();
    const {onSpinnerChange, onLeftSpinnerChange, onRightSpinnerChange} = this.value;
    const {onKeydown, onKeyup} = this.keyboard;
    const {firstTooltipRef, secondTooltipRef, getTooltipProps, hide} = this.tooltip;
    const tooltipProps = getTooltipProps();
    const tooltip = value => (
        (_$blocks['tooltip'] = function($super) {
            return value;
        }),
        (__$blocks['tooltip'] = function($super, data) {
            var block = $blocks['tooltip'];
            var callBlock = function() {
                return _$blocks['tooltip'].call($this, $super, data);
            };
            return block ?
                block.call($this, callBlock, data) :
                callBlock();
        }),
        __$blocks['tooltip'](_$no, value)
    )
    const createThumb = (thumbStyle, isFirst, ref, value, tooltipRef) => {
        return (
            _$ce(2, 'div', (
                _$cc(Tooltip, {
                    'disabled': !showTooltip,
                    'ref': tooltipRef,
                    ...tooltipProps,
                    'className': _$cn(`${k}-slider-tooltip`),
                    'children': (
                        _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-slider-thumb`), {
                            'tabindex': disabled ? '-1' : '0',
                            'ev-mousedown': onStart,
                            'ev-focusin': linkEvent(isFirst, onFocusin),
                            'ev-focusout': hide,
                            'ev-keydown': onKeydown,
                            'ev-keyup': onKeyup
                        }, null, ref)
                    ),
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['content'] = function($super) {
                                    return tooltip(value);
                                }),
                                (__$blocks['content'] = function($super, data) {
                                    var block = $blocks['content'];
                                    var callBlock = function() {
                                        return _$blocks['content'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, null, tooltipRef)
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-slider-thumb-wrapper`), {
                'style': thumbStyle
            })
        );
    };
    
    const {onClickTrack, setOneValue} = this.click;
    const points = this.points();
    const marks = this.marks();
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        _$ce(2, 'div', [
            _$ce(2, 'div', (
                _$ce(2, 'div', [
                    _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-slider-bar`), {
                        'style': barStyle
                    }),
                    createThumb(
                        thumbFirstStyle,
                        true,
                        firstThumbRef,
                        range ? value[0] : value,
                        firstTooltipRef,
                    ),
                    (range) ?
                        createThumb(
                            thumbSecondStyle,
                            false,
                            secondThumbRef,
                            value[1],
                            secondTooltipRef,
                        ) :
                        undefined,
                    _$ma(points, function($value, $key) {
                        return _$ce(2, 'i', null, 1 /* HasInvalidChildren */, _$cn({[`${k}-slider-point`]: true, [`${k}-active`]: $value.active}), {
                            'style': $value.style
                        });
                    }, $this)
                ], 0 /* UnknownChildren */, _$cn(`${k}-slider-track`), null, null, trackRef)
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-slider-track-wrapper`), {
                'ev-click': onClickTrack
            }),
            (marks.length) ?
                _$ce(2, 'div', (
                    _$ma(marks, function($value, $key) {
                        return _$ce(2, 'span', $value.text, 0 /* UnknownChildren */, _$cn({[`${k}-active`]: $value.active}), {
                            'style': $value.style,
                            'ev-click': linkEvent($value.value, setOneValue)
                        });
                    }, $this)
                ), 4 /* HasNonKeyedChildren */, _$cn(`${k}-slider-marks`)) :
                showEnd ?
                    _$ce(2, 'div', [
                        _$ce(2, 'span', min + unit, 0 /* UnknownChildren */, null, {
                            'ev-click': linkEvent(min, setOneValue)
                        }),
                        _$ce(2, 'span', max + unit, 0 /* UnknownChildren */, null, {
                            'ev-click': linkEvent(max, setOneValue)
                        })
                    ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-slider-ends`)) :
                    undefined
        ], 0 /* UnknownChildren */, _$cn(`${k}-slider-main`)),
        (showInput) ?
            _$ce(2, 'div', (() => {
                const props = {
                    disabled,
                    min,
                    max,
                    step: step || 0.01,
                    vertical: true,
                    suffix: unit,
                    forceStep: !!step && forceStep,
                };
            
                return [
                    _$cc(Spinner, {
                        ...props,
                        'value': range ? value[0] : value,
                        'ev-$change:value': range ? onLeftSpinnerChange : onSpinnerChange,
                        ...spinnerProps
                    }),
                    (range) ?
                        [
                            _$ce(2, 'span', '-', 16 /* HasTextChildren */, _$cn(`${k}-slider-spinner-sep`)),
                            _$cc(Spinner, {
                                ...props,
                                'min': value[0],
                                'value': range ? value[1] : value,
                                'ev-$change:value': onRightSpinnerChange,
                                ...spinnerProps
                            })
                        ] :
                        undefined
                ]
            })(), 0 /* UnknownChildren */, _$cn(`${k}-slider-spinner`)) :
            undefined
    ]);
};