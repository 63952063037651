export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

interface Props {
    policy?: string | null
    data: DataItem[]
    keywords?: string
    value?: string[]
}

type DataItem = {
    name: string
    policy: string
    members: number
    desc: string
    disabled?: boolean
}

function mockApi(policy: string) {
    const data: DataItem[] = [
        {name: 'AdministratorAccess', policy: 'system', 'members': 11, desc: '管理所有资源的权限'},
        {name: 'OSSFullAccess', policy: 'common', 'members': 11, desc: '管理所有资源的权限'},
        {name: 'SupportFullAccess', policy: 'common', 'members': 11, desc: '管理所有资源的权限'},
        {name: 'BSSFullSupportAccess', policy: 'common', 'members': 11, desc: '管理所有资源的权限', disabled: true},
        {name: 'SystemFullAccess', policy: 'system', 'members': 11, desc: '管理所有资源的权限'},
        {name: 'CommonAccess', policy: 'common', 'members': 11, desc: '管理所有资源的权限'},
    ];

    return new Promise<DataItem[]>(resolve => {
        setTimeout(() => {
            if (policy === 'all') resolve(data);
            else resolve(data.filter(item => item.policy === policy));
        }, 500);
    });
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            policy: 'all',
            data: [],
            keywords: '',
            value: [],
        } as Props;
    }

    private originData: DataItem[] | null = null;

    init() {
        // when policy changed, fetch data
        this.watch('policy', this.fetch);
        this.watch('keywords', this.filter);
        this.fetch();
    }

    @bind
    fetch() {
        mockApi(this.get('policy')!).then(data => {
            if (this.$unmounted) return;
            this.originData = data;
            this.filter();
        });
    }
    
    @bind
    filter() {
        const keywords = this.get('keywords');
        const data = keywords ? 
            this.originData!.filter(data => {
                return data.name.includes(keywords);
            }) :
            this.originData!;
        this.set('data', data);
    }
}