import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button, Dialog} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.set.bind(this, 'show', true),
            'type': 'primary',
            'children': 'Show Dialog'
        }),
        _$cc(Dialog, {
            'title': 'Dialog Title',
            'ev-ok': this.onOk,
            'ev-cancel': this.onCancel,
            'children': 'Dialog Body',
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        })
    ], 4 /* HasNonKeyedChildren */);
};