export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, TagProps} from 'kpc';

interface Props {
    tags: TagProps['size'][]
    sizes: TagProps['size'][]
    types: TagProps['type'][]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            sizes: ['large', 'default', 'small', 'mini'],
            tags: ['large', 'default', 'small', 'mini'],
            types: ['primary', 'warning', 'success', 'danger'],
        } as Props;
    };

    @bind
    onClose(index: number) {
        let tags = this.get('tags').slice(0);
        tags.splice(index, 1);
        this.set('tags', tags);
    }
}