import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-search',
    'size': 'large'
};
var _$tmp1 = {
    'className': 'k-icon-search'
};
var _$tmp2 = {
    'className': 'k-icon-search',
    'size': 'small'
};
var _$tmp3 = {
    'className': 'k-icon-search',
    'size': 'mini'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'size': 'large',
            'children': 'large'
        }),
        _$cc(Button, {
            'children': 'default'
        }),
        _$cc(Button, {
            'size': 'small',
            'children': 'small'
        }),
        _$cc(Button, {
            'size': 'mini',
            'children': 'mini'
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'size': 'large',
            'children': (
                _$cc(Icon, _$tmp0)
            )
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'children': (
                _$cc(Icon, _$tmp1)
            )
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'size': 'small',
            'children': (
                _$cc(Icon, _$tmp2)
            )
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'size': 'mini',
            'children': (
                _$cc(Icon, _$tmp3)
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};