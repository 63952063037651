export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {range, bind} from 'kpc/components/utils';

interface Props {
    data: DataItem[]
}

type DataItem = {
    name: string
    ip: string
}

const data: DataItem[] = range(1, 20).map(item => {
    return {
        name: 'name ' + item,
        ip: '127.0.0.' + item
    };
});

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            data, 
        };
    }

    @bind
    onChangePage(data: { value: number, limit: number }) {
        console.log(data);
    }
}