import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Search} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Search, {
            'ev-submit': this.search
        }),
        _$ce(2, 'br'),
        _$cc(Search, {
            'type': 'line',
            'ev-submit': this.search
        })
    ], 4 /* HasNonKeyedChildren */);
};