export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
   days1?: string[]
   days2?: string[]
}

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            days1: ['Monday', 'Tuesday', 'Wednesday', 'Thursday'],
            days2: ['Monday', 'Tuesday', 'Wednesday', 'Thursday'],
        } as Props;
    }
}