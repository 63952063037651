import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Table, TableColumn, Button} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Title 1'
};
var _$tmp1 = {
    'key': 'b',
    'title': 'Title 2'
};
var _$tmp2 = {
    'key': 'c',
    'title': 'Title 3'
};
var _$tmp3 = {
    'key': 'a',
    'title': 'Title 1'
};
var _$tmp4 = {
    'key': 'b',
    'title': 'Title 2'
};
var _$tmp5 = {
    'key': 'c',
    'title': 'Title 3'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.addRow,
            'children': '增加一行'
        }),
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.removeRow,
            'children': '删除一行'
        }),
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.toggleColumn,
            'children': [
                (this.get('isShow')) ?
                    _$ct('删除') :
                    '增加',
                _$ct('\n        一列\n    ')
            ]
        }),
        _$cc(Table, {
            'data': this.get('data'),
            'resizable': true,
            'rowKey': row => row.a,
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                (this.get('isShow')) ?
                    _$cc(TableColumn, _$tmp1, 'b') :
                    undefined,
                _$cc(TableColumn, _$tmp2, 'c')
            ]
        }),
        _$cc(Table, {
            'data': this.get('data'),
            'resizable': true,
            'rowKey': row => row.a,
            'animation': false,
            'children': [
                _$cc(TableColumn, _$tmp3, 'a'),
                (this.get('isShow')) ?
                    _$cc(TableColumn, _$tmp4, 'b') :
                    undefined,
                _$cc(TableColumn, _$tmp5, 'c')
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};