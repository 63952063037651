import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn, Checkbox} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '表头1'
};
var _$tmp1 = {
    'key': 'b',
    'title': '表头2'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'loading': this.get('loading'),
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        }),
        _$cc(Checkbox, {
            'children': '是否展示loading状态',
            'ev-$model:value': function($v) {
                $this.set('loading', $v);
            },
            'value': $this.get('loading')
        })
    ], 4 /* HasNonKeyedChildren */);
};