import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
} from 'intact';
import {ConfigProvider, Button, Input} from 'kpc';

var _$tmp0 = {
    'style': 'margin-top: 8px;'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(ConfigProvider, {
        'value': {classNamePrefix: 'kd'},
        'children': [
            _$cc(Button, {
                'ev-click': this.showMessage,
                'children': 'Button'
            }),
            _$ce(2, 'div', (
                _$cc(Input)
            ), 2 /* HasVNodeChildren */, null, _$tmp0)
        ]
    });
};