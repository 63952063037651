export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind} from 'kpc';

interface Props {
    users: any[]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            users: []
        } as Props;
    }

    private lastFetchId = 0;

    @bind
    search(e: Event) {
        const keywords = (e.target as HTMLInputElement).value.trim();

        if (!keywords) return;

        const fetchId = ++this.lastFetchId
        fetch('https://randomuser.me/api/?results=10')
            .then(response => response.json())
            .then(body => {
                if (fetchId !== this.lastFetchId) return;
                this.set('users', body.results);
            });
    }
}