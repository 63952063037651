export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message} from 'kpc';

export default class extends Component {
    static template = template;

    search(keywords: string) {
        Message.success('Search by keywords: ' + keywords);
    }
}