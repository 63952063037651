import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Descriptions, DescriptionItem, Copy, Button, Tooltip, Icon} from 'kpc';

var _$tmp0 = {
    'text': 'success'
};
var _$tmp1 = {
    'className': 'k-icon-question'
};
var _$tmp2 = {
    'className': 'k-icon-question'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Descriptions, {
            'title': '基础信息',
            'children': [
                _$cc(DescriptionItem, {
                    'label': 'label1',
                    'children': '\n            测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试\n            ',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['append'] = function($super) {
                                    return (
                                        _$cc(Copy, _$tmp0)
                                    );
                                }),
                                (__$blocks['append'] = function($super, data) {
                                    var block = $blocks['append'];
                                    var callBlock = function() {
                                        return _$blocks['append'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }),
                _$cc(DescriptionItem, {
                    'label': 'label2',
                    'children': '\n            测试\n        '
                }),
                _$cc(DescriptionItem, {
                    'label': 'label3',
                    'children': '\n            测试\n        ',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['label'] = function($super) {
                                    return [
                                        _$ce(2, 'span', '自定义label', 16 /* HasTextChildren */),
                                        _$cc(Tooltip, {
                                            'content': 'custom label',
                                            'children': (
                                                _$cc(Icon, _$tmp1)
                                            )
                                        })
                                    ];
                                }),
                                (__$blocks['label'] = function($super, data) {
                                    var block = $blocks['label'];
                                    var callBlock = function() {
                                        return _$blocks['label'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }),
                _$cc(DescriptionItem, {
                    'label': 'label4',
                    'children': [
                        _$ce(2, 'span', '测试4', 16 /* HasTextChildren */),
                        _$cc(Button, {
                            'type': 'link',
                            'children': '分配项目'
                        })
                    ]
                }),
                _$cc(DescriptionItem, {
                    'label': 'label5',
                    'children': '测试5'
                }),
                _$cc(DescriptionItem, {
                    'label': 'label6',
                    'children': '测试6'
                })
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['title'] = function($super) {
                            return [
                                _$ce(2, 'span', '自定义标题', 16 /* HasTextChildren */),
                                _$cc(Tooltip, {
                                    'content': 'custom title',
                                    'children': (
                                        _$cc(Icon, _$tmp2)
                                    )
                                })
                            ];
                        }),
                        (__$blocks['title'] = function($super, data) {
                            var block = $blocks['title'];
                            var callBlock = function() {
                                return _$blocks['title'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ), 2 /* HasVNodeChildren */);
};