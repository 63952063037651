import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {ConfigProvider, Button, Input, Checkbox, Radio, Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(ConfigProvider, {
        'value': {disableWave: true},
        'children': [
            _$cc(Button, {
                'children': 'Button'
            }),
            _$cc(Input),
            _$cc(Checkbox, {
                'children': 'checkbox',
                'ev-$model:value': function($v) {
                    $this.set('value', $v);
                },
                'value': $this.get('value')
            }),
            _$cc(Radio, {
                'children': 'radio',
                'ev-$model:value': function($v) {
                    $this.set('value', $v);
                },
                'value': $this.get('value')
            }),
            _$cc(Select, {
                'children': [
                    _$cc(Option, {
                        'value': 'Monday',
                        'children': '星期一'
                    }),
                    _$cc(Option, {
                        'value': 'Tuesday',
                        'children': '星期二'
                    }),
                    _$cc(Option, {
                        'value': 'Wednesday',
                        'children': '星期三'
                    }),
                    _$cc(Option, {
                        'value': 'Thursday',
                        'children': '星期四'
                    }),
                    _$cc(Option, {
                        'value': 'Friday',
                        'children': '星期五'
                    }),
                    _$cc(Option, {
                        'value': 'Saturday',
                        'children': '星期六'
                    }),
                    _$cc(Option, {
                        'value': 'Sunday',
                        'children': '星期天'
                    })
                ],
                'ev-$model:value': function($v) {
                    $this.set('day', $v);
                },
                'value': $this.get('day')
            })
        ]
    });
};