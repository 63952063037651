import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Datepicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Datepicker, {
            'placeholder': '开始日期',
            'max': this.get('to'),
            'min': new Date(),
            'ev-$model:value': function($v) {
                $this.set('from', $v);
            },
            'value': $this.get('from')
        }),
        _$ct(' ~\n    '),
        _$cc(Datepicker, {
            'placeholder': '结束日期',
            'min': this.get('from'),
            'ev-$model:value': function($v) {
                $this.set('to', $v);
            },
            'value': $this.get('to')
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'placeholder': '开始时间',
            'type': 'datetime',
            'max': this.get('toTime') || new Date(),
            'ev-$model:value': function($v) {
                $this.set('fromTime', $v);
            },
            'value': $this.get('fromTime')
        }),
        _$ct(' ~\n    '),
        _$cc(Datepicker, {
            'placeholder': '结束时间',
            'type': 'datetime',
            'min': this.get('fromTime'),
            'max': new Date(),
            'ev-$model:value': function($v) {
                $this.set('toTime', $v);
            },
            'value': $this.get('toTime')
        })
    ], 4 /* HasNonKeyedChildren */);
};