import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Code} from 'kpc/components/code';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Code, {
        'height': '200px',
        'readOnly': true,
        'ev-$model:value': function($v) {
            $this.set('value', $v);
        },
        'value': $this.get('value')
    });
};