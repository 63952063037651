import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Breadcrumb, BreadcrumbItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Breadcrumb, {
        'children': [
            _$cc(BreadcrumbItem, {
                'to': '#/',
                'children': 'item 1'
            }),
            _$cc(BreadcrumbItem, {
                'to': '/components/breadcrumb/',
                'children': 'item 2'
            }),
            _$cc(BreadcrumbItem, {
                'children': 'item 3'
            })
        ]
    });
};