import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Input, Icon} from 'kpc';

var _$tmp0 = {
    'clearable': true,
    'placeholder': 'please enter'
};
var _$tmp1 = {
    'value': 'disabled',
    'clearable': true,
    'disabled': true
};
var _$tmp2 = {
    'className': 'k-icon-search',
    'hoverable': true
};
var _$tmp3 = {
    'className': 'k-icon-search',
    'hoverable': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Input, _$tmp0),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp1),
        _$ce(2, 'br'),
        _$cc(Input, {
            'clearable': true,
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['suffix'] = function($super) {
                            return (
                                _$cc(Icon, _$tmp2)
                            );
                        }),
                        (__$blocks['suffix'] = function($super, data) {
                            var block = $blocks['suffix'];
                            var callBlock = function() {
                                return _$blocks['suffix'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'clearable': true,
            'stackClearIcon': true,
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['suffix'] = function($super) {
                            return (
                                _$cc(Icon, _$tmp3)
                            );
                        }),
                        (__$blocks['suffix'] = function($super, data) {
                            var block = $blocks['suffix'];
                            var callBlock = function() {
                                return _$blocks['suffix'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */);
};