import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Pagination} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Pagination, {
            'total': 200,
            'showGoto': true,
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        })
    ), 2 /* HasVNodeChildren */);
};