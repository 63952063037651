export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    value?: boolean,
    day?: string | null
}
export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            value: false,
            day: ''
        }
    }
}