export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {SliderMarks} from 'kpc';

interface Props {
    marks: SliderMarks
}

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            marks: {
                1: ['1', '1月'],
                2: ['2', '2月'],
                3: ['3', '3月'],
                4: ['4', '4月'],
                5: ['5', '5月'],
                6: ['6', '6月'],
                7: ['7', '7月'],
                8: ['8', '8月'],
                9: ['9', '9月'],
                10: ['1年'],
                11: '2年',
                12: '3年',
            }
        } as Props;
    }
}