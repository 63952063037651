export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

export default class extends Component {
    static template = template;

    @bind
    upload() {
        this.refs.instance.submit();
    }
}