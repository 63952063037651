import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Icon} from '../icon';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';

var _$tmp0 = {
    'attributeName': 'opacity',
    'begin': '0s',
    'dur': '2s',
    'repeatCount': 'indefinite',
    'from': '0.2',
    'to': '0'
};
var _$tmp1 = {
    'viewBox': '0 0 120 120'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        type, className, percent, size,
        showOuterText, showInnerText, strokeWidth, 
        children
    } = this.get();
    
    const { k } = this.config;
    const status = this.status.value;
    const background = this.color.value || '';
    
    const classNameObj = {
        [`${k}-progress`] : true,
        [`${k}-${type}`]: type,
        [className]: className,
        [`${k}-${status}`]: status !== 'normal',
        [`${k}-${size}`]: size !== 'default',
        [makeStyles(k)]: true,
    }
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        (type === 'bar') ?
            _$ce(2, 'div', (
                _$ce(2, 'div', (
                    _$ce(2, 'div', (
                        (showInnerText) ?
                            _$ce(2, 'div', [
                                percent,
                                _$ct(' %\n                ')
                            ], 0 /* UnknownChildren */, _$cn(`${k}-progress-inner-text`)) :
                            undefined
                    ), 0 /* UnknownChildren */, _$cn(`${k}-progress-bg`), {
                        'style': {width: percent + '%', background}
                    })
                ), 2 /* HasVNodeChildren */, _$cn(`${k}-progress-content`))
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-progress-container`)) :
            undefined,
        (type === 'circle') ?
            _$ce(512, 'svg', do {
                const r = (120 - strokeWidth) / 2;
                const circumference = 2 * Math.PI * r;
            
                [
                    _$ce(2, 'circle', null, 1 /* HasInvalidChildren */, _$cn(`${k}-progress-meter`), {
                        'cx': '60',
                        'cy': '60',
                        'r': r,
                        'stroke-width': strokeWidth
                    }),
                    _$ce(2, 'circle', null, 1 /* HasInvalidChildren */, _$cn(`${k}-progress-value`), {
                        'cx': '60',
                        'cy': '60',
                        'r': r,
                        'stroke-dashoffset': circumference * (1 - percent / 100),
                        'stroke-dasharray': circumference,
                        'stroke-width': strokeWidth
                    }),
                    (status === 'active') ?
                        _$ce(2, 'circle', [
                            _$ce(2, 'animate', null, 1 /* HasInvalidChildren */, null, {
                                'attributeName': 'stroke-dashoffset',
                                'begin': '0s',
                                'dur': '2s',
                                'repeatCount': 'indefinite',
                                'from': circumference,
                                'to': circumference * (1 - percent / 100)
                            }),
                            _$ce(2, 'animate', null, 1 /* HasInvalidChildren */, null, _$tmp0)
                        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-progress-animate`), {
                            'cx': '60',
                            'cy': '60',
                            'r': r,
                            'stroke-width': strokeWidth,
                            'stroke-dasharray': circumference,
                            'stroke-dashoffset': circumference
                        }) :
                        undefined
                ]
            }, 0 /* UnknownChildren */, _$cn(`${k}-progress-canvas`), _$tmp1) :
            undefined,
        (children) ?
            _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-progress-text`)) :
            showOuterText ?
                _$ce(2, 'div', do {
                    let icon;
                    if (type !== 'circle') {
                        switch (status) {
                            case 'success':
                                icon = [`${k}-icon-success-fill`];
                                break;
                            case 'error':
                                icon = [`${k}-icon-error-fill`];
                                break;
                            case 'warning':
                                icon = [`${k}-icon-warning-fill`];
                                break;
                            default:
                                break;
                        }
                    } else {
                        switch (status) {
                            case 'success':
                                icon = [`${k}-icon-check`];
                                break;
                            case 'error':
                            case 'warning':
                                icon = [`${k}-icon-close`];
                                break;
                            default:
                                break;
                        }
                    }
                
                    if (icon) {
                        _$cc(Icon, {
                            'className': _$cn({[`${k}-progress-icon`]: true, [icon]: true}),
                            'color': status === 'error' ? 'danger' : status
                        })
                    } else {
                        _$ce(2, 'span', [
                            percent,
                            _$ct('%')
                        ], 0 /* UnknownChildren */)
                    }
                }, 0 /* UnknownChildren */, _$cn(`${k}-progress-text`)) :
                undefined
    ]);
};