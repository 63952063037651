import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Code} from 'kpc/components/code';
import {ButtonGroup, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ct('\n    主题：'),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': (
                _$ma(['vs', 'vs-dark', 'hc-black'], function($value, $key) {
                    return _$cc(Button, {
                        'value': $value,
                        'children': $value
                    });
                }, $this)
            ),
            'ev-$model:value': function($v) {
                $this.set('theme', $v);
            },
            'value': $this.get('theme')
        }),
        _$cc(Code, {
            'height': '200px',
            'theme': this.get('theme'),
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        })
    ], 4 /* HasNonKeyedChildren */);
};