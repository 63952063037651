export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';


interface Props {
    border?: 'solid' | 'dashed' | 'dotted' | 'double'
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            border: 'dashed'
        } as Props;
    }
}