import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Slider} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Slider, {
            'step': 5,
            'showEnd': false,
            'ref': _$ref___test,
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }, null, _$ref___test),
        _$cc(Slider, {
            'min': 50,
            'max': 500,
            'step': 0.1,
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        }),
        _$cc(Slider, {
            'value': 0.234234,
            'max': 1,
            'step': 0
        })
    ], 4 /* HasNonKeyedChildren */);
};