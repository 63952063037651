export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import type {TransferDataItem} from 'kpc';

interface Props {
    data: TransferDataItem[]
    value?: number[]
}

const data = [
    {label: '主机名0', key: 0},
    {label: '主机名1', key: 1, disabled: true},
    {label: '主机名2', key: 2},
    {label: '主机名3', key: 3, disabled: true},
    {label: '主机名4', key: 4},
];

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: data,
            value: [0, 1]
        }
    }
}