import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Progress} from 'kpc';

var _$tmp0 = {
    'type': 'circle',
    'percent': '50'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Progress, _$tmp0)
    ), 2 /* HasVNodeChildren */);
};