export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    time1?: string | null
    time2?: string | null
    time3?: [string, string] | null
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            time1: null,
            time2: null,
            time3: null
        } as Props;
    };
}