import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Dialog, Form, FormItem, Input, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_dialog = function(i) {_$refs['dialog'] = i};
    var _$ref_form = function(i) {_$refs['form'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.set.bind(this, 'show', true),
            'children': 'Close Dialog Asynchronously'
        }),
        _$cc(Dialog, {
            'title': 'Customized ok callback',
            'ref': _$ref_dialog,
            'ok': this.ok,
            'children': (
                _$cc(Form, {
                    'ref': _$ref_form,
                    'children': (
                        _$cc(FormItem, {
                            'value': this.get('code'),
                            'rules': {required: true, digits: true},
                            'label': 'Code:',
                            'children': (
                                _$cc(Input, {
                                    'placeholder': 'please enter digits',
                                    'ev-$model:value': function($v) {
                                        $this.set('code', $v);
                                    },
                                    'value': $this.get('code')
                                })
                            )
                        })
                    )
                }, null, _$ref_form)
            ),
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        }, null, _$ref_dialog)
    ], 4 /* HasNonKeyedChildren */);
};