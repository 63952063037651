export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    effect: 'slide' | 'fade'
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            effect: 'fade'
        } as Props;
    };
}