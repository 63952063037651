import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '点击整行不选中'
};
var _$tmp1 = {
    'key': 'b',
    'title': 'B'
};
var _$tmp2 = {
    'key': 'a',
    'title': '点击整行选中'
};
var _$tmp3 = {
    'key': 'b',
    'title': 'B'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': [{a: 'A', b: 'B'}, {a: 'A', b: '点我不会选中整行'}],
            'rowCheckable': false,
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        }),
        _$cc(Table, {
            'data': [{a: 'A', b: '任何位置都可以哦～'}, {a: 'A', b: '点我会选中整行'}],
            'children': [
                _$cc(TableColumn, _$tmp2, 'a'),
                _$cc(TableColumn, _$tmp3, 'b')
            ]
        })
    ], 4 /* HasNonKeyedChildren */, 'tables');
};