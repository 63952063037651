import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Select, Option, OptionGroup} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Select, {
            'filterable': true,
            'children': [
                _$cc(OptionGroup, {
                    'label': '工作日',
                    'children': [
                        _$cc(Option, {
                            'value': 'Monday',
                            'children': '星期一'
                        }),
                        _$cc(Option, {
                            'value': 'Tuesday',
                            'children': '星期二'
                        }),
                        _$cc(Option, {
                            'value': 'Wednesday',
                            'children': '星期三'
                        }),
                        _$cc(Option, {
                            'value': 'Thursday',
                            'children': '星期四'
                        }),
                        _$cc(Option, {
                            'value': 'Friday',
                            'children': '星期五'
                        })
                    ]
                }),
                _$cc(OptionGroup, {
                    'label': '休息日',
                    'children': [
                        _$cc(Option, {
                            'value': 'Saturday',
                            'children': '星期六'
                        }),
                        _$cc(Option, {
                            'value': 'Sunday',
                            'children': '星期天'
                        })
                    ]
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('day', $v);
            },
            'value': $this.get('day')
        }),
        _$cc(Select, {
            'card': true,
            'filterable': true,
            'children': [
                _$cc(OptionGroup, {
                    'label': '工作日',
                    'children': [
                        _$cc(Option, {
                            'value': 'Monday',
                            'children': '星期一'
                        }),
                        _$cc(Option, {
                            'value': 'Tuesday',
                            'children': '星期二'
                        }),
                        _$cc(Option, {
                            'value': 'Wednesday',
                            'children': '星期三'
                        }),
                        _$cc(Option, {
                            'value': 'Thursday',
                            'children': '星期四'
                        }),
                        _$cc(Option, {
                            'value': 'Friday',
                            'children': '星期五'
                        })
                    ]
                }),
                _$cc(OptionGroup, {
                    'children': [
                        _$cc(Option, {
                            'value': 'Saturday',
                            'children': '星期六'
                        }),
                        _$cc(Option, {
                            'value': 'Sunday',
                            'children': '星期天'
                        })
                    ],
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['label'] = function($super) {
                                    return [
                                        _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-wineglass'),
                                        _$ct('休息日')
                                    ];
                                }),
                                (__$blocks['label'] = function($super, data) {
                                    var block = $blocks['label'];
                                    var callBlock = function() {
                                        return _$blocks['label'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('day', $v);
            },
            'value': $this.get('day')
        })
    ], 4 /* HasNonKeyedChildren */);
};