export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import type {FormProps} from 'kpc';

interface Props {
    model: Model
    layout: FormProps['layout']
    size: FormProps['size']
}

type Model = {
    input?: string
    select?: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            model: {},
            layout: 'horizontal',
            size: 'default'
        } as Props;
    }
}