import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tree} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Tree, {
        'data': this.get('data'),
        'checkbox': true,
        'load': this.loadData
    });
};