import {
    createElementVNode as _$ce,
} from 'intact';

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, href} = this.get();
    
    return _$ce(2, 'a', children, 0 /* UnknownChildren */, null, {
        'href': href,
        'ev-click': this.goTo
    });
};