import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Popover, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Popover, {
            'title': '确定删除？',
            'ev-ok': this.ok,
            'ev-cancel': this.cancel,
            'okText': 'confirm',
            'cancelText': 'cancel',
            'children': (
                _$cc(Button, {
                    'children': '删除'
                })
            )
        })
    ), 2 /* HasVNodeChildren */);
};