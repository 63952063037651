const style = {
    defaultVertex: {
        "shape": "label",
        "perimeter": "rectanglePerimeter",
        "fontSize": 12,
        "fontFamily": "Helvetica",
        "align": "center",
        "verticalAlign": "middle",
        "fillColor": "#ffffff",
        "strokeColor": "#000000",
        "fontColor": "#000000"
    },
    defaultEdge: {
        "shape": "connector",
        "labelBackgroundColor": "#ffffff",
        "endArrow": "classic",
        "fontSize": 11,
        "fontFamily": "Helvetica",
        "align": "center",
        "verticalAlign": "middle",
        "rounded": 1,
        "strokeColor": "#000000",
        "fontColor": "#000000"
    },
    text: {
        "fillColor": "none",
        "gradientColor": "none",
        "strokeColor": "none",
        "align": "left",
        "verticalAlign": "top"
    },
    label: {
        "fontStyle": 1,
        "align": "left",
        "verticalAlign": "middle",
        "spacing": 2,
        "spacingLeft": 52,
        "imageWidth": 42,
        "imageHeight": 42,
        "rounded": 1
    },
    icon: {
        "fontStyle": 0,
        "align": "center",
        "verticalAlign": "top",
        "spacing": 0,
        "spacingLeft": 0,
        "imageWidth": 48,
        "imageHeight": 48,
        "rounded": 1,
        "imageAlign": "center",
        "verticalLabelPosition": "bottom",
        "spacingTop": 6,
        "labelBackgroundColor": "#ffffff"
    },
    swimline: {
        "shape": "swimlane",
        "fontSize": 12,
        "fontStyle": 1,
        "startSize": 23
    },
    group: {
        "verticalAlign": "top",
        "fillColor": "none",
        "strokeColor": "none",
        "gradientColor": "none",
        "pointerEvents": 0
    },
    ellipse: {
        "shape": "ellipse",
        "perimeter": "ellipsePerimeter"
    },
    rhombus: {
        "shape": "rhombus",
        "perimeter": "rhombusPerimeter"
    },
    triangle: {
        "shape": "triangle",
        "perimeter": "trianglePerimeter"
    },
    line: {
        "shape": "line",
        "strokeWidth": 4,
        "labelBackgroundColor": "#ffffff",
        "verticalAlign": "top",
        "spacingTop": 8
    },
    image: {
        "shape": "image",
        "labelBackgroundColor": "white",
        "verticalAlign": "top",
        "verticalLabelPosition": "bottom"
    },
    roundImage: {
        "shape": "image",
        "labelBackgroundColor": "white",
        "verticalAlign": "top",
        "verticalLabelPosition": "bottom",
        "perimeter": "ellipsePerimeter"
    },
    rhombusImage: {
        "shape": "image",
        "labelBackgroundColor": "white",
        "verticalAlign": "top",
        "verticalLabelPosition": "bottom",
        "perimeter": "rhombusPerimeter"
    },
    arrow: {
        "shape": "arrow",
        "edgeStyle": "none",
        "fillColor": "#ffffff"
    },
};

export default style;
