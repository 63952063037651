import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    noop as _$no,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button} from '../button';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        showArrow, confirm, className,
        theme, hoverable, trigger,
        value, cancelText, okText,
        size, always,
    } = this.dropdown.get();
    const {children} = this.get();
    const {arrowRef, arrowType} = this.arrow;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-tooltip-content`]: true,
        [`${k}-hoverable`]: hoverable || trigger === 'click',
        [`${k}-always`]: always,
        [`${k}-${theme}`]: true,
        [`${k}-${size}`]: size !== 'default',
        [className]: className,
        [makeStyles(k)]: true,
        [$props.className]: $props.className,
    };
    
    return _$su.call($this, {
        'value': !!value && !this.isEmptyChildren,
        'transition': {
            name: `${k}-fade`,
            onEnter: this.onEnter,
            onAfterLeave: this.onAfterLeave,
            onLeaveCancelled: this.onAfterLeave,
        },
        'className': _$cn(classNameObj)
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['children'] = function($super) {
                    return [
                        (
                            (_$blocks['body'] = function($super) {
                                return children;
                            }),
                            (__$blocks['body'] = function($super, data) {
                                var block = $blocks['body'];
                                var callBlock = function() {
                                    return _$blocks['body'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            }),
                            __$blocks['body'](_$no)
                        ),
                        (confirm) ?
                            _$ce(2, 'div', (
                                (
                                    (_$blocks['footer'] = function($super) {
                                        return [
                                            _$cc(Button, {
                                                'ev-click': this.cancel,
                                                'size': 'small',
                                                'children': cancelText
                                            }),
                                            _$cc(Button, {
                                                'type': 'primary',
                                                'ev-click': this.ok,
                                                'size': 'small',
                                                'children': okText
                                            })
                                        ];
                                    }),
                                    (__$blocks['footer'] = function($super, data) {
                                        var block = $blocks['footer'];
                                        var callBlock = function() {
                                            return _$blocks['footer'].call($this, $super, data);
                                        };
                                        return block ?
                                            block.call($this, callBlock, data) :
                                            callBlock();
                                    }),
                                    __$blocks['footer'](_$no)
                                )
                            ), 0 /* UnknownChildren */, _$cn(`${k}-tooltip-footer`), null, 'tooltip-footer') :
                            undefined,
                        (showArrow) ?
                            _$ce(2, 'i', null, 1 /* HasInvalidChildren */, _$cn({
                                [`${k}-tooltip-arrow`]: true,
                                [`${k}-${arrowType.value}`]: arrowType.value,
                            }), null, 'tooltip-arrow', arrowRef) :
                            undefined
                    ];
                }),
                (__$blocks['children'] = function($super, data) {
                    var block = $blocks['children'];
                    var callBlock = function() {
                        return _$blocks['children'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};