export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message,bind} from 'kpc';

export default class extends Component {
    static template = template;

    @bind
    remove() {
        Message.warning("Clicked remove!");
        this.refs.popover.hide();
    }

    @bind
    moveToTrash() {
        Message.success('Clicked move to trash!');
        this.refs.popover.hide();
    }

    @bind
    cancel() {
        Message.info('Clicked cancel!');
        this.refs.popover.hide();
    }
}