import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Pagination} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Pagination, {
            'total': 200,
            'disableBtn': this.disableBtn,
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }),
        _$cc(Pagination, {
            'total': 200,
            'showGoto': true,
            'disablePage': this.disablePage,
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        })
    ], 4 /* HasNonKeyedChildren */);
};