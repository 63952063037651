import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {placement, overlay} = this.get();
    const { k } = this.config;
    const classNameObj = {
        [`${k}-drawer`]: true,
        [`${k}-${placement}`]: true,
        [makeStyles(overlay, k)]: true
    };
    
    return _$su.call($this, {
        'className': _$cn(classNameObj)
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['_content'] = function($super) {
                    return (
                        _$ce(2, 'div', $super(), 0 /* UnknownChildren */, _$cn(`${k}-drawer-content`))
                    );
                }),
                (__$blocks['_content'] = function($super, data) {
                    var block = $blocks['_content'];
                    var callBlock = function() {
                        return _$blocks['_content'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};