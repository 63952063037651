import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Radio} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Radio, {
            'children': [
                _$ct('默认值：'),
                JSON.stringify(this.get('value1'))
            ],
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }),
        _$cc(Radio, {
            'trueValue': 'checked',
            'children': [
                _$ct('\n        指定选中时的取值为\"checked\": '),
                JSON.stringify(this.get('value2'))
            ],
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        }),
        _$cc(Radio, {
            'trueValue': this.get('checkedValue'),
            'children': [
                _$ct('\n        指定选中时的取值为对象：'),
                JSON.stringify(this.get('value3'))
            ],
            'ev-$model:value': function($v) {
                $this.set('value3', $v);
            },
            'value': $this.get('value3')
        })
    ], 4 /* HasNonKeyedChildren */);
};