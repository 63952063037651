export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    loading?: boolean
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            loading: true,
            data: [
                {a: '第一行', b: '哈哈1'}, 
                {a: '第二行', b: '哈哈2'},
                {a: '第三行', b: '哈哈3'}
            ]
        };
    }
}