import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    createTextVNode as _$ct,
} from 'intact';
import {Transfer} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$cc(Transfer, {
        'data': this.get('data'),
        'filter': this.filter,
        'filterable': true,
        'keyName': 'name',
        'ref': _$ref___test,
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['label'] = function($super, [data, index, type]) {
                        return (
                            _$ce(2, 'div', [
                                _$ce(2, 'div', data.name, 0 /* UnknownChildren */),
                                _$ce(2, 'p', [
                                    data.desc,
                                    _$ct(' | '),
                                    data.ip
                                ], 0 /* UnknownChildren */)
                            ], 4 /* HasNonKeyedChildren */)
                        );
                    }),
                    (__$blocks['label'] = function($super, data) {
                        var block = $blocks['label'];
                        var callBlock = function() {
                            return _$blocks['label'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    }, null, _$ref___test);
};