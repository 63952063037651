import {
    createElementVNode as _$ce,
} from 'intact';

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    
    const {width, height, style} = this.get();
    
    return _$ce(2, 'iframe', null, 1 /* HasInvalidChildren */, null, {
        'src': this.get('href'),
        'frameborder': '0',
        'width': width == 0 ? 'auto' : width,
        'height': height == 0 ? 'auto' : height,
        'style': {...style}
    }, null, this.frameRef);
};