import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Menu, MenuItem, Icon, MenuTitle, Switch, ButtonGroup, Button, Divider} from 'kpc';

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'value': 'light',
                    'children': 'light'
                }),
                _$cc(Button, {
                    'value': 'dark',
                    'children': 'dark'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('theme', $v);
            },
            'value': $this.get('theme')
        }),
        _$cc(Switch, {
            'on': '展示折叠按钮',
            'off': '隐藏折叠按钮',
            'width': '110',
            'trueValue': true,
            'falseValue': false,
            'ev-$model:value': function($v) {
                $this.set('showCollapseArrow', $v);
            },
            'value': $this.get('showCollapseArrow')
        }),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'value': 'large',
                    'children': 'large'
                }),
                _$cc(Button, {
                    'value': 'default',
                    'children': 'default'
                }),
                _$cc(Button, {
                    'value': 'small',
                    'children': 'small'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('size', $v);
            },
            'value': $this.get('size')
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Menu, {
            'theme': this.get('theme'),
            'size': this.get('size'),
            'showCollapseArrow': this.get('showCollapseArrow'),
            'children': [
                _$cc(MenuItem, {
                    'key': '0',
                    'children': 'menu 0'
                }, '0'),
                _$cc(MenuTitle, {
                    'children': 'title 1'
                }),
                _$cc(MenuItem, {
                    'key': '1',
                    'children': 'menu 1'
                }, '1'),
                _$cc(MenuItem, {
                    'key': '2',
                    'children': 'menu 2'
                }, '2'),
                _$cc(MenuItem, {
                    'key': '3',
                    'children': 'menu 3menu 3menu 3menu 3menu 3menu 3'
                }, '3'),
                _$cc(MenuTitle, {
                    'children': 'title 2'
                }),
                _$cc(MenuItem, {
                    'key': '4',
                    'children': 'menu 1'
                }, '4'),
                _$cc(MenuItem, {
                    'key': '5',
                    'disabled': true,
                    'children': 'menu 2'
                }, '5'),
                _$cc(MenuItem, {
                    'key': '6',
                    'children': [
                        _$ct('\n            sub menu 6\n            '),
                        _$cc(Menu, {
                            'children': [
                                _$cc(MenuItem, {
                                    'key': '6-1',
                                    'children': 'sub menu 1'
                                }, '6-1'),
                                _$cc(MenuItem, {
                                    'key': '6-2',
                                    'children': 'sub menu 2'
                                }, '6-2'),
                                _$cc(MenuItem, {
                                    'key': '6-3',
                                    'disabled': true,
                                    'children': 'sub menu 3'
                                }, '6-3'),
                                _$cc(MenuItem, {
                                    'key': '6-4',
                                    'children': [
                                        _$ct('\n                    sub menu 7\n                    '),
                                        _$cc(Menu, {
                                            'children': [
                                                _$cc(MenuItem, {
                                                    'key': '6-7-1',
                                                    'children': 'option 1'
                                                }, '6-7-1'),
                                                _$cc(MenuItem, {
                                                    'key': '6-7-2',
                                                    'children': 'option 2'
                                                }, '6-7-2')
                                            ]
                                        })
                                    ]
                                }, '6-4')
                            ]
                        })
                    ]
                }, '6'),
                _$cc(Divider, {
                    'theme': this.get('theme') === 'white' ? 'light' : this.get('theme'),
                    'className': 'divider-style'
                }),
                _$cc(MenuItem, {
                    'key': '8',
                    'to': '/',
                    'children': 'menu 8'
                }, '8')
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['header'] = function($super) {
                            return '\n            自定义Header\n        ';
                        }),
                        (__$blocks['header'] = function($super, data) {
                            var block = $blocks['header'];
                            var callBlock = function() {
                                return _$blocks['header'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:expandedKeys': function($v) {
                $this.set('expandedKeys', $v);
            },
            'expandedKeys': $this.get('expandedKeys'),
            'ev-$model:selectedKey': function($v) {
                $this.set('selectedKey', $v);
            },
            'selectedKey': $this.get('selectedKey'),
            'ev-$model:collapse': function($v) {
                $this.set('collapse', $v);
            },
            'collapse': $this.get('collapse')
        })
    ], 4 /* HasNonKeyedChildren */);
};