export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Dialog, bind} from 'kpc';

interface Props {
    show?: boolean
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            show: false
        }
    }

    @bind
    onClick() {
        this.set('show', true);
    }

    @bind
    terminate() {
        Dialog.confirm({
            content: 'Are you sure you want to close the dialog?'
        }).then(() => {
            this.set('show', false);
        }, () => {});
    }
}