import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'rowClassName'
};
var _$tmp1 = {
    'key': 'a',
    'title': 'stripe'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': [{a: '1'}, {a: '2'}, {a: '3'}, {a: '4'}, {a: '5'}],
            'rowClassName': (value, index) => index % 2 === 0 ? 'odd' : 'even',
            'children': (
                _$cc(TableColumn, _$tmp0, 'a')
            )
        }),
        _$cc(Table, {
            'data': [{a: '1'}, {a: '2'}, {a: '3'}, {a: '4'}, {a: '5'}],
            'stripe': true,
            'children': (
                _$cc(TableColumn, _$tmp1, 'a')
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};