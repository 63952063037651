import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    className as _$cn,
} from 'intact';
import {Button, Icon} from 'kpc';
import {makeStyles} from './styles';

var _$tmp0 = {
    'className': 'icon ion-plus'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        buttonStatus, contentInput, iconPosition
    } = this.get();
    
    const innerButton = () => {
        const buttonContent = _$ce(2, 'span', contentInput, 0 /* UnknownChildren */)
        const buttonIcon = _$cc(Icon, _$tmp0)
        const isLeft = iconPosition == 'left'
        
        return _$cc(Button, {
            'type': buttonStatus,
            'children': [
                isLeft ? buttonIcon : buttonContent,
                _$ct('\n        '),
                isLeft ? buttonContent : buttonIcon
            ]
        })
    }
    
    return _$ce(2, 'div', innerButton(), 0 /* UnknownChildren */, _$cn(makeStyles()));
};