export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {Message, bind} from 'kpc';

interface Props {
    model: Model
}

type Model = {
    input?: string
    select?: string
    checkbox: string[] 
    radio?: string
    buttonGroup?: string
    switch?: boolean
    slider?: number
    date?: string
    textarea?: string
    password?: string
    confirmPassword?: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            model: {
                checkbox: []
            }
        } as Props;
    }

    @bind
    submit() {
        Message.success('验证通过，开始提交');
        console.log(this.get('model'));
    }

    @bind
    async handleSubmit() {
        if (await this.refs.form.validate()) {
            console.log('验证通过，开始提交');
        } else {
            // 验证失败，我们可以获取第一条出错的FormItem
            console.log(this.refs.form.getFirstInvalidFormItem());
        }
    }

    @bind
    reset() {
        this.set('model', {checkbox: []});
        this.refs.form.reset();
        console.log(this.get('model'));
    }
}