import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Affix, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    
    return _$ce(2, 'div', [
        _$cc(Affix, {
            'top': 150,
            'shouldFix': this.shouldFix,
            'children': (
                _$cc(Button, {
                    'type': 'primary',
                    'children': 'fix at 150px from the top when scroll top 100px'
                })
            )
        }),
        _$cc(Affix, {
            'bottom': this.get('bottom'),
            'exclude': this.exclude,
            'children': (
                _$cc(Button, {
                    'type': 'primary',
                    'children': 'fix at the bottom only when this demo is in viewport'
                })
            )
        })
    ], 4 /* HasNonKeyedChildren */, null, null, null, function(i) {_$refs['node'] = i});
};