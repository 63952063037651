import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '表头1'
};
var _$tmp1 = {
    'key': 'b',
    'title': '表头2'
};
var _$tmp2 = {
    'key': 'a',
    'title': '表头1'
};
var _$tmp3 = {
    'key': 'b',
    'title': '表头2'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test1 = function(i) {_$refs['__test1'] = i};
    var _$ref___test2 = function(i) {_$refs['__test2'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'rowSelectable': true,
            'ev-$change:selectedKeys': this.onSelectedKeysChanged,
            'rowCheckable': false,
            'ref': _$ref___test1,
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        }, null, _$ref___test1),
        _$cc(Table, {
            'data': this.get('data'),
            'rowSelectable': 'multiple',
            'rowCheckable': false,
            'ref': _$ref___test2,
            'children': [
                _$cc(TableColumn, _$tmp2, 'a'),
                _$cc(TableColumn, _$tmp3, 'b')
            ]
        }, null, _$ref___test2)
    ], 4 /* HasNonKeyedChildren */);
};