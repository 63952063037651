import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Steps, Step} from 'kpc';

var _$tmp0 = {
    'title': '设置VPC'
};
var _$tmp1 = {
    'title': '设置VPC'
};
var _$tmp2 = {
    'title': '设置VPC'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Steps, {
            'value': 1,
            'status': 'error',
            'children': [
                _$cc(Step, {
                    'title': '选择配置',
                    'children': '请选择主机的配置信息'
                }),
                _$cc(Step, {
                    'title': '选择弹性IP',
                    'children': '请选择主机弹性IP的配置信息'
                }),
                _$cc(Step, _$tmp0)
            ]
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Steps, {
            'value': 1,
            'status': 'error',
            'type': 'line',
            'children': [
                _$cc(Step, {
                    'title': '选择配置',
                    'children': '请选择主机的配置信息'
                }),
                _$cc(Step, {
                    'title': '选择弹性IP',
                    'children': '请选择主机弹性IP的配置信息'
                }),
                _$cc(Step, _$tmp1)
            ]
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Steps, {
            'value': 1,
            'status': 'error',
            'type': 'simple',
            'children': [
                _$cc(Step, {
                    'title': '选择配置',
                    'children': '请选择主机的配置信息'
                }),
                _$cc(Step, {
                    'title': '选择弹性IP',
                    'children': '请选择主机弹性IP的配置信息'
                }),
                _$cc(Step, _$tmp2)
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};