import {
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {CollapseItem} from './item';
import {mapChildren, getRestProps} from '../utils';
import {makeStyles} from './styles';
import {isNullOrUndefined} from 'intact-shared';
import {directClone} from 'intact';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, className, arrow, noBorder} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-collapse`]: true,
        [makeStyles(k)]: true,
        [className]: className,
        [`${k}-left`]: arrow === 'left',
        [`${k}-border`]: !noBorder,
    };
    
    const _children = mapChildren(children, (vNode, index) => {
        if(vNode.tag === CollapseItem) {
            const props = {...vNode.props};
            if (isNullOrUndefined(props.value)) {
                props.value = `$${index}`;
            }
            const clonedVNode = directClone(vNode);
            clonedVNode.props = props;
    
            return clonedVNode; 
        }
    
        return vNode;
    });
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, _children);
};