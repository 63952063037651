import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    map as _$ma,
    className as _$cn,
} from 'intact';
import {TransitionGroup, Transition} from 'intact';
import {makeMessagesStyles} from './styles';
import { ConfigProvider } from '../config';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const k =  this.get('classNamePrefix') || this.config.k;
    
    return _$cc(ConfigProvider, {
        'value': {classNamePrefix: k},
        'children': (
            _$ce(2, 'div', (
                _$cc(TransitionGroup, {
                    'children': (
                        _$ma(this.messages, function($value, $key) {
                            return $value;
                        }, $this)
                    )
                })
            ), 2 /* HasVNodeChildren */, _$cn({[`${k}-messages`]: true, [makeMessagesStyles(k)]: true}))
        )
    });
};