import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Card, Divider, Icon} from 'kpc';

var _$tmp0 = {
    'type': 'vertical'
};
var _$tmp1 = {
    'href': '#'
};
var _$tmp2 = {
    'href': '#'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ct('\n    Text\n    '),
        _$cc(Divider, _$tmp0),
        _$ce(2, 'a', 'Link', 16 /* HasTextChildren */, null, _$tmp1),
        _$cc(Divider, {
            'type': 'vertical',
            'children': 'Text'
        }),
        _$ce(2, 'a', 'Link', 16 /* HasTextChildren */, null, _$tmp2)
    ], 4 /* HasNonKeyedChildren */, 'divider-content');
};