export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    date?: string[]
    datetime?: string[]
    year?: string[]
    month?: string[]
    dateRange?: [string, string][]
    datetimeRange?: [string, string][]
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            date: [],
            datetime: [],
            year: [],
            month: [],
            dateRange: [],
            datetimeRange: [],
        } as Props;
    }
}