import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
    className as _$cn,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'title': '点击整行展开内容',
    'key': 'name'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test1 = function(i) {_$refs['__test1'] = i};
    var _$ref___test2 = function(i) {_$refs['__test2'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'checkType': 'none',
            'ref': _$ref___test1,
            'children': (
                _$cc(TableColumn, _$tmp0, 'name')
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['expand'] = function($super, [data, index]) {
                            return (
                                _$ce(2, 'div', [
                                    _$ct('Email: '),
                                    data.email
                                ], 0 /* UnknownChildren */, 'expand')
                            );
                        }),
                        (__$blocks['expand'] = function($super, data) {
                            var block = $blocks['expand'];
                            var callBlock = function() {
                                return _$blocks['expand'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }, null, _$ref___test1),
        _$cc(Table, {
            'data': this.get('data'),
            'checkType': 'none',
            'rowExpandable': false,
            'expandedKeys': this.get('expandedKeys'),
            'ref': _$ref___test2,
            'children': (
                _$cc(TableColumn, {
                    'title': '点击+，展开内容',
                    'key': 'name',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data, index]) {
                                    return (
                                        _$ce(2, 'div', [
                                            _$ce(2, 'i', null, 1 /* HasInvalidChildren */, _$cn({
                                                'icon': true,
                                                'ion-ios-plus-outline': this.get('expandedKeys').indexOf(index) < 0,
                                                'ion-ios-minus-outline': this.get('expandedKeys').indexOf(index) > -1
                                            }), {
                                                'ev-click': this.toggleExpand.bind(this, data, index)
                                            }),
                                            _$ce(2, 'span', data.name, 0 /* UnknownChildren */, 'name')
                                        ], 4 /* HasNonKeyedChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'name')
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['expand'] = function($super, [data, index]) {
                            return (
                                _$ce(2, 'div', [
                                    _$ct('Email: '),
                                    data.email
                                ], 0 /* UnknownChildren */, 'expand')
                            );
                        }),
                        (__$blocks['expand'] = function($super, data) {
                            var block = $blocks['expand'];
                            var callBlock = function() {
                                return _$blocks['expand'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }, null, _$ref___test2)
    ], 4 /* HasNonKeyedChildren */, 'tables');
};