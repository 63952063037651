import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Form, FormItem, Input} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_form = function(i) {_$refs['form'] = i};
    
    return _$cc(Form, {
        'ref': _$ref_form,
        'children': (
            _$cc(FormItem, {
                'label': '用户名',
                'value': this.get('userName'),
                'rules': {required: true, userName: this.validateUserName},
                'children': (
                    _$cc(Input, {
                        'ev-$model:value': function($v) {
                            $this.set('userName', $v);
                        },
                        'value': $this.get('userName')
                    })
                )
            })
        )
    }, null, _$ref_form);
};