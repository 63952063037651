import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    noop as _$no,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {getRestProps} from '../utils';
import {Tooltip} from '../tooltip';
import {Icon} from '../icon';
import {Transition} from 'intact';
import {makeItemStyles} from './styles';
import {context as ErrorContext} from './useError';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        className, children, label,
        hideLabel, htmlFor, errorClassName,
        rules, fluid, 
    } = this.get();
    const {isDirty: {value: isDirty}, onChange, onFocusout} = this.dirty;
    const {
        isValid: {value: isValid},
        errorClassName: {value: customErrorClassName},
    } = this.validateHook;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-form-item`]: true,
        [`${k}-invalid`]: isDirty && !isValid,
        [`${k}-valid`]: isDirty && isValid,
        [className]: className,
        [`${k}-fluid`]: fluid,
        [makeItemStyles(k)]: true,
    };
    
    const starOnRequired = this.form && this.form.get('starOnRequired');
    const labelWidth = this.form && this.form.get('labelWidth');
    let labelStyle;
    if (labelWidth != null) {
        if (/^\d+$/.test(labelWidth)) {
            labelStyle = {width: labelWidth + 'px'};
        } else {
            labelStyle = {width: labelWidth};
        }
    }
    
    const {ellipsis, message, errorRef} = this.error;
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'ev-change': onChange,
        'ev-focusout': onFocusout
    }, [
        (!hideLabel) ?
            _$ce(2, 'label', [
                (starOnRequired && rules && rules.required) ?
                    _$ce(2, 'span', '*', 16 /* HasTextChildren */, _$cn(`${k}-form-star`)) :
                    undefined,
                (
                    (_$blocks['label'] = function($super) {
                        return label;
                    }),
                    (__$blocks['label'] = function($super, data) {
                        var block = $blocks['label'];
                        var callBlock = function() {
                            return _$blocks['label'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['label'](_$no)
                )
            ], 0 /* UnknownChildren */, _$cn(`${k}-form-label`), {
                'htmlFor': htmlFor,
                'style': labelStyle
            }) :
            undefined,
        _$ce(2, 'div', [
            _$cc(ErrorContext.Provider, {
                'value': isDirty && !isValid,
                'children': (
                    (
                        (
                            (_$blocks['content'] = function($super) {
                                return children;
                            }),
                            (__$blocks['content'] = function($super, data) {
                                var block = $blocks['content'];
                                var callBlock = function() {
                                    return _$blocks['content'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            }),
                            __$blocks['content'](_$no)
                        )
                    )
                )
            }),
            _$cc(Transition, {
                'key': 'error',
                'name': 'k-fade',
                'children': (
                    (isDirty && !isValid) ?
                        _$ce(2, 'div', [
                            _$ce(2, 'div', message.value, 0 /* UnknownChildren */, _$cn(`${k}-error-message c-ellipsis`), null, null, errorRef),
                            _$cc(Tooltip, {
                                'hoverable': true,
                                'position': 'bottom',
                                'children': (
                                    _$cc(Icon, {
                                        'className': _$cn(`ion-ios-information-outline ${k}-form-error-more`),
                                        'size': 'small',
                                        'color': 'inherit'
                                    })
                                ),
                                '$blocks': function($blocks) {
                                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                    return (
                                        (
                                            (_$blocks['content'] = function($super) {
                                                return message.value;
                                            }),
                                            (__$blocks['content'] = function($super, data) {
                                                var block = $blocks['content'];
                                                var callBlock = function() {
                                                    return _$blocks['content'].call($this, $super, data);
                                                };
                                                return block ?
                                                    block.call($this, callBlock, data) :
                                                    callBlock();
                                            })
                                        ),
                                        __$blocks
                                    );
                                }.call($this, _$em)
                            })
                        ], 4 /* HasNonKeyedChildren */, _$cn({
                            [`${k}-form-error`]: true,
                            [errorClassName]: errorClassName,
                            [customErrorClassName]: customErrorClassName,
                            [`${k}-ellipsis`]: ellipsis.value
                        })) :
                        undefined
                )
            }, 'error')
        ], 0 /* UnknownChildren */, _$cn(`${k}-form-content`)),
        ($blocks.append) ?
            _$ce(2, 'div', (
                (
                    (_$blocks['append'] = function($super) {
                        return null;
                    }),
                    (__$blocks['append'] = function($super, data) {
                        var block = $blocks['append'];
                        var callBlock = function() {
                            return _$blocks['append'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['append'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-form-append`)) :
            undefined
    ]);
};