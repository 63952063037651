export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {range, bind} from 'kpc/components/utils';
import {Message, TableRowKey} from 'kpc';

interface Props {
    data: DataItem[]
}

type DataItem = {
    name: string
    ip: string
}

const data: DataItem[] = range(1, 20).map(item => {
    return {
        name: 'name ' + item,
        ip: '127.0.0.' + item
    };
});

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: data 
        }
    }

    @bind
    _remove(index: number) {
        const data = this.get('data').slice(0);
        data.splice(index, 1);
        this.set('data', data);
    }

    @bind
    _onDragEnd({key, from, to}: {key: TableRowKey, from: number, to: number}) {
        Message.success(`Drag ${key} from ${from} to ${to}.`);
        // change the data
        const data = this.get('data').slice(0);
        const row = data.splice(from, 1);
        data.splice(to, 0, row[0]);
        this.set('data', data);
    }
}