import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {ScrollSelect} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(ScrollSelect, {
        'data': this.generateData,
        'disable': this.disable,
        'ev-$model:value': function($v) {
            $this.set('value', $v);
        },
        'value': $this.get('value')
    });
};