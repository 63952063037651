import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Datepicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Datepicker, {
            'type': 'datetime',
            'clearable': true,
            'ev-$model:value': function($v) {
                $this.set('datetime1', $v);
            },
            'value': $this.get('datetime1')
        }),
        _$cc(Datepicker, {
            'type': 'datetime',
            'placeholder': '时间精确到分钟',
            'format': 'YYYY-MM-DD HH:mm',
            'ev-$model:value': function($v) {
                $this.set('datetime2', $v);
            },
            'value': $this.get('datetime2')
        })
    ], 4 /* HasNonKeyedChildren */);
};