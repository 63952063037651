import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'title': 'Weekday',
    'key': 'weekday',
    'fixed': 'left'
};
var _$tmp1 = {
    'title': 'Time',
    'key': 'forenoonTime',
    'fixed': 'left'
};
var _$tmp2 = {
    'title': 'Class 1',
    'key': 'class1'
};
var _$tmp3 = {
    'title': 'Class 2',
    'key': 'class2'
};
var _$tmp4 = {
    'title': 'Class 3',
    'key': 'class3'
};
var _$tmp5 = {
    'title': 'Class 4',
    'key': 'class4'
};
var _$tmp6 = {
    'title': 'Time',
    'key': 'afternoonTime'
};
var _$tmp7 = {
    'title': 'Class 5',
    'key': 'class5'
};
var _$tmp8 = {
    'title': 'Class 6',
    'key': 'class6',
    'fixed': 'right'
};
var _$tmp9 = {
    'title': 'Class 7',
    'key': 'class7',
    'fixed': 'right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': this.get('data'),
        'type': 'grid',
        'resizable': true,
        'stickHeader': '64',
        'minColWidth': 100,
        'children': [
            _$cc(TableColumn, _$tmp0, 'weekday'),
            _$cc(TableColumn, {
                'title': 'Forenoon',
                'key': 'forenoon',
                'children': [
                    _$cc(TableColumn, _$tmp1, 'forenoonTime'),
                    _$cc(TableColumn, {
                        'title': 'Classes',
                        'key': 'classes',
                        'children': [
                            _$cc(TableColumn, _$tmp2, 'class1'),
                            _$cc(TableColumn, _$tmp3, 'class2'),
                            _$cc(TableColumn, _$tmp4, 'class3'),
                            _$cc(TableColumn, _$tmp5, 'class4')
                        ]
                    }, 'classes')
                ]
            }, 'forenoon'),
            _$cc(TableColumn, {
                'title': 'Afternoon',
                'key': 'afternoon',
                'children': [
                    _$cc(TableColumn, _$tmp6, 'afternoonTime'),
                    _$cc(TableColumn, _$tmp7, 'class5'),
                    _$cc(TableColumn, _$tmp8, 'class6'),
                    _$cc(TableColumn, _$tmp9, 'class7')
                ]
            }, 'afternoon')
        ]
    });
};