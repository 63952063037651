import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'title': 'Weekday',
    'key': 'weekday',
    'fixed': 'left'
};
var _$tmp1 = {
    'title': 'Time',
    'key': 'forenoonTime'
};
var _$tmp2 = {
    'title': 'Class 1',
    'key': 'class1'
};
var _$tmp3 = {
    'title': 'Class 2',
    'key': 'class2'
};
var _$tmp4 = {
    'title': 'Class 3',
    'key': 'class3'
};
var _$tmp5 = {
    'title': 'Class 4',
    'key': 'class4'
};
var _$tmp6 = {
    'title': 'Class 5',
    'key': 'class5'
};
var _$tmp7 = {
    'title': 'Class 6',
    'key': 'class6'
};
var _$tmp8 = {
    'title': 'Class 7',
    'key': 'class7'
};
var _$tmp9 = {
    'title': 'Time',
    'key': 'afternoonTime',
    'fixed': 'right'
};
var _$tmp10 = {
    'title': 'Weekday',
    'key': 'weekday',
    'fixed': 'left'
};
var _$tmp11 = {
    'title': 'Time',
    'key': 'forenoonTime'
};
var _$tmp12 = {
    'title': 'Class 1',
    'key': 'class1'
};
var _$tmp13 = {
    'title': 'Class 2',
    'key': 'class2'
};
var _$tmp14 = {
    'title': 'Class 3',
    'key': 'class3'
};
var _$tmp15 = {
    'title': 'Class 4',
    'key': 'class4'
};
var _$tmp16 = {
    'title': 'Class 5',
    'key': 'class5'
};
var _$tmp17 = {
    'title': 'Class 6',
    'key': 'class6'
};
var _$tmp18 = {
    'title': 'Class 7',
    'key': 'class7'
};
var _$tmp19 = {
    'title': 'Time',
    'key': 'afternoonTime',
    'fixed': 'right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test1 = function(i) {_$refs['__test1'] = i};
    var _$ref___test2 = function(i) {_$refs['__test2'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'checkType': 'checkbox',
            'checkedKeys': [3],
            'type': 'grid',
            'resizable': true,
            'merge': this.merge,
            'disableRow': this.disableRow,
            'ref': _$ref___test1,
            'children': [
                _$cc(TableColumn, _$tmp0, 'weekday'),
                _$cc(TableColumn, {
                    'title': 'Forenoon',
                    'key': 'forenoon',
                    'children': [
                        _$cc(TableColumn, _$tmp1, 'forenoonTime'),
                        _$cc(TableColumn, _$tmp2, 'class1'),
                        _$cc(TableColumn, _$tmp3, 'class2'),
                        _$cc(TableColumn, _$tmp4, 'class3'),
                        _$cc(TableColumn, _$tmp5, 'class4')
                    ]
                }, 'forenoon'),
                _$cc(TableColumn, {
                    'title': 'Afternoon',
                    'key': 'afternoon',
                    'children': [
                        _$cc(TableColumn, _$tmp6, 'class5'),
                        _$cc(TableColumn, _$tmp7, 'class6'),
                        _$cc(TableColumn, _$tmp8, 'class7'),
                        _$cc(TableColumn, _$tmp9, 'afternoonTime')
                    ]
                }, 'afternoon')
            ]
        }, null, _$ref___test1),
        _$cc(Table, {
            'data': this.get('data'),
            'checkType': 'radio',
            'checkedKeys': [0, 1],
            'type': 'grid',
            'resizable': true,
            'merge': this.merge,
            'disableRow': this.disableRow,
            'ref': _$ref___test2,
            'children': [
                _$cc(TableColumn, _$tmp10, 'weekday'),
                _$cc(TableColumn, {
                    'title': 'Forenoon',
                    'key': 'forenoon',
                    'children': [
                        _$cc(TableColumn, _$tmp11, 'forenoonTime'),
                        _$cc(TableColumn, _$tmp12, 'class1'),
                        _$cc(TableColumn, _$tmp13, 'class2'),
                        _$cc(TableColumn, _$tmp14, 'class3'),
                        _$cc(TableColumn, _$tmp15, 'class4')
                    ]
                }, 'forenoon'),
                _$cc(TableColumn, {
                    'title': 'Afternoon',
                    'key': 'afternoon',
                    'children': [
                        _$cc(TableColumn, _$tmp16, 'class5'),
                        _$cc(TableColumn, _$tmp17, 'class6'),
                        _$cc(TableColumn, _$tmp18, 'class7'),
                        _$cc(TableColumn, _$tmp19, 'afternoonTime')
                    ]
                }, 'afternoon')
            ]
        }, null, _$ref___test2)
    ], 4 /* HasNonKeyedChildren */, 'merge-cell');
};