import {
    map as _$ma,
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    className as _$cn,
} from 'intact';
import {SkeletonItem} from './item';
import {makeStyles, kls} from './styles';

var _$tmp0 = {
    'type': 'avator'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {rows, avator, animated, children, loading, size} = this.get();
    const { k } = this.config;
    
    const targetRows = typeof rows !== 'number'
        ? 3
        : rows < 1
            ? 1
            : parseInt(rows);
    
    const skeletonList = Array(targetRows + 2).fill();
    const skeletonItems = _$ma(skeletonList, function($value, $key) {
        return _$cc(SkeletonItem);
    }, $this);
    
    const classNameObj = {
        [`${k}-skeleton`]: true,
        [`${k}-animated`]: animated,
        [makeStyles(k, size)]: true
    };
    
    return (loading) ?
        _$ce(2, 'div', (
            (children) ?
                (
                    _$ce(2, 'div', children, 0 /* UnknownChildren */)
                ) :
                (
                    (avator) ?
                        _$ce(2, 'div', [
                            _$cc(SkeletonItem, _$tmp0),
                            _$ce(2, 'div', skeletonItems, 0 /* UnknownChildren */, _$cn(`${k}-skeleton-items`))
                        ], 4 /* HasNonKeyedChildren */, _$cn(kls('avator-box', k))) :
                        _$ce(2, 'div', skeletonItems, 0 /* UnknownChildren */, _$cn(`${k}-skeleton-items`))
                )
        ), 2 /* HasVNodeChildren */, _$cn(classNameObj)) :
        undefined;
};