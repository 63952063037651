import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Upload} from 'kpc';
import {Button} from 'kpc';

var _$tmp0 = {
    'directory': true,
    'action': '//fakestoreapi.com/products'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Upload, _$tmp0);
};