export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, TableColumnProps} from 'kpc';

interface Props {
    data: DataItem[]
}

type DataItem = {
    weekday: string
    class1: string
    class2: string
    class3: string
    class4: string
    class5: string
    class6: string
    class7: string
    forenoonTime: string
    afternoonTime: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    "weekday": "Monday",
                    "class1": "English",
                    "class2": "Mathematics",
                    "class3": "History",
                    "class4": "English",
                    "class5": "History",
                    "class6": "English",
                    "class7": "History",
                    "forenoonTime": "08:00 ~ 12:00",
                    "afternoonTime": "14:00 ~ 17:00"
                },
                {
                    "weekday": "Tuesday",
                    "class1": "English",
                    "class2": "English",
                    "class3": "Geopraghy",
                    "class4": "History",
                    "class5": "English",
                    "class6": "History",
                    "class7": "Mathematics",
                    "forenoonTime": "08:00 ~ 12:00",
                    "afternoonTime": "14:00 ~ 17:00"
                },
                {
                    "weekday": "Wendesday",
                    "class1": "History",
                    "class2": "Chinese",
                    "class3": "History",
                    "class4": "English",
                    "class5": "English",
                    "class6": "Chinese",
                    "class7": "History",
                    "forenoonTime": "08:00 ~ 12:00",
                    "afternoonTime": "14:00 ~ 17:00"
                },
                {
                    "weekday": "Thursday",
                    "class1": "History",
                    "class2": "English",
                    "class3": "Geopraghy",
                    "class4": "Mathematics",
                    "class5": "Geopraghy",
                    "class6": "English",
                    "class7": "Mathematics",
                    "forenoonTime": "08:00 ~ 12:00",
                    "afternoonTime": "14:00 ~ 17:00"
                },
                {
                    "weekday": "Friday",
                    "class1": "Geopraghy",
                    "class2": "English",
                    "class3": "Geopraghy",
                    "class4": "Geopraghy",
                    "class5": "Geopraghy",
                    "class6": "Geopraghy",
                    "class7": "English",
                    "forenoonTime": "08:00 ~ 12:00",
                    "afternoonTime": "14:00 ~ 17:00"
                },
                {
                    "weekday": "Saturday",
                    "class1": "Geopraghy",
                    "class2": "English",
                    "class3": "Geopraghy",
                    "class4": "Geopraghy",
                    "class5": "Geopraghy",
                    "class6": "Geopraghy",
                    "class7": "English",
                    "forenoonTime": "08:00 ~ 12:00",
                    "afternoonTime": "14:00 ~ 17:00"
                }
            ] 
        };
    }

    @bind
    merge(row: DataItem, column: TableColumnProps | null, rowIndex: number, columnIndex: number) {
        if (columnIndex === 0) {
            // is check column
            if (rowIndex === 0) {
                return {
                    rowspan: 2,
                    colspan: 1,
                }
            }

            if (rowIndex === 2) {
                return {
                    rowspan: 3,
                    colspan: 1,
                }
            }

            return;
        }

        const data = this.get('data');
        if (column!.key === 'forenoonTime' || column!.key === 'afternoonTime') {
            return {
                rowspan: data.length,
                colspan: 1,
            };
        }

        // merge the same classes horizontally
        const columns: (keyof DataItem)[] = [];
        for (let i = 1; i <= 7; i++) columns.push(`class${i}` as keyof DataItem);

        let colspan = 1;
        const value = row[column!.key as keyof DataItem];
        for (let i = columns.indexOf(column!.key as keyof DataItem) + 1; i < 7; i++) {
            const nextValue = row[columns[i]];
            if (nextValue !== value) break;
            colspan++;
        }

        return {colspan};
    }

    @bind
    disableRow(data: DataItem, index: number) {
        return index === 1 || index === 5;
    }
}