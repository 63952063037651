import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, children} = this.get();
    const { k } = this.config;
    const classNameObj = {
        [`${k}-diagram`]: true,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-diagram-scheme`)),
        _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-diagram-canvas`), null, null, this.canvasRef)
    ]);
};