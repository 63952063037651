import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createVNode as _$cv,
    createElementVNode as _$ce,
    noop as _$no,
    map as _$ma,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {TransitionGroup, Transition} from 'intact';
import {Input} from '../input';
import {Icon} from '../icon';
import {isNullOrUndefined, isStringOrNumber} from 'intact-shared';
import {makeStyles} from './styles';
import {Dropdown} from '../dropdown';
import {getRestProps, addStyle} from '../utils';
import {Wave} from '../wave';
import {context as ErrorContext} from '../form/useError';
import {Tags, Tag} from '../tag';

var _$tmp0 = {
    'className': 'ion-load-c',
    'rotate': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        value, multiple,
        loading, disabled, name,
        size, hideIcon, fluid,
        clearable, filterable,
        className, container,
        inline, style, width, show,
        position, flat, nowrap,
        draggable,
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-select`]: true,
        [`${k}-disabled`]: disabled,
        [`${k}-${size}`]: size !== 'default',
        [`${k}-fluid`]: fluid,
        [`${k}-inline`]: inline,
        [`${k}-flat`]: flat,
        [`${k}-nowrap`]: nowrap,
        [className]: className,
        [$props.className]: $props.className,
        [makeStyles(k)]: true,
    };
    
    const placeholder = this.getPlaceholder();
    const label = this.getLabel();
    const hasValue = this.hasValue();
    const {onInput, inputRef, keywords: {value: keywords}} = this.input;
    const {onFocusout, triggerRef} = this.focusout;
    const {isClosable} = this.immutable;
    const filterInput = (filterable) ?
        _$cc(Input, {
            'className': _$cn(`${k}-select-input`),
            'value': keywords,
            'ev-$change:value': onInput,
            'disabled': disabled,
            'placeholder': !hasValue ? placeholder : '',
            'ref': inputRef,
            'autoWidth': true,
            'inline': true,
            'size': size,
            'key': 'filter',
            'readonly': !show,
            'waveDisabled': true,
            'flat': flat
        }, 'filter', inputRef) :
        undefined
    
    return _$cc(ErrorContext.Consumer, {
        'defaultValue': false,
        'children': isInvalid => {
            return _$cc(Wave, {
                'disabled': disabled || isInvalid || inline,
                'inset': '-2px',
                'children': (
                    _$cc(Dropdown, {
                        'trigger': 'click',
                        'ref': this.dropdownRef,
                        'disabled': disabled,
                        'container': container,
                        'position': position,
                        'children': [
                            _$cv('div', {
                                ...getRestProps(this),
                                'className': _$cn(classNameObj),
                                'tabindex': disabled ? '-1' : '0',
                                'ev-keydown': this.onKeydown,
                                'ev-focusout': onFocusout,
                                'style': !isNullOrUndefined(width) ? addStyle(style, {width: `${width}px`}) : style,
                                'ref': triggerRef
                            }, [
                                ($blocks.prefix) ?
                                    _$ce(2, 'div', (
                                        (
                                            (_$blocks['prefix'] = function($super) {
                                                return null;
                                            }),
                                            (__$blocks['prefix'] = function($super, data) {
                                                var block = $blocks['prefix'];
                                                var callBlock = function() {
                                                    return _$blocks['prefix'].call($this, $super, data);
                                                };
                                                return block ?
                                                    block.call($this, callBlock, data) :
                                                    callBlock();
                                            }),
                                            __$blocks['prefix'](_$no)
                                        )
                                    ), 0 /* UnknownChildren */, _$cn(`${k}-select-prefix`)) :
                                    undefined,
                                _$ce(2, 'div', [
                                    _$ce(64, 'input', null, 1 /* HasInvalidChildren */, null, {
                                        'type': 'hidden',
                                        'value': value,
                                        'name': name
                                    }, 'hidden-input'),
                                    null,
                                    _$cc(TransitionGroup, {
                                        'name': 'k-fade',
                                        'appear': false,
                                        'children': (
                                            (!multiple && filterable) ?
                                                _$cc(Input, {
                                                    'value': show ? keywords : label,
                                                    'ev-$change:value': onInput,
                                                    'disabled': disabled,
                                                    'placeholder': label || placeholder,
                                                    'ref': inputRef,
                                                    'size': size,
                                                    'fluid': true,
                                                    'inline': true,
                                                    'key': 'input',
                                                    'readonly': !show,
                                                    'waveDisabled': true,
                                                    'flat': flat
                                                }, 'input', inputRef) :
                                                !filterable && !hasValue ?
                                                    _$ce(2, 'div', placeholder, 0 /* UnknownChildren */, _$cn(`${k}-select-placeholder c-ellipsis`), null, 'placeholder') :
                                                    !multiple ?
                                                        _$ce(2, 'div', (
                                                            (
                                                                (_$blocks['value'] = function($super) {
                                                                    return label;
                                                                }),
                                                                (__$blocks['value'] = function($super, data) {
                                                                    var block = $blocks['value'];
                                                                    var callBlock = function() {
                                                                        return _$blocks['value'].call($this, $super, data);
                                                                    };
                                                                    return block ?
                                                                        block.call($this, callBlock, data) :
                                                                        callBlock();
                                                                }),
                                                                __$blocks['value'](_$no, [value, label])
                                                            )
                                                        ), 0 /* UnknownChildren */, _$cn(`${k}-select-value c-ellipsis`), null, 'value') :
                                                        _$ce(2, 'div', [
                                                            (
                                                                (_$blocks['values'] = function($super) {
                                                                    return (
                                                                        (label && label.length || filterable) ?
                                                                            _$cc(Tags, {
                                                                                'nowrap': nowrap,
                                                                                'size': size,
                                                                                'draggable': draggable,
                                                                                'ev-dragend': this.draggable,
                                                                                'children': (
                                                                                    _$ma(label, function($label, $key) {
                                                                                        return _$cc(Tag, {
                                                                                            'key': isStringOrNumber($label) ? 
                                                                                                $label :
                                                                                                isStringOrNumber(value[$key]) ?
                                                                                                    value[$key] :
                                                                                                    $key,
                                                                                            'closable': isClosable(value[$key]),
                                                                                            'ev-close': this.delete.bind(this, $key),
                                                                                            'disabled': disabled,
                                                                                            'size': size,
                                                                                            'children': (
                                                                                                (
                                                                                                    (
                                                                                                        (_$blocks['value'] = function($super) {
                                                                                                            return $label;
                                                                                                        }),
                                                                                                        (__$blocks['value'] = function($super, data) {
                                                                                                            var block = $blocks['value'];
                                                                                                            var callBlock = function() {
                                                                                                                return _$blocks['value'].call($this, $super, data);
                                                                                                            };
                                                                                                            return block ?
                                                                                                                block.call($this, callBlock, data) :
                                                                                                                callBlock();
                                                                                                        }),
                                                                                                        __$blocks['value'](_$no, [value[$key], $label])
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        }, isStringOrNumber($label) ? 
                                                                                            $label :
                                                                                            isStringOrNumber(value[$key]) ?
                                                                                                value[$key] :
                                                                                                $key);
                                                                                    }, $this)
                                                                                ),
                                                                                '$blocks': function($blocks) {
                                                                                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                                                                    return (
                                                                                        (
                                                                                            (_$blocks['append'] = function($super) {
                                                                                                return filterInput;
                                                                                            }),
                                                                                            (__$blocks['append'] = function($super, data) {
                                                                                                var block = $blocks['append'];
                                                                                                var callBlock = function() {
                                                                                                    return _$blocks['append'].call($this, $super, data);
                                                                                                };
                                                                                                return block ?
                                                                                                    block.call($this, callBlock, data) :
                                                                                                    callBlock();
                                                                                            })
                                                                                        ),
                                                                                        __$blocks
                                                                                    );
                                                                                }.call($this, _$em)
                                                                            }) :
                                                                            undefined
                                                                    );
                                                                }),
                                                                (__$blocks['values'] = function($super, data) {
                                                                    var block = $blocks['values'];
                                                                    var callBlock = function() {
                                                                        return _$blocks['values'].call($this, $super, data);
                                                                    };
                                                                    return block ?
                                                                        block.call($this, callBlock, data) :
                                                                        callBlock();
                                                                }),
                                                                __$blocks['values'](_$no, [value, label])
                                                            ),
                                                            ($blocks && $blocks.values) ?
                                                                filterInput :
                                                                undefined
                                                        ], 0 /* UnknownChildren */, _$cn(`${k}-select-values`), null, 'values')
                                        )
                                    })
                                ], 0 /* UnknownChildren */, _$cn(`${k}-select-main`)),
                                (!hideIcon || clearable || loading || $blocks.suffix) ?
                                    _$ce(2, 'span', [
                                        (clearable && !disabled) ?
                                            _$cc(Icon, {
                                                'className': _$cn({[`${k}-select-clear ion-ios-close`]: true, [`${k}-show`]: hasValue}),
                                                'ev-click': this.clear,
                                                'hoverable': true
                                            }) :
                                            undefined,
                                        _$ce(2, 'span', (
                                            (
                                                (_$blocks['suffix'] = function($super) {
                                                    return (
                                                        (loading) ?
                                                            _$cc(Icon, _$tmp0) :
                                                            !hideIcon ?
                                                                (
                                                                    (filterable && show) ?
                                                                        _$cc(Icon, {
                                                                            'className': _$cn(`${k}-icon-search`)
                                                                        }) :
                                                                        _$cc(Icon, {
                                                                            'className': _$cn(`${k}-select-arrow ${k}-icon-down`),
                                                                            'disabled': $props.isDisableArrow
                                                                        })
                                                                ) :
                                                                undefined
                                                    );
                                                }),
                                                (__$blocks['suffix'] = function($super, data) {
                                                    var block = $blocks['suffix'];
                                                    var callBlock = function() {
                                                        return _$blocks['suffix'].call($this, $super, data);
                                                    };
                                                    return block ?
                                                        block.call($this, callBlock, data) :
                                                        callBlock();
                                                }),
                                                __$blocks['suffix'](_$no)
                                            )
                                        ), 0 /* UnknownChildren */, _$cn(`${k}-select-suffix-icon`))
                                    ], 0 /* UnknownChildren */, _$cn(`${k}-select-suffix`)) :
                                    undefined
                            ]),
                            (
                                (
                                    (_$blocks['base-menu'] = function($super) {
                                        return (
                                            _$ce(2, 'div', 'should be implemeted', 16 /* HasTextChildren */)
                                        );
                                    }),
                                    (__$blocks['base-menu'] = function($super, data) {
                                        var block = $blocks['base-menu'];
                                        var callBlock = function() {
                                            return _$blocks['base-menu'].call($this, $super, data);
                                        };
                                        return block ?
                                            block.call($this, callBlock, data) :
                                            callBlock();
                                    }),
                                    __$blocks['base-menu'](_$no)
                                )
                            )
                        ],
                        'ev-$model:value': function($v) {
                            $this.set('show', $v);
                        },
                        'value': $this.get('show')
                    }, null, this.dropdownRef)
                )
            })
        }
    });
};