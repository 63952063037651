import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button, Dialog, Input} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___demo = function(i) {_$refs['__demo'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.onClick,
            'type': 'primary',
            'children': 'Show Dialog'
        }),
        _$cc(Dialog, {
            'title': 'Dialog Title',
            'ref': _$ref___demo,
            'mode': 'destroy',
            'children': (
                _$cc(Input)
            ),
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        }, null, _$ref___demo)
    ], 4 /* HasNonKeyedChildren */);
};