export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
   day?: string[]
}

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            day: [] 
        } as Props;
    }
}