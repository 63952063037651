import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';
import {ScrollSelect} from '../scrollSelect';
import {hours, minutes, seconds} from './constants';
import {makeTimeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const value = this.value.value;
    const {disableHours, disableMinutes, disableSeconds, disableItem} = this.disabled;
    const { k } = this.config;
    const classNameObj = {
        [`${k}-datepicker-time`]: true,
        [makeTimeStyles(k)]: true,
    }
    
    return _$ce(2, 'div', [
        _$cc(ScrollSelect, {
            'data': hours,
            'value': value.h,
            'ev-$change:value': this.changeHour,
            'disabled': disableHours.value,
            'disable': v => disableItem(v, 'h')
        }),
        _$cc(ScrollSelect, {
            'data': minutes,
            'value': value.m,
            'ev-$change:value': this.changeMinute,
            'disabled': disableMinutes.value,
            'disable': v => disableItem(v, 'm')
        }),
        _$cc(ScrollSelect, {
            'data': seconds,
            'value': value.s,
            'ev-$change:value': this.changeSecond,
            'disabled': disableSeconds.value,
            'disable': v => disableItem(v, 's')
        })
    ], 4 /* HasNonKeyedChildren */, _$cn(classNameObj));
};