import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Button, Dialog} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.set.bind(this, 'show', true),
            'type': 'primary',
            'children': 'Show Dialog'
        }),
        _$cc(Button, {
            'ev-click': this.set.bind(this, 'show1', true),
            'type': 'primary',
            'children': 'Show Dialog without Footer'
        }),
        _$cc(Dialog, {
            'children': '\n\n        Dialog Body \n\n        ',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['header'] = function($super) {
                            return (
                                _$ce(2, 'div', [
                                    _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-person'),
                                    _$ct('\n                Custom Header\n            ')
                                ], 4 /* HasNonKeyedChildren */, 'k-title')
                            );
                        }),
                        (__$blocks['header'] = function($super, data) {
                            var block = $blocks['header'];
                            var callBlock = function() {
                                return _$blocks['header'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['footer'] = function($super) {
                            return [
                                _$ct('\n            Custom Footer\n            '),
                                $super()
                            ];
                        }),
                        (__$blocks['footer'] = function($super, data) {
                            var block = $blocks['footer'];
                            var callBlock = function() {
                                return _$blocks['footer'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        }),
        _$cc(Dialog, {
            'title': 'No Footer',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['body'] = function($super) {
                            return 'body';
                        }),
                        (__$blocks['body'] = function($super, data) {
                            var block = $blocks['body'];
                            var callBlock = function() {
                                return _$blocks['body'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['footer-wrapper'] = function($super) {
                            return null;
                        }),
                        (__$blocks['footer-wrapper'] = function($super, data) {
                            var block = $blocks['footer-wrapper'];
                            var callBlock = function() {
                                return _$blocks['footer-wrapper'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:value': function($v) {
                $this.set('show1', $v);
            },
            'value': $this.get('show1')
        })
    ], 4 /* HasNonKeyedChildren */);
};