import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Split} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Split, {
        'min': '40',
        'max': '50%+10',
        'firstSize': '80px',
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['first'] = function($super) {
                        return (
                            _$ce(2, 'div', 'left', 16 /* HasTextChildren */, 'panel')
                        );
                    }),
                    (__$blocks['first'] = function($super, data) {
                        var block = $blocks['first'];
                        var callBlock = function() {
                            return _$blocks['first'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                (
                    (_$blocks['last'] = function($super) {
                        return (
                            _$ce(2, 'div', 'right', 16 /* HasTextChildren */, 'panel')
                        );
                    }),
                    (__$blocks['last'] = function($super, data) {
                        var block = $blocks['last'];
                        var callBlock = function() {
                            return _$blocks['last'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    });
};