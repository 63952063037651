import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Radio} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Radio, {
            'children': 'radio',
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }),
        _$cc(Radio, {
            'disabled': true,
            'children': 'disabled radio'
        }),
        _$cc(Radio, {
            'value': true,
            'disabled': true,
            'children': 'disabled checked radio'
        })
    ], 4 /* HasNonKeyedChildren */);
};