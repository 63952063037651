import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Card, Divider} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Card, {
            'children': [
                _$ce(2, 'div', '默认直线', 16 /* HasTextChildren */),
                _$cc(Divider),
                _$ce(2, 'div', '虚线', 16 /* HasTextChildren */),
                _$cc(Divider, {
                    'borderType': this.get('border')
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};