import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn, Button} from 'kpc';

var _$tmp0 = {
    'title': '普通文本',
    'key': 'b'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_table = function(i) {_$refs['table'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.exportTable,
            'children': '导出表格'
        }),
        _$cc(Button, {
            'type': 'primary',
            'ev-click': this.exportData,
            'children': '导出自定义数据'
        }),
        _$cc(Table, {
            'data': this.get('data'),
            'ref': _$ref_table,
            'children': [
                _$cc(TableColumn, {
                    'title': '定义该列单元格内容',
                    'key': 'a',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return (
                                        _$ce(2, 'a', data.a, 0 /* UnknownChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'a'),
                _$cc(TableColumn, _$tmp0, 'b'),
                _$cc(TableColumn, {
                    'title': '自定义导出文本',
                    'key': 'c',
                    'exportTitle': '自定义导出标题',
                    'exportCell': (data, index) => '自定义导出文本:' + data.c,
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return data.c;
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'c'),
                _$cc(TableColumn, {
                    'title': '操作',
                    'key': 'op',
                    'ignore': true,
                    'align': 'right',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return (
                                        _$ce(2, 'a', '删除', 16 /* HasTextChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'op')
            ]
        }, null, _$ref_table)
    ], 4 /* HasNonKeyedChildren */);
};