import {Component} from 'intact';
import Article from '~/../src/components/article';
import data from './index.json';
import sidebar from '~/doc.json';

const r = require.context('./', true, /demos.*(index|demo).ts/);
const keys = r.keys();
const demos: Component<any>[] = [];
for (let i = 0; i < keys.length; i++) {
    const file = keys[i];
    if (!file.startsWith('.')) continue;
    // if we found demo.js then ignore index.js
    if (/demo.ts$/.test(file)) i++;
    demos.push(r(file));
}

export default class extends Article {
    static sidebar = sidebar;
    static data = data;
    static defaults = () => ({
        ...Article.defaults, ...data, demos
    });
}