import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    noop as _$no,
    className as _$cn,
    createVNode as _$cv,
} from 'intact';
import {getRestProps, mapChildren} from '../utils';
import {BreadcrumbItem} from './item';
import { makeStyles } from './styles';
import { Icon } from '../icon';

var _$tmp0 = {
    'className': 'k-icon-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {children, separator, className} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-breadcrumb`]: true,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    const separatorVNode = (
        _$ce(2, 'span', (
            (!$blocks.separator && !separator) ?
                (
                    _$cc(Icon, _$tmp0)
                ) :
                (
                    (_$blocks['separator'] = function($super) {
                        return separator;
                    }),
                    (__$blocks['separator'] = function($super, data) {
                        var block = $blocks['separator'];
                        var callBlock = function() {
                            return _$blocks['separator'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['separator'](_$no)
                )
        ), 0 /* UnknownChildren */, _$cn(`${k}-breadcrumb-separator`))
    );
    const vNodes = [];
    let lastIndex = -1;
    let separatorAmount = 0;
    mapChildren(children, (vNode, index) => {
        vNodes.push(vNode);
        if (vNode.tag === BreadcrumbItem) {
            lastIndex = index;
            separatorAmount++;
            vNodes.push(separatorVNode);
        }
    });
    // remove the last BreadcrumbItem's separator,
    // because it may have other vNodes follow the last BreadcrumbItem.
    // we record the last separator's index and splice array like bellow to remove it
    if (lastIndex > -1) {
        vNodes.splice(lastIndex + separatorAmount, 1);
    }
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, vNodes);
};