import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Row, {
            'className': 'no-gutter',
            'children': [
                _$cc(Col, {
                    'span': '6',
                    'offset': '6',
                    'children': 'col-6 offset-6'
                }),
                _$cc(Col, {
                    'span': '6',
                    'offset': '6',
                    'children': 'col-6 offset-6'
                })
            ]
        }),
        _$cc(Row, {
            'gutter': '16',
            'className': 'gutter',
            'children': [
                _$cc(Col, {
                    'span': '6',
                    'offset': '6',
                    'children': 'col-6 gutter-16 offset-6'
                }),
                _$cc(Col, {
                    'span': '6',
                    'offset': '6',
                    'children': 'col-6 gutter-16 offset-6'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};