export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

interface Props {
    index?: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            index: 1
        };
    }

    @bind
    previous() {
        this.set('index', this.get('index')! - 1);
    }

    @bind
    next() {
        this.set('index', this.get('index')! + 1);
    }
}