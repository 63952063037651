export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    date?: [string, string] | null
    time?: [string, string] | null
    year?: [string, string] | null
    month?: [string, string] | null
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            date: null,
            time: null,
            year: null,
            month: null,
        } as Props;
    }
}