import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Datepicker, shortcuts} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Datepicker, {
            'shortcuts': [
                shortcuts.yesterday,
                shortcuts.today,
                shortcuts.tomorrow,
                shortcuts.afterOneWeek,
                shortcuts.afterOneMonth,
                shortcuts.afterThreeMonths,
                shortcuts.beforeOneWeek,
                shortcuts.beforeOneMonth,
                shortcuts.beforeThreeMonths
            ],
            'ev-$model:value': function($v) {
                $this.set('date', $v);
            },
            'value': $this.get('date')
        }),
        _$cc(Datepicker, {
            'range': true,
            'type': 'datetime',
            'shortcuts': [
                shortcuts.lastWeek,
                shortcuts.lastMonth,
                shortcuts.lastThreeMonths,
                shortcuts.nextWeek,
                shortcuts.nextMonth,
                shortcuts.nextThreeMonths
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};