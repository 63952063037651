export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Dialog, Message} from 'kpc';

type Type = 'success' | 'warning' | 'error' | 'confirm'

interface Props {
    types: Type[]
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            types: ['success', 'warning', 'error', 'confirm']
        } as Props;
    }

    showDialog(type: Type) {
        Dialog[type]({
            content: type,
        }).then(() => {
            Message.info('clicked ok button');
        }, () => {
            Message.info('clicked cancel button');
        });
    }

    showDialogWithTitle(type: Type) {
        Dialog[type]({
            title: type[0].toUpperCase() + type.substring(1),
            content: type + ' dialog',
        }).then(() => {
            Message.info('clicked ok button');
        }, () => {
            Message.info('clicked cancel button');
        });
    }

    showAsyncCloseConfirm() {
        let dialog: Dialog;
        Dialog.confirm({
            content: '点击确认，异步关闭',
            ref: (_dialog) => dialog = _dialog,
            ok: () => {
                dialog.showLoading();
                setTimeout(() => {
                    dialog.close();
                }, 3000);
            },
        });
    }
}