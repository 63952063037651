export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc/components/utils';

interface Props {
    data: any[]
    num: number
    isShow: boolean
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    a: 'Cell 1-1', 
                    b: 'Cell 1-2', 
                    c: 'Cell 1-3'
                },
                {
                    a: 'Cell 2-1', 
                    b: 'Cell 2-2', 
                    c: 'Cell 2-3'
                }
            ],
            num: 3,
            isShow: false,
        }
    }
            
    @bind
    addRow() {
        const num = this.get('num');
        const data = this.get('data').slice();
        data.push({
            a: `Cell ${num}-1`, 
            b: `Cell ${num}-2`, 
            c: `Cell ${num}-3`
        });
        this.set({
            data,
            num: num + 1
        });
    }

    @bind
    removeRow() {
        const data = this.get('data').slice();
        data.shift();
        this.set({data});
    }

    @bind
    toggleColumn() {
        this.set('isShow', !this.get('isShow'));
    }
}