import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
} from 'intact';
import {Button} from '../button';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {Icon} from '../icon';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, children, vertical, size, type} = this.get();
    const {
        isScroll, offset, enablePrev, enableNext,
        prev, next, scrollRef, wrapperRef
    } = this.scroll;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-tabs`]: true,
        [`${k}-size-${size}`]: size,
        [`${k}-vertical`]: vertical,
        [`${k}-type-${type}`]: true,
        [`${k}-is-scroll`]: isScroll.value,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        (isScroll.value) ?
            _$cc(Button, {
                'className': _$cn(`${k}-tabs-prev`),
                'type': 'none',
                'icon': true,
                'key': 'prev',
                'size': size,
                'disabled': !enablePrev.value,
                'ev-click': prev,
                'children': (
                    _$cc(Icon, {
                        'className': _$cn(vertical ? `${k}-icon-up` : `${k}-icon-left`)
                    })
                )
            }, 'prev') :
            undefined,
        _$ce(2, 'div', (
            _$ce(2, 'div', [
                children,
                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-tabs-active-bar`), {
                    'style': this.activeBar.value
                })
            ], 0 /* UnknownChildren */, _$cn(`${k}-tabs-wrapper`), {
                'style': {transform: vertical ? `translateY(${offset.value}px)` : `translateX(${offset.value}px)`}
            }, null, wrapperRef)
        ), 2 /* HasVNodeChildren */, _$cn(`${k}-tabs-scroll c-clearfix`), null, null, scrollRef),
        (isScroll.value) ?
            _$cc(Button, {
                'className': _$cn(`${k}-tabs-next`),
                'type': 'none',
                'icon': true,
                'key': 'next',
                'size': size,
                'disabled': !enableNext.value,
                'ev-click': next,
                'children': (
                    _$cc(Icon, {
                        'className': _$cn(vertical ? `${k}-icon-down` : `${k}-icon-right`)
                    })
                )
            }, 'next') :
            undefined
    ]);
};