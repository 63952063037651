export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind, TableSortValue} from 'kpc';

interface Props {
    data: DataItem[]    
    sort: TableSortValue
}

type DataItem = {
    name: string
    age: number
}

const oData: DataItem[] = [
    {name: 'aa', age: 1},
    {name: 'cc', age: 5},
    {name: 'bb', age: 9}
];

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: oData,
            sort: {}
        }
    }

    @bind
    _onSort(sort: TableSortValue<'name' | 'age'> | undefined) {
        console.log(sort);
        const data = oData.slice(0);
        if (sort!.type) {
            data.sort((a, b) => {
                return sort!.type === 'desc' ? 
                    (a[sort!.key!] > b[sort!.key!] ? -1 : 1) : 
                    (a[sort!.key!] > b[sort!.key!] ? 1 : -1);
            });
        }
        this.set({data, sort: sort!});
    }
}