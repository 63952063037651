export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {CascaderData} from 'kpc';

interface Props {
    data: CascaderData<string>[]
}

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    value: 'beijing',
                    label: '北京',
                    children: []
                },
                {
                    value: 'hunan',
                    label: '湖南',
                    children: []
                }
            ]
        };
    }

    loadData(item: CascaderData<string>) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                switch (item.value) {
                    case 'beijing':
                        item.children = [
                            {
                                value: 'haidian',
                                label: '海淀区'
                            },
                            {
                                value: 'chaoyang',
                                label: '朝阳区'
                            },
                            {
                                value: 'fengtai',
                                label: '丰台区'
                            }
                        ];
                        break;
                    case 'hunan':
                        item.children = [
                            {
                                value: 'changsha',
                                label: '长沙市',
                                children: [
                                    {
                                        value: 'yuelu',
                                        label: '岳麓区',
                                    }
                                ]
                            },
                            {
                                value: 'yueyang',
                                label: '岳阳市',
                                children: [],
                                loaded: true,
                            }
                        ];
                        break;
                    case 'yueyang':
                        // 该分支不会执行，因为yueyang已经将loaded设为true
                        item.children = [
                            {
                                value: 'yueyanglou',
                                label: '岳阳楼区',
                            },
                            {
                                value: 'yueyangxian',
                                label: '岳阳县',
                            }
                        ];
                        break;
                }

                resolve();
            }, 1000);
        });
    }
}