import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Rate} from 'kpc';
import {Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-heart'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Rate, {
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['icon'] = function($super) {
                        return (
                            _$cc(Icon, _$tmp0)
                        );
                    }),
                    (__$blocks['icon'] = function($super, data) {
                        var block = $blocks['icon'];
                        var callBlock = function() {
                            return _$blocks['icon'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em),
        'ev-$model:value': function($v) {
            $this.set('value', $v);
        },
        'value': $this.get('value')
    });
};