import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Split, Table, TableColumn, Tabs, Tab, FormItem, Button, Icon} from 'kpc';

var _$tmp0 = {
    'title': '名称',
    'key': 'name'
};
var _$tmp1 = {
    'title': '网段',
    'key': 'ip'
};
var _$tmp2 = {
    'className': 'ion-ios-close-empty',
    'size': '40'
};
var _$tmp3 = {
    'className': 'ion-drag'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_table = function(i) {_$refs['table'] = i};
    
    return _$ce(2, 'div', (
        _$cc(Split, {
            'mode': 'vertical',
            'className': _$cn({closed: this.get('size') === '0px'}),
            'min': 10,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['first'] = function($super) {
                            return (
                                _$cc(Table, {
                                    'data': this.get('data'),
                                    'fixHeader': true,
                                    'ref': _$ref_table,
                                    'rowCheckable': false,
                                    'checkedKeys': this.get('checkedKeys'),
                                    'ev-clickRow': this._onClickRow,
                                    'ev-$change:checkedKeys': this._togglePanel,
                                    'children': [
                                        _$cc(TableColumn, _$tmp0, 'name'),
                                        _$cc(TableColumn, _$tmp1, 'ip'),
                                        _$cc(TableColumn, {
                                            'title': '操作',
                                            'key': 'op',
                                            '$blocks': function($blocks) {
                                                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                                return (
                                                    (
                                                        (_$blocks['template'] = function($super, [value, index]) {
                                                            return (
                                                                _$ce(2, 'a', '删除', 16 /* HasTextChildren */)
                                                            );
                                                        }),
                                                        (__$blocks['template'] = function($super, data) {
                                                            var block = $blocks['template'];
                                                            var callBlock = function() {
                                                                return _$blocks['template'].call($this, $super, data);
                                                            };
                                                            return block ?
                                                                block.call($this, callBlock, data) :
                                                                callBlock();
                                                        })
                                                    ),
                                                    __$blocks
                                                );
                                            }.call($this, _$em)
                                        }, 'op')
                                    ]
                                }, null, _$ref_table)
                            );
                        }),
                        (__$blocks['first'] = function($super, data) {
                            var block = $blocks['first'];
                            var callBlock = function() {
                                return _$blocks['first'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['last'] = function($super) {
                            return [
                                _$cc(Button, {
                                    'type': 'none',
                                    'icon': true,
                                    'className': 'close',
                                    'ev-click': this._close,
                                    'children': (
                                        _$cc(Icon, _$tmp2)
                                    )
                                }),
                                _$cc(Tabs, {
                                    'children': [
                                        _$cc(Tab, {
                                            'value': 'detail',
                                            'children': '详情'
                                        }),
                                        _$cc(Tab, {
                                            'value': 'data',
                                            'children': '流量统计'
                                        }),
                                        _$cc(Tab, {
                                            'value': 'tag',
                                            'children': '标签'
                                        })
                                    ],
                                    'ev-$model:value': function($v) {
                                        $this.set('tab', $v);
                                    },
                                    'value': $this.get('tab')
                                }),
                                (this.get('tab') === 'detail' && this.get('selectedData')) ?
                                    _$ce(2, 'div', [
                                        _$cc(FormItem, {
                                            'label': '创建时间：',
                                            'children': '2018-09-28 17:31:25'
                                        }),
                                        _$cc(FormItem, {
                                            'label': '弹性IP：',
                                            'children': this.get('selectedData.ip')
                                        }),
                                        _$cc(FormItem, {
                                            'label': '名称：',
                                            'children': this.get('selectedData.name')
                                        })
                                    ], 4 /* HasNonKeyedChildren */, 'tab-panel') :
                                    undefined
                            ];
                        }),
                        (__$blocks['last'] = function($super, data) {
                            var block = $blocks['last'];
                            var callBlock = function() {
                                return _$blocks['last'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['drag'] = function($super) {
                            return (
                                _$cc(Icon, _$tmp3)
                            );
                        }),
                        (__$blocks['drag'] = function($super, data) {
                            var block = $blocks['drag'];
                            var callBlock = function() {
                                return _$blocks['drag'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:lastSize': function($v) {
                $this.set('size', $v);
            },
            'lastSize': $this.get('size')
        })
    ), 2 /* HasVNodeChildren */, 'split-container');
};