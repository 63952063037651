export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message} from 'kpc';

interface Props {
    types: ('info' | 'success' | 'error' | 'warning')[]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            types: ['info', 'success', 'error', 'warning'],
        } as Props;
    }

    ok() {
        Message.success('Clicked ok!');
    }

    cancel() {
        Message.info('Clicked cancel!');
    }
}