import {
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Menu, MenuItem, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-star'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const renderMenuItem = (item) => {
        return _$cc(MenuItem, {
            'key': item.key,
            'children': [
                item.title,
                _$ct('\n        '),
                item.children && item.children.length && (
                    _$cc(Menu, {
                        'children': item.children.map(renderMenuItem)
                    })
                )
            ]
        }, item.key)
    }
    
    return _$cc(Menu, {
        'children': this.get('menuList').map(renderMenuItem),
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['header'] = function($super) {
                        return [
                            _$cc(Icon, _$tmp0),
                            _$ct('测试\n    ')
                        ];
                    }),
                    (__$blocks['header'] = function($super, data) {
                        var block = $blocks['header'];
                        var callBlock = function() {
                            return _$blocks['header'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em),
        'ev-$model:expandedKeys': function($v) {
            $this.set('expandedKeys', $v);
        },
        'expandedKeys': $this.get('expandedKeys'),
        'ev-$model:selectedKey': function($v) {
            $this.set('selectedKey', $v);
        },
        'selectedKey': $this.get('selectedKey')
    });
};