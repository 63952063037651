import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Datepicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Datepicker, {
            'disabledDate': this.disabledDate
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'disabledDate': this.disabledMonth,
            'type': 'month'
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Datepicker, {
            'disabledDate': this.disabledYear,
            'type': 'year',
            'max': '2025'
        })
    ], 4 /* HasNonKeyedChildren */);
};