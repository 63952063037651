import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Tooltip, Slider, Button, Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tooltip, {
            'trigger': 'click',
            'children': (
                _$cc(Button, {
                    'children': 'Adjust Volume'
                })
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['content'] = function($super) {
                            return (
                                _$cc(Slider, {
                                    'style': 'width: 200px;',
                                    'showInput': false,
                                    'showEnd': false,
                                    'ev-$model:value': function($v) {
                                        $this.set('volume', $v);
                                    },
                                    'value': $this.get('volume')
                                })
                            );
                        }),
                        (__$blocks['content'] = function($super, data) {
                            var block = $blocks['content'];
                            var callBlock = function() {
                                return _$blocks['content'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$cc(Tooltip, {
            'trigger': 'click',
            'theme': 'light',
            'children': (
                _$cc(Button, {
                    'children': 'Test Dropdown'
                })
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['content'] = function($super) {
                            return (
                                _$cc(Select, {
                                    'width': 200,
                                    'children': [
                                        _$cc(Option, {
                                            'value': 'Monday',
                                            'children': '星期一'
                                        }),
                                        _$cc(Option, {
                                            'value': 'Tuesday',
                                            'children': '星期二'
                                        })
                                    ],
                                    'ev-$model:value': function($v) {
                                        $this.set('day', $v);
                                    },
                                    'value': $this.get('day')
                                })
                            );
                        }),
                        (__$blocks['content'] = function($super, data) {
                            var block = $blocks['content'];
                            var callBlock = function() {
                                return _$blocks['content'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */);
};