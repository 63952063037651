export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

type DataItem = {
    name: string
    desc: string
    ip: string
    disabled?: boolean
}

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data: [
                {name: '主机名0', desc: '前端服务器0', ip: '192.168.1.0'},
                {name: '主机名1', desc: '前端服务器1', ip: '192.168.1.1'},
                {name: '主机名2', desc: '前端服务器2', ip: '192.168.1.2'},
                {name: '主机名3', desc: '前端服务器3', ip: '192.168.1.3', disabled: true},
                {name: '主机名4', desc: '前端服务器4', ip: '192.168.1.4'},
            ]
        }
    }

    filter(data: DataItem, keywords: string) {
        return data.name.includes(keywords) || 
            data.desc.includes(keywords) ||
            data.ip.includes(keywords);
    }
}