import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {getRestProps} from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {to, children, className} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-breadcrumb-item`]: true,
        [className]: className,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, (
        (to) ?
            _$ce(2, 'a', children, 0 /* UnknownChildren */, _$cn(`${k}-breadcrumb-link`), {
                'ev-click': this.onClick
            }) :
            _$ce(2, 'span', children, 0 /* UnknownChildren */, _$cn(`${k}-breadcrumb-link`))
    ));
};