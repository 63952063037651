import {
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {addStyle, getRestProps} from '../utils';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        className, style, size, color,
        rotate, hoverable, children, disabled
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-icon`]: true,
        [`${k}-rotate`]: rotate,
        [`${k}-hoverable`]: !disabled && hoverable,
        [`${k}-disabled`]: disabled,
        [className]: className,
    };
    const _style = {};
    if (/^\d+$/.test(size)) {
        _style.fontSize = size + 'px'; 
    } else if (size !== 'default') {
        classNameObj[`${k}-${size}`] = true;
    }
    if (this.colors.indexOf(color) > -1) {
        classNameObj[`${k}-${color}`] = color !== 'default';
        classNameObj[makeStyles(k)] = true;
    } else if (color) {
        _style.color = color;
        classNameObj[makeStyles(k, color)] = true;
    } else {
        classNameObj[makeStyles(k)] = true;
    }
    
    return _$cv('i', {
        'className': _$cn(classNameObj),
        ...getRestProps(this),
        'style': addStyle(style, _style)
    }, children);
};