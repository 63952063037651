import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {getRestProps, addStyle} from '../utils';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, className, style} = this.get();
    const {style: _style, containerStyle} = this.style;
    const { cls, k } = this.config;
    
    const classNameObj = {
        [`${k}-affix`]: true,
        [`${k}-fixed`]: _style.value,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this),
        'style': addStyle(style, containerStyle.value),
        'ref': this.elementRef
    }, (
        _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(cls('affix-wrapper')), {
            'style': _style.value
        })
    ));
};