import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Datepicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$cc(Datepicker, {
                'format': 'MM/DD/YYYY',
                'ev-$model:value': function($v) {
                    $this.set('date1', $v);
                },
                'value': $this.get('date1')
            }),
            _$ct('\n        value: '),
            this.get('date1')
        ], 0 /* UnknownChildren */),
        _$ce(2, 'div', [
            _$cc(Datepicker, {
                'showFormat': 'YYYY年M月D日',
                'ev-$model:value': function($v) {
                    $this.set('date2', $v);
                },
                'value': $this.get('date2')
            }),
            _$ct('\n        value: '),
            this.get('date2')
        ], 0 /* UnknownChildren */),
        _$ce(2, 'div', [
            _$cc(Datepicker, {
                'type': 'datetime',
                'valueFormat': 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
                'ev-$model:value': function($v) {
                    $this.set('date3', $v);
                },
                'value': $this.get('date3')
            }),
            _$ct('\n        value: '),
            this.get('date3')
        ], 0 /* UnknownChildren */)
    ], 4 /* HasNonKeyedChildren */);
};