import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    className as _$cn,
} from 'intact';
import {Tabs, Tab} from 'kpc/components/tabs';
/* import {Tooltip} from 'kpc/components/tooltip'; */

var _$tmp0 = {
    'id': 'demos'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        (this.get('demos').length) ?
            _$ce(2, 'div', [
                _$ce(2, 'h1', '示例', 16 /* HasTextChildren */, null, _$tmp0),
                this.get('demos').map(Demo => {
                    const highlighted = Demo.data.highlighted;
                    /* highlighted.sort() */
                    return _$ce(2, 'div', [
                        _$ce(2, 'div', Demo.data.setting.title, 0 /* UnknownChildren */, 'example-title'),
                        _$ce(2, 'div', (
                            _$cc(Demo.default)
                        ), 2 /* HasVNodeChildren */, 'example-opera'),
                        _$ce(2, 'div', (
                            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'desc', {
                                'innerHTML': Demo.data.contents
                            })
                        ), 2 /* HasVNodeChildren */, 'example-desc'),
                        _$ce(2, 'div', [
                            _$cc(Tabs, {
                                'children': (
                                    _$ma(Demo.data.highlighted, function({file, language}, $key) {
                                        return (language !== 'vdt' && language !== 'ts') ?
                                            _$cc(Tab, {
                                                'value': file,
                                                'children': (
                                                    _$ce(2, 'span', (() => { 
                                                        switch (file) {
                                                            case 'index.styl': return 'index.styl (Stylus)';
                                                            case 'index.vue': return 'Vue (Legacy)';
                                                            case 'next.vue': return 'Vue';
                                                            case 'react.tsx': 
                                                            case 'index.tsx':
                                                                return 'React';
                                                        }
                                                    })(), 0 /* UnknownChildren */, 'filetype')
                                                )
                                            }) :
                                            undefined;
                                    }, $this)
                                ),
                                'ev-$model:value': function($v) {
                                    $this.set(`file${Demo.data.index}`, $v);
                                },
                                'value': $this.get(`file${Demo.data.index}`)
                            }),
                            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'code', {
                                'innerHTML': (() => {
                                    let content = (Demo.data.highlighted.find(item => {
                                        const file = item.file ? item.file : `index.${item.language}`;
                                        return file === this.get('file' + Demo.data.index);
                                    }) || {}).content;
                                    if (content) {
                                        const file = this.get('file' + Demo.data.index);
                                        if (file === 'next.vue') {
                                            content = content.replace('@king-design/vue/node_modules/vue', 'vue');
                                        } else if (file === 'react.tsx' ){
                                            // use `States` to replace `Props`, it's the convention of React
                                            content = content.replace(/\bProps\b/g, 'States');
                                        }
                                    }
                                
                                    return content;
                                })()
                            })
                        ], 4 /* HasNonKeyedChildren */, 'example-code')
                    ], 4 /* HasNonKeyedChildren */, _$cn(`example ${Demo.data.index}`), {
                        'id': Demo.data.index
                    })
                })
            ], 0 /* UnknownChildren */, null, null, 'demos') :
            undefined,
        _$ce(2, 'div', this.get('contents').map((value, index) => {
            const Example = this.get('examples')[index];
            return [
                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, null, {
                    'innerHTML': value
                }),
                Example ? _$cc(Example.default): undefined,
            ];
        }), 0 /* UnknownChildren */, 'api', null, 'contents')
    ], 0 /* UnknownChildren */, 'contents');
};