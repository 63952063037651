export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {Form, bind} from 'kpc';

interface Props {
    descriptions: string[]
}

// 添加全局规则
Form.addMethod('letter', (value, param) => {
    return /^[a-z|A-Z]+$/.test(value);
}, '只能输入字母');

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            descriptions: ['', '']
        }
    };

    @bind
    add() {
        this.set('descriptions', this.get('descriptions').concat(''));
    }

    @bind
    remove(index: number) {
        const descriptions = this.get('descriptions').slice(0);
        descriptions.splice(index, 1);
        this.set('descriptions', descriptions);
    }

    @bind
    unique(value: string) {
        let count = 0;
        this.get('descriptions').find(item => {
            if (item === value) count++;
            return count > 1;
        });

        // 直接返回错误文案，或者也可以单独定义messages为{unique: '不能相同'}
        return count === 1 || '不能相同';
    }
}