export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind} from 'kpc';

interface Props {
    show?: boolean
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {show: false};
    } 

    @bind
    onClick() {
        this.set('show', true);
    }
}