import {
    createVNode as _$cv,
    className as _$cn,
    noop as _$no,
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    map as _$ma,
} from 'intact';
import {Button} from '../button';
import {Progress} from '../progress';
import {Dialog} from '../dialog';
import {expandAnimationCallbacks, getRestProps} from '../utils';
import {makeStyles, makeUploadDialogStyles} from './styles';
import {TransitionGroup, Transition, linkEvent} from 'intact';
import {_$} from '../../i18n';
import {Icon} from '../icon';
import {UploadFileStatus} from './useUpload';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        className, accept, multiple, limit,
        files, children, type, directory, disabled
    } = this.get();
    
    const drag = this.drag;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-upload`]: true,
        [className]: className,
        [`${k}-drag`]: type === 'drag', 
        [`${k}-dragover`]: drag.dragOver.value, 
        [`${k}-gallery`]: type === 'gallery',
        [`${k}-disabled`]: disabled,
        [makeStyles(k)]: true,
    };
    
    const events = {
        'ev-click': this.selectFile,
    };
    if (type === 'drag') {
        events['ev-dragover'] = drag.stopEvent;
        events['ev-dragenter'] = drag.onDragEnter;
        events['ev-dragleave'] = drag.onDragLeave;
        events['ev-drop'] = drag.onDrop;
    }
    
    const handle = (
        _$cv('div', {
            'className': _$cn(`${k}-upload-handle`),
            ...events
        }, (
            (
                (_$blocks['content'] = function($super) {
                    return (
                        (children) ?
                            (
                                (_$blocks['children'] = function($super) {
                                    return children;
                                }),
                                (__$blocks['children'] = function($super, data) {
                                    var block = $blocks['children'];
                                    var callBlock = function() {
                                        return _$blocks['children'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                }),
                                __$blocks['children'](_$no)
                            ) :
                            type !== 'gallery' ?
                                (
                                    (type === 'select') ?
                                        _$cc(Button, {
                                            'disabled': disabled,
                                            'children': [
                                                _$cc(Icon, {
                                                    'className': _$cn(`${k}-icon-upload`)
                                                }),
                                                _$('点击上传')
                                            ]
                                        }) :
                                        _$ce(2, 'div', [
                                            _$cc(Icon, {
                                                'className': _$cn(`${k}-icon-add-bold`)
                                            }),
                                            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, null, {
                                                'innerHTML': _$('将文件拖到此处，或<a>点击上传</a>')
                                            })
                                        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-upload-area`))
                                ) :
                                _$ce(2, 'div', [
                                    _$cc(Icon, {
                                        'className': _$cn(`${k}-icon-add-bold`),
                                        'size': 'large'
                                    }),
                                    _$ce(2, 'div', _$('上传图片'), 0 /* UnknownChildren */)
                                ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-upload-picture-card ${k}-upload-add`))
                    );
                }),
                (__$blocks['content'] = function($super, data) {
                    var block = $blocks['content'];
                    var callBlock = function() {
                        return _$blocks['content'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                }),
                __$blocks['content'](_$no)
            )
        ))
    );
    
    const {removeFile} = this.files;
    const closeBtn = (value, index) => (
        _$cc(Icon, {
            'className': _$cn(`${k}-upload-close ${k}-icon-delete`),
            'ev-click': () => removeFile(value, index),
            'hoverable': true
        })
    );
    
    const {show, close, image, isShow} = this.showImage;
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        _$ce(64, 'input', null, 1 /* HasInvalidChildren */, 'c-hidden', {
            'type': 'file',
            'accept': accept,
            'ev-change': this.onInputChange,
            'multiple': multiple,
            'directory': directory ? '' : undefined,
            'webkitdirectory': directory ? '' : undefined
        }, null, this.inputRef),
        (type === 'gallery') ?
            _$ce(2, 'div', (
                _$cc(TransitionGroup, {
                    'name': 'k-dropdown',
                    'children': [
                        _$ma(files, function($value, $key) {
                            return _$ce(2, 'div', [
                                _$ce(2, 'img', null, 1 /* HasInvalidChildren */, _$cn(`${k}-upload-img`), {
                                    'src': $value.url
                                }),
                                _$cc(Transition, {
                                    'name': 'k-fade',
                                    'children': (
                                        ($value.status === UploadFileStatus.Ready || 
                                        $value.status === UploadFileStatus.Uploading) ?
                                            _$ce(2, 'div', (
                                                _$cc(Progress, {
                                                    'percent': $value.percent,
                                                    'size': 'mini',
                                                    'className': _$cn(`${k}-upload-progress`)
                                                })
                                            ), 2 /* HasVNodeChildren */, _$cn(`${k}-upload-overlap`), null, 'overlap') :
                                            _$ce(2, 'div', [
                                                _$cc(Icon, {
                                                    'className': _$cn(`${k}-upload-zoom ion-ios-eye-outline`),
                                                    'size': 'large',
                                                    'hoverable': true,
                                                    'ev-click': () => show($value)
                                                }),
                                                closeBtn($value, $key)
                                            ], 0 /* UnknownChildren */, _$cn(`${k}-upload-overlap ${k}-upload-icons`))
                                    )
                                })
                            ], 4 /* HasNonKeyedChildren */, _$cn({
                                [`${k}-upload-picture ${k}-upload-picture-card`]: true, 
                                [`${k}-error`]: $value.status === UploadFileStatus.Error,
                            }), null, $value.uid);
                        }, $this),
                        (!limit || files.length < limit) ?
                            _$ce(2, 'div', handle, 0 /* UnknownChildren */, 'c-middle', null, 'handle') :
                            undefined
                    ]
                })
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-upload-pictures`)) :
            handle,
        ($blocks.tip) ?
            _$ce(2, 'div', (
                (
                    (_$blocks['tip'] = function($super) {
                        return null;
                    }),
                    (__$blocks['tip'] = function($super, data) {
                        var block = $blocks['tip'];
                        var callBlock = function() {
                            return _$blocks['tip'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['tip'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-upload-tip`)) :
            undefined,
        (type !== 'gallery') ?
            _$ce(2, 'div', (
                _$cc(TransitionGroup, {
                    ...expandAnimationCallbacks,
                    'children': (
                        _$ma(files, function($value, $key) {
                            return _$ce(2, 'div', [
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', [
                                        _$cc(Icon, {
                                            'className': _$cn(`${k}-upload-file-icon ion-document`)
                                        }),
                                        _$ce(2, 'span', $value.name, 0 /* UnknownChildren */, _$cn(`${k}-upload-file-name`)),
                                        ($value.status === UploadFileStatus.Done) ?
                                            _$cc(Icon, {
                                                'className': _$cn(`${k}-upload-status-icon ${k}-icon-success-fill`)
                                            }) :
                                            $value.status === UploadFileStatus.Error ?
                                                _$cc(Icon, {
                                                    'className': _$cn(`${k}-upload-status-icon ${k}-icon-error-fill`)
                                                }) :
                                                undefined
                                    ], 0 /* UnknownChildren */, _$cn(`${k}-upload-name c-ellipsis`)),
                                    _$cc(Transition, {
                                        ...expandAnimationCallbacks,
                                        'children': (
                                            ($value.status === UploadFileStatus.Ready || 
                                            $value.status === UploadFileStatus.Uploading) ?
                                                _$cc(Progress, {
                                                    'percent': $value.percent,
                                                    'size': 'mini',
                                                    'className': _$cn(`${k}-upload-progress`)
                                                }) :
                                                undefined
                                        )
                                    })
                                ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-upload-file-main`)),
                                closeBtn($value, $key)
                            ], 0 /* UnknownChildren */, _$cn({
                                [`${k}-upload-file`]: true,
                                [`${k}-error`]: $value.status === UploadFileStatus.Error
                            }), null, $value.uid);
                        }, $this)
                    )
                })
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-upload-files`)) :
            undefined,
        _$cc(Dialog, {
            'title': _$("查看图片"),
            'key': 'dialog',
            'value': isShow.value,
            'ev-close': close,
            'className': _$cn({[`${k}-upload-dialog`]: true, [makeUploadDialogStyles(k)]: true}),
            'children': (
                (image.value) ?
                    _$ce(2, 'a', (
                        _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                            'src': image.value.url
                        })
                    ), 2 /* HasVNodeChildren */, null, {
                        'href': image.value.url,
                        'target': '_blank'
                    }) :
                    undefined
            )
        }, 'dialog')
    ]);
};