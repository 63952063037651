import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {makeStyles} from './styles';
import {getRestProps, addStyle} from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        children, className, borderType, 
        position, type, theme, margin,
        style
    } = this.get();
    const { k } = this.config;
    const _style = {};
    if (typeof margin === 'number') {
        const _margin = type === 'vertical' ? `0 ${margin}px` : `${margin}px 0`;
        _style.margin = _margin;
    }
    
    const classNameObj = {
        [`${k}-divider`]: true,
        [`${k}-${theme === 'dark' ? 'dark' : 'light'}`]: theme,
        [`${k}-${type}`]: type,
        [`${k}-${margin}`]: margin && typeof margin !== 'number',
        [`${k}-with-text`]: children && type === 'horizontal',
        [`${k}-${position}`]: position !== 'center',
        [makeStyles(k, borderType)]: true,
        [className]: className,
    };
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'style': addStyle(style, _style)
    }, (
        (children && type === 'horizontal') ?
            _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-divider-text`)) :
            undefined
    ));
};