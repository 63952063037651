import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Diagram, DTreeLayout, DRectangle, DCircle, DDiamond, DLine} from 'kpc/components/diagram';

var _$tmp0 = {
    'label': '<h2>hello</h2>',
    'key': '1'
};
var _$tmp1 = {
    'key': '2',
    'label': 'world'
};
var _$tmp2 = {
    'key': '3',
    'label': 'kpc'
};
var _$tmp3 = {
    'from': '1',
    'to': '2',
    'type': 'rounded'
};
var _$tmp4 = {
    'from': '1',
    'to': '3',
    'type': 'rounded'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Diagram, {
        'children': (
            _$cc(DTreeLayout, {
                'type': 'vertical',
                'children': [
                    _$cc(DRectangle, _$tmp0, '1'),
                    _$cc(DDiamond, _$tmp1, '2'),
                    _$cc(DCircle, _$tmp2, '3'),
                    _$cc(DLine, _$tmp3),
                    _$cc(DLine, _$tmp4)
                ]
            })
        )
    });
};