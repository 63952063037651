import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Transfer} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Transfer, {
            'data': this.get('data'),
            'ref': _$ref___test,
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }, null, _$ref___test),
        _$ce(2, 'p', [
            _$ct('You selected: '),
            JSON.stringify(this.get('value'))
        ], 0 /* UnknownChildren */)
    ], 4 /* HasNonKeyedChildren */);
};