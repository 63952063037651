import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Datepicker} from 'kpc';

var _$tmp0 = {
    'disabled': true,
    'value': '2018-01-01'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___demo = function(i) {_$refs['__demo'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Datepicker, {
            'ref': _$ref___demo,
            'ev-$model:value': function($v) {
                $this.set('date', $v);
            },
            'value': $this.get('date')
        }, null, _$ref___demo),
        _$cc(Datepicker, {
            'flat': true,
            'ev-$model:value': function($v) {
                $this.set('date', $v);
            },
            'value': $this.get('date')
        }),
        _$cc(Datepicker, _$tmp0)
    ], 4 /* HasNonKeyedChildren */);
};