export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

interface Props {
    expandedKeys?: string[]
    selectedKey?: string
    collapse: boolean
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            expandedKeys: [],
            selectedKey: '3-1',
            collapse: false,
        };
    }

    @bind
    toggle() {
        this.set('collapse', !this.get('collapse'));
    }
}