import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Title 1',
    'minWidth': 200
};
var _$tmp1 = {
    'key': 'b',
    'title': 'Title 2',
    'minWidth': 300
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Table, {
            'data': this.get('data'),
            'resizable': true,
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['footer'] = function($super) {
                            return (
                                _$ce(2, 'div', '自定义底部', 16 /* HasTextChildren */, 'footer')
                            );
                        }),
                        (__$blocks['footer'] = function($super, data) {
                            var block = $blocks['footer'];
                            var callBlock = function() {
                                return _$blocks['footer'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ), 2 /* HasVNodeChildren */);
};