import {
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Menu, MenuItem, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-flag'
};
var _$tmp1 = {
    'className': 'ion-star'
};
var _$tmp2 = {
    'className': 'ion-heart'
};
var _$tmp3 = {
    'className': 'ion-gear-b'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Menu, {
        'accordion': true,
        'children': [
            _$cc(MenuItem, {
                'key': '1',
                'children': [
                    _$cc(Icon, _$tmp0),
                    _$ct('menu 1\n        '),
                    _$cc(Menu, {
                        'children': [
                            _$cc(MenuItem, {
                                'key': '1-1',
                                'children': 'sub menu 1'
                            }, '1-1'),
                            _$cc(MenuItem, {
                                'key': '1-2',
                                'children': 'sub menu 2'
                            }, '1-2')
                        ]
                    })
                ]
            }, '1'),
            _$cc(MenuItem, {
                'key': '2',
                'disabled': true,
                'children': [
                    _$cc(Icon, _$tmp1),
                    _$ct('menu 2\n    ')
                ]
            }, '2'),
            _$cc(MenuItem, {
                'key': '3',
                'children': [
                    _$cc(Icon, _$tmp2),
                    _$ct('menu 3\n        '),
                    _$cc(Menu, {
                        'children': [
                            _$cc(MenuItem, {
                                'key': '3-1',
                                'children': [
                                    _$ct('\n                sub menu 1\n                '),
                                    _$cc(Menu, {
                                        'children': (
                                            _$cc(MenuItem, {
                                                'key': '3-1-1',
                                                'children': 'Option 1'
                                            }, '3-1-1')
                                        )
                                    })
                                ]
                            }, '3-1'),
                            _$cc(MenuItem, {
                                'key': '3-2',
                                'children': 'sub menu 2'
                            }, '3-2'),
                            _$cc(MenuItem, {
                                'key': '3-3',
                                'disabled': true,
                                'children': 'sub menu 3'
                            }, '3-3'),
                            _$cc(MenuItem, {
                                'key': '3-4',
                                'children': [
                                    _$ct('\n                sub menu 4\n                '),
                                    _$cc(Menu, {
                                        'children': [
                                            _$cc(MenuItem, {
                                                'key': '3-4-1',
                                                'children': 'Option 1'
                                            }, '3-4-1'),
                                            _$cc(MenuItem, {
                                                'key': '3-4-2',
                                                'children': 'Option 2'
                                            }, '3-4-2')
                                        ]
                                    })
                                ]
                            }, '3-4')
                        ]
                    })
                ]
            }, '3'),
            _$cc(MenuItem, {
                'key': '4',
                'children': [
                    _$cc(Icon, _$tmp3),
                    _$ct('menu 4\n        '),
                    _$cc(Menu, {
                        'children': [
                            _$cc(MenuItem, {
                                'key': '4-1',
                                'children': 'sub menu 1'
                            }, '4-1'),
                            _$cc(MenuItem, {
                                'key': '4-2',
                                'children': 'sub menu 2'
                            }, '4-2')
                        ]
                    })
                ]
            }, '4')
        ],
        'ev-$model:expandedKeys': function($v) {
            $this.set('expandedKeys', $v);
        },
        'expandedKeys': $this.get('expandedKeys')
    });
};