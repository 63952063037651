export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    from?: string | null
    to?: string | null
    fromTime?: string | null
    toTime?: string | null
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            from: null,
            to: null,
            fromTime: null,
            toTime: null,
        } as Props;
    }
}