import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Transfer, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_transfer = function(i) {_$refs['transfer'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.getData,
            'children': 'get data'
        }),
        _$cc(Button, {
            'ev-click': this.getCheckedData.bind(this, 'left'),
            'children': 'get left checked data'
        }),
        _$cc(Button, {
            'ev-click': this.getCheckedData.bind(this, 'right'),
            'children': 'get right checked data'
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Transfer, {
            'data': this.get('data'),
            'ref': _$ref_transfer,
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }, null, _$ref_transfer),
        _$ce(2, 'p', [
            _$ct('You selected: '),
            JSON.stringify(this.get('value'))
        ], 0 /* UnknownChildren */)
    ], 4 /* HasNonKeyedChildren */);
};