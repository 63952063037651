import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';
import kingvisionLogo from '../../../imgs/kingvision_logo.png';
import kingvisioinTitle from '../../../imgs/kingvisioin_title.png';
import {Button} from 'kpc';

var _$tmp0 = {
    'href': 'http://vision.ksyun.com/#/icon-reposition',
    'target': '_blank'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$ce(2, 'div', [
            _$ce(2, 'div', [
                _$ce(2, 'div', (
                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                        'src': kingvisionLogo,
                        'width': '307'
                    })
                ), 2 /* HasVNodeChildren */, 'main-logo'),
                _$ce(2, 'div', (
                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                        'src': kingvisioinTitle,
                        'width': '163'
                    })
                ), 2 /* HasVNodeChildren */, 'vis-title'),
                _$ce(2, 'div', '\n                专业的企业级设计资源，丰富的图片与icon定制平台。\n            ', 16 /* HasTextChildren */, 'vis-description web-info'),
                _$ce(2, 'div', [
                    _$ce(2, 'div', '专业的企业级设计资源，', 16 /* HasTextChildren */),
                    _$ce(2, 'div', '丰富的图片与icon定制平台。', 16 /* HasTextChildren */)
                ], 4 /* HasNonKeyedChildren */, 'vis-description h5-info'),
                _$ce(2, 'a', (
                    _$cc(Button, {
                        'className': 'visit-king-vision',
                        'type': 'primary',
                        'children': '访问KingVision'
                    })
                ), 2 /* HasVNodeChildren */, null, _$tmp0)
            ], 4 /* HasNonKeyedChildren */),
            _$ce(2, 'div', (
                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'king-vision-bg')
            ), 2 /* HasVNodeChildren */)
        ], 4 /* HasNonKeyedChildren */, 'king-vis-wrapper')
    ), 2 /* HasVNodeChildren */, _$cn(makeStyles()));
};