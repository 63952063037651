import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Spinner} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Spinner, {
            'max': 10,
            'min': 0,
            'precision': 0,
            'ev-$model:value': function($v) {
                $this.set('value1', $v);
            },
            'value': $this.get('value1')
        }),
        _$cc(Spinner, {
            'max': 10,
            'min': -10,
            'step': 0.1,
            'precision': 1,
            'ev-$model:value': function($v) {
                $this.set('value2', $v);
            },
            'value': $this.get('value2')
        })
    ], 4 /* HasNonKeyedChildren */);
};