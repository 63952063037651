import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Progress, Button, ButtonGroup} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Progress, {
            'percent': this.get('percent'),
            'color': this.get('color')
        }),
        _$cc(Progress, {
            'percent': this.get('percent'),
            'color': this.get('colors')
        }),
        _$cc(Progress, {
            'percent': this.get('percent'),
            'color': this.setColor
        }),
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'size': 'mini',
                    'icon': true,
                    'ev-click': this.minus,
                    'children': '-'
                }),
                _$cc(Button, {
                    'size': 'mini',
                    'icon': true,
                    'ev-click': this.add,
                    'children': '+'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};