import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Icon} from '../icon';
import {Ellipsis} from '../ellipsis';
import {getRestProps, findChildren, isComponentVNode} from '../utils';
import {makeTitleStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const rootMenu = this.rootMenu;
    const {collapse} = rootMenu.get();
    const {children, className} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-menu-title`]: true,
        [className]: className,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, (
        _$ce(2, 'div', (
            (!collapse) ?
                _$cc(Ellipsis, {
                    'position': 'bottom',
                    'children': children
                }) :
                findChildren(children, vNode => isComponentVNode(vNode, Icon))
        ), 0 /* UnknownChildren */, _$cn({
            [`${k}-menu-name`]: true,
            [makeTitleStyles(k)]: true
        }))
    ));
};