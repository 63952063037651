import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-search'
};
var _$tmp1 = {
    'className': 'k-icon-search'
};
var _$tmp2 = {
    'className': 'k-icon-search'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'type': 'primary',
            'loading': true,
            'children': '确认'
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'loading': true,
            'children': (
                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'k-icon k-icon-search')
            )
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Button, {
            'type': 'primary',
            'loading': this.get('loading1'),
            'ev-click': this.onClick.bind(this, 'loading1'),
            'children': '点击加载'
        }),
        _$cc(Button, {
            'type': 'primary',
            'loading': this.get('loading2'),
            'ev-click': this.onClick.bind(this, 'loading2'),
            'children': [
                _$cc(Icon, _$tmp0),
                _$ct('点击加载')
            ]
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'loading': this.get('loading3'),
            'ev-click': this.onClick.bind(this, 'loading3'),
            'children': (
                _$cc(Icon, _$tmp1)
            )
        }),
        _$cc(Button, {
            'type': 'primary',
            'loading': this.get('loading4'),
            'ev-click': this.onClick.bind(this, 'loading4'),
            'children': [
                _$ct('图标在右侧'),
                _$cc(Icon, _$tmp2)
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};