import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    createTextVNode as _$ct,
} from 'intact';
import {Form, FormItem, Input} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Form, {
        'starOnRequired': true,
        'children': [
            _$cc(FormItem, {
                'rules': {required: true},
                'label': '姓名',
                'children': (
                    _$cc(Input)
                )
            }),
            _$cc(FormItem, {
                'children': (
                    _$cc(Input)
                ),
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['label'] = function($super) {
                                return [
                                    _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-person'),
                                    _$ct(' 姓名')
                                ];
                            }),
                            (__$blocks['label'] = function($super, data) {
                                var block = $blocks['label'];
                                var callBlock = function() {
                                    return _$blocks['label'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            })
        ]
    });
};