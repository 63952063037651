import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Diagram, DTreeLayout, DCircle, DLine} from 'kpc/components/diagram';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Diagram, {
        'ev-selectionChanged': this.onSelect,
        'children': (
            _$cc(DTreeLayout, {
                'type': 'vertical',
                'children': [
                    _$ma(this.get('nodes'), function($value, $key) {
                        return _$cc(DCircle, {
                            'key': $value.key,
                            'label': $value.label,
                            'selectable': $value.selectable,
                            'data': $value.data
                        }, $value.key);
                    }, $this),
                    _$ma(this.get('lines'), function($value, $key) {
                        return _$cc(DLine, {
                            'from': $value.from,
                            'to': $value.to,
                            'type': 'rounded'
                        });
                    }, $this)
                ]
            })
        )
    });
};