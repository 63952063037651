import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Slider} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Slider, {
            'min': 1,
            'max': 12,
            'value': 6,
            'showInput': false,
            'marks': this.get('marks')
        }),
        _$cc(Slider, {
            'min': 1,
            'max': 12,
            'value': [2, 6],
            'showInput': false,
            'range': true,
            'marks': this.get('marks')
        })
    ], 4 /* HasNonKeyedChildren */);
};