export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind, Message} from 'kpc';

interface Props {
    show?: boolean
    code?: string
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            show: false,
            code: ''
        };
    }

    private timer: number | undefined;

    @bind
    async ok() {
        // validate the form firstly
        const valid = await this.refs.form.validate();
        if (valid) {
            // make the ok button show loading
            this.refs.dialog.showLoading(); 
            // mock api
            new Promise<void>((resolve, reject) => {
                this.timer = window.setTimeout(() => {
                    if (Math.random() > 0.5) {
                        resolve();
                    } else {
                        reject();
                    }
                }, 2000);
            }).then(() => {
                // if success, close dialog 
                this.refs.dialog.hideLoading();
                this.refs.dialog.close();
            }, () => {
                // if error, don't close dialog
                this.refs.dialog.hideLoading();
                Message.error('error occured');
            });
        }
    }

    beforeUnmount() {
        window.clearTimeout(this.timer);
    }
}