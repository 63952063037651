export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    loading1?: boolean
    loading2?: boolean
    loading3?: boolean
    loading4?: boolean
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            'loading1': false,
            'loading2': false,
            'loading3': false,
            'loading4': false,
        } as Props;
    }

    onClick(name: keyof Props) {
        this.set(name, true);
    }
}