import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Tree, Button, ButtonGroup} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_tree = function(i) {_$refs['tree'] = i};
    
    const data = this.get('data');
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'className': 'root-btns',
            'children': (
                _$cc(Button, {
                    'icon': true,
                    'size': 'small',
                    'ev-click': this.appendToRoot,
                    'children': '+'
                })
            )
        }),
        _$cc(Tree, {
            'data': data,
            'ref': _$ref_tree,
            'checkbox': true,
            'selectable': false,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['label'] = function($super, [data, node, index]) {
                            return [
                                _$ce(2, 'div', data.label, 0 /* UnknownChildren */, 'text'),
                                (!data.disabled) ?
                                    _$cc(ButtonGroup, {
                                        'children': [
                                            _$cc(Button, {
                                                'icon': true,
                                                'size': 'small',
                                                'ev-click': this.append.bind(this, data, node, index),
                                                'children': '+'
                                            }),
                                            _$cc(Button, {
                                                'icon': true,
                                                'size': 'small',
                                                'ev-click': this.remove.bind(this, data, node, index),
                                                'children': '-'
                                            })
                                        ]
                                    }) :
                                    undefined
                            ];
                        }),
                        (__$blocks['label'] = function($super, data) {
                            var block = $blocks['label'];
                            var callBlock = function() {
                                return _$blocks['label'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }, null, _$ref_tree)
    ], 4 /* HasNonKeyedChildren */, 'wrapper');
};