import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Editable} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test1 = function(i) {_$refs['__test1'] = i};
    var _$ref___test2 = function(i) {_$refs['__test2'] = i};
    var _$ref___test3 = function(i) {_$refs['__test3'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Editable, {
            'validate': value => /\d+/.test(value),
            'ref': _$ref___test1,
            'ev-change': this._onChange,
            'children': this.get('value'),
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }, null, _$ref___test1),
        _$ce(2, 'br'),
        _$cc(Editable, {
            'validate': /\d+/,
            'ref': _$ref___test2,
            'children': this.get('value'),
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }, null, _$ref___test2),
        _$ce(2, 'br'),
        _$cc(Editable, {
            'validate': '\\d+',
            'ev-error': this._showErrorTip,
            'ref': _$ref___test3,
            'children': this.get('value'),
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }, null, _$ref___test3)
    ], 4 /* HasNonKeyedChildren */);
};