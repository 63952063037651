import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'title': 'key形式',
    'key': 'b'
};
var _$tmp1 = {
    'title': 'key为一个路径字符串',
    'key': 'c.c1'
};
var _$tmp2 = {
    'title': '没有这个key，则返回空',
    'key': 'd.d1'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': this.get('data'),
        'resizable': true,
        'children': [
            _$cc(TableColumn, {
                'title': '定义该列单元格内容',
                'key': 'a',
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['template'] = function($super, [data, index]) {
                                return (
                                    _$ce(2, 'a', data.a, 0 /* UnknownChildren */)
                                );
                            }),
                            (__$blocks['template'] = function($super, data) {
                                var block = $blocks['template'];
                                var callBlock = function() {
                                    return _$blocks['template'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, 'a'),
            _$cc(TableColumn, _$tmp0, 'b'),
            _$cc(TableColumn, _$tmp1, 'c.c1'),
            _$cc(TableColumn, _$tmp2, 'd.d1')
        ]
    });
};