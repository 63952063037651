import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    createElementVNode as _$ce,
} from 'intact';
import {Select, Option} from 'kpc';

var _$tmp0 = {
    'style': 'float: right; color: #ccc'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Select, {
        'filterable': true,
        'children': (
            _$ma(this.get('data'), function($value, $key) {
                return _$cc(Option, {
                    'value': $value.value,
                    'label': $value.label,
                    'children': (
                        _$ce(2, 'div', [
                            _$ce(2, 'span', $value.label, 0 /* UnknownChildren */),
                            _$ce(2, 'span', $value.value, 0 /* UnknownChildren */, null, _$tmp0)
                        ], 4 /* HasNonKeyedChildren */)
                    )
                });
            }, $this)
        ),
        'ev-$model:value': function($v) {
            $this.set('day', $v);
        },
        'value': $this.get('day')
    });
};