import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Cascader} from 'kpc';

var _$tmp0 = {
    'style': 'line-height: 1; margin-right: 8px; vertical-align: middle;'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Cascader, {
        'data': this.get('data'),
        'clearable': true,
        'format': labels => labels[labels.length - 1],
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['value'] = function($super, [value, label]) {
                        return (
                            _$ce(2, 'div', [
                                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-ios-location', _$tmp0),
                                label
                            ], 0 /* UnknownChildren */)
                        );
                    }),
                    (__$blocks['value'] = function($super, data) {
                        var block = $blocks['value'];
                        var callBlock = function() {
                            return _$blocks['value'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em),
        'ev-$model:value': function($v) {
            $this.set('value', $v);
        },
        'value': $this.get('value')
    });
};