import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Tree, Dropdown, DropdownMenu, DropdownItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_tree = function(i) {_$refs['tree'] = i};
    
    return _$ce(2, 'div', (
        _$cc(Tree, {
            'ref': _$ref_tree,
            'data': this.get('data'),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['label'] = function($super, [data, node, index]) {
                            return (
                                _$cc(Dropdown, {
                                    'trigger': 'contextmenu',
                                    'disabled': data.disabled,
                                    'children': [
                                        _$ce(2, 'div', data.label, 0 /* UnknownChildren */),
                                        _$cc(DropdownMenu, {
                                            'children': [
                                                _$cc(DropdownItem, {
                                                    'ev-select': this.appendNode.bind(this, data, node),
                                                    'children': 'Append'
                                                }),
                                                _$cc(DropdownItem, {
                                                    'ev-select': this.removeNode.bind(this, data, node, index),
                                                    'children': 'Remove'
                                                })
                                            ]
                                        })
                                    ]
                                })
                            );
                        }),
                        (__$blocks['label'] = function($super, data) {
                            var block = $blocks['label'];
                            var callBlock = function() {
                                return _$blocks['label'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:expandedKeys': function($v) {
                $this.set('expandedKeys', $v);
            },
            'expandedKeys': $this.get('expandedKeys')
        }, null, _$ref_tree)
    ), 2 /* HasVNodeChildren */);
};