import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Card, CardColumn, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-person',
    'size': 'large'
};
var _$tmp1 = {
    'className': 'ion-person',
    'size': 'large'
};
var _$tmp2 = {
    'className': 'ion-person',
    'size': 'large'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Card, {
            'children': [
                _$cc(CardColumn, {
                    'width': '60px',
                    'center': true,
                    'children': (
                        _$cc(Icon, _$tmp0)
                    )
                }),
                _$cc(CardColumn, {
                    'children': '用户名：test'
                })
            ]
        }),
        _$cc(Card, {
            'children': [
                _$cc(CardColumn, {
                    'width': '60px',
                    'center': true,
                    'children': (
                        _$cc(Icon, _$tmp1)
                    )
                }),
                _$cc(CardColumn, {
                    'children': '用户名：test'
                })
            ]
        }),
        _$cc(Card, {
            'type': 'border',
            'children': [
                _$cc(CardColumn, {
                    'width': '60px',
                    'center': true,
                    'children': (
                        _$cc(Icon, _$tmp2)
                    )
                }),
                _$cc(CardColumn, {
                    'children': '用户名：test'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};