import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '表头1'
};
var _$tmp1 = {
    'key': 'b',
    'title': '表头2'
};
var _$tmp2 = {
    'key': 'a',
    'title': '表头1'
};
var _$tmp3 = {
    'key': 'b',
    'title': '表头2'
};
var _$tmp4 = {
    'style': 'color: red'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': [],
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        }),
        _$cc(Table, {
            'data': undefined,
            'children': [
                _$cc(TableColumn, _$tmp2, 'a'),
                _$cc(TableColumn, _$tmp3, 'b')
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['empty'] = function($super) {
                            return (
                                _$ce(2, 'div', '没有数据', 16 /* HasTextChildren */, null, _$tmp4)
                            );
                        }),
                        (__$blocks['empty'] = function($super, data) {
                            var block = $blocks['empty'];
                            var callBlock = function() {
                                return _$blocks['empty'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */);
};