import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Tree, Input, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-paper'
};
var _$tmp1 = {
    'className': 'k-icon-folder'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Tree, {
        'draggable': true,
        'ev-dragend': this.onDragEnd,
        'allowDrag': this.allowDrag,
        'allowDrop': this.allowDrop,
        'ev-denydrag': this.onDenyDrag,
        'ev-denydrop': this.onDenyDrop,
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['label'] = function($super, [data, node]) {
                        return [
                            (data.type === 'file') ?
                                _$cc(Icon, _$tmp0) :
                                _$cc(Icon, _$tmp1),
                            data.label
                        ];
                    }),
                    (__$blocks['label'] = function($super, data) {
                        var block = $blocks['label'];
                        var callBlock = function() {
                            return _$blocks['label'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em),
        'ev-$model:data': function($v) {
            $this.set('data', $v);
        },
        'data': $this.get('data'),
        'ev-$model:expandedKeys': function($v) {
            $this.set('expandedKeys', $v);
        },
        'expandedKeys': $this.get('expandedKeys')
    });
};