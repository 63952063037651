export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

interface Props {
    bottom: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            bottom: 0
        }
    }

    @bind
    shouldFix() {
        // when scroll top greater than or equal to 100
        // fix it at 150px from top
        return window.pageYOffset >= 100;
    }

    @bind
    exclude() {
        // when this demo is not in the viewport
        // don't fix it
        const {top} = (this.refs.node as HTMLElement).getBoundingClientRect();
        const viewport = document.documentElement.clientHeight;

        return top > viewport; 
    }
}