import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createElementVNode as _$ce,
    createCommentVNode as _$ccv,
    map as _$ma,
} from 'intact';
import {makeStyles, DiaStyles} from './style';
import {Layout, Header, Icon, Card, Input, Colorpicker, Dialog} from 'kpc';
import KD_logo from '../../imgs/KD_logo.png';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const color = {
        '探险者蓝': '#1E52E4',
        '日出红' : '#FF0000',                                      
        '赤铁橙': '#E7682A',
        '金云黄': '#B89534',
        '流光绿': '#57CBCB',
        '云游蓝': '#236FE3',
        '青铜绿': '#10ABBF',
        '祥云紫': '#6769AC',
        '熔岩灰': '#5C879B'
    }
    const {inputColor, colorType, theme, contrastMenu, preColor, bacColor} = this.get();
    const palette = this._palette;
    
    function getColorPelette(color, num, colorType) {
        let colorList = new Array(num).fill('');
    
        if(colorType == 'HEX') {
            return colorList.map((element, index) => {
                return palette(color, index - 4);
            })
        }
    
        return colorList.map((element, index) => {
            return hexToRgb(palette(color, index - 4));
        })
    }
    
    /**
 * 将hex颜色转成rgb
 */
    function hexToRgb(hex) {
        const RGBA = "rgb(" + parseInt("0x" + hex.slice(1, 3)) + "," + 
            parseInt("0x" + hex.slice(3, 5)) + "," + parseInt( "0x" + hex.slice(5, 7)) + ")";
    
        return {
            red: parseInt("0x" + hex.slice(1, 3)),
            green: parseInt("0x" + hex.slice(3, 5)),
            blue: parseInt("0x" + hex.slice(5, 7)),
            rgb: RGBA
        };
    }
    
    function contrast(preColor, bacColor) {
        function computeRGB(R, G, B) {
            const List = [...arguments];
            return List.map((element, index) => {
                const XsRGB = element / 255;
                if(XsRGB <= .03928) {
                    return XsRGB / 12.92;
                } 
                return ((XsRGB + .055) / 1.055) ** 2.4;
            })
        }
        if(typeof(preColor) == 'string') {
            preColor = hexToRgb(preColor);
            bacColor = hexToRgb(bacColor);
        }
    
        const preRGB = computeRGB(preColor['red'], preColor['green'], preColor['blue']);
        const bacRGB = computeRGB(bacColor['red'], bacColor['green'], bacColor['blue']);
        const preLight = 0.2126 * preRGB[0] + 0.7152 * preRGB[1] + .0722 * preRGB[2];
        const bacLight = 0.2126 * bacRGB[0] + 0.7152 * bacRGB[1] + .0722 * bacRGB[2];
        return ((bacLight + 0.05) / (preLight + 0.05)).toFixed(2);
    }
    
    const colorPelette = {};
    for(let i in color) {
        colorPelette[i] = getColorPelette(color[i], 9, colorType);
    }
    
    return _$cc(Layout, {
        'className': _$cn(makeStyles(theme)),
        'children': [
            _$cc(Header, {
                'height': 70,
                'children': [
                    _$ce(2, 'div', (
                        _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                            'src': KD_logo
                        })
                    ), 2 /* HasVNodeChildren */, 'header-logo'),
                    _$ce(2, 'div', '颜色定制', 16 /* HasTextChildren */, 'header-title'),
                    _$ce(2, 'div', [
                        _$ce(2, 'div', [
                            _$ce(2, 'a', '色彩对比度', 16 /* HasTextChildren */, null, {
                                'ev-click': this._changeContrastShow.bind(this, true)
                            }),
                            _$cc(Dialog, {
                                'title': '色彩对比度',
                                'className': _$cn(DiaStyles()),
                                'showClose': true,
                                'children': [
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', '云服务器', 16 /* HasTextChildren */),
                                        _$ce(2, 'div', '\n                            云服务器KEC（Kingsoft Cloud Elastic Compute）是云计算基础\n                            设施的重要组成部分，提供弹性可伸缩的计算容量，令开发者能够轻松进\n                            行互联网规模计算，部署所需服务器环境。云服务器相对物理服务器更为\n                            简单高效，可随时根据业务需要进行调配，提升运维效率。\n                        ', 16 /* HasTextChildren */)
                                    ], 4 /* HasNonKeyedChildren */, 'content-left', {
                                        'style': {backgroundColor: bacColor, color: preColor}
                                    }),
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', '对比度', 16 /* HasTextChildren */, 'title'),
                                            _$ce(2, 'div', [
                                                _$ccv('TODO 对比度数值 '),
                                                _$ce(2, 'div', contrast(preColor, bacColor), 0 /* UnknownChildren */),
                                                _$ce(2, 'div', ': 1', 16 /* HasTextChildren */)
                                            ], 4 /* HasNonKeyedChildren */, 'contrast-number'),
                                            _$ce(2, 'div', (
                                                _$ce(2, 'div', '正常文本', 16 /* HasTextChildren */)
                                            ), 2 /* HasVNodeChildren */),
                                            _$ce(2, 'div', (
                                                _$ce(2, 'div', '大文本', 16 /* HasTextChildren */)
                                            ), 2 /* HasVNodeChildren */)
                                        ], 4 /* HasNonKeyedChildren */, 'contrast-box'),
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', '前景色', 16 /* HasTextChildren */),
                                                _$ce(2, 'div', (
                                                    _$cc(Colorpicker, {
                                                        'ev-$model:value': function($v) {
                                                            $this.set('preColor', $v);
                                                        },
                                                        'value': $this.get('preColor')
                                                    })
                                                ), 2 /* HasVNodeChildren */)
                                            ], 4 /* HasNonKeyedChildren */, 'pre'),
                                            _$ce(2, 'div', (
                                                _$ce(2, 'button', '切换', 16 /* HasTextChildren */, null, {
                                                    'ev-click': this._switchColor.bind(this)
                                                })
                                            ), 2 /* HasVNodeChildren */),
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', '背景色', 16 /* HasTextChildren */),
                                                _$ce(2, 'div', (
                                                    _$cc(Colorpicker, {
                                                        'ev-$model:value': function($v) {
                                                            $this.set('bacColor', $v);
                                                        },
                                                        'value': $this.get('bacColor')
                                                    })
                                                ), 2 /* HasVNodeChildren */)
                                            ], 4 /* HasNonKeyedChildren */, 'bac')
                                        ], 4 /* HasNonKeyedChildren */, 'choose-box')
                                    ], 4 /* HasNonKeyedChildren */, 'content-right')
                                ],
                                'ev-$model:value': function($v) {
                                    $this.set('contrastMenu', $v);
                                },
                                'value': $this.get('contrastMenu')
                            })
                        ], 4 /* HasNonKeyedChildren */, 'contrast'),
                        _$ccv('TODO:主题定制以及颜色转换'),
                        _$ce(2, 'div', [
                            _$ce(2, 'button', '明', 16 /* HasTextChildren */, _$cn({"checkout": theme == 'light'}), {
                                'ev-click': this._changeTheme.bind(this, 'light')
                            }),
                            _$ce(2, 'button', '暗', 16 /* HasTextChildren */, _$cn({"checkout": theme == 'dark'}), {
                                'ev-click': this._changeTheme.bind(this, 'dark')
                            })
                        ], 4 /* HasNonKeyedChildren */, 'theme'),
                        _$ce(2, 'div', [
                            _$ce(2, 'button', 'HEX', 16 /* HasTextChildren */, _$cn({"checkout": colorType == 'HEX'}), {
                                'ev-click': this._changeColorType.bind(this, 'HEX')
                            }),
                            _$ce(2, 'button', 'RGB', 16 /* HasTextChildren */, _$cn({"checkout":colorType == 'RGB'}), {
                                'ev-click': this._changeColorType.bind(this, 'RGB')
                            })
                        ], 4 /* HasNonKeyedChildren */, 'color-type')
                    ], 4 /* HasNonKeyedChildren */, 'header-options')
                ]
            }),
            _$cc(Layout, {
                'collapse': true,
                'className': 'cp-body',
                'children': [
                    _$cc(Card, {
                        'children': [
                            _$ce(2, 'div', (
                                _$ce(2, 'div', '自定义主题色', 16 /* HasTextChildren */)
                            ), 2 /* HasVNodeChildren */, 'card-title'),
                            (colorType == 'HEX') ?
                                _$ce(2, 'div', (
                                    _$cc(Input, {
                                        'ev-change': this._changeColor.bind(this),
                                        'defaultValue': inputColor,
                                        'placeholder': '请输入正确的颜色值'
                                    })
                                ), 2 /* HasVNodeChildren */, 'Color-HEX input') :
                                _$ce(2, 'div', [
                                    _$ce(2, 'strong', '选择颜色：', 16 /* HasTextChildren */),
                                    _$cc(Colorpicker, {
                                        'ev-$model:value': function($v) {
                                            $this.set('inputColor', $v);
                                        },
                                        'value': $this.get('inputColor')
                                    }),
                                    _$ce(2, 'div', hexToRgb(inputColor)['rgb'], 0 /* UnknownChildren */)
                                ], 4 /* HasNonKeyedChildren */, 'Color-RGB input'),
                            _$ce(2, 'div', (
                                _$ma(getColorPelette(inputColor, 9, colorType), function($value, $key) {
                                    return _$ce(2, 'div', colorType == 'HEX' 
                                        ? $value
                                        : $value['rgb'], 0 /* UnknownChildren */, 'color-pelette-item', {
                                        'style': {
                                            backgroundColor: colorType == 'HEX' 
                                                ? $value 
                                                : $value['rgb']
                                        }
                                    });
                                }, $this)
                            ), 4 /* HasNonKeyedChildren */, 'color-pelette')
                        ]
                    }),
                    _$cc(Card, {
                        'children': [
                            _$ce(2, 'div', (
                                _$ce(2, 'div', '推荐色板', 16 /* HasTextChildren */)
                            ), 2 /* HasVNodeChildren */, 'card-title'),
                            _$ma(colorPelette, function($value, $key) {
                                return _$ce(2, 'div', (
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', $key, 0 /* UnknownChildren */, 'recomend-item-title'),
                                        _$ce(2, 'div', (
                                            _$ma($value, function($value, $key) {
                                                return _$ce(2, 'div', colorType == 'HEX' 
                                                    ? $value 
                                                    : $value['rgb'], 0 /* UnknownChildren */, 'color-pelette-item', {
                                                    'style': {
                                                        backgroundColor: colorType == 'HEX' 
                                                            ? $value 
                                                            : $value['rgb']
                                                    }
                                                });
                                            }, $this)
                                        ), 4 /* HasNonKeyedChildren */, 'color-pelette')
                                    ], 4 /* HasNonKeyedChildren */, 'recomend-item')
                                ), 2 /* HasVNodeChildren */, 'recomend');
                            }, $this)
                        ]
                    })
                ]
            })
        ]
    });
};