import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tree} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Tree, {
        'data': this.get('data'),
        'checkbox': true,
        'uncorrelated': true,
        'ev-$model:checkedKeys': function($v) {
            $this.set('checkedKeys', $v);
        },
        'checkedKeys': $this.get('checkedKeys')
    });
};