import {
    createElementVNode as _$ce,
    map as _$ma,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Divider} from 'kpc';

var _$tmp0 = {
    'margin': 30
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ma(this.get('margin'), function($value, $key) {
            return _$ce(2, 'div', [
                _$ct('\n        margin '),
                $value,
                _$cc(Divider, {
                    'margin': $value
                })
            ], 0 /* UnknownChildren */);
        }, $this),
        _$ct('\n    自定义margin\n    '),
        _$cc(Divider, _$tmp0),
        _$ct('\n    自定义margin\n    '),
        _$cc(Divider, {
            'style': {margin: '8px 0 0 0'}
        })
    ], 0 /* UnknownChildren */);
};