import {
    superCall as _$su,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';
import {DropdownMenu} from '../dropdown';
import {Tree} from '../tree';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        data, uncorrelated, load, showLine,
        defaultExpandAll, checkbox, multiple,
        filterable,
    } = this.get();
    
    const {
        selectedKeys, checkedKeys, treeRef, 
        onChangeCheckedKeys, onChangeSelectedKeys,
    } = this.value;
    
    const { k } = this.config;
    
    return _$su.call($this, null, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['base-menu'] = function($super) {
                    return (
                        _$cc(DropdownMenu, {
                            'className': _$cn({
                                [`${k}-tree-select-menu`]: true,
                                [makeStyles(k)]: true,
                            }),
                            'children': (
                                _$cc(Tree, {
                                    'ref': treeRef,
                                    'data': data,
                                    'selectedKeys': selectedKeys.value,
                                    'ev-$change:selectedKeys': onChangeSelectedKeys,
                                    'checkedKeys': checkedKeys.value,
                                    'ev-$change:checkedKeys': onChangeCheckedKeys,
                                    'defaultExpandAll': defaultExpandAll,
                                    'uncorrelated': uncorrelated,
                                    'checkbox': checkbox,
                                    'multiple': multiple,
                                    'selectable': !checkbox,
                                    'load': load,
                                    'showLine': showLine,
                                    'filter': filterable ? this.filter : null,
                                    'ev-transitionEnd': this.position
                                }, null, treeRef)
                            )
                        })
                    );
                }),
                (__$blocks['base-menu'] = function($super, data) {
                    var block = $blocks['base-menu'];
                    var callBlock = function() {
                        return _$blocks['base-menu'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};