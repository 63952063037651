import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {addStyle, getRestProps} from '../utils';
import {Icon} from '../icon';
import {makeButtonStyles} from './styles';
import {DynamicButton} from './dynamicButton';
import {Wave} from '../wave';
import {button as theme} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    let {
        type, className, size, icon, circle, ref, key, tabindex,
        tagName, htmlType, fluid, children, loading, disabled,
        name, ghost, color, style
    } = this.get();
    
    const { cls, k } = this.config;
    
    const checked = this.isChecked();
    
    const isIcon = child => (
        child.tag === Icon ||
        child.className && child.className.indexOf('icon') > -1
    );
    
    let iconSide;
    // hack for loading transition of width
    if (!icon && Array.isArray(children)) {
        children.forEach((child, index) => {
            if (child) {
                // whether the icon is on the left side or right
                if (index === 0) {
                    if (isIcon(child)) {
                        iconSide = 'left';
                    }
                } else if (index === children.length - 1) {
                    if (isIcon(child)) {
                        iconSide = 'right';
                    }
                }
            }
        });
    }
    
    const classNameObj = {
        [cls('btn')]: true,
        [cls(size)]: size !== 'default',
        [cls(type)]: !color,
        [cls(`btn-icon`)]: icon,
        [className]: className, 
        [cls('circle')]: circle,
        [cls('loading')]: loading,
        [cls('fluid')]: fluid,
        [cls('active')]: checked,
        [cls('custom')]: color,
        [cls('disabled')]: disabled || loading,
        [cls('ghost')]: ghost,
        [makeButtonStyles(k, iconSide, color)]: true,
    };
    
    const loadingIcon = (
        _$cc(Icon, {
            'className': _$cn(`ion-load-c ${k}-icon-loading`),
            'size': size /*loadingSizeMap[size]*/,
            'key': 'k-loading',
            'rotate': true
        }, 'k-loading')
    );
    
    const buttonGroup = this.buttonGroup;
    const checkType = buttonGroup?.get('checkType') ?? 'none';
    const btnWidth = buttonGroup?.get('btnWidth');
    const _style = {
        width: typeof btnWidth === 'number' ? `${btnWidth}px` : btnWidth,
    }
    const isCheckType = checkType !== 'none';
    const typeStyles = theme[type];
    const waveColor = typeStyles && typeStyles.borderColor || color || theme.primary.borderColor;
    return _$cc(Wave, {
        'disabled': loading || disabled || type === 'none' || type === 'link',
        'inset': type === 'flat' ? '-1px' : '-2px',
        'color': waveColor,
        'children': (
            _$cc(DynamicButton, {
                'className': _$cn(classNameObj),
                'tagName': tagName,
                ...getRestProps(this),
                'style': btnWidth ? addStyle(style, _style) : style,
                'ref': this.elementRef,
                'tabindex': disabled || loading ? '-1' : tabindex,
                'ev-click': this.onClick,
                'ev-mouseup': this.onMouseUp,
                'htmlType': htmlType,
                'name': isCheckType ? null : name,
                'children': [
                    loading ? 
                        iconSide === 'right' ?
                            [children, loadingIcon] :
                            [loadingIcon, children] :
                        children,
                    (isCheckType) ?
                        _$ce(64, 'input', null, 1 /* HasInvalidChildren */, _$cn(`${k}-button-input`), {
                            'type': checkType,
                            'name': name,
                            'checked': checked,
                            'tabindex': '-1'
                        }) :
                        undefined
                ]
            }, null, this.elementRef)
        )
    });
};