import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Select, Table, TableColumn, Input, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-search'
};
var _$tmp1 = {
    'title': 'Name',
    'key': 'name'
};
var _$tmp2 = {
    'title': 'Domain',
    'key': 'domain'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_select = function(i) {_$refs['select'] = i};
    var _$ref_table = function(i) {_$refs['table'] = i};
    
    return _$cc(Select, {
        'value': this.text(),
        'className': 'custom-select',
        'ref': _$ref_select,
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['menu'] = function($super) {
                        return [
                            _$cc(Input, {
                                'placeholder': '请输入关键字',
                                'size': 'small',
                                'fluid': true,
                                'waveDisabled': true,
                                '$blocks': function($blocks) {
                                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                    return (
                                        (
                                            (_$blocks['suffix'] = function($super) {
                                                return (
                                                    _$cc(Icon, _$tmp0)
                                                );
                                            }),
                                            (__$blocks['suffix'] = function($super, data) {
                                                var block = $blocks['suffix'];
                                                var callBlock = function() {
                                                    return _$blocks['suffix'].call($this, $super, data);
                                                };
                                                return block ?
                                                    block.call($this, callBlock, data) :
                                                    callBlock();
                                            })
                                        ),
                                        __$blocks
                                    );
                                }.call($this, _$em),
                                'ev-$model:value': function($v) {
                                    $this.set('keywords', $v);
                                },
                                'value': $this.get('keywords')
                            }),
                            _$cc(Table, {
                                'data': this.filter(),
                                'type': 'border',
                                'fixHeader': '200',
                                'ref': _$ref_table,
                                'rowKey': i => i.name,
                                'checkedKeys': this.checkedKeys(),
                                'children': [
                                    _$cc(TableColumn, _$tmp1, 'name'),
                                    _$cc(TableColumn, _$tmp2, 'domain')
                                ]
                            }, null, _$ref_table),
                            _$ce(2, 'div', [
                                _$cc(Button, {
                                    'size': 'small',
                                    'ev-click': this.hide,
                                    'children': '取消'
                                }),
                                _$cc(Button, {
                                    'type': 'primary',
                                    'size': 'small',
                                    'ev-click': this.confirm,
                                    'children': '确定'
                                })
                            ], 4 /* HasNonKeyedChildren */, 'footer')
                        ];
                    }),
                    (__$blocks['menu'] = function($super, data) {
                        var block = $blocks['menu'];
                        var callBlock = function() {
                            return _$blocks['menu'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    }, null, _$ref_select);
};