import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tree} from 'kpc';
import {Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_tree = function(i) {_$refs['tree'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.getSelectedData,
            'children': 'get selected data'
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tree, {
            'data': this.get('data'),
            'ref': _$ref_tree,
            'multiple': true,
            'ev-$model:selectedKeys': function($v) {
                $this.set('selectedKeys', $v);
            },
            'selectedKeys': $this.get('selectedKeys'),
            'ev-$model:expandedKeys': function($v) {
                $this.set('expandedKeys', $v);
            },
            'expandedKeys': $this.get('expandedKeys')
        }, null, _$ref_tree)
    ], 4 /* HasNonKeyedChildren */);
};