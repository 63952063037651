export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    money?: number
    percent?: number
}

export default class extends Component<Props>  {
    static template = template;

    static defaults() {
        return {
            money: 1000,
            percent: 78,
        };
    };

    formater(value: number) {
        return ('￥' + value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    parser(value: string) {
        return +value.replace(/￥|,/g, '')
    }
}