import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Descriptions, DescriptionItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Descriptions, {
            'title': '基础信息',
            'items': this.get('items'),
            'columns': 4
        }),
        _$cc(Descriptions, {
            'title': '基础信息',
            'children': [
                _$cc(DescriptionItem, {
                    'label': '测试1',
                    'children': '测试1'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试2',
                    'children': '测试超长测试超长测试超长测试超长测试超长测试超长测试超长测试超长测试超长测试超长'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试3',
                    'children': '测试3'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试4',
                    'children': '测试4'
                }),
                _$cc(DescriptionItem, {
                    'label': '测试5',
                    'children': '测试5'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};