import {
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {addStyle, getRestProps} from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, className, width, style, center} = this.get();
    const { k } = this.config;
    
    let _style = style;
    if (width) {
        _style = addStyle(style, {width})
    }
    
    const classNameObj = {
        [`${k}-card-column`]: true,
        [`${k}-fluid`]: !width,
        [`${k}-center`]: center,
        [className]: className,
    }
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        'style': _style,
        ...getRestProps(this)
    }, children);
};