import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Form, FormItem, Input, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Form, {
        'children': [
            _$cc(FormItem, {
                'label': '描述',
                'children': (
                    _$ma(this.get('descriptions'), function($value, $key) {
                        return _$cc(FormItem, {
                            'value': $value,
                            'hideLabel': true,
                            'rules': {
                                required: true, 
                                // 自定义全局规则
                                letter: true,
                                // 自定义局部规则: 所有描述必须不重复
                                unique: this.unique 
                            },
                            'children': (
                                _$cc(Input, {
                                    'ev-$model:value': function($v) {
                                        $this.set(`descriptions[${$key}]`, $v);
                                    },
                                    'value': $this.get(`descriptions[${$key}]`)
                                })
                            ),
                            '$blocks': function($blocks) {
                                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                return (
                                    (
                                        (_$blocks['append'] = function($super) {
                                            return (
                                                _$cc(Button, {
                                                    'ev-click': this.remove.bind(self, $key),
                                                    'children': '删除'
                                                })
                                            );
                                        }),
                                        (__$blocks['append'] = function($super, data) {
                                            var block = $blocks['append'];
                                            var callBlock = function() {
                                                return _$blocks['append'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        })
                                    ),
                                    __$blocks
                                );
                            }.call($this, _$em)
                        });
                    }, $this)
                )
            }),
            _$cc(FormItem, {
                'children': (
                    _$cc(Button, {
                        'ev-click': this.add,
                        'children': '添加'
                    })
                )
            })
        ]
    });
};