import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '通过minColWidth控制最小宽度100px',
    'width': '50%'
};
var _$tmp1 = {
    'key': 'none',
    'title': '隐藏列',
    'className': 'hidden'
};
var _$tmp2 = {
    'key': 'b',
    'title': '单独设置最小宽度300px',
    'width': '10%',
    'minWidth': 300
};
var _$tmp3 = {
    'key': 'c',
    'title': '标题',
    'width': '40%'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {data} = this.get();
    
    return _$cc(Table, {
        'data': data,
        'resizable': true,
        'minColWidth': 100,
        'type': 'grid',
        'widthStoreKey': 'resizableTable',
        'children': [
            _$cc(TableColumn, _$tmp0, 'a'),
            _$cc(TableColumn, _$tmp1, 'none'),
            _$cc(TableColumn, _$tmp2, 'b'),
            _$cc(TableColumn, _$tmp3, 'c')
        ]
    });
};