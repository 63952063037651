import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';
import {Card} from '../../../components/card';
import {
    Button, Icon, Select, Option, 
    Switch, Input, Tabs, Tab, Slider,
    Menu, MenuItem, Rate, ButtonGroup,
    Spinner, Tree
} from 'kpc';
import {BezierBox} from '../../../components/BezierBox'
import king_version_logo from '../../../imgs/king_version_logo.png';
import sketch_img from '../../../imgs/sketch_img.png';
import axure_img from '../../../imgs/axure_img.png';
import rectangle from '../../../imgs/rectangle.png';
import design from '../../../imgs/design_img.png';
import menu_header from '../../../imgs/header_logo.png';
import {getAxure, getSketch, getFigma, getDesign} from '../../resource/index';

var _$tmp0 = {
    'target': '_blank',
    'href': 'http://vision.ksyun.com/'
};
var _$tmp1 = {
    'showInput': false
};
var _$tmp2 = {
    'className': 'icon ion-chevron-left'
};
var _$tmp3 = {
    'className': 'icon ion-chevron-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {cardLeft, totalCards, curCardNum, cardsPerPage, 
        animationType, bezierValue, curTabIndex, textTop, 
        transition, curComponentsGroup, curTreeValue, 
        selectedMenu, themeColor, documentWidth} = this.get();
    
    const canNext = totalCards > curCardNum;
    const canPrev = curCardNum > cardsPerPage;
    
    const componentsSubTitle = `高复用组件，快速构建你的网站${documentWidth > 768 ? '和用户界面' : ''}`;
    
    const textTransitionStyle = {
        transition: transition,
        transform: `translateY(${textTop}px)`
    };
    
    const buttonTransitionStyle = {
        transition: transition
    }
    
    const treeData = [
        {
            label: 'UED部门',
            children: [
                {
                    label: 'UI设计小组',
                    children: [
                        {
                            label: '设计师小绿'
                        },
                        {
                            label: '设计师小蓝',
                            key: '设计师小蓝'
                        },
                    ]
                }
            ]
        }
    ];
    
    return _$ce(2, 'div', (
        _$ce(2, 'div', [
            _$ce(2, 'div', [
                _$ce(2, 'div', '全新功能，全新体验', 16 /* HasTextChildren */),
                _$ce(2, 'div', '多样的设计资源助力团队提速', 16 /* HasTextChildren */)
            ], 4 /* HasNonKeyedChildren */, 'title-box'),
            _$ce(2, 'div', [
                _$ce(2, 'div', (
                    _$ce(2, 'div', [
                        _$cc(Card, {
                            'mainTitle': '资源',
                            'subTitle': '生态设计工具与资源',
                            'href': '/resources',
                            'children': (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', '组件下载', 16 /* HasTextChildren */),
                                        _$ce(2, 'div', [
                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                'src': axure_img,
                                                'ev-click': getAxure
                                            }),
                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                'src': sketch_img,
                                                'ev-click': getSketch
                                            }),
                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                'src': rectangle,
                                                'ev-click': getFigma
                                            }),
                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                'src': design,
                                                'ev-click': getDesign
                                            })
                                        ], 4 /* HasNonKeyedChildren */, 'comp-download')
                                    ], 4 /* HasNonKeyedChildren */, 'white-card'),
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', '更多KD资源', 16 /* HasTextChildren */),
                                        _$ce(2, 'div', (
                                            _$ce(2, 'a', (
                                                _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                    'src': king_version_logo
                                                })
                                            ), 2 /* HasVNodeChildren */, null, _$tmp0)
                                        ), 2 /* HasVNodeChildren */, 'resource-img-box')
                                    ], 4 /* HasNonKeyedChildren */, 'white-card')
                                ], 4 /* HasNonKeyedChildren */, 'resource-box')
                            )
                        }),
                        _$cc(Card, {
                            'mainTitle': '组件',
                            'subTitle': componentsSubTitle,
                            'href': '/docs/vue/',
                            'children': (
                                _$ce(2, 'div', [
                                    _$cc(Button, {
                                        'className': 'refresh-btn',
                                        'type': 'none',
                                        'ev-click': this.changeComponentsGroup,
                                        'children': [
                                            _$cc(Icon, {
                                                'className': 'icon ion-refresh',
                                                'style': {color: themeColor}
                                            }),
                                            _$ce(2, 'span', '换一换', 16 /* HasTextChildren */, 'h5-swap-btn')
                                        ]
                                    }),
                                    (curComponentsGroup == 0) ?
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', [
                                                _$cc(Button, {
                                                    'type': 'primary',
                                                    'className': 'comp-list-btn',
                                                    'children': 'Button'
                                                }),
                                                _$cc(Switch)
                                            ], 4 /* HasNonKeyedChildren */, 'input-select'),
                                            _$ce(2, 'div', (
                                                _$cc(Input, {
                                                    'placeholder': '请输入',
                                                    '$blocks': function($blocks) {
                                                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                                        return (
                                                            (
                                                                (_$blocks['prepend'] = function($super) {
                                                                    return (
                                                                        _$cc(Select, {
                                                                            'fluid': true,
                                                                            'placeholder': '选择一项',
                                                                            'inline': true,
                                                                            'children': [
                                                                                _$cc(Option, {
                                                                                    'value': '1',
                                                                                    'children': '设计部门'
                                                                                }),
                                                                                _$cc(Option, {
                                                                                    'value': '2',
                                                                                    'children': '开发部门'
                                                                                }),
                                                                                _$cc(Option, {
                                                                                    'value': '3',
                                                                                    'children': '产品部门'
                                                                                })
                                                                            ]
                                                                        })
                                                                    );
                                                                }),
                                                                (__$blocks['prepend'] = function($super, data) {
                                                                    var block = $blocks['prepend'];
                                                                    var callBlock = function() {
                                                                        return _$blocks['prepend'].call($this, $super, data);
                                                                    };
                                                                    return block ?
                                                                        block.call($this, callBlock, data) :
                                                                        callBlock();
                                                                })
                                                            ),
                                                            __$blocks
                                                        );
                                                    }.call($this, _$em)
                                                })
                                            ), 2 /* HasVNodeChildren */, 'select-input')
                                        ], 4 /* HasNonKeyedChildren */, 'comp-content') :
                                        undefined,
                                    (curComponentsGroup == 1) ?
                                        _$ce(2, 'div', (
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', (
                                                    _$cc(Tree, {
                                                        'data': treeData,
                                                        'selectable': false,
                                                        'checkbox': true,
                                                        'defaultExpandAll': true,
                                                        'ev-$model:checkedKeys': function($v) {
                                                            $this.set('curTreeValue', $v);
                                                        },
                                                        'checkedKeys': $this.get('curTreeValue')
                                                    })
                                                ), 2 /* HasVNodeChildren */, 'tree-box'),
                                                _$ce(2, 'div', (
                                                    _$cc(Menu, {
                                                        'theme': 'light',
                                                        'children': [
                                                            _$cc(MenuItem, {
                                                                'key': '1',
                                                                'children': '首页'
                                                            }, '1'),
                                                            _$cc(Menu, {
                                                                'children': [
                                                                    _$cc(MenuItem, {
                                                                        'key': '11',
                                                                        'children': '概览'
                                                                    }, '11'),
                                                                    _$cc(MenuItem, {
                                                                        'key': '12',
                                                                        'children': '文档'
                                                                    }, '12')
                                                                ]
                                                            })
                                                        ],
                                                        '$blocks': function($blocks) {
                                                            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                                            return (
                                                                (
                                                                    (_$blocks['header'] = function($super) {
                                                                        return (
                                                                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                                                                'src': menu_header,
                                                                                'width': '80'
                                                                            })
                                                                        );
                                                                    }),
                                                                    (__$blocks['header'] = function($super, data) {
                                                                        var block = $blocks['header'];
                                                                        var callBlock = function() {
                                                                            return _$blocks['header'].call($this, $super, data);
                                                                        };
                                                                        return block ?
                                                                            block.call($this, callBlock, data) :
                                                                            callBlock();
                                                                    })
                                                                ),
                                                                __$blocks
                                                            );
                                                        }.call($this, _$em),
                                                        'ev-$model:selectedKey': function($v) {
                                                            $this.set('selectedMenu', $v);
                                                        },
                                                        'selectedKey': $this.get('selectedMenu')
                                                    })
                                                ), 2 /* HasVNodeChildren */, 'menu-box')
                                            ], 4 /* HasNonKeyedChildren */, 'menu-tree-box')
                                        ), 2 /* HasVNodeChildren */, 'comp-content') :
                                        undefined,
                                    (curComponentsGroup == 2) ?
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', (
                                                    _$cc(Slider, _$tmp1)
                                                ), 2 /* HasVNodeChildren */, 'comp-item slider-box'),
                                                _$ce(2, 'div', (
                                                    _$cc(Rate, {
                                                        'value': 2.5,
                                                        'half': true
                                                    })
                                                ), 2 /* HasVNodeChildren */, 'comp-item rate-box')
                                            ], 4 /* HasNonKeyedChildren */, 'comp-menu-box'),
                                            _$ce(2, 'div', [
                                                _$ce(2, 'div', (
                                                    _$cc(Spinner)
                                                ), 2 /* HasVNodeChildren */, 'comp-item'),
                                                _$ce(2, 'div', (
                                                    _$cc(ButtonGroup, {
                                                        'checkType': 'radio',
                                                        'value': 'beijing',
                                                        'children': [
                                                            _$cc(Button, {
                                                                'value': 'beijing',
                                                                'children': '北京'
                                                            }),
                                                            _$cc(Button, {
                                                                'value': 'shanghai',
                                                                'children': '上海'
                                                            }),
                                                            _$cc(Button, {
                                                                'value': 'guangzhou',
                                                                'children': '广州'
                                                            })
                                                        ]
                                                    })
                                                ), 2 /* HasVNodeChildren */, 'comp-item radio-box')
                                            ], 4 /* HasNonKeyedChildren */, 'slider-radio-box')
                                        ], 4 /* HasNonKeyedChildren */, 'comp-content') :
                                        undefined
                                ], 0 /* UnknownChildren */, 'component-box white-card')
                            )
                        }),
                        _$cc(Card, {
                            'mainTitle': '动效',
                            'subTitle': '重构数字体验感知',
                            'href': '/docs/design/model-1/',
                            'children': (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', (
                                        _$cc(BezierBox, {
                                            'ev-valueChange': this.handleBezierValChange,
                                            'value': bezierValue
                                        })
                                    ), 2 /* HasVNodeChildren */, 'animate-left white-card'),
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', [
                                            _$ce(2, 'div', '曲线选择：', 16 /* HasTextChildren */, 'animate-title'),
                                            _$ce(2, 'div', (
                                                _$cc(Select, {
                                                    'fluid': true,
                                                    'placeholder': '选择曲线',
                                                    'children': [
                                                        _$cc(Option, {
                                                            'value': '1',
                                                            'children': '轻柔缓动'
                                                        }),
                                                        _$cc(Option, {
                                                            'value': '2',
                                                            'children': '效率至上'
                                                        }),
                                                        _$cc(Option, {
                                                            'value': '3',
                                                            'children': '优雅主义'
                                                        })
                                                    ],
                                                    'ev-$model:value': function($v) {
                                                        $this.set('animationType', $v);
                                                    },
                                                    'value': $this.get('animationType')
                                                })
                                            ), 2 /* HasVNodeChildren */)
                                        ], 4 /* HasNonKeyedChildren */),
                                        _$ce(2, 'div', [
                                            _$cc(Tabs, {
                                                'children': [
                                                    _$cc(Tab, {
                                                        'value': '1',
                                                        'children': 'Tab1'
                                                    }),
                                                    _$cc(Tab, {
                                                        'value': '2',
                                                        'children': 'Tab2'
                                                    })
                                                ],
                                                'ev-$model:value': function($v) {
                                                    $this.set('curTabIndex', $v);
                                                },
                                                'value': $this.get('curTabIndex')
                                            }),
                                            _$ce(2, 'div', [
                                                (curTabIndex == 1) ?
                                                    _$ce(2, 'div', [
                                                        _$cc(Button, {
                                                            'type': 'primary',
                                                            'style': buttonTransitionStyle,
                                                            'children': 'Button'
                                                        }),
                                                        _$cc(Button, {
                                                            'type': 'secondary',
                                                            'style': buttonTransitionStyle,
                                                            'children': 'Button'
                                                        })
                                                    ], 4 /* HasNonKeyedChildren */) :
                                                    undefined,
                                                (curTabIndex == 2) ?
                                                    _$ce(2, 'div', (
                                                        _$ce(2, 'div', '优秀又合适的动效，总是能给产品体验带来更多可能。', 16 /* HasTextChildren */, 'tab-text', {
                                                            'style': textTransitionStyle
                                                        })
                                                    ), 2 /* HasVNodeChildren */) :
                                                    undefined
                                            ], 0 /* UnknownChildren */, 'tab-content')
                                        ], 4 /* HasNonKeyedChildren */)
                                    ], 4 /* HasNonKeyedChildren */, 'animate-right white-card')
                                ], 4 /* HasNonKeyedChildren */, 'animate-box')
                            )
                        })
                    ], 4 /* HasNonKeyedChildren */, 'card-box', {
                        'style': {left: `-${cardLeft}px`}
                    }, null, this.cardBoxRef)
                ), 2 /* HasVNodeChildren */),
                (canPrev) ?
                    _$cc(Button, {
                        'className': 'arrow-btn prev-arrow',
                        'icon': true,
                        'circle': true,
                        'size': 'large',
                        'ev-click': this.handlePrevClick,
                        'children': (
                            _$cc(Icon, _$tmp2)
                        )
                    }) :
                    undefined,
                (canNext) ?
                    _$cc(Button, {
                        'className': 'arrow-btn next-arrow',
                        'icon': true,
                        'circle': true,
                        'size': 'large',
                        'ev-click': this.handleNextClick,
                        'children': (
                            _$cc(Icon, _$tmp3)
                        )
                    }) :
                    undefined
            ], 0 /* UnknownChildren */, 'card-wrapper')
        ], 4 /* HasNonKeyedChildren */, 'new-fn-box')
    ), 2 /* HasVNodeChildren */, _$cn(makeStyles(themeColor)));
};