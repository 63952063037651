import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Transfer} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Transfer, {
            'data': this.get('data'),
            'ev-$model:leftCheckedKeys': function($v) {
                $this.set('checked', $v);
            },
            'leftCheckedKeys': $this.get('checked')
        }),
        _$ce(2, 'p', [
            _$ct('You checked left side: '),
            JSON.stringify(this.get('checked'))
        ], 0 /* UnknownChildren */)
    ], 4 /* HasNonKeyedChildren */);
};