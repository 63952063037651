import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    createTextVNode as _$ct,
} from 'intact';
import {Button, ButtonGroup, Dialog} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'children': (
                _$ma(this.get('sizes'), function($value, $key) {
                    return [
                        _$cc(Button, {
                            'ev-click': this.set.bind(this, `show${$value}`, true),
                            'children': [
                                _$ct('Show'),
                                ' ',
                                $value,
                                ' ',
                                _$ct('Dialog')
                            ]
                        }),
                        _$cc(Dialog, {
                            'title': 'Dialog Title',
                            'size': $value,
                            'children': [
                                _$ct('Size: '),
                                $value
                            ],
                            'ev-$model:value': function($v) {
                                $this.set(`show${$value}`, $v);
                            },
                            'value': $this.get(`show${$value}`)
                        })
                    ];
                }, $this)
            )
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Button, {
            'ev-click': this.set.bind(this, 'customSize', true),
            'children': 'Show 300px wide dialog'
        }),
        _$cc(Dialog, {
            'title': 'Dialog Title',
            'width': 300,
            'children': '\n        width: 300px\n    ',
            'ev-$model:value': function($v) {
                $this.set('customSize', $v);
            },
            'value': $this.get('customSize')
        })
    ], 4 /* HasNonKeyedChildren */);
};