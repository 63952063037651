import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    createTextVNode as _$ct,
} from 'intact';
import {Checkbox} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Checkbox, {
            'indeterminate': this.getLength() > 0 && this.getLength() < 3,
            'value': this.getLength() === 3,
            'ev-$change:value': this.toggleSelectAll,
            'children': '全选'
        }),
        _$ce(2, 'hr'),
        _$ma(this.get('options'), function($value, $key) {
            return _$cc(Checkbox, {
                'name': 'languages',
                'trueValue': $value,
                'children': $value,
                'ev-$model:value': function($v) {
                    $this.set('languages', $v);
                },
                'value': $this.get('languages')
            });
        }, $this),
        _$ct('\n    Your selected: '),
        JSON.stringify(this.get('languages'))
    ], 0 /* UnknownChildren */);
};