import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    map as _$ma,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {makeStyles} from './styles';
import {Input, Select, Option, Slider} from 'kpc';
import {Code} from 'kpc/components/code';
import {ThemeFrame} from '../../../components/ThemeFrame';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {codeContent, buttonRadiusMax, buttonRadiusMin,
        supportList, themeColor} = this.get();
    
    return _$ce(2, 'div', (
        _$ce(2, 'div', [
            _$ce(2, 'div', (
                _$ce(2, 'div', [
                    _$ce(2, 'div', [
                        _$ct('忠于原生增强原生'),
                        _$ce(2, 'span', '，多框架支持', 16 /* HasTextChildren */, 'web-info')
                    ], 4 /* HasNonKeyedChildren */, 'main-title'),
                    _$ce(2, 'div', '在保持浏览器原生特性的基础上增强交互能力，支持', 16 /* HasTextChildren */, 'sub-title web-info'),
                    _$ce(2, 'div', '按需加载、主题定制，国际化等特性', 16 /* HasTextChildren */, 'sub-title web-info'),
                    _$ce(2, 'div', '支持多种技术框架和灵活的主题定制', 16 /* HasTextChildren */, 'sub-title h5-info')
                ], 4 /* HasNonKeyedChildren */, 'title-box')
            ), 2 /* HasVNodeChildren */),
            _$ce(2, 'div', (
                _$ce(2, 'div', (
                    _$ma(supportList, function($value, $key) {
                        return _$ce(2, 'div', [
                            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                'src': $value.isActive ? $value.activeSrc : $value.src
                            }),
                            _$ce(2, 'div', $value.title, 0 /* UnknownChildren */)
                        ], 4 /* HasNonKeyedChildren */, _$cn($value.isActive ? 'active' : ''), {
                            'ev-click': this.handleLanguageClick.bind(this, $value)
                        });
                    }, $this)
                ), 4 /* HasNonKeyedChildren */, 'imgs-box')
            ), 2 /* HasVNodeChildren */),
            _$ce(2, 'div', [
                _$ce(2, 'div', [
                    _$ce(2, 'div', 'Code', 16 /* HasTextChildren */),
                    _$ce(2, 'div', (
                        _$cc(Code, {
                            'language': 'javascript',
                            'theme': 'vs-dark',
                            'options': {minimap: {enabled: false}},
                            'readOnly': true,
                            'ev-ready': this.handleCodeBoxReady,
                            'height': '200px',
                            'ev-$model:value': function($v) {
                                $this.set('codeContent', $v);
                            },
                            'value': $this.get('codeContent')
                        })
                    ), 2 /* HasVNodeChildren */)
                ], 4 /* HasNonKeyedChildren */, 'code-pen'),
                _$ce(2, 'div', [
                    _$ce(2, 'div', [
                        _$ma(this.rowNum, function($value, $key) {
                            return _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'row', {
                                'style': {top: `${$value.top}px`}
                            });
                        }, $this),
                        _$ma(this.columnNum, function($value, $key) {
                            return _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'column', {
                                'style': {left: `${$value.left}px`}
                            });
                        }, $this),
                        _$cc(ThemeFrame, {
                            'href': './iframe/button',
                            'ref': this.iframeBoxRef,
                            'height': '60'
                        }, null, this.iframeBoxRef)
                    ], 0 /* UnknownChildren */, 'left', null, null, this.leftBox),
                    _$ce(2, 'div', [
                        _$ce(2, 'div', [
                            _$ce(2, 'div', '内容：', 16 /* HasTextChildren */),
                            _$ce(2, 'div', (
                                _$cc(Input, {
                                    'maxlength': '20',
                                    'ev-$model:value': function($v) {
                                        $this.set('contentInput', $v);
                                    },
                                    'value': $this.get('contentInput')
                                })
                            ), 2 /* HasVNodeChildren */, 'input-box')
                        ], 4 /* HasNonKeyedChildren */, 'item'),
                        _$ce(2, 'div', [
                            _$ce(2, 'div', '图标：', 16 /* HasTextChildren */),
                            _$ce(2, 'div', (
                                _$ce(2, 'div', (
                                    _$cc(Select, {
                                        'fluid': true,
                                        'children': [
                                            _$cc(Option, {
                                                'value': 'left',
                                                'children': 'Icon左置'
                                            }),
                                            _$cc(Option, {
                                                'value': 'right',
                                                'children': 'Icon右置'
                                            })
                                        ],
                                        'ev-$model:value': function($v) {
                                            $this.set('iconPosition', $v);
                                        },
                                        'value': $this.get('iconPosition')
                                    })
                                ), 2 /* HasVNodeChildren */)
                            ), 2 /* HasVNodeChildren */)
                        ], 4 /* HasNonKeyedChildren */, 'item'),
                        _$ce(2, 'div', [
                            _$ce(2, 'div', '圆角：', 16 /* HasTextChildren */),
                            _$ce(2, 'div', (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', buttonRadiusMin, 0 /* UnknownChildren */, 'radius-value'),
                                    _$cc(Slider, {
                                        'min': buttonRadiusMin,
                                        'max': buttonRadiusMax,
                                        'showInput': false,
                                        'showEnd': false,
                                        'ev-$model:value': function($v) {
                                            $this.set('buttonRadius', $v);
                                        },
                                        'value': $this.get('buttonRadius')
                                    }),
                                    _$ce(2, 'div', buttonRadiusMax, 0 /* UnknownChildren */, 'radius-value')
                                ], 4 /* HasNonKeyedChildren */, 'radius-box')
                            ), 2 /* HasVNodeChildren */)
                        ], 4 /* HasNonKeyedChildren */, 'item'),
                        _$ce(2, 'div', [
                            _$ce(2, 'div', '状态：', 16 /* HasTextChildren */),
                            _$ce(2, 'div', (
                                _$ce(2, 'div', (
                                    _$cc(Select, {
                                        'fluid': true,
                                        'children': [
                                            _$cc(Option, {
                                                'value': 'primary',
                                                'children': '主按钮'
                                            }),
                                            _$cc(Option, {
                                                'value': 'secondary',
                                                'children': '次按钮'
                                            })
                                        ],
                                        'ev-$model:value': function($v) {
                                            $this.set('buttonStatus', $v);
                                        },
                                        'value': $this.get('buttonStatus')
                                    })
                                ), 2 /* HasVNodeChildren */)
                            ), 2 /* HasVNodeChildren */)
                        ], 4 /* HasNonKeyedChildren */, 'item')
                    ], 4 /* HasNonKeyedChildren */, 'right')
                ], 4 /* HasNonKeyedChildren */, 'optional-box')
            ], 4 /* HasNonKeyedChildren */)
        ], 4 /* HasNonKeyedChildren */, 'best-box')
    ), 2 /* HasVNodeChildren */, _$cn(makeStyles(themeColor)));
};