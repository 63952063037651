export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {SliderMarks} from 'kpc';

interface Props {
    value1?: number
    values?: [number, number]
    value2?: number
    value3?: number
    marks: SliderMarks 
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            value1: 277,
            values: [50, 76],
            value2: 6,
            value3: 300,
            marks: {
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
                6: '6月',
                7: '7',
                8: '8',
                9: '9',
                10: '1年',
                11: '2年',
                12: '3年',
            }
        } as Props;
    }
}