import {
    createElementVNode as _$ce,
    className as _$cn,
} from 'intact';

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const { k } = this.config;
    
    return _$ce(2, 'div', this.get('children'), 0 /* UnknownChildren */, _$cn(`${k}-colorpicker-drag ${k}-colorpicker-text`), {
        'ev-mousedown': this.drag.start
    });
};