import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    className as _$cn,
    noop as _$no,
} from 'intact';
import {Button} from '../button';
import {Icon} from '../icon';
import {Portal} from '../portal';
import {_$} from '../../i18n';
import {Transition} from 'intact';
import {getRestProps, stopPropagation} from '../utils';
import {makeDialogStyles, makeWrapperStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        size, container, hideClose,
        overlay, value, width,
        mode, className, cancelText,
        okText, loading, disabledOk,
        title, children,
    } = this.get();
    
    const isHide = mode === 'hide';
    const { k } = this.config;
    const classNameObj = {
        [`${k}-dialog`]: true, 
        [`${k}-dragging`]: this.drag.dragging.value,
        [className]: className,
        [$props.className]: $props.className,
        [`${k}-${size}`]: size !== 'default',
        [makeDialogStyles(k)]: true,
    };
    const dialog = (
        _$cc(Transition, {
            'show': value,
            'appear': value,
            'key': 'dialog',
            'onAfterLeave': this.onAfterLeave,
            'children': (
                (isHide ? true : value) ?
                    _$ce(2, 'div', [
                        null,
                        (
                            (_$blocks['_content'] = function($super) {
                                return (
                                    (
                                        (_$blocks['content'] = function($super) {
                                            return [
                                                _$ce(2, 'div', [
                                                    (
                                                        (_$blocks['header'] = function($super) {
                                                            return (
                                                                _$ce(2, 'span', title, 0 /* UnknownChildren */, _$cn(`${k}-dialog-title`))
                                                            );
                                                        }),
                                                        (__$blocks['header'] = function($super, data) {
                                                            var block = $blocks['header'];
                                                            var callBlock = function() {
                                                                return _$blocks['header'].call($this, $super, data);
                                                            };
                                                            return block ?
                                                                block.call($this, callBlock, data) :
                                                                callBlock();
                                                        }),
                                                        __$blocks['header'](_$no)
                                                    ),
                                                    (!hideClose && !$props.hideClose) ?
                                                        _$cc(Button, {
                                                            'type': 'none',
                                                            'icon': true,
                                                            'title': _$("关闭"),
                                                            'className': _$cn(`${k}-dialog-close`),
                                                            'ev-click': this.terminate,
                                                            'ev-mousedown': stopPropagation,
                                                            'children': (
                                                                _$cc(Icon, {
                                                                    'className': _$cn(`${k}-icon-close`)
                                                                })
                                                            )
                                                        }) :
                                                        undefined
                                                ], 0 /* UnknownChildren */, _$cn(`${k}-dialog-header`), {
                                                    'ev-mousedown': this.drag.start
                                                }),
                                                _$ce(2, 'div', (
                                                    (
                                                        (_$blocks['body'] = function($super) {
                                                            return children;
                                                        }),
                                                        (__$blocks['body'] = function($super, data) {
                                                            var block = $blocks['body'];
                                                            var callBlock = function() {
                                                                return _$blocks['body'].call($this, $super, data);
                                                            };
                                                            return block ?
                                                                block.call($this, callBlock, data) :
                                                                callBlock();
                                                        }),
                                                        __$blocks['body'](_$no)
                                                    )
                                                ), 0 /* UnknownChildren */, _$cn(`${k}-dialog-body`)),
                                                (
                                                    (_$blocks['footer-wrapper'] = function($super) {
                                                        return (
                                                            _$ce(2, 'div', (
                                                                (
                                                                    (_$blocks['footer'] = function($super) {
                                                                        return [
                                                                            _$cc(Button, {
                                                                                'className': _$cn(`${k}-dialog-cancel`),
                                                                                'ev-click': this.cancel,
                                                                                'children': cancelText
                                                                            }),
                                                                            _$cc(Button, {
                                                                                'className': _$cn(`${k}-dialog-ok`),
                                                                                'type': 'primary',
                                                                                'ev-click': this.ok,
                                                                                'loading': loading,
                                                                                'disabled': disabledOk,
                                                                                'children': okText
                                                                            })
                                                                        ];
                                                                    }),
                                                                    (__$blocks['footer'] = function($super, data) {
                                                                        var block = $blocks['footer'];
                                                                        var callBlock = function() {
                                                                            return _$blocks['footer'].call($this, $super, data);
                                                                        };
                                                                        return block ?
                                                                            block.call($this, callBlock, data) :
                                                                            callBlock();
                                                                    }),
                                                                    __$blocks['footer'](_$no)
                                                                )
                                                            ), 0 /* UnknownChildren */, _$cn(`${k}-dialog-footer`))
                                                        );
                                                    }),
                                                    (__$blocks['footer-wrapper'] = function($super, data) {
                                                        var block = $blocks['footer-wrapper'];
                                                        var callBlock = function() {
                                                            return _$blocks['footer-wrapper'].call($this, $super, data);
                                                        };
                                                        return block ?
                                                            block.call($this, callBlock, data) :
                                                            callBlock();
                                                    }),
                                                    __$blocks['footer-wrapper'](_$no)
                                                )
                                            ];
                                        }),
                                        (__$blocks['content'] = function($super, data) {
                                            var block = $blocks['content'];
                                            var callBlock = function() {
                                                return _$blocks['content'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        }),
                                        __$blocks['content'](_$no)
                                    )
                                );
                            }),
                            (__$blocks['_content'] = function($super, data) {
                                var block = $blocks['_content'];
                                var callBlock = function() {
                                    return _$blocks['_content'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            }),
                            __$blocks['_content'](_$no)
                        )
                    ], 0 /* UnknownChildren */, _$cn(classNameObj), {
                        'style': {width: typeof width === 'number' ? `${width}px` : width},
                        'ev-mousedown': this.mouseOutsidable.onMouseDown
                    }, null, this.dialogRef) :
                    undefined
            )
        }, 'dialog')
    );
    
    return _$cc(Portal, {
        'container': container,
        'children': (
            _$ce(2, 'div', (
                (overlay) ?
                    _$cc(Transition, {
                        'key': 'overlay',
                        'show': overlay ? value : false,
                        'appear': value,
                        'name': 'k-fade',
                        'children': (
                            _$ce(2, 'div', dialog, 0 /* UnknownChildren */, _$cn(`${k}-dialog-overlay`), {
                                'ev-click': this.onClickWrapper
                            }, null, this.overlayRef)
                        )
                    }, 'overlay') :
                    dialog
            ), 0 /* UnknownChildren */, _$cn({
                [`${k}-dialog-wrapper`]: true,
                [`${k}-open`]: value,
                [makeWrapperStyles(k)]: true,
            }))
        )
    });
};