import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Menu, MenuItem, MenuTitle, Switch, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-flag'
};
var _$tmp1 = {
    'className': 'ion-star'
};
var _$tmp2 = {
    'className': 'ion-heart'
};
var _$tmp3 = {
    'className': 'ion-gear-b'
};
var _$tmp4 = {
    'className': 'ion-cloud'
};
var _$tmp5 = {
    'className': 'ion-stats-bars'
};
var _$tmp6 = {
    'className': 'ion-upload'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Switch, {
            'on': '收起',
            'off': '展开',
            'width': '60',
            'style': 'margin-right: 16px;',
            'ev-$model:value': function($v) {
                $this.set('collapse', $v);
            },
            'value': $this.get('collapse')
        }),
        _$cc(Switch, {
            'value': this.get('theme'),
            'ev-$change:value': this.setTheme,
            'on': 'light',
            'off': 'dark',
            'width': '60',
            'trueValue': 'light',
            'falseValue': 'dark'
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Menu, {
            'collapse': this.get('collapse'),
            'theme': this.get('theme'),
            'ref': _$ref___test,
            'children': [
                _$cc(MenuItem, {
                    'key': '1',
                    'children': [
                        _$cc(Icon, _$tmp0),
                        _$ct('menu 1')
                    ]
                }, '1'),
                _$cc(MenuItem, {
                    'key': '2',
                    'disabled': true,
                    'children': [
                        _$cc(Icon, _$tmp1),
                        _$ct('menu 2')
                    ]
                }, '2'),
                _$cc(MenuItem, {
                    'key': '3',
                    'children': [
                        _$cc(Icon, _$tmp2),
                        _$ct('menu 3\n            '),
                        _$cc(Menu, {
                            'children': [
                                _$cc(MenuItem, {
                                    'key': '3-1',
                                    'children': 'sub menu 1'
                                }, '3-1'),
                                _$cc(MenuItem, {
                                    'key': '3-2',
                                    'children': 'sub menu 2'
                                }, '3-2'),
                                _$cc(MenuItem, {
                                    'key': '3-3',
                                    'disabled': true,
                                    'children': 'sub menu 3'
                                }, '3-3'),
                                _$cc(MenuItem, {
                                    'key': '3-4',
                                    'children': [
                                        _$ct('\n                    sub menu 4\n                    '),
                                        _$cc(Menu, {
                                            'children': [
                                                _$cc(MenuItem, {
                                                    'key': '3-4-1',
                                                    'children': 'option 1'
                                                }, '3-4-1'),
                                                _$cc(MenuItem, {
                                                    'key': '3-4-2',
                                                    'children': 'option 2'
                                                }, '3-4-2')
                                            ]
                                        })
                                    ]
                                }, '3-4')
                            ]
                        })
                    ]
                }, '3'),
                _$cc(MenuItem, {
                    'key': '4',
                    'to': '/',
                    'children': [
                        _$cc(Icon, _$tmp3),
                        _$ct('menu 4')
                    ]
                }, '4'),
                _$cc(MenuTitle, {
                    'children': [
                        _$cc(Icon, _$tmp4),
                        _$ct('menu 5')
                    ]
                }),
                _$cc(MenuItem, {
                    'key': '5-1',
                    'children': [
                        _$cc(Icon, _$tmp5),
                        _$ct('sub menu 1')
                    ]
                }, '5-1'),
                _$cc(MenuItem, {
                    'key': '5-2',
                    'children': [
                        _$cc(Icon, _$tmp6),
                        _$ct('sub menu 2')
                    ]
                }, '5-2')
            ],
            'ev-$model:expandedKeys': function($v) {
                $this.set('expandedKeys', $v);
            },
            'expandedKeys': $this.get('expandedKeys'),
            'ev-$model:selectedKey': function($v) {
                $this.set('selectedKey', $v);
            },
            'selectedKey': $this.get('selectedKey')
        }, null, _$ref___test)
    ], 4 /* HasNonKeyedChildren */);
};