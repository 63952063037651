import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Input} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___demo = function(i) {_$refs['__demo'] = i};
    
    return _$cc(Input, {
        'placeholder': 'password',
        'type': 'password',
        'showPassword': true,
        'ref': _$ref___demo
    }, null, _$ref___demo);
};