import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Tabs, Tab, Button, ButtonGroup} from 'kpc';

var _$tmp0 = {
    'style': 'display: flex'
};
var _$tmp1 = {
    'style': 'width: 600px;'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': (
                _$ma(['large', 'default', 'small', 'mini'], function($value, $key) {
                    return _$cc(Button, {
                        'value': $value,
                        'children': $value
                    });
                }, $this)
            ),
            'ev-$model:value': function($v) {
                $this.set('size', $v);
            },
            'value': $this.get('size')
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$ma(this.get('types'), function($value, $key) {
            return _$cc(Tabs, {
                'type': $value,
                'size': this.get('size'),
                'children': (
                    _$ma(this.get('tabs'), function($value, $key) {
                        return _$cc(Tab, {
                            'value': $value.value,
                            'key': $value.value,
                            'children': $value.label
                        }, $value.value);
                    }, $this)
                ),
                'ev-$model:value': function($v) {
                    $this.set('tab', $v);
                },
                'value': $this.get('tab')
            });
        }, $this),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$ce(2, 'div', (
            _$ma(this.get('types'), function($value, $key) {
                return _$ce(2, 'div', (
                    _$cc(Tabs, {
                        'type': $value,
                        'size': this.get('size'),
                        'vertical': true,
                        'children': (
                            _$ma(this.get('tabs'), function($value, $key) {
                                return _$cc(Tab, {
                                    'value': $value.value,
                                    'key': $value.value,
                                    'children': $value.label
                                }, $value.value);
                            }, $this)
                        ),
                        'ev-$model:value': function($v) {
                            $this.set('tab', $v);
                        },
                        'value': $this.get('tab')
                    })
                ), 2 /* HasVNodeChildren */, null, {
                    'style': {height: '200px'}
                }, $value);
            }, $this)
        ), 8 /* HasKeyedChildren */, null, _$tmp0)
    ], 0 /* UnknownChildren */, null, _$tmp1);
};