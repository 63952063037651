export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

const data = [
    {name: 'John'},
    {name: 'Tom'},
    {name: 'Javey'},
].map((item: any) => {
    for (let i = 0; i < 4; i++) {
        item[`column${i + 1}`] = 'test';
    }
    return item;
});
export default class extends Component {
    static template = template;
    static defaults() {
        return {
            data
        }
    }
}