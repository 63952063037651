import {
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {getRestProps} from '../utils';
import {makeColStyles} from './styles';
import {context as GutterContext, gutterStyle} from './useGutter';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, children, span, style, offset, push, pull, order} = this.get();
    const { k } = this.config;
    
    return _$cc(GutterContext.Consumer, {
        'children': gutter => {
            const classNameObj = {
                [`${k}-col`]: true,
                [`${k}-${span}`]: span,
                [`${k}-offset-${offset}`]: offset,
                [`${k}-pull-${pull}`]: pull,
                [`${k}-push-${push}`]: push,
                [`${k}-order-${order}`]: order,
                [className]: className && !gutter,
                [makeColStyles(k)]: true,
                ...this.breakpoints.getBreakpointsClassName(k),
            };
            const newStyle = gutter ? gutterStyle(gutter, style, 'padding') : style;
        
            return (
                _$cv('div', {
                    'className': _$cn(classNameObj),
                    ...getRestProps(this),
                    'style': newStyle
                }, gutter ? 
                    _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn({[`${k}-col-wrapper`]: true, [className]: className})):
                    children)
            );
        }
    });
};