import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn, Switch} from 'kpc';

var _$tmp0 = {
    'title': 'Name',
    'key': 'name'
};
var _$tmp1 = {
    'title': 'IP',
    'key': 'ip'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_table = function(i) {_$refs['table'] = i};
    
    return _$cc(Table, {
        'data': this.get('data'),
        'pagination': true,
        'ref': _$ref_table,
        'draggable': true,
        'rowKey': item => item.name,
        'ev-page': this.onChangePage,
        'children': [
            _$cc(TableColumn, _$tmp0, 'name'),
            _$cc(TableColumn, _$tmp1, 'ip')
        ]
    }, null, _$ref_table);
};