import {
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tag} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$ma(this.get('borders'), function(border, $key) {
            return _$ce(2, 'div', [
                _$ma(this.get('types'), function($value, $key) {
                    return _$cc(Tag, {
                        'type': $value,
                        'border': border,
                        'closable': true,
                        'children': $value
                    });
                }, $this),
                _$cc(Tag, {
                    'disabled': true,
                    'closable': true,
                    'border': border,
                    'children': 'disabled'
                })
            ], 0 /* UnknownChildren */, 'row');
        }, $this)
    ), 4 /* HasNonKeyedChildren */);
};