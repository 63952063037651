import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Slider} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Slider, {
        'min': 0,
        'max': 500,
        'step': {
            // 0~100 step: 10
            '100': 10,
            // 100~500 step: 50
            '500': 50
        },
        'showTooltip': true,
        'points': true,
        'ev-$model:value': function($v) {
            $this.set('value1', $v);
        },
        'value': $this.get('value1')
    });
};