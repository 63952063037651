export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props  {
    position: 'left' | 'bottom' | 'right' | 'top' | 'custom'
    xAxisKeywords: string[]
    yAxisKeywords: string[]
    pos: Pos
}
type Pos = { 
    myX: string
    myXOffset: number
    myY: string
    myYOffset: number
    atX: string
    atXOffset: number
    atY: string
    atYOffset: number
}
export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            position: 'left',
            xAxisKeywords: ['left', 'center', 'right'],
            yAxisKeywords: ['top', 'center', 'bottom'],
            pos: {
                myX: 'center',
                myXOffset: 0,
                myY: 'bottom',
                myYOffset: -10,
                atX: 'center',
                atXOffset: 0,
                atY: 'top',
                atYOffset: 0,
            },
        } as Props;
    }

    getPosition() {
        const position = this.get('position');
        const getNumStr = (num: number) => num > 0 ? `+${num}` : num === 0 ? '' : num;

        if (position === 'custom') {
            const pos = this.get('pos');
            return {
                my: `${pos.myX}${getNumStr(pos.myXOffset)} ${pos.myY}${getNumStr(pos.myYOffset)}`,
                at: `${pos.atX}${getNumStr(pos.atXOffset)} ${pos.atY}${getNumStr(pos.atYOffset)}`,
            };
        }

        return position;
    }

    getContent() {
        return JSON.stringify(this.getPosition());
    }
}