import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Popover, Button} from 'kpc';

var _$tmp0 = {
    'style': 'color: red'
};
var _$tmp1 = {
    'style': 'color: red'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Popover, {
            'ev-ok': this.ok,
            'ev-cancel': this.cancel,
            'children': (
                _$cc(Button, {
                    'children': '删除'
                })
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['title'] = function($super) {
                            return [
                                _$ct('是否确认'),
                                _$ce(2, 'span', '删除', 16 /* HasTextChildren */, null, _$tmp0),
                                _$ct('？')
                            ];
                        }),
                        (__$blocks['title'] = function($super, data) {
                            var block = $blocks['title'];
                            var callBlock = function() {
                                return _$blocks['title'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['content'] = function($super) {
                            return [
                                _$ce(2, 'span', '删除', 16 /* HasTextChildren */, null, _$tmp1),
                                _$ct('是一个危险操作，请谨慎操作\n        ')
                            ];
                        }),
                        (__$blocks['content'] = function($super, data) {
                            var block = $blocks['content'];
                            var callBlock = function() {
                                return _$blocks['content'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ), 2 /* HasVNodeChildren */);
};