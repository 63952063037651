export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message} from 'kpc';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            value: '100' 
        };
    }

    _showErrorTip() {
        Message.error('Please enter digits.');
    }

    _onChange(newValue: string, oldValue?: string) {
        console.log(newValue, oldValue);
    }
}