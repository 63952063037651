export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

export default class extends Component {
    static template = template;

    container(dom: Element) {
        return dom.parentElement!; 
    }
}