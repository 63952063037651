import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Select, {
            'multiple': true,
            'children': [
                _$cc(Option, {
                    'value': 'Monday',
                    'children': '星期一'
                }),
                _$cc(Option, {
                    'value': 'Tuesday',
                    'children': '星期二'
                }),
                _$cc(Option, {
                    'value': 'Wednesday',
                    'children': '星期三'
                }),
                _$cc(Option, {
                    'value': 'Thursday',
                    'children': '星期四'
                }),
                _$cc(Option, {
                    'value': 'Friday',
                    'children': '星期五'
                }),
                _$cc(Option, {
                    'value': 'Saturday',
                    'children': '星期六'
                }),
                _$cc(Option, {
                    'value': 'Sunday',
                    'children': '星期天'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('day', $v);
            },
            'value': $this.get('day')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('day'))
    ], 0 /* UnknownChildren */);
};