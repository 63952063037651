import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Radio without rowKey'
};
var _$tmp1 = {
    'key': 'text',
    'title': 'Radio with rowKey'
};
var _$tmp2 = {
    'key': 'a',
    'title': 'checkbox without rowKey'
};
var _$tmp3 = {
    'key': 'text',
    'title': 'checkbox with rowKey'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$cc(Table, {
                'data': this.get('data1'),
                'checkedKeys': [0],
                'checkType': 'radio',
                'children': (
                    _$cc(TableColumn, _$tmp0, 'a')
                )
            }),
            _$cc(Table, {
                'data': this.get('data2'),
                'checkType': 'radio',
                'rowKey': this.setRowKey,
                'checkedKeys': ['yes'],
                'children': (
                    _$cc(TableColumn, _$tmp1, 'text')
                )
            })
        ], 4 /* HasNonKeyedChildren */, 'tables'),
        _$ce(2, 'div', [
            _$cc(Table, {
                'data': this.get('data3'),
                'checkedKeys': [0, 2],
                'children': (
                    _$cc(TableColumn, _$tmp2, 'a')
                )
            }),
            _$cc(Table, {
                'data': this.get('data2'),
                'rowKey': this.setRowKey,
                'checkedKeys': ['yes'],
                'children': (
                    _$cc(TableColumn, _$tmp3, 'text')
                )
            })
        ], 4 /* HasNonKeyedChildren */, 'tables')
    ], 4 /* HasNonKeyedChildren */);
};