import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Transfer, Tree} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_left = function(i) {_$refs['left'] = i};
    var _$ref_right = function(i) {_$refs['right'] = i};
    
    return _$cc(Transfer, {
        'enableAdd': this.enableAdd,
        'enableRemove': this.enableRemove,
        'ev-add': this.onAdd,
        'ev-remove': this.onRemove,
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['header'] = function($super, type) {
                        return (
                            (type === 'left') ?
                                _$ce(2, 'div', '请选择', 16 /* HasTextChildren */) :
                                _$ce(2, 'div', '已选择', 16 /* HasTextChildren */)
                        );
                    }),
                    (__$blocks['header'] = function($super, data) {
                        var block = $blocks['header'];
                        var callBlock = function() {
                            return _$blocks['header'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                (
                    (_$blocks['list'] = function($super, type) {
                        return (
                            (type === 'left') ?
                                _$cc(Tree, {
                                    'data': this.get('leftData'),
                                    'checkbox': true,
                                    'ref': _$ref_left,
                                    'ev-$model:checkedKeys': function($v) {
                                        $this.set('leftCheckedKeys', $v);
                                    },
                                    'checkedKeys': $this.get('leftCheckedKeys'),
                                    'ev-$model:expandedKeys': function($v) {
                                        $this.set('leftExpandedKeys', $v);
                                    },
                                    'expandedKeys': $this.get('leftExpandedKeys')
                                }, null, _$ref_left) :
                                _$cc(Tree, {
                                    'data': this.get('rightData'),
                                    'checkbox': true,
                                    'ref': _$ref_right,
                                    'ev-$model:checkedKeys': function($v) {
                                        $this.set('rightCheckedKeys', $v);
                                    },
                                    'checkedKeys': $this.get('rightCheckedKeys'),
                                    'ev-$model:expandedKeys': function($v) {
                                        $this.set('rightExpandedKeys', $v);
                                    },
                                    'expandedKeys': $this.get('rightExpandedKeys')
                                }, null, _$ref_right)
                        );
                    }),
                    (__$blocks['list'] = function($super, data) {
                        var block = $blocks['list'];
                        var callBlock = function() {
                            return _$blocks['list'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    });
};