import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {
    Diagram, DStackLayout,
    DRectangle, DSquare, DCircle, DEllipse,
    DImage, DText, DDiamond, DParallelogram,
    DHexagon, DTriangle, DCylinder, DCloud,
    DDocument, DCallout,
} from 'kpc/components/diagram';
import {Button, ButtonGroup} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const states = this.get('states');
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'checkType': 'checkbox',
            'children': (
                _$ma(states, function($value, $key) {
                    return _$cc(Button, {
                        'value': $key,
                        'children': $key
                    });
                }, $this)
            ),
            'ev-$model:value': function($v) {
                $this.set('selectedStates', $v);
            },
            'value': $this.get('selectedStates')
        }),
        _$ce(2, 'br'),
        _$cc(Diagram, {
            'children': (
                _$cc(DStackLayout, {
                    'spacing': '20',
                    'wrap': '500',
                    'border': '20',
                    'children': [
                        _$cc(DRectangle, {
                            ...states
                        }),
                        _$cc(DSquare, {
                            ...states
                        }),
                        _$cc(DCircle, {
                            ...states
                        }),
                        _$cc(DEllipse, {
                            ...states
                        }),
                        _$cc(DImage, {
                            'src': 'https://design.ksyun.com/fonts/logo.png',
                            ...states
                        }),
                        _$cc(DText, {
                            'label': 'text',
                            ...states
                        }),
                        _$cc(DDiamond, {
                            ...states
                        }),
                        _$cc(DParallelogram, {
                            ...states
                        }),
                        _$cc(DHexagon, {
                            ...states
                        }),
                        _$cc(DTriangle, {
                            ...states
                        }),
                        _$cc(DCylinder, {
                            ...states
                        }),
                        _$cc(DCloud, {
                            ...states
                        }),
                        _$cc(DDocument, {
                            ...states
                        }),
                        _$cc(DCallout, {
                            ...states
                        })
                    ]
                })
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};