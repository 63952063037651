export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Dayjs} from 'dayjs';

export default class extends Component {
    static template = template;

    disabledDate(date: Dayjs) {
        // 禁用掉每月的5号和15号
        const d = date.date();
        return d === 5 || d === 15;
    }

    disabledMonth(date: Dayjs) {
        // 禁用掉每年的5月
        const m = date.month() + 1;
        return m === 5;
    }

    disabledYear(date: Dayjs) {
        // 禁用掉2015年
        const y = date.year();
        return y === 2015;
    }
}