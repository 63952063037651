import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Form, FormItem, Input, Select, Option, ButtonGroup, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Form, {
        'layout': this.get('layout'),
        'size': this.get('size'),
        'children': [
            _$cc(FormItem, {
                'label': 'Layout',
                'children': (
                    _$cc(ButtonGroup, {
                        'checkType': 'radio',
                        'children': [
                            _$cc(Button, {
                                'value': 'horizontal',
                                'children': 'horizontal'
                            }),
                            _$cc(Button, {
                                'value': 'vertical',
                                'children': 'vertical'
                            }),
                            _$cc(Button, {
                                'value': 'inline',
                                'children': 'inline'
                            })
                        ],
                        'ev-$model:value': function($v) {
                            $this.set('layout', $v);
                        },
                        'value': $this.get('layout')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Size',
                'children': (
                    _$cc(ButtonGroup, {
                        'checkType': 'radio',
                        'children': [
                            _$cc(Button, {
                                'value': 'default',
                                'children': 'default'
                            }),
                            _$cc(Button, {
                                'value': 'small',
                                'children': 'small'
                            }),
                            _$cc(Button, {
                                'value': 'mini',
                                'children': 'mini'
                            })
                        ],
                        'ev-$model:value': function($v) {
                            $this.set('size', $v);
                        },
                        'value': $this.get('size')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Input',
                'value': this.get('model.input'),
                'rules': {required: true},
                'children': (
                    _$cc(Input, {
                        'ev-$model:value': function($v) {
                            $this.set('model.input', $v);
                        },
                        'value': $this.get('model.input')
                    })
                )
            }),
            _$cc(FormItem, {
                'label': 'Select',
                'value': this.get("model.select"),
                'rules': {required: true},
                'children': (
                    _$cc(Select, {
                        'children': [
                            _$cc(Option, {
                                'value': 'Javascript',
                                'children': 'Javascript'
                            }),
                            _$cc(Option, {
                                'value': 'PHP',
                                'children': 'PHP'
                            }),
                            _$cc(Option, {
                                'value': 'C++',
                                'children': 'C++'
                            })
                        ],
                        'ev-$model:value': function($v) {
                            $this.set('model.select', $v);
                        },
                        'value': $this.get('model.select')
                    })
                )
            }),
            _$cc(FormItem, {
                'children': (
                    _$cc(Button, {
                        'type': 'primary',
                        'htmlType': 'submit',
                        'children': 'Submit'
                    })
                )
            })
        ]
    });
};