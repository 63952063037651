import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Tabs, Tab, Button, ButtonGroup, Icon} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': (
                _$ma(['large', 'default', 'small', 'mini'], function($value, $key) {
                    return _$cc(Button, {
                        'value': $value,
                        'children': $value
                    });
                }, $this)
            ),
            'ev-$model:value': function($v) {
                $this.set('size', $v);
            },
            'value': $this.get('size')
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Button, {
            'ev-click': this._add,
            'children': '新增Tab'
        }),
        _$ma(this.get('types'), function($value, $key) {
            return _$cc(Tabs, {
                'closable': this.get('tabs').length > 1,
                'ev-remove': this._remove,
                'type': $value,
                'size': this.get('size'),
                'children': (
                    _$ma(this.get('tabs'), function($value, $key) {
                        return _$cc(Tab, {
                            'value': $value.value,
                            'key': $value.value,
                            'closable': $value.value !== 10,
                            'children': [
                                _$cc(Icon, {
                                    'className': 'k-icon-paper',
                                    'size': this.get('size'),
                                    'style': 'margin-right: 8px;',
                                    'color': 'inherit'
                                }),
                                $value.label
                            ]
                        }, $value.value);
                    }, $this)
                ),
                'ev-$model:value': function($v) {
                    $this.set('tab', $v);
                },
                'value': $this.get('tab')
            });
        }, $this)
    ], 0 /* UnknownChildren */);
};