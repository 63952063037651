import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Copy, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-ios-copy',
    'size': 'large',
    'hoverable': true
};
var _$tmp1 = {
    'className': 'ion-ios-copy-outline'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Copy, {
            'text': 'Hello King Desgin!',
            'children': '\n        Copy\n    '
        }),
        _$cc(Copy, {
            'text': 'Hello King Desgin!',
            'children': (
                _$cc(Button, {
                    'children': 'Copy'
                })
            )
        }),
        _$cc(Copy, {
            'text': 'Hello King Desgin!',
            'children': (
                _$cc(Button, {
                    'type': 'link',
                    'size': 'mini',
                    'children': 'Copy'
                })
            )
        }),
        _$cc(Copy, {
            'text': 'Hello King Desgin!',
            'children': (
                _$cc(Icon, _$tmp0)
            )
        }),
        _$cc(Copy, {
            'text': 'Hello King Desgin!',
            'children': (
                _$cc(Button, {
                    'icon': true,
                    'circle': true,
                    'children': (
                        _$cc(Icon, _$tmp1)
                    )
                })
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};