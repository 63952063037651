import {
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
    className as _$cn,
    noop as _$no,
} from 'intact';
import {Icon} from '../icon';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {Transition} from 'intact';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        className, children, type, border,
        disabled, closable, closed, size
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-tag`]: !$props.className,
        [className]: className,
        [`${k}-${type}`]: type !== 'default',
        [`${k}-${size}`]: size !== 'default',
        [`${k}-${border}`]: border,
        [`${k}-disabled`]: disabled,
        [`${k}-closable`]: closable,
        [makeStyles(k)]: true,
        [$props.className]: $props.className,
    };
    
    return _$cc(Transition, {
        'name': $props.name || `${k}-scale`,
        'children': (
            (!closed) ?
                _$cv('div', {
                    'className': _$cn(classNameObj),
                    ...getRestProps(this)
                }, [
                    (
                        (_$blocks['body'] = function($super) {
                            return children;
                        }),
                        (__$blocks['body'] = function($super, data) {
                            var block = $blocks['body'];
                            var callBlock = function() {
                                return _$blocks['body'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        }),
                        __$blocks['body'](_$no)
                    ),
                    (
                        (_$blocks['_close'] = function($super) {
                            return (
                                (closable) ?
                                    _$cc(Icon, {
                                        'className': _$cn(`${k}-tag-close ion-ios-close-empty`),
                                        'color': type,
                                        'hoverable': !disabled,
                                        'ev-click': this.onClose
                                    }) :
                                    undefined
                            );
                        }),
                        (__$blocks['_close'] = function($super, data) {
                            var block = $blocks['_close'];
                            var callBlock = function() {
                                return _$blocks['_close'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        }),
                        __$blocks['_close'](_$no)
                    )
                ]) :
                undefined
        )
    });
};