import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Row, {
            'gutter': '16',
            'children': [
                _$cc(Col, {
                    'span': '12',
                    'children': (
                        _$ce(2, 'div', 'col-12', 16 /* HasTextChildren */)
                    )
                }),
                _$cc(Col, {
                    'span': '12',
                    'children': (
                        _$ce(2, 'div', 'col-12', 16 /* HasTextChildren */)
                    )
                })
            ]
        }),
        _$cc(Row, {
            'gutter': {lg: 16, md: 4},
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': (
                        _$ce(2, 'div', 'col-4', 16 /* HasTextChildren */)
                    )
                }),
                _$cc(Col, {
                    'span': '6',
                    'children': (
                        _$ce(2, 'div', 'col-6', 16 /* HasTextChildren */)
                    )
                }),
                _$cc(Col, {
                    'span': '8',
                    'children': (
                        _$ce(2, 'div', 'col-8', 16 /* HasTextChildren */)
                    )
                }),
                _$cc(Col, {
                    'span': '6',
                    'children': (
                        _$ce(2, 'div', 'col-6', 16 /* HasTextChildren */)
                    )
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};