export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind} from 'kpc';

interface Props {
    value1?: number
    value2?: number
    min: number
    max: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            'value1': 60,
            'value2': 277,
            'min': 0,
            'max': 100,
        }
    }

    @bind
    _changeRange() {
        const max = Math.floor(Math.random() * 100);
        const min = Math.floor(Math.random() * max);
        this.set({max, min});
    }
}