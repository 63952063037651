import {
    createElementVNode as _$ce,
    map as _$ma,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {makeStyles} from './styles';
import {Icon, Button} from 'kpc';

var _$tmp0 = {
    'className': 'icon ion-chevron-left'
};
var _$tmp1 = {
    'className': 'icon ion-chevron-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {show, imgList, baseWidth, curImgIndex, imgWidth} = this.get();
    
    const left = curImgIndex == 0 
        ? baseWidth 
        : -(curImgIndex) * imgWidth + baseWidth;
    
    const imgBoxStyle = {
        transform: `translateX(${left}px)`
    };
    
    return (show) ?
        _$ce(2, 'div', (
            _$ce(2, 'div', [
                _$ce(2, 'div', (
                    _$ma(imgList, function($value, $key) {
                        return _$ce(2, 'div', (
                            _$ce(2, 'div', [
                                _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                    'src': $value
                                }),
                                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn({active: $key === curImgIndex}))
                            ], 4 /* HasNonKeyedChildren */, 'img-with-shadow')
                        ), 2 /* HasVNodeChildren */, 'img-item');
                    }, $this)
                ), 4 /* HasNonKeyedChildren */, 'img-box', {
                    'style': imgBoxStyle
                }),
                (curImgIndex > 0) ?
                    _$cc(Button, {
                        'icon': true,
                        'circle': true,
                        'size': 'large',
                        'className': 'icon-arrow arrow-left',
                        'ev-click': this.handleRightArrowClick.bind(this),
                        'children': (
                            _$cc(Icon, _$tmp0)
                        )
                    }) :
                    undefined,
                (curImgIndex < imgList.length - 1) ?
                    _$cc(Button, {
                        'icon': true,
                        'circle': true,
                        'size': 'large',
                        'className': 'icon-arrow arrow-right',
                        'ev-click': this.handleLeftArrowClick.bind(this),
                        'children': (
                            _$cc(Icon, _$tmp1)
                        )
                    }) :
                    undefined
            ], 0 /* UnknownChildren */, 'img-wrapper')
        ), 2 /* HasVNodeChildren */, _$cn(makeStyles()), {
            'ev-click': this.handleShadowClick.bind(this)
        }) :
        undefined;
};