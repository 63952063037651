import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Badge, Button} from 'kpc';

var _$tmp0 = {
    'text': 'new'
};
var _$tmp1 = {
    'text': 19
};
var _$tmp2 = {
    'text': 100,
    'max': 99
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Badge),
        _$cc(Badge, _$tmp0),
        _$cc(Badge, _$tmp1),
        _$cc(Badge, _$tmp2)
    ], 4 /* HasNonKeyedChildren */);
};