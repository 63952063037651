import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    createElementVNode as _$ce,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {DropdownMenu} from '../dropdown';
import {_$} from '../../i18n';
import {makeMenuStyles} from './styles';
import {isEmptyChildren, eachChildren} from '../utils';
import {OptionGroup} from './group';
import {noop, isFunction} from 'intact-shared';
import {Input} from '../input';
import {Button} from '../button';
import {Icon} from '../icon';
import {context} from './useSearchable';
import {Tabs, Tab} from '../tabs';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    let {children, className} = this.get();
    const {card, searchable, multiple} = this.select.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-select-menu`]: true,
        [`${k}-card`]: card,
        [`${k}-searchable`]: searchable,
        [className]: className,
        [makeMenuStyles(k)]: true,
    }
    
    if (card) {
        const {process, activeIndex} = this.card;
        const {labels, group} = process(children);
        children = (
            [
                _$cc(Tabs, {
                    'vertical': true,
                    'type': 'card',
                    'value': activeIndex.value,
                    'ev-$change:value': activeIndex.set,
                    'children': (
                        _$ma(labels, function($value, $key) {
                            return _$cc(Tab, {
                                'value': $key,
                                'children': isFunction($value) /* is <b:label> block */ ? $value(noop) : $value
                            });
                        }, $this)
                    )
                }),
                group
            ]
        );
    }
    
    if (isEmptyChildren(children)) {
        children = (
            _$ce(2, 'div', _$('无数据'), 0 /* UnknownChildren */, _$cn(`${k}-select-empty`))
        );
    }
    
    if (searchable) {
        const {selectAll, toggleSelect, unselectAll, confirm, checkedKeys} = this.searchable;
        const {onInput, keywords} = this.select.input;
        const {Provider} = context;
    
        children = (
            _$cc(Provider, {
                'value': checkedKeys,
                'children': [
                    _$ce(2, 'div', [
                        _$cc(Input, {
                            'fluid': true,
                            'size': 'small',
                            'placeholder': _$('请输入关键字'),
                            'value': keywords.value,
                            'ev-$change:value': onInput,
                            'clearable': true,
                            'waveDisabled': true,
                            '$blocks': function($blocks) {
                                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                return (
                                    (
                                        (_$blocks['suffix'] = function($super) {
                                            return (
                                                _$cc(Icon, {
                                                    'className': _$cn(`${k}-icon-search`)
                                                })
                                            );
                                        }),
                                        (__$blocks['suffix'] = function($super, data) {
                                            var block = $blocks['suffix'];
                                            var callBlock = function() {
                                                return _$blocks['suffix'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        })
                                    ),
                                    __$blocks
                                );
                            }.call($this, _$em)
                        }),
                        (multiple) ?
                            _$ce(2, 'div', [
                                _$cc(Button, {
                                    'type': 'link',
                                    'size': 'small',
                                    'ev-click': selectAll,
                                    'children': _$('全选')
                                }),
                                _$cc(Button, {
                                    'type': 'link',
                                    'size': 'small',
                                    'ev-click': toggleSelect,
                                    'children': _$('反选')
                                }),
                                _$cc(Button, {
                                    'type': 'link',
                                    'size': 'small',
                                    'ev-click': unselectAll,
                                    'children': _$('清空')
                                })
                            ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-select-op`)) :
                            undefined
                    ], 0 /* UnknownChildren */, _$cn(`${k}-select-header`)),
                    _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-select-body`)),
                    (multiple) ?
                        _$ce(2, 'div', [
                            _$cc(Button, {
                                'size': 'small',
                                'ev-click': this.select.hide,
                                'children': _$('取消')
                            }),
                            _$cc(Button, {
                                'type': 'primary',
                                'size': 'small',
                                'ev-click': confirm,
                                'children': _$('确定')
                            })
                        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-select-footer`)) :
                        undefined
                ]
            })
        );
    }
    
    return _$cc(DropdownMenu, {
        'className': _$cn(classNameObj),
        'children': children
    });
};