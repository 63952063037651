import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    createTextVNode as _$ct,
    createElementVNode as _$ce,
} from 'intact';
import {Tooltip, ButtonGroup, Button, Form, FormItem, Select, Option, Spinner} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Form, {
        'children': [
            _$cc(FormItem, {
                'label': 'position:',
                'children': (
                    _$cc(ButtonGroup, {
                        'checkType': 'radio',
                        'children': (
                            _$ma(['left', 'right', 'top', 'bottom', 'custom'], function($value, $key) {
                                return _$cc(Button, {
                                    'value': $value,
                                    'children': $value
                                });
                            }, $this)
                        ),
                        'ev-$model:value': function($v) {
                            $this.set('position', $v);
                        },
                        'value': $this.get('position')
                    })
                )
            }),
            (this.get('position') === 'custom') ?
                [
                    _$cc(FormItem, {
                        'label': 'my:',
                        'children': [
                            _$ct('\n            x: '),
                            _$cc(Select, {
                                'width': '100',
                                'children': (
                                    _$ma(this.get('xAxisKeywords'), function($value, $key) {
                                        return _$cc(Option, {
                                            'value': $value,
                                            'children': $value
                                        });
                                    }, $this)
                                ),
                                'ev-$model:value': function($v) {
                                    $this.set('pos.myX', $v);
                                },
                                'value': $this.get('pos.myX')
                            }),
                            _$cc(Spinner, {
                                'vertical': true,
                                'ev-$model:value': function($v) {
                                    $this.set('pos.myXOffset', $v);
                                },
                                'value': $this.get('pos.myXOffset')
                            }),
                            _$ct('\n            y: '),
                            _$cc(Select, {
                                'width': '100',
                                'children': (
                                    _$ma(this.get('yAxisKeywords'), function($value, $key) {
                                        return _$cc(Option, {
                                            'value': $value,
                                            'children': $value
                                        });
                                    }, $this)
                                ),
                                'ev-$model:value': function($v) {
                                    $this.set('pos.myY', $v);
                                },
                                'value': $this.get('pos.myY')
                            }),
                            _$cc(Spinner, {
                                'vertical': true,
                                'ev-$model:value': function($v) {
                                    $this.set('pos.myYOffset', $v);
                                },
                                'value': $this.get('pos.myYOffset')
                            })
                        ]
                    }),
                    _$cc(FormItem, {
                        'label': 'at:',
                        'children': [
                            _$ct('\n            x: '),
                            _$cc(Select, {
                                'width': '100',
                                'children': (
                                    _$ma(this.get('xAxisKeywords'), function($value, $key) {
                                        return _$cc(Option, {
                                            'value': $value,
                                            'children': $value
                                        });
                                    }, $this)
                                ),
                                'ev-$model:value': function($v) {
                                    $this.set('pos.atX', $v);
                                },
                                'value': $this.get('pos.atX')
                            }),
                            _$cc(Spinner, {
                                'vertical': true,
                                'ev-$model:value': function($v) {
                                    $this.set('pos.atXOffset', $v);
                                },
                                'value': $this.get('pos.atXOffset')
                            }),
                            _$ct('\n            y: '),
                            _$cc(Select, {
                                'width': '100',
                                'children': (
                                    _$ma(this.get('yAxisKeywords'), function($value, $key) {
                                        return _$cc(Option, {
                                            'value': $value,
                                            'children': $value
                                        });
                                    }, $this)
                                ),
                                'ev-$model:value': function($v) {
                                    $this.set('pos.atY', $v);
                                },
                                'value': $this.get('pos.atY')
                            }),
                            _$cc(Spinner, {
                                'vertical': true,
                                'ev-$model:value': function($v) {
                                    $this.set('pos.atYOffset', $v);
                                },
                                'value': $this.get('pos.atYOffset')
                            })
                        ]
                    })
                ] :
                undefined,
            _$cc(FormItem, {
                'key': 'opera',
                'fluid': true,
                'children': (
                    _$ce(2, 'div', (
                        _$cc(Tooltip, {
                            'content': this.getContent(),
                            'position': this.getPosition(),
                            'always': true,
                            'value': true,
                            'container': '.opera',
                            'hoverable': true,
                            'children': (
                                _$cc(Button, {
                                    'children': 'at'
                                })
                            )
                        })
                    ), 2 /* HasVNodeChildren */, 'opera')
                )
            }, 'opera')
        ]
    });
};