import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Slider} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Slider, {
            'disabled': true,
            'value': 50,
            'ref': _$ref___test
        }, null, _$ref___test),
        _$cc(Slider, {
            'disabled': true,
            'range': true,
            'value': [55, 80],
            'min': 50,
            'max': 100
        })
    ], 4 /* HasNonKeyedChildren */);
};