import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    noop as _$no,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Transition} from 'intact';
import {expandAnimationCallbacks, getRestProps} from '../utils';
import {makeItemStyles} from './styles';
import {Icon} from '../icon';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        children, title, value,
        className, disabled
    } = this.get();
    
    const isActive = this.collapse.isActive(value);
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-collapse-item`]: true,
        [makeItemStyles(k)]: true,
        [className]: className,
        [`${k}-active`]: isActive,
        [`${k}-disabled`]: disabled
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        _$ce(2, 'div', [
            _$ce(2, 'div', (
                (
                    (_$blocks['title'] = function($super) {
                        return title;
                    }),
                    (__$blocks['title'] = function($super, data) {
                        var block = $blocks['title'];
                        var callBlock = function() {
                            return _$blocks['title'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['title'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-collapse-title-wrapper`)),
            _$cc(Icon, {
                'className': _$cn({[`${k}-collapse-arrow`]: true, [`${k}-icon-right`]: true}),
                'hoverable': true,
                'disabled': disabled
            })
        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-collapse-title`), {
            'ev-click': this.toggle
        }),
        _$cc(Transition, {
            'show': isActive,
            ...expandAnimationCallbacks,
            'onEnter': el => {
                expandAnimationCallbacks.onEnter(el);
                this.onEnter();
            },
            'onAfterLeave': el => {
                expandAnimationCallbacks.onAfterLeave(el);
                this.onAfterLeave();
            },
            'children': (
                _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-collapse-wrapper`))
            )
        })
    ]);
};