import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'title': '名称',
    'key': 'name'
};
var _$tmp1 = {
    'title': '名称',
    'key': 'name'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test1 = function(i) {_$refs['__test1'] = i};
    var _$ref___test2 = function(i) {_$refs['__test2'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'group': this.get('group'),
            'ev-$change:group': this._onChangeGroup,
            'ref': _$ref___test1,
            'rowKey': row => row.name,
            'children': [
                _$cc(TableColumn, _$tmp0, 'name'),
                _$cc(TableColumn, {
                    'title': '状态',
                    'key': 'status',
                    'group': this.get('statusGroup'),
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return (
                                        _$ce(2, 'span', data.status === 'active' ? '运行中' : '已关闭', 0 /* UnknownChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'status')
            ]
        }, null, _$ref___test1),
        _$cc(Table, {
            'data': this.get('multipleData'),
            'group': this.get('multipleGroup'),
            'ev-$change:group': this._onChangeMultipleGroup,
            'ref': _$ref___test2,
            'rowKey': row => row.name,
            'children': [
                _$cc(TableColumn, _$tmp1, 'name'),
                _$cc(TableColumn, {
                    'title': '状态',
                    'key': 'status',
                    'group': this.get('multipleStatusGroup'),
                    'multiple': true,
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return (
                                        _$ce(2, 'span', data.status === 'active' ? '运行中' : '已关闭', 0 /* UnknownChildren */)
                                    );
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'status')
            ]
        }, null, _$ref___test2)
    ], 4 /* HasNonKeyedChildren */, 'tables');
};