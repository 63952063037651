import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';
import {Row, Col} from 'kpc';
import {Link} from '../link';
import qrcode from '../../imgs/qrcode.png';
import h5Qrcode from '../../imgs/h5-footer-qrcode.png';
import h5Logo from '../../imgs/h5-footer-logo.png';

var _$tmp0 = {
    'href': 'mailto:ksc_ued@kingsoft.com'
};
var _$tmp1 = {
    'href': 'https://www.ksyun.com/',
    'target': '_blank'
};
var _$tmp2 = {
    'target': '_blank',
    'href': 'http://vision.ksyun.com/#/icon-reposition'
};
var _$tmp3 = {
    'target': '_blank',
    'href': 'https://www.uisdc.com/'
};
var _$tmp4 = {
    'target': '_blank',
    'href': 'https://uiiiuiii.com/'
};
var _$tmp5 = {
    'target': '_blank',
    'href': 'https://js.design/resourceDetails?id=62c6c0834835c771da15dfe0&source=ksd&plan=web'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$ce(2, 'div', [
            _$ce(2, 'div', [
                _$cc(Row, {
                    'className': 'footer-logo-box',
                    'children': (
                        _$cc(Col, {
                            'span': '24',
                            'children': (
                                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'logo')
                            )
                        })
                    )
                }),
                _$cc(Row, {
                    'className': 'info-box',
                    'children': [
                        _$cc(Col, {
                            'className': 'info-left',
                            'md': '12',
                            'sm': '24',
                            'children': [
                                _$ce(2, 'div', '用于企业级产品和数字体验的开源设计系统', 16 /* HasTextChildren */, 'title'),
                                _$ce(2, 'div', [
                                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                                        'src': qrcode,
                                        'width': '90',
                                        'height': '90'
                                    }),
                                    _$ce(2, 'div', [
                                        _$ce(2, 'div', [
                                            _$ct('联系我们：'),
                                            _$ce(2, 'a', 'ksc_ued@kingsoft.com', 16 /* HasTextChildren */, null, _$tmp0)
                                        ], 4 /* HasNonKeyedChildren */),
                                        _$ce(2, 'div', '关注我们：微信公众号“⾦⼭云⽤户体验设计”', 16 /* HasTextChildren */)
                                    ], 4 /* HasNonKeyedChildren */)
                                ], 4 /* HasNonKeyedChildren */, 'qrcode-box')
                            ]
                        }),
                        _$cc(Col, {
                            'className': 'info-right',
                            'md': '12',
                            'sm': '24',
                            'children': [
                                _$cc(Row, {
                                    'className': 'quick-access-box',
                                    'children': (
                                        _$cc(Col, {
                                            'span': '24',
                                            'children': [
                                                _$ce(2, 'div', '快速访问', 16 /* HasTextChildren */, 'title'),
                                                _$ce(2, 'div', [
                                                    _$ce(2, 'div', (
                                                        _$cc(Link, {
                                                            'href': '/docs/design/about/',
                                                            'children': '设计'
                                                        })
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', (
                                                        _$cc(Link, {
                                                            'href': '/docs/vue/',
                                                            'children': '组件'
                                                        })
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', (
                                                        _$cc(Link, {
                                                            'href': '/resources',
                                                            'children': '资源'
                                                        })
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', (
                                                        _$cc(Link, {
                                                            'href': '/solutions',
                                                            'children': '解决方案'
                                                        })
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', 'Color-Process', 16 /* HasTextChildren */)
                                                ], 4 /* HasNonKeyedChildren */, 'link-list')
                                            ]
                                        })
                                    )
                                }),
                                _$cc(Row, {
                                    'children': (
                                        _$cc(Col, {
                                            'span': '24',
                                            'children': [
                                                _$ce(2, 'div', '友情链接', 16 /* HasTextChildren */, 'title'),
                                                _$ce(2, 'div', [
                                                    _$ce(2, 'div', (
                                                        _$ce(2, 'a', '金山云官网', 16 /* HasTextChildren */, null, _$tmp1)
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', (
                                                        _$ce(2, 'a', '金山云素材站', 16 /* HasTextChildren */, null, _$tmp2)
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', (
                                                        _$ce(2, 'a', '优设网', 16 /* HasTextChildren */, null, _$tmp3)
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', (
                                                        _$ce(2, 'a', '优设教育网', 16 /* HasTextChildren */, null, _$tmp4)
                                                    ), 2 /* HasVNodeChildren */),
                                                    _$ce(2, 'div', (
                                                        _$ce(2, 'a', '即时设计', 16 /* HasTextChildren */, null, _$tmp5)
                                                    ), 2 /* HasVNodeChildren */)
                                                ], 4 /* HasNonKeyedChildren */, 'link-list')
                                            ]
                                        })
                                    )
                                })
                            ]
                        })
                    ]
                }),
                _$cc(Row, {
                    'children': (
                        _$cc(Col, {
                            'span': '24',
                            'className': 'copy-right-info',
                            'children': '\n                    ©北京金山云网络技术有限公司   2021 Ksyun All Rights Reserved Kingsoft Corp.      京ICP证120829号      京ICP备 12032080号\n                '
                        })
                    )
                })
            ], 4 /* HasNonKeyedChildren */, 'footer-table web-info'),
            _$ce(2, 'div', [
                _$ce(2, 'div', (
                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                        'src': h5Logo,
                        'width': '181'
                    })
                ), 2 /* HasVNodeChildren */, 'footer-logo'),
                _$ce(2, 'div', '用于企业级产品的设计系统', 16 /* HasTextChildren */),
                _$ce(2, 'div', (
                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                        'src': h5Qrcode,
                        'width': '160'
                    })
                ), 2 /* HasVNodeChildren */),
                _$ce(2, 'div', [
                    _$ce(2, 'div', '长按保存识别二维码，', 16 /* HasTextChildren */),
                    _$ce(2, 'div', '关注“⾦⼭云⽤户体验设计”公众号', 16 /* HasTextChildren */)
                ], 4 /* HasNonKeyedChildren */),
                _$ce(2, 'div', '©北京金山云网络技术有限公司 2021 Ksyun All Rights Reserved Kingsoft Corp. 京ICP证120829号 京ICP备 12032080号 ', 16 /* HasTextChildren */)
            ], 4 /* HasNonKeyedChildren */, 'h5-footer h5-info')
        ], 4 /* HasNonKeyedChildren */, 'footer-box')
    ), 2 /* HasVNodeChildren */, _$cn(makeStyles()), {
        'style': {background: this.get('bgColor')}
    });
};