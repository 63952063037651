import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Input, Button, Icon, ButtonGroup} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-search'
};
var _$tmp1 = {
    'className': 'k-icon-search'
};
var _$tmp2 = {
    'className': 'k-icon-internet'
};
var _$tmp3 = {
    'className': 'k-icon-search',
    'hoverable': true
};
var _$tmp4 = {
    'className': 'k-icon-internet'
};
var _$tmp5 = {
    'className': 'k-icon-search',
    'hoverable': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['prepend'] = function($super) {
                            return 'http://';
                        }),
                        (__$blocks['prepend'] = function($super, data) {
                            var block = $blocks['prepend'];
                            var callBlock = function() {
                                return _$blocks['prepend'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['append'] = function($super) {
                            return '.com';
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                _$cc(Button, {
                                    'icon': true,
                                    'type': 'none',
                                    'children': (
                                        _$cc(Icon, _$tmp0)
                                    )
                                })
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                _$cc(Button, {
                                    'children': '提交'
                                })
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                _$cc(ButtonGroup, {
                                    'children': [
                                        _$cc(Button, {
                                            'type': 'primary',
                                            'children': '提交'
                                        }),
                                        _$cc(Button, {
                                            'type': 'primary',
                                            'children': '创建'
                                        })
                                    ]
                                })
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                _$cc(Button, {
                                    'icon': true,
                                    'type': 'primary',
                                    'children': (
                                        _$cc(Icon, _$tmp1)
                                    )
                                })
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                _$cc(Button, {
                                    'type': 'primary',
                                    'children': '搜索'
                                })
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['prefix'] = function($super) {
                            return (
                                _$cc(Icon, _$tmp2)
                            );
                        }),
                        (__$blocks['prefix'] = function($super, data) {
                            var block = $blocks['prefix'];
                            var callBlock = function() {
                                return _$blocks['prefix'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['suffix'] = function($super) {
                            return (
                                _$cc(Icon, _$tmp3)
                            );
                        }),
                        (__$blocks['suffix'] = function($super, data) {
                            var block = $blocks['suffix'];
                            var callBlock = function() {
                                return _$blocks['suffix'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['prepend'] = function($super) {
                            return 'http://';
                        }),
                        (__$blocks['prepend'] = function($super, data) {
                            var block = $blocks['prepend'];
                            var callBlock = function() {
                                return _$blocks['prepend'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['append'] = function($super) {
                            return '.com';
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['prefix'] = function($super) {
                            return (
                                _$cc(Icon, _$tmp4)
                            );
                        }),
                        (__$blocks['prefix'] = function($super, data) {
                            var block = $blocks['prefix'];
                            var callBlock = function() {
                                return _$blocks['prefix'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['suffix'] = function($super) {
                            return (
                                _$cc(Icon, _$tmp5)
                            );
                        }),
                        (__$blocks['suffix'] = function($super, data) {
                            var block = $blocks['suffix'];
                            var callBlock = function() {
                                return _$blocks['suffix'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */);
};