import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Drawer, Button, ButtonGroup} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___demoTwo = function(i) {_$refs['__demoTwo'] = i};
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'value': 'large',
                    'children': 'large'
                }),
                _$cc(Button, {
                    'value': 'default',
                    'children': 'default'
                }),
                _$cc(Button, {
                    'value': 'small',
                    'children': 'small'
                }),
                _$cc(Button, {
                    'value': 'mini',
                    'children': 'mini'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('size', $v);
            },
            'value': $this.get('size')
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(ButtonGroup, {
            'children': (
                _$ma(this.get('showList'), function($value, $key) {
                    return _$cc(Button, {
                        'ev-click': this.showDrawer.bind(self, $value.key),
                        'className': 'placement-btn',
                        'children': $value.value
                    });
                }, $this)
            )
        }),
        _$cc(Drawer, {
            'title': 'Drawer Title',
            'ref': _$ref___demoTwo,
            'placement': this.get('showPosition'),
            'size': this.get('size'),
            'children': '\n        Drawer Body\n    ',
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        }, null, _$ref___demoTwo)
    ], 4 /* HasNonKeyedChildren */);
};