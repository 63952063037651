import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
} from 'intact';

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {name} = this.get();
    
    return _$ce(2, 'blockquote', (
        _$ce(2, 'p', [
            _$ct('\n        React下，以下扩展点都对应组件的属性名，如：\n        '),
            _$ce(2, 'code', name, 0 /* UnknownChildren */),
            _$ct('\n        ->\n        '),
            _$ce(2, 'code', `slot${name.charAt(0).toUpperCase() + name.substring(1)}`, 0 /* UnknownChildren */)
        ], 4 /* HasNonKeyedChildren */)
    ), 2 /* HasVNodeChildren */);
};