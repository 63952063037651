export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            data: [
                {
                    a: '虚拟DOM',
                    b: '获取到了item.b',
                    c: {c1: 'item.c.c1'}
                }
            ]
        };
    }
}