import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Timeline, TimelineItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Timeline, {
        'children': [
            _$cc(TimelineItem, {
                'children': 'August'
            }),
            _$cc(TimelineItem, {
                'type': 'success',
                'size': 'small',
                'children': 'July'
            }),
            _$cc(TimelineItem, {
                'type': 'danger',
                'size': 'large',
                'children': 'June'
            }),
            _$cc(TimelineItem, {
                'type': 'warning',
                'size': 'mini',
                'children': 'May'
            })
        ]
    });
};