export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, Tree, TreeDataItem, TreeNode} from 'kpc';

interface Props {
    data: TreeDataItem<string | number>[]
    expandedKeys?: (string | number)[]
}

let uniqueId = 0;
export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    label: 'First floor-1',
                    children: [
                        {
                            label: 'Second floor-1.1',
                            children: [
                                {
                                    label: 'Third floor-1.1.1'
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'First floor-2',
                    key: '2',
                    children: [
                        {
                            label: 'Second floor-2.1',
                            children: [
                                {
                                    label: 'Third floor-2.1.1',
                                    key: '2-1' 
                                },
                                {
                                    label: 'Third floor-2.1.2'
                                }
                            ]
                        },
                        {
                            label: 'Second floor-2.2',
                            disabled: true,
                            children: [
                                {
                                    label: 'Third floor-2.2.1'
                                }
                            ]
                        }
                    ]
                }
            ],
            expandedKeys: ['2', '2-1'],
        };
    }

    appendNode(data: TreeDataItem<string | number>, node: TreeNode<string | number>) {
        const children = data.children || (data.children = []);
        children.push({
            label: 'Appended node',
            key: uniqueId++,
        });
        this.set('data', [...this.get('data')]);
        // expand the node
        (this.refs.tree as Tree).expand(node.key);
    }

    removeNode(data: TreeDataItem<string | number>, node: TreeNode<string | number>, index: number) {
        const children = node.parent ? node.parent.data.children! : this.get('data');
        children.splice(index, 1);
        this.set('data', [...this.get('data')]);
    }
}