import {
    createElementVNode as _$ce,
    className as _$cn,
    noop as _$no,
    createVNode as _$cv,
} from 'intact';
import {Transition} from 'intact';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';

var _$tmp0 = {
    'cx': '60',
    'cy': '60',
    'r': '57'
};
var _$tmp1 = {
    'viewBox': '0 0 120 120'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {size, center, className, overlay} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-spin`]: true,
        [`${k}-${size}`]: size !== 'default',
        [`${k}-center`]: !overlay && center,
        [`${k}-overlay`]: overlay,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    const canvas = (
        _$ce(2, 'div', (
            (
                (_$blocks['canvas'] = function($super) {
                    return (
                        _$ce(512, 'svg', (
                            _$ce(2, 'circle', null, 1 /* HasInvalidChildren */, _$cn(`${k}-spin-circle`), _$tmp0)
                        ), 2 /* HasVNodeChildren */, null, _$tmp1)
                    );
                }),
                (__$blocks['canvas'] = function($super, data) {
                    var block = $blocks['canvas'];
                    var callBlock = function() {
                        return _$blocks['canvas'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                }),
                __$blocks['canvas'](_$no)
            )
        ), 0 /* UnknownChildren */, _$cn(`${k}-spin-canvas`))
    );
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, (
        (overlay) ?
            _$ce(2, 'div', canvas, 0 /* UnknownChildren */, _$cn(`${k}-spin-wrapper`)) :
            canvas
    ));
};