import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '表头1'
};
var _$tmp1 = {
    'key': 'b',
    'title': '表头2'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': [
            {a: '第一行', b: '哈哈1'}, 
            {a: '第二行', b: '哈哈2'},
            {a: '第三行', b: '哈哈3'}
        ],
        'children': [
            _$cc(TableColumn, _$tmp0, 'a'),
            _$cc(TableColumn, _$tmp1, 'b')
        ],
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['tooltip'] = function($super, [data, index]) {
                        return (
                            _$ce(2, 'div', data.a, 0 /* UnknownChildren */)
                        );
                    }),
                    (__$blocks['tooltip'] = function($super, data) {
                        var block = $blocks['tooltip'];
                        var callBlock = function() {
                            return _$blocks['tooltip'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    });
};