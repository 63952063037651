import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Card, Divider} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Card, {
            'children': [
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */),
                _$cc(Divider, {
                    'children': 'Text'
                }),
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */),
                _$cc(Divider, {
                    'position': 'left',
                    'children': 'Text'
                }),
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */),
                _$cc(Divider, {
                    'position': 'right',
                    'children': 'Text'
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};