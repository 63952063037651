import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-left'
};
var _$tmp1 = {
    'className': 'k-icon-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ghost': true,
            'children': [
                _$cc(Icon, _$tmp0),
                _$ct('default')
            ]
        }),
        _$cc(Button, {
            'type': 'primary',
            'ghost': true,
            'children': [
                _$ct('primay'),
                _$cc(Icon, _$tmp1)
            ]
        }),
        _$cc(Button, {
            'type': 'warning',
            'ghost': true,
            'children': 'warning'
        }),
        _$cc(Button, {
            'type': 'danger',
            'ghost': true,
            'children': 'danger'
        }),
        _$cc(Button, {
            'type': 'success',
            'ghost': true,
            'children': 'success'
        }),
        _$cc(Button, {
            'color': 'rgb(183, 18, 193)',
            'ghost': true,
            'children': 'custom'
        }),
        _$cc(Button, {
            'type': 'none',
            'ghost': true,
            'children': 'none'
        }),
        _$cc(Button, {
            'type': 'primary',
            'ghost': true,
            'disabled': true,
            'children': 'primay'
        })
    ], 4 /* HasNonKeyedChildren */, 'wrapper');
};