import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Switch} from 'kpc';

var _$tmp0 = {
    'on': '开',
    'off': '关'
};
var _$tmp1 = {
    'on': '开',
    'off': '关'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Switch, _$tmp0),
        _$cc(Switch, {
            'value': true,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['on'] = function($super) {
                            return (
                                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-ios-checkmark-empty')
                            );
                        }),
                        (__$blocks['on'] = function($super, data) {
                            var block = $blocks['on'];
                            var callBlock = function() {
                                return _$blocks['on'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['off'] = function($super) {
                            return (
                                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-ios-close-empty')
                            );
                        }),
                        (__$blocks['off'] = function($super, data) {
                            var block = $blocks['off'];
                            var callBlock = function() {
                                return _$blocks['off'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$cc(Switch, _$tmp1),
        _$cc(Switch, {
            'value': true,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['on'] = function($super) {
                            return (
                                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-ios-checkmark-empty')
                            );
                        }),
                        (__$blocks['on'] = function($super, data) {
                            var block = $blocks['on'];
                            var callBlock = function() {
                                return _$blocks['on'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['off'] = function($super) {
                            return (
                                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-ios-close-empty')
                            );
                        }),
                        (__$blocks['off'] = function($super, data) {
                            var block = $blocks['off'];
                            var callBlock = function() {
                                return _$blocks['off'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */);
};