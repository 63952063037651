import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Carousel, CarouselItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Carousel, {
        'autoplay': this.get('time'),
        'children': [
            _$cc(CarouselItem, {
                'children': '1'
            }),
            _$cc(CarouselItem, {
                'children': '2'
            }),
            _$cc(CarouselItem, {
                'children': '3'
            }),
            _$cc(CarouselItem, {
                'children': '4'
            })
        ]
    });
};