import {
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Tip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ma(this.get('types'), function($value, $key) {
            return _$cc(Tip, {
                'key': $value,
                'type': $value,
                'closable': true,
                'children': $value
            }, $value);
        }, $this),
        _$cc(Tip, {
            'closable': true,
            'border': 'dashed',
            'children': '\n        custom closing content\n        ',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['close'] = function($super) {
                            return 'No longer show';
                        }),
                        (__$blocks['close'] = function($super, data) {
                            var block = $blocks['close'];
                            var callBlock = function() {
                                return _$blocks['close'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 0 /* UnknownChildren */);
};