export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
   day?: string | null
   days?: string[]
   day1?: string | null
}

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            day: null,
            days: [],
            day1: null
        } as Props;
    }
}