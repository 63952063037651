import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'name',
    'title': 'Name'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': this.get('data'),
        'rowKey': data => data.name,
        'load': this.loadData,
        'children': [
            _$cc(TableColumn, _$tmp0, 'name'),
            _$cc(TableColumn, {
                'key': 'size',
                'title': 'Size',
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['template'] = function($super, [data]) {
                                return [
                                    data.size,
                                    _$ct('MB\n        ')
                                ];
                            }),
                            (__$blocks['template'] = function($super, data) {
                                var block = $blocks['template'];
                                var callBlock = function() {
                                    return _$blocks['template'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, 'size')
        ]
    });
};