export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, TableGroupValue, TableColumnGroupItem} from 'kpc';

interface Props {
    data: DataItem[]
    group?: TableGroupValue
    statusGroup: TableColumnGroupItem[] 
    multipleData: DataItem[]
    multipleGroup?: TableGroupValue
    multipleStatusGroup: TableColumnGroupItem[]
}

type DataItem = {
    name: string
    status: string
}

const oData: DataItem[] = [
    {name: '主机1', status: 'active'},
    {name: '主机2', status: 'stopped'},
    {name: '主机3', status: 'active'},
];
export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: oData, 
            group: {status: ''},
            statusGroup: [
                {label: '全部', value: ''},
                {label: '运行中', value: 'active'},
                {label: '已关闭', value: 'stopped'}
            ],
            multipleData: oData,
            multipleGroup: {status: []},
            multipleStatusGroup: [
                {label: '运行中', value: 'active'},
                {label: '已关闭', value: 'stopped'}
            ]
        } as Props;
    }

    @bind
    _onChangeGroup(group?: TableGroupValue) {
        console.log(group);
        const data = oData.filter(item => {
            let matched = true;
            for (let key in group!) {
                const value = group![key];
                if (value && item[key as keyof DataItem] !== value) {
                    matched = false;
                    break;
                }
            }
            return matched;
        });

        this.set({data, group});
    }

    @bind
    _onChangeMultipleGroup(group?: TableGroupValue) {
        console.log(group);
        const data = oData.filter(item => {
            let matched = true;
            for (let key in group!) {
                const value = group![key];
                if (value.length && value.indexOf(item[key as keyof DataItem]) === -1) {
                    matched = false;
                    break;
                }
            }
            return matched;
        });

        this.set({multipleData: data, multipleGroup: group});
    }
}