import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Skeleton, SkeletonItem} from 'kpc';

var _$tmp0 = {
    'type': 'image'
};
var _$tmp1 = {
    'type': 'button',
    'style': 'height: 16px;'
};
var _$tmp2 = {
    'type': 'button',
    'style': 'height: 16px; margin-top: 8px;'
};
var _$tmp3 = {
    'type': 'button'
};
var _$tmp4 = {
    'style': 'display: flex; justify-content: space-between; width: 200px; margin-top: 20px;'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Skeleton, {
            'loading': this.get('showSkeleton'),
            'avator': true,
            'animated': true,
            'children': [
                _$cc(SkeletonItem, _$tmp0),
                _$ce(2, 'div', [
                    _$ce(2, 'div', [
                        _$cc(SkeletonItem, _$tmp1),
                        _$cc(SkeletonItem, _$tmp2)
                    ], 4 /* HasNonKeyedChildren */),
                    _$cc(SkeletonItem, _$tmp3)
                ], 4 /* HasNonKeyedChildren */, null, _$tmp4)
            ]
        })
    ), 2 /* HasVNodeChildren */);
};