import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    superCall as _$su,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {linkEvent} from 'intact';
import {DropdownMenu} from '../dropdown';
import {DatepickerCalendar} from './calendar';
import {DatepickerTime} from './time';
import {Icon} from '../icon';
import {Button} from '../button';
import {_$} from '../../i18n';
import {makePanelStyles} from './styles';
import {PanelTypes, PanelFlags} from './usePanel';
import {isFunction} from 'intact-shared';
import {Tabs, Tab} from '../tabs';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, type, range, multiple, shortcuts} = this.get();
    const {
        value: {value},
        onChangeDate,
        onConfirm,
        onChangeTime,
        onChangeDateForRange,
        getTimeValue,
    } = this.value;
    const { k } = this.config;
    const classNameObj = {
        [`${k}-datepicker-content`]: true,
        [className]: className,
        [makePanelStyles(k)]: true,
    };
    
    const {isDisabledTime, isDisabledConfirm} = this.disabled;
    const {startPanel, endPanel, getPanel, startRef, endRef} = this.panel;
    const generatePanel = (flag) => {
        const panel = getPanel(flag);
        const timeValue = getTimeValue(flag);
        return (
            _$ce(2, 'div', [
                (() => {
                    if (type === 'datetime') {
                        let dateString = '0000-00-00';
                        let timeString = '00:00:00';
                        if (timeValue) {
                            const valueString = this.formats.getShowString(timeValue).split(/\s+/);
                            dateString = valueString[0];
                            timeString = valueString[1];
                        }
                        return (
                            _$cc(Tabs, {
                                'type': 'card',
                                'value': panel.value,
                                'ev-$change:value': panel.set,
                                'children': [
                                    _$cc(Tab, {
                                        'value': PanelTypes.Date,
                                        'children': dateString
                                    }),
                                    _$cc(Tab, {
                                        'value': PanelTypes.Time,
                                        'disabled': !timeValue,
                                        'children': timeString
                                    })
                                ]
                            })
                        );
                    }
                })(),
                (panel.value === PanelTypes.Date) ?
                    _$cc(DatepickerCalendar, {
                        'value': value,
                        'ev-change': onChangeDate,
                        'type': type === 'datetime' ? 'date' : type,
                        'flag': flag,
                        'ref': flag === PanelFlags.Start ? startRef : endRef
                    }, null, flag === PanelFlags.Start ? startRef : endRef) :
                    _$cc(DatepickerTime, {
                        'value': timeValue,
                        'ev-$change:value': v => onChangeTime(v, flag),
                        'format': this.formats.getValueFormat(),
                        'flag': flag,
                        'isDisabledTime': isDisabledTime
                    })
            ], 0 /* UnknownChildren */, _$cn(`${k}-datepicker-calendar-wrapper`))
        );
    };
    
    return _$su.call($this, {
        'className': _$cn(`${k}-datepicker`)
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['base-menu'] = function($super) {
                    return (
                        _$cc(DropdownMenu, {
                            'className': _$cn(classNameObj),
                            'children': [
                                (shortcuts && shortcuts.length) ?
                                    _$ce(2, 'div', shortcuts.map($value => {
                                        const label = isFunction($value.label) ? $value.label() : $value.label;
                                        return _$ce(2, 'div', label, 0 /* UnknownChildren */, _$cn(`${k}-datepicker-shortcut c-ellipsis`), {
                                            'title': label,
                                            'ev-click': linkEvent($value, this.setByShortcut)
                                        })
                                    }), 0 /* UnknownChildren */, _$cn(`${k}-datepicker-shortcuts`)) :
                                    undefined,
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', [
                                        generatePanel(PanelFlags.Start),
                                        _$ct('\n                    '),
                                        range ? generatePanel(PanelFlags.End) : null
                                    ], 0 /* UnknownChildren */, _$cn(`${k}-datepicker-calendars`)),
                                    (type === 'datetime') ?
                                        _$ce(2, 'div', (
                                            _$cc(Button, {
                                                'type': 'primary',
                                                'size': 'small',
                                                'disabled': isDisabledConfirm(),
                                                'ev-click': onConfirm,
                                                'children': _$('确定')
                                            })
                                        ), 2 /* HasVNodeChildren */, _$cn(`${k}-datepicker-footer`)) :
                                        undefined
                                ], 0 /* UnknownChildren */, _$cn(`${k}-datepicker-wrapper`))
                            ]
                        })
                    );
                }),
                (__$blocks['base-menu'] = function($super, data) {
                    var block = $blocks['base-menu'];
                    var callBlock = function() {
                        return _$blocks['base-menu'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            (
                (_$blocks['suffix'] = function($super) {
                    return (
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-calendar`)
                        })
                    );
                }),
                (__$blocks['suffix'] = function($super, data) {
                    var block = $blocks['suffix'];
                    var callBlock = function() {
                        return _$blocks['suffix'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};