import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'footer不固定'
};
var _$tmp1 = {
    'key': 'a',
    'title': 'footer固定'
};
var _$tmp2 = {
    'key': 'a',
    'title': 'footer，header同时固定'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': [{a: 'footer不固定'}, {a: '下拉'}, {a: 'yeah!'}, {a: '你好'}, {a: '我很好'}],
            'children': (
                _$cc(TableColumn, _$tmp0, 'a')
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['footer'] = function($super) {
                            return (
                                _$ce(2, 'span', '自定义footer', 16 /* HasTextChildren */)
                            );
                        }),
                        (__$blocks['footer'] = function($super, data) {
                            var block = $blocks['footer'];
                            var callBlock = function() {
                                return _$blocks['footer'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$cc(Table, {
            'data': [{a: 'footer固定啦'}, {a: '下拉'}, {a: 'yeah!'}, {a: '你好'}, {a: '我很好'}],
            'fixFooter': true,
            'children': (
                _$cc(TableColumn, _$tmp1, 'a')
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['footer'] = function($super) {
                            return (
                                _$ce(2, 'span', '自定义footer', 16 /* HasTextChildren */)
                            );
                        }),
                        (__$blocks['footer'] = function($super, data) {
                            var block = $blocks['footer'];
                            var callBlock = function() {
                                return _$blocks['footer'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$cc(Table, {
            'data': [{a: 'footer固定啦'}, {a: '下拉'}, {a: 'yeah!'}, {a: '你好'}, {a: '我很好'}],
            'fixFooter': true,
            'fixHeader': true,
            'children': (
                _$cc(TableColumn, _$tmp2, 'a')
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['footer'] = function($super) {
                            return (
                                _$ce(2, 'span', '自定义footer', 16 /* HasTextChildren */)
                            );
                        }),
                        (__$blocks['footer'] = function($super, data) {
                            var block = $blocks['footer'];
                            var callBlock = function() {
                                return _$blocks['footer'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */, 'wrapper');
};