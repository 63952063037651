import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    className as _$cn,
} from 'intact';
import {Icon, Input, Copy} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Input, {
            'placeholder': 'Search icon',
            'clearable': true,
            'ev-$model:value': function($v) {
                $this.set('keywords', $v);
            },
            'value': $this.get('keywords')
        }),
        _$ce(2, 'div', (
            _$ma(this.filter(), function($value, $key) {
                return _$cc(Copy, {
                    'text': this.config.k + '-icon-' + $value[0],
                    'children': (
                        _$ce(2, 'div', [
                            _$cc(Icon, {
                                'className': _$cn(this.config.k + '-icon-' + $value[0]),
                                'size': 'large'
                            }),
                            _$ce(2, 'div', this.config.k + '-icon-' + $value[0], 0 /* UnknownChildren */)
                        ], 4 /* HasNonKeyedChildren */, 'icon')
                    )
                });
            }, $this)
        ), 4 /* HasNonKeyedChildren */, 'icons')
    ], 4 /* HasNonKeyedChildren */);
};