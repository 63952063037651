import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    className as _$cn,
    noop as _$no,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    map as _$ma,
} from 'intact';
import {getClassAndStyleForFixed} from './useFixedColumns';
import {Dropdown, DropdownMenu, DropdownItem} from '../dropdown';
import {Button} from '../button';
import {Icon} from '../icon';
import {Checkbox} from '../checkbox';
import {makeGroupMenuStyles} from './styles';
import {isStringOrNumber, get, isArray} from 'intact-shared';
import {context as GroupContext} from './useGroup';
import {context as SortableContext} from './useSortable';
import {linkEvent} from 'intact';
import {context as TableContext} from './useColumns';
import {context as ResizableContext} from './useResizable';
import {context as FixedColumnsContext} from './useFixedColumns';
import {stopPropagation} from '../utils';
import {Input} from '../input';
import {_$} from '../../i18n';
import {ignoreSortable} from './useSortable';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {title, fixed, group, multiple, key, sortable, cols, rows, hidden, hideGroupHeader} = this.get();
    const {
        onSelect, isChecked, keywords, filteredGroup,
        onShow, reset, confirm, dropdownRef,
        localGroupValue, isEmptyValue, checkAll,
    } = this.group;
    const { k } = this.config;
    
    return _$cc(TableContext.Consumer, {
        'children': checkType => {
            return _$cc(GroupContext.Consumer, {
                'children': ({group: currentGroup, onChange}) => {
                    return _$cc(SortableContext.Consumer, {
                        'children': ({sort, onChange: onChangeSort}) => {
                            return _$cc(ResizableContext.Consumer, {
                                'children': ({resizable, onStart}) => {
                                    return _$cc(FixedColumnsContext.Consumer, {
                                        'children': (offsetMap) => {
                                            const {className, style} = getClassAndStyleForFixed(this.get(), offsetMap[key], k, checkType);
                                            const classNameObj = {
                                                [`${k}-column-sortable`]: sortable,
                                                [`${k}-hidden`]: hidden,
                                                [className]: className,
                                            }
                                        
                                            const groupValue = currentGroup && currentGroup[key];
                                            const type = sort && sort.key === key && sort.type;
                                        
                                            let checked;
                                            return _$ce(2, 'th', [
                                                (resizable && this.$vNode.props.prevVNode) ?
                                                    _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-table-resize`), {
                                                        'ev-mousedown': e => {
                                                            e._vNode = this.$vNode;
                                                            onStart(e);
                                                        },
                                                        'ev-click': ignoreSortable
                                                    }) :
                                                    undefined,
                                                _$ce(2, 'div', [
                                                    _$ce(2, 'div', (
                                                        (
                                                            (_$blocks['title'] = function($super) {
                                                                return title;
                                                            }),
                                                            (__$blocks['title'] = function($super, data) {
                                                                var block = $blocks['title'];
                                                                var callBlock = function() {
                                                                    return _$blocks['title'].call($this, $super, data);
                                                                };
                                                                return block ?
                                                                    block.call($this, callBlock, data) :
                                                                    callBlock();
                                                            }),
                                                            __$blocks['title'](_$no)
                                                        )
                                                    ), 0 /* UnknownChildren */, _$cn(`${k}-table-title-text c-ellipsis`)),
                                                    (group) ?
                                                        _$cc(Dropdown, {
                                                            'position': {my: 'left top', at: 'left bottom+5', collision: 'flipfit'},
                                                            'key': 'dropdown',
                                                            'trigger': 'click',
                                                            'ev-show': () => onShow(groupValue),
                                                            'ref': dropdownRef,
                                                            'children': [
                                                                _$cc(Icon, {
                                                                    'className': _$cn(`${k}-icon-down-squared ${k}-table-group`),
                                                                    'ev-click': ignoreSortable,
                                                                    'hoverable': true,
                                                                    'color': isEmptyValue(groupValue) ? null : 'primary'
                                                                }),
                                                                _$cc(DropdownMenu, {
                                                                    'className': _$cn({[`${k}-table-group-dropdown`]: true, [makeGroupMenuStyles(k)]: true}),
                                                                    'children': [
                                                                        (!hideGroupHeader) ?
                                                                            _$ce(2, 'div', (
                                                                                _$cc(Input, {
                                                                                    'size': 'small',
                                                                                    'fluid': true,
                                                                                    'placeholder': _$('请输入关键字'),
                                                                                    'value': keywords.value,
                                                                                    'ev-$change:value': keywords.set,
                                                                                    'clearable': true,
                                                                                    '$blocks': function($blocks) {
                                                                                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                                                                        return (
                                                                                            (
                                                                                                (_$blocks['suffix'] = function($super) {
                                                                                                    return (
                                                                                                        _$cc(Icon, {
                                                                                                            'className': _$cn(`${k}-icon-search`)
                                                                                                        })
                                                                                                    );
                                                                                                }),
                                                                                                (__$blocks['suffix'] = function($super, data) {
                                                                                                    var block = $blocks['suffix'];
                                                                                                    var callBlock = function() {
                                                                                                        return _$blocks['suffix'].call($this, $super, data);
                                                                                                    };
                                                                                                    return block ?
                                                                                                        block.call($this, callBlock, data) :
                                                                                                        callBlock();
                                                                                                })
                                                                                            ),
                                                                                            __$blocks
                                                                                        );
                                                                                    }.call($this, _$em)
                                                                                })
                                                                            ), 2 /* HasVNodeChildren */, _$cn(`${k}-table-group-header`)) :
                                                                            undefined,
                                                                        _$ce(2, 'div', (
                                                                            _$ma(filteredGroup.value, function($value, $key) {
                                                                                return _$cc(DropdownItem, {
                                                                                    'ev-select': () => onSelect($value.value, onChange),
                                                                                    'hideOnSelect': !multiple,
                                                                                    'className': _$cn({[`${k}-active`]: (checked = isChecked($value.value))}),
                                                                                    'children': (
                                                                                        (multiple) ?
                                                                                            _$cc(Checkbox, {
                                                                                                'value': checked,
                                                                                                'children': $value.label
                                                                                            }) :
                                                                                            _$ce(2, 'span', $value.label, 0 /* UnknownChildren */)
                                                                                    )
                                                                                });
                                                                            }, $this)
                                                                        ), 4 /* HasNonKeyedChildren */, _$cn(`${k}-table-group-body`)),
                                                                        (multiple) ?
                                                                            _$ce(2, 'div', [
                                                                                _$cc(Button, {
                                                                                    'size': 'mini',
                                                                                    'type': 'link',
                                                                                    'ev-click': checkAll,
                                                                                    'children': _$('全选')
                                                                                }),
                                                                                _$cc(Button, {
                                                                                    'size': 'small',
                                                                                    'disabled': isEmptyValue(localGroupValue.value),
                                                                                    'ev-click': () => reset(onChange),
                                                                                    'children': _$('重置')
                                                                                }),
                                                                                _$cc(Button, {
                                                                                    'type': 'primary',
                                                                                    'size': 'small',
                                                                                    'ev-click': () => confirm(onChange),
                                                                                    'children': _$('确定')
                                                                                })
                                                                            ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-table-group-footer`)) :
                                                                            undefined
                                                                    ]
                                                                })
                                                            ]
                                                        }, 'dropdown', dropdownRef) :
                                                        undefined,
                                                    (sortable) ?
                                                        _$ce(2, 'div', [
                                                            _$cc(Icon, {
                                                                'className': _$cn(`${k}-icon-up-bold ${k}-asc`),
                                                                'size': 'mini'
                                                            }),
                                                            _$cc(Icon, {
                                                                'className': _$cn(`${k}-icon-down-bold ${k}-desc`),
                                                                'size': 'mini'
                                                            })
                                                        ], 4 /* HasNonKeyedChildren */, _$cn({[`${k}-column-sort`]: true, [`${k}-${type}`]: type})) :
                                                        undefined
                                                ], 0 /* UnknownChildren */, _$cn(`${k}-table-title`))
                                            ], 0 /* UnknownChildren */, _$cn(classNameObj), {
                                                'style': style,
                                                'title': isStringOrNumber(title) ? title : null,
                                                'ev-click': sortable ? linkEvent(key, onChangeSort) : null,
                                                'colspan': cols,
                                                'rowspan': rows
                                            }, null, this.elementRef)
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    });
};