import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Popover, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_popover = function(i) {_$refs['popover'] = i};
    
    return _$ce(2, 'div', (
        _$cc(Popover, {
            'title': '确定删除？',
            'ref': _$ref_popover,
            'children': (
                _$cc(Button, {
                    'children': '删除'
                })
            ),
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['footer'] = function($super) {
                            return [
                                _$cc(Button, {
                                    'size': 'small',
                                    'ev-click': this.cancel,
                                    'children': '取消'
                                }),
                                _$cc(Button, {
                                    'size': 'small',
                                    'ev-click': this.remove,
                                    'children': '永久删除'
                                }),
                                _$cc(Button, {
                                    'size': 'small',
                                    'type': 'primary',
                                    'ev-click': this.moveToTrash,
                                    'children': '移到回收站'
                                })
                            ];
                        }),
                        (__$blocks['footer'] = function($super, data) {
                            var block = $blocks['footer'];
                            var callBlock = function() {
                                return _$blocks['footer'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }, null, _$ref_popover)
    ), 2 /* HasVNodeChildren */);
};