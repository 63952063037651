import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    noop as _$no,
} from 'intact';
import {getRestProps} from '../utils';
import {CardColumn} from './column';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        children, className, title, type, size,
    } = this.get();
    const { k } = this.config;
    
    const hasHeader = $blocks.header || $blocks.title || title || $blocks.extra;
    let hasColumn = false;
    if (children && children.length) {
        for (let i = 0; i < children.length; i++) {
            const vNode = children[i];
            if (vNode && vNode.tag === CardColumn) {
                hasColumn = true;
                break;
            }
        }
    }
    const classNameObj = {
        [`${k}-card`]: true,
        [`${k}-shadow`]: type === 'shadow',
        [`${k}-border`]: type === 'border',
        [`${k}-none`]: type === 'none',
        [`${k}-no-header`]: !hasHeader,
        [`${k}-card-grid`]: hasColumn,
        [`${k}-${size}`]: size !== 'default',
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        (hasHeader) ?
            _$ce(2, 'div', (
                (
                    (_$blocks['header'] = function($super) {
                        return [
                            _$ce(2, 'div', (
                                (
                                    (_$blocks['title'] = function($super) {
                                        return title;
                                    }),
                                    (__$blocks['title'] = function($super, data) {
                                        var block = $blocks['title'];
                                        var callBlock = function() {
                                            return _$blocks['title'].call($this, $super, data);
                                        };
                                        return block ?
                                            block.call($this, callBlock, data) :
                                            callBlock();
                                    }),
                                    __$blocks['title'](_$no)
                                )
                            ), 0 /* UnknownChildren */, _$cn(`${k}-card-title`)),
                            ($blocks.extra) ?
                                _$ce(2, 'div', (
                                    (
                                        (_$blocks['extra'] = function($super) {
                                            return null;
                                        }),
                                        (__$blocks['extra'] = function($super, data) {
                                            var block = $blocks['extra'];
                                            var callBlock = function() {
                                                return _$blocks['extra'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        }),
                                        __$blocks['extra'](_$no)
                                    )
                                ), 0 /* UnknownChildren */, _$cn(`${k}-card-extra`)) :
                                undefined
                        ];
                    }),
                    (__$blocks['header'] = function($super, data) {
                        var block = $blocks['header'];
                        var callBlock = function() {
                            return _$blocks['header'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['header'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-card-header c-clearfix`)) :
            undefined,
        _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-card-body`))
    ]);
};