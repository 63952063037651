import {
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {getRestProps} from '../utils';
import {makeFormStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {children, className, layout, size} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-form`]: true,
        [className]: className,
        [`${k}-${layout}`]: layout,
        [`${k}-${size}`]: size !== 'default',
        [makeFormStyles(k)]: true,
    };
    
    return _$cv('form', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'ev-submit': this.onSubmit,
        'noValidate': true
    }, children);
};