import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Select, Option, Icon} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$ce(2, 'span', 'border:', 16 /* HasTextChildren */, 'label'),
            _$cc(Select, {
                'children': (
                    _$ma(['solid', 'dashed', 'dotted'], function($value, $key) {
                        return _$cc(Option, {
                            'value': $value,
                            'children': (
                                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'line', {
                                    'style': {borderBottom: `1px ${$value} #666`}
                                })
                            )
                        });
                    }, $this)
                ),
                'ev-$model:value': function($v) {
                    $this.set('type', $v);
                },
                'value': $this.get('type')
            }),
            _$ce(2, 'div', '\n            Rectangle\n        ', 16 /* HasTextChildren */, 'rect', {
                'style': {border: `1px ${this.get('type')} #b2b2b2`}
            })
        ], 4 /* HasNonKeyedChildren */, 'item'),
        _$ce(2, 'div', [
            _$ce(2, 'span', 'multiple:', 16 /* HasTextChildren */, 'label'),
            _$cc(Select, {
                'multiple': true,
                'children': (
                    _$ma(['ion-chatbubble', 'ion-person-stalker', 'ion-beer', 'ion-camera'], function($value, $key) {
                        return _$cc(Option, {
                            'value': $value,
                            'children': (
                                _$cc(Icon, {
                                    'className': _$cn($value)
                                })
                            )
                        });
                    }, $this)
                ),
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['value'] = function($super, [value, label]) {
                                return [
                                    _$cc(Icon, {
                                        'className': _$cn(value),
                                        'style': 'vertical-align: middle;'
                                    }),
                                    _$ce(2, 'span', value, 0 /* UnknownChildren */, 'middle')
                                ];
                            }),
                            (__$blocks['value'] = function($super, data) {
                                var block = $blocks['value'];
                                var callBlock = function() {
                                    return _$blocks['value'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em),
                'ev-$model:value': function($v) {
                    $this.set('icons', $v);
                },
                'value': $this.get('icons')
            })
        ], 4 /* HasNonKeyedChildren */, 'item'),
        _$ce(2, 'div', [
            _$ce(2, 'span', 'multiple values:', 16 /* HasTextChildren */, 'label'),
            _$cc(Select, {
                'multiple': true,
                'children': (
                    _$ma(['ion-chatbubble', 'ion-person-stalker', 'ion-beer', 'ion-camera'], function($value, $key) {
                        return _$cc(Option, {
                            'value': $value,
                            'children': (
                                _$cc(Icon, {
                                    'className': _$cn($value)
                                })
                            )
                        });
                    }, $this)
                ),
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['values'] = function($super, [values, labels]) {
                                return (
                                    _$ce(2, 'div', [
                                        _$ct('已选择'),
                                        values.length,
                                        _$ct('项 / 总共4项')
                                    ], 0 /* UnknownChildren */, 'k-value')
                                );
                            }),
                            (__$blocks['values'] = function($super, data) {
                                var block = $blocks['values'];
                                var callBlock = function() {
                                    return _$blocks['values'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em),
                'ev-$model:value': function($v) {
                    $this.set('icons1', $v);
                },
                'value': $this.get('icons1')
            })
        ], 4 /* HasNonKeyedChildren */, 'item')
    ], 4 /* HasNonKeyedChildren */);
};