export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import type {DialogProps} from 'kpc';

interface Props {
    sizes: NonNullable<DialogProps['size']>[]
    showlarge?: boolean 
    showdefault?: boolean
    showsmall?: boolean
    showmini?: boolean
    customSize?: boolean
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            sizes: ['large', 'default', 'small', 'mini'],
            showlarge: false,
            showdefault: false,
            showsmall: false,
            showmini: false,
            customSize: false
        } as Props;
    }
}