import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tree, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tree, {
            'defaultExpandAll': true,
            'data': this.get('data')
        }),
        _$cc(Tree, {
            'defaultExpandAll': true,
            'data': this.get('delayData')
        }),
        _$cc(Button, {
            'ev-click': this.load,
            'children': '加载数据'
        })
    ], 4 /* HasNonKeyedChildren */, 'wrapper');
};