import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    noop as _$no,
    createVNode as _$cv,
} from 'intact';
import {Transition, TransitionGroup, linkEvent} from 'intact';
import {Checkbox} from '../checkbox';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {Icon} from '../icon';
import {Mode} from './useDraggable';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {className, checkbox, showLine, selectable, draggable} = this.get();
    const expandedKeys = this.expanded.get();
    const toggleExpanded = this.expanded.toggle;
    const toggleChecked = this.checked.toggle;
    const selectedKeys = this.selected.get();
    const toggleSelected = this.selected.toggle;
    const {onDragStart, onDragOver, onDragEnd, draggingKey, overKey, mode} = this.draggable;
    const { k } = this.config;
    
    const treeNodes = (nodes) => {
        return nodes.map((node, index) => {
            if (!node.filter) return; 
    
            const key = node.key;
            const expanded = expandedKeys.has(key);
            const data = node.data;
            const disabled = data.disabled;
            const selected = selectable && selectedKeys.has(key);
            const classNameObj = {
                [`${k}-tree-node`]: true,
                [`${k}-expanded`]: expanded,
                [`${k}-disabled`]: disabled,
                [`${k}-selected`]: selected,
                [`${k}-dragging`]: key === draggingKey.value,
            };
            const labelClassNameObj = {
                [`${k}-tree-label`]: true,
                [`${k}-tree-leaf`]: !node.children,
            };
            if (key === overKey.value) {
                switch (mode.value) {
                    case Mode.Before:
                        labelClassNameObj[[`${k}-before`]] = true;
                        break;
                    case Mode.After:
                        labelClassNameObj[[`${k}-after`]] = true;
                        break;
                    case Mode.Inner:
                        labelClassNameObj[[`${k}-inner`]] = true;
                        break;
                }
            }
    
            return (
                _$ce(2, 'div', [
                    _$ce(2, 'div', [
                        (node.children && node.loaded !== false) ?
                            _$cc(Icon, {
                                'className': _$cn(`${k}-tree-icon ion-android-arrow-dropdown`),
                                'hoverable': true,
                                'ev-click': (e) => {
                                    // it may be in dropdownMenu, e.g. TreeSelect
                                    e._ignore = true;
                                    toggleExpanded(node);
                                }
                            }) :
                            undefined,
                        (node.loaded === false) ?
                            _$cc(Icon, {
                                'className': _$cn(`${k}-tree-icon ion-load-c`),
                                'rotate': true
                            }) :
                            undefined,
                        (checkbox) ?
                            _$cc(Checkbox, {
                                'value': node.checked,
                                'disabled': disabled,
                                'indeterminate': node.indeterminate,
                                'ev-$change:value': () => toggleChecked(node),
                                'className': _$cn(`${k}-tree-checkbox`)
                            }) :
                            undefined,
                        _$ce(2, 'div', (
                            (
                                (_$blocks['label'] = function($super) {
                                    return data.label;
                                }),
                                (__$blocks['label'] = function($super, data) {
                                    var block = $blocks['label'];
                                    var callBlock = function() {
                                        return _$blocks['label'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                }),
                                __$blocks['label'](_$no, [data, node, index])
                            )
                        ), 0 /* UnknownChildren */, _$cn(`${k}-tree-text c-ellipsis`), {
                            'ev-click': linkEvent(node, this.onClick)
                        })
                    ], 0 /* UnknownChildren */, _$cn(labelClassNameObj)),
                    _$cc(Transition, {
                        ...this.transition,
                        'children': (
                            (expanded && node.children) ?
                                _$ce(2, 'div', (
                                    _$cc(TransitionGroup, {
                                        ...this.transition,
                                        'children': treeNodes(node.children)
                                    })
                                ), 2 /* HasVNodeChildren */, _$cn(`${k}-tree`)) :
                                undefined
                        )
                    })
                ], 4 /* HasNonKeyedChildren */, _$cn(classNameObj), {
                    'draggable': draggable ? true : null,
                    'ev-dragstart': draggable && linkEvent(node, onDragStart),
                    'ev-dragover': draggable && linkEvent(node, onDragOver),
                    'ev-dragend': draggable && onDragEnd
                }, key)
            );
        });
    };
    
    const classNameObj = {
        [`${k}-tree`]: true,
        [`${k}-line`]: showLine,
        [`${k}-selectable`]: selectable,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, (
        _$cc(TransitionGroup, {
            ...this.transition,
            'children': treeNodes(this.nodes.getNodes())
        })
    ));
};