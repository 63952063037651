import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Drawer, Button, ButtonGroup, Select, Option} from 'kpc';

var _$tmp0 = {
    'style': 'padding: 16px; text-align: center;'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.set.bind(this, 'show', true),
            'type': 'primary',
            'children': 'Show Drawer'
        }),
        _$cc(Drawer, {
            'title': 'Drawer Title',
            'placement': 'top',
            'size': 'small',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['content'] = function($super) {
                            return (
                                _$ce(2, 'div', '\n                Hello King-Design!\n            ', 16 /* HasTextChildren */, null, _$tmp0)
                            );
                        }),
                        (__$blocks['content'] = function($super, data) {
                            var block = $blocks['content'];
                            var callBlock = function() {
                                return _$blocks['content'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:value': function($v) {
                $this.set('show', $v);
            },
            'value': $this.get('show')
        })
    ], 4 /* HasNonKeyedChildren */);
};