import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createVNode as _$cv,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {directClone, Transition} from 'intact';
import {Dropdown, DropdownItem} from '../dropdown';
import {Tooltip} from '../tooltip';
import {Icon} from '../icon';
import {Menu} from './menu';
import {Ellipsis} from '../ellipsis';
import {getRestProps, expandAnimationCallbacks} from '../utils';
import {isNullOrUndefined} from 'intact-shared';
import {makeItemStyles, makeTitleStyles, makeNestedMenuStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const rootMenu = this.rootMenu;
    const {theme, type, dot: rootDot, size} = rootMenu.get();
    const {children, key, className, disabled, dot} = this.get();
    const {isHighlighted, isSelected} = rootMenu.highlight;
    const {isExpanded: isExpandedKey} = this.expanded;
    const isExpanded = isExpandedKey(key);
    const {
        isTopItem, subMenuVNode, isDropdownMenu,
        tooltipContents, iconVNode, titleVNodes,
        position, isCollapse,
    } = this.dropdown();
    
    const { k } = this.config;
    const classNameObj = {
        [`${k}-menu-item`]: true,
        [className]: className,
        [`${k}-expanded`]: isExpanded, 
        [`${k}-disabled`]: disabled,
        [`${k}-active`]:  isSelected(key), 
        [`${k}-highlighted`]: isHighlighted(key),
        [makeItemStyles(k)]: true,
    };
    
    const showDot = isTopItem && !iconVNode && isNullOrUndefined(dot) ? rootDot : dot;
    const title = (children) => (
        _$ce(2, 'div', [
            (showDot) ?
                _$cc(Icon, {
                    'className': _$cn(`${k}-menu-dot ion-record`)
                }) :
                undefined,
            _$ce(2, 'div', (
                _$cc(Ellipsis, {
                    'position': 'bottom',
                    'children': children
                })
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-menu-name`)),
            (subMenuVNode) ?
                _$cc(Icon, {
                    'className': _$cn(`${k}-menu-item-arrow ion-arrow-down-b`)
                }) :
                undefined
        ], 0 /* UnknownChildren */, _$cn({[`${k}-menu-item-title`]: true, [makeTitleStyles(k)]: true}), {
            'ev-click': this.onClick.bind(this, subMenuVNode)
        })
    );
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, (
        (!isDropdownMenu) ?
            [
                title(titleVNodes),
                _$cc(Transition, {
                    'show': isExpanded,
                    'key': 'subMenu',
                    ...expandAnimationCallbacks,
                    'children': (() => {
                        if (subMenuVNode) {
                            const paddingLeft = this.parentMenu.get('_paddingLeft');
                            const [_classname, _paddingLeft] = makeNestedMenuStyles(k, showDot || !!iconVNode, paddingLeft, size);
                            const className = _$cn({
                                [_classname]: true,
                                [subMenuVNode.className]: subMenuVNode.className,
                                [`${k}-sub-menu`]: true,
                            });
                            const clonedVNode = directClone(subMenuVNode);
                            clonedVNode.props = { ...clonedVNode.props, className, _paddingLeft };
                            clonedVNode.className = className;
                    
                            return clonedVNode;
                        }
                    })()
                }, 'subMenu')
            ] :
            !subMenuVNode ?
                (
                    (isTopItem && isCollapse) ?
                        _$cc(Tooltip, {
                            'position': rootMenu.get('type') !== 'horizontal' ? 'right' : 'bottom',
                            'theme': rootMenu.get('theme') === 'dark' ? 'dark' : 'light',
                            'children': title(iconVNode),
                            '$blocks': function($blocks) {
                                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                return (
                                    (
                                        (_$blocks['content'] = function($super) {
                                            return tooltipContents;
                                        }),
                                        (__$blocks['content'] = function($super, data) {
                                            var block = $blocks['content'];
                                            var callBlock = function() {
                                                return _$blocks['content'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        })
                                    ),
                                    __$blocks
                                );
                            }.call($this, _$em)
                        }) :
                        title(titleVNodes)
                ) :
                [
                    _$cc(Dropdown, {
                        'position': position,
                        'children': [
                            title(isTopItem && isCollapse ? iconVNode : titleVNodes),
                            subMenuVNode
                        ]
                    }),
                    _$cc(Transition, {
                        'show': isExpanded,
                        'key': 'subMenu',
                        ...expandAnimationCallbacks
                    }, 'subMenu')
                ]
    ));
};