import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Progress} from 'kpc';

var _$tmp0 = {
    'percent': '50'
};
var _$tmp1 = {
    'percent': '50',
    'status': 'normal'
};
var _$tmp2 = {
    'percent': '50',
    'status': 'warning'
};
var _$tmp3 = {
    'percent': '50',
    'status': 'error'
};
var _$tmp4 = {
    'percent': '50',
    'status': 'success'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Progress, _$tmp0),
        _$cc(Progress, _$tmp1),
        _$cc(Progress, _$tmp2),
        _$cc(Progress, _$tmp3),
        _$cc(Progress, _$tmp4)
    ], 4 /* HasNonKeyedChildren */);
};