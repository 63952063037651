import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Split} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Split, {
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['first'] = function($super) {
                            return (
                                _$ce(2, 'div', 'left', 16 /* HasTextChildren */, 'panel')
                            );
                        }),
                        (__$blocks['first'] = function($super, data) {
                            var block = $blocks['first'];
                            var callBlock = function() {
                                return _$blocks['first'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['last'] = function($super) {
                            return (
                                _$ce(2, 'div', 'right', 16 /* HasTextChildren */, 'panel')
                            );
                        }),
                        (__$blocks['last'] = function($super, data) {
                            var block = $blocks['last'];
                            var callBlock = function() {
                                return _$blocks['last'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$cc(Split, {
            'firstSize': '80px',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['first'] = function($super) {
                            return (
                                _$ce(2, 'div', 'left', 16 /* HasTextChildren */, 'panel')
                            );
                        }),
                        (__$blocks['first'] = function($super, data) {
                            var block = $blocks['first'];
                            var callBlock = function() {
                                return _$blocks['first'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['last'] = function($super) {
                            return (
                                _$ce(2, 'div', 'right', 16 /* HasTextChildren */, 'panel')
                            );
                        }),
                        (__$blocks['last'] = function($super, data) {
                            var block = $blocks['last'];
                            var callBlock = function() {
                                return _$blocks['last'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$cc(Split, {
            'lastSize': '80px',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['first'] = function($super) {
                            return (
                                _$ce(2, 'div', 'left', 16 /* HasTextChildren */, 'panel')
                            );
                        }),
                        (__$blocks['first'] = function($super, data) {
                            var block = $blocks['first'];
                            var callBlock = function() {
                                return _$blocks['first'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['last'] = function($super) {
                            return (
                                _$ce(2, 'div', 'right', 16 /* HasTextChildren */, 'panel')
                            );
                        }),
                        (__$blocks['last'] = function($super, data) {
                            var block = $blocks['last'];
                            var callBlock = function() {
                                return _$blocks['last'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        })
    ], 4 /* HasNonKeyedChildren */);
};