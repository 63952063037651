import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Title'
};
var _$tmp1 = {
    'key': 'a',
    'title': 'Title'
};
var _$tmp2 = {
    'key': 'a',
    'title': 'Title'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___checkbox = function(i) {_$refs['__checkbox'] = i};
    var _$ref___radio = function(i) {_$refs['__radio'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data1'),
            'ref': _$ref___checkbox,
            'children': (
                _$cc(TableColumn, _$tmp0, 'a')
            )
        }, null, _$ref___checkbox),
        _$cc(Table, {
            'data': this.get('data2'),
            'checkType': 'radio',
            'ref': _$ref___radio,
            'children': (
                _$cc(TableColumn, _$tmp1, 'a')
            )
        }, null, _$ref___radio),
        _$cc(Table, {
            'data': this.get('data3'),
            'checkType': 'none',
            'children': (
                _$cc(TableColumn, _$tmp2, 'a')
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};