import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
} from 'intact';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {Wave} from '../wave';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        className, disabled, falseValue, 
        children, value, trueValue, style,
        indeterminate, title, ...rest
    } = this.get();
    
    const isChecked = this.isChecked();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-checkbox`]: true,
        [`${k}-disabled`]: disabled,
        [`${k}-checked`]: isChecked,
        [`${k}-indeterminate`]: indeterminate,
        [className]: className,
        [makeStyles(k)]: true
    };
    
    const events = [
        'ev-click', 'ev-change',
        'ev-mouseenter', 'ev-mouseleave'
    ].reduce((memo, name) => {
        if (rest[name]) {
            if (name === 'ev-click' || name === 'ev-change') {
                // do not bind click to label, otherwise it will trigger click event twice
                // don't bind change to input, use click instead of, #448
                // evClick = rest[name];
            } else {
                memo[name] = rest[name];
            }
            delete rest[name];
        }
        return memo;
    }, {});
    
    return _$cv('label', {
        'className': _$cn(classNameObj),
        'style': style,
        'tabindex': disabled ? null : "0",
        'title': title,
        'ev-click': this.onClick,
        'ev-keypress': this.onKeypress,
        ...events
    }, [
        _$cc(Wave, {
            'disabled': disabled,
            'inset': '-2px',
            'children': (
                _$ce(2, 'span', (
                    _$cv('input', {
                        'type': 'checkbox',
                        'checked': isChecked,
                        'value': trueValue,
                        'trueValue': trueValue,
                        'falseValue': falseValue,
                        'disabled': disabled,
                        'tabindex': '-1',
                        'ref': this.inputRef,
                        ...getRestProps(this, rest)
                    })
                ), 2 /* HasVNodeChildren */, _$cn(`${k}-checkbox-wrapper`))
            )
        }),
        (children) ?
            _$ce(2, 'span', children, 0 /* UnknownChildren */, _$cn(`${k}-checkbox-text`)) :
            undefined
    ]);
};