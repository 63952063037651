import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const Demo = this.get('Demo');
    
    return _$ce(2, 'div', (
        _$cc(Demo.default)
    ), 2 /* HasVNodeChildren */, _$cn(`example ${Demo.data.index}`));
};