import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createElementVNode as _$ce,
    superCall as _$su,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    map as _$ma,
} from 'intact';
import {Dropdown, DropdownMenu, DropdownItem} from '../dropdown';
import {Icon} from '../icon';
import {_$} from '../../i18n';
import {makeMenuStyles, makeFilterMenuStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {data, trigger, filterable, fields, multiple, show} = this.get();
    const { k } = this.config;
    const baseMenuStyles = makeMenuStyles(k);
    const classNameObj = {
        [`${k}-cascader-menu`]: true,
        [makeMenuStyles(k)]: true,
    };
    const getField = this.fields;
    
    const {values, isShowed, isSelected, onSelect, toggleShowedValue} = this.value;
    const Options = (data, level, loaded, parentSelected) => {
        if (!data.length) {
            if (!loaded) {
                return _$cc(Icon, {
                    'className': _$cn(`ion-load-c ${k}-cascader-loading`),
                    'rotate': true
                })
            } else {
                return _$ce(2, 'div', _$('无数据'), 0 /* UnknownChildren */, _$cn(`${k}-cascader-empty`))
            }
        }
    
        return data.map((item, index) => {
            const value = getField(item, 'value');
            const showed = show && isShowed(value, level);
            const selected = parentSelected && isSelected(value, level);
            const children = getField(item, 'children');
    
            const Item = () => {
                return (
                    _$cc(DropdownItem, {
                        'disabled': getField(item, 'disabled'),
                        'className': _$cn({
                            [`${k}-cascader-option`]: true,
                            [`${k}-active`]: showed,
                            [`${k}-selected`]: selected,
                        }),
                        'ev-select': onSelect.bind(null, value, level),
                        'hideOnSelect': !multiple,
                        'children': [
                            getField(item, 'label'),
                            (children) ?
                                _$cc(Icon, {
                                    'className': _$cn(`${k}-cascader-arrow ${k}-icon-right`)
                                }) :
                                undefined
                        ]
                    })
                );
            };
    
            return (
                children ?
                    _$cc(Dropdown, {
                        'position': this.positionObj,
                        'of': 'parent',
                        'disabled': getField(item, 'disabled'),
                        'trigger': trigger,
                        'value': showed,
                        'ev-$change:value': toggleShowedValue.bind(null, value, level),
                        'ev-show': this.load.bind(null, item),
                        'children': [
                            Item(),
                            _$cc(DropdownMenu, {
                                'className': _$cn(classNameObj),
                                'children': showed ? Options(children, level + 1, item.loaded, selected) : null
                            })
                        ]
                    }) :
                    Item()
            );
        });
    }
    
    const {filter, keywords: {value: keywords}, selectByFilter, isSelectedItem} = this.filterable;
    return _$su.call($this, {
        'className': _$cn(`${k}-cascader`)
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['base-menu'] = function($super) {
                    return (
                        (!filterable || !keywords) ?
                            _$cc(DropdownMenu, {
                                'className': _$cn(classNameObj),
                                'key': 'common',
                                'children': Options(data, 0, true, true)
                            }, 'common') :
                            _$cc(DropdownMenu, {
                                'key': 'filter',
                                'className': _$cn({
                                    [`${k}-cascader-filter`]: true,
                                    [baseMenuStyles]: true,
                                    [makeFilterMenuStyles(k)]: true,
                                }),
                                'children': (() => {
                                    const items = filter();
                                    if (!items.length) return _$ce(2, 'div', _$('无匹配数据'), 0 /* UnknownChildren */, _$cn(`${k}-cascader-empty`))
                                
                                    return _$ma(items, function($value, $key) {
                                        return _$cc(DropdownItem, {
                                            'ev-select': selectByFilter.bind(null, $value),
                                            'disabled': $value.disabled,
                                            'hideOnSelect': !multiple,
                                            'className': _$cn({[`${k}-selected`]: isSelectedItem($value)}),
                                            'children': (() => {
                                                // highlight keywords
                                                const label = $value.map(item => getField(item, 'label')).join(' / ');
                                                const labels = label.split(keywords);
                                                const length = labels.length; 
                                                return labels.map((item, index) => {
                                                    const ret = [item];
                                                    if (index !== length - 1) {
                                                        ret.push(_$ce(2, 'em', keywords, 0 /* UnknownChildren */));
                                                    }
                                                    return ret;
                                                });
                                            })()
                                        });
                                    }, $this)
                                })()
                            }, 'filter')
                    );
                }),
                (__$blocks['base-menu'] = function($super, data) {
                    var block = $blocks['base-menu'];
                    var callBlock = function() {
                        return _$blocks['base-menu'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};