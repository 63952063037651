import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';
import {ThemeFrame} from '../../../components/ThemeFrame';
import cp_bg1 from '../../../imgs/cp_bg1.png';
import cp_bg2 from '../../../imgs/cp_bg2.png';
import cp_bg3 from '../../../imgs/cp_bg3.png';
import cp_logo from '../../../imgs/cp_logo.png';
import cp_desc from '../../../imgs/cp_desc.png';
import {Icon} from 'kpc';

var _$tmp0 = {
    'href': 'https://www.w3.org/TR/WCAG22/'
};
var _$tmp1 = {
    'className': 'icon ion-checkmark-round'
};
var _$tmp2 = {
    'className': 'icon ion-ios-moon'
};
var _$tmp3 = {
    'className': 'icon ion-ios-sunny'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {colorList, curColor, iframeWidth, iframeHeight, 
        iframeScale, curThemeType} = this.get();
    
    const getTextStyle = (showColor, color) => {
        if(!showColor) return {};
        if(color.includes('linear-gradient')) {
            return {
                backgroundImage: color,
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
            };
        } else {
            return {color};
        }
    };
    
    const getColorItemStyle = (value) => {
        // Get first value of color if it is gradient.
        const shadowColor = value.color.match(/#\w{6}/)[0];
        
        return {
            background: value.color,
            boxShadow: curColor == value.name ? `0px 0px 20px ${shadowColor}` : ''
        }
    };
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$ce(2, 'div', [
                _$ce(2, 'div', (
                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                        'src': cp_logo,
                        'width': '382'
                    })
                ), 2 /* HasVNodeChildren */, 'cp-logo'),
                _$ce(2, 'div', (
                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                        'src': cp_desc,
                        'width': '163'
                    })
                ), 2 /* HasVNodeChildren */, 'cp-desc-box'),
                _$ce(2, 'p', [
                    _$ct('KD设计系统置入了'),
                    _$ce(2, 'strong', '实时颜色算法', 16 /* HasTextChildren */),
                    _$ct('，并提供多元化的主题配色板。')
                ], 4 /* HasNonKeyedChildren */, 'cp-desc web-info'),
                _$ce(2, 'p', [
                    _$ce(2, 'p', [
                        _$ct('KD设计系统置入了'),
                        _$ce(2, 'strong', '实时颜色算法', 16 /* HasTextChildren */),
                        _$ct('，')
                    ], 4 /* HasNonKeyedChildren */),
                    _$ce(2, 'p', '并提供多元化的主题配色板。', 16 /* HasTextChildren */)
                ], 4 /* HasNonKeyedChildren */, 'cp-desc h5-info'),
                _$ce(2, 'div', (
                    _$ce(2, 'div', [
                        _$ce(2, 'span', '符合', 16 /* HasTextChildren */),
                        _$ce(2, 'a', 'WCAG2.2', 16 /* HasTextChildren */, null, _$tmp0),
                        _$ce(2, 'span', '国际可访问性标准', 16 /* HasTextChildren */)
                    ], 4 /* HasNonKeyedChildren */)
                ), 2 /* HasVNodeChildren */, 'info-box'),
                _$ce(2, 'div', (
                    _$ce(2, 'div', [
                        _$ce(2, 'div', (
                            _$ma(colorList, function($value, $key) {
                                return _$ce(2, 'div', [
                                    _$ce(2, 'div', (
                                        (curColor == $value.name) ?
                                            _$cc(Icon, _$tmp1) :
                                            undefined
                                    ), 0 /* UnknownChildren */, null, {
                                        'ev-click': this.handleColorClick.bind(this, $value),
                                        'style': getColorItemStyle($value)
                                    }),
                                    _$ce(2, 'div', $value.name, 0 /* UnknownChildren */, null, {
                                        'style': getTextStyle(curColor == $value.name, $value.color)
                                    })
                                ], 4 /* HasNonKeyedChildren */, 'color-item');
                            }, $this)
                        ), 4 /* HasNonKeyedChildren */, 'color-item-box'),
                        _$ce(2, 'div', (
                            _$ce(2, 'div', [
                                _$ce(2, 'div', (
                                    _$cc(Icon, _$tmp2)
                                ), 2 /* HasVNodeChildren */, _$cn({active: curThemeType == 'dark'}), {
                                    'ev-click': this.handleThemeTypeChange.bind(this, 'dark')
                                }),
                                _$ce(2, 'div', (
                                    _$cc(Icon, _$tmp3)
                                ), 2 /* HasVNodeChildren */, _$cn({active: curThemeType == 'light'}), {
                                    'ev-click': this.handleThemeTypeChange.bind(this, 'light')
                                })
                            ], 4 /* HasNonKeyedChildren */, 'theme-type-switch')
                        ), 2 /* HasVNodeChildren */, 'color-swtich-box')
                    ], 4 /* HasNonKeyedChildren */, 'color-wrapper', {
                        'style': {background: curThemeType == 'dark' ? '#000000' : '#F5F6FF'}
                    })
                ), 2 /* HasVNodeChildren */, 'color-box')
            ], 4 /* HasNonKeyedChildren */),
            _$ce(2, 'div', (
                _$ce(2, 'div', [
                    _$ce(2, 'div', [
                        _$ce(2, 'div'),
                        _$ce(2, 'div'),
                        _$ce(2, 'div')
                    ], 4 /* HasNonKeyedChildren */),
                    _$ce(2, 'div', (
                        _$cc(ThemeFrame, {
                            'style': {transform: `scale(${iframeScale})`},
                            'href': './iframe/colorProcess',
                            'ref': this.iframeBoxRef,
                            'width': iframeWidth,
                            'height': iframeHeight
                        }, null, this.iframeBoxRef)
                    ), 2 /* HasVNodeChildren */, null, {
                        'style': {height: `${iframeHeight * iframeScale - 1}px`}
                    })
                ], 4 /* HasNonKeyedChildren */, 'cp-box', null, null, this.cpBoxRef)
            ), 2 /* HasVNodeChildren */)
        ], 4 /* HasNonKeyedChildren */, 'cp-content'),
        _$ce(2, 'div', [
            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                'src': cp_bg1
            }),
            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                'src': cp_bg2
            }),
            _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, {
                'src': cp_bg3
            })
        ], 4 /* HasNonKeyedChildren */, 'bg-layer')
    ], 4 /* HasNonKeyedChildren */, _$cn(makeStyles(colorList)));
};