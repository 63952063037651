import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Menu, MenuItem, Switch, ButtonGroup, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-flag'
};
var _$tmp1 = {
    'className': 'ion-heart'
};
var _$tmp2 = {
    'className': 'ion-gear-b'
};
var _$tmp3 = {
    'className': 'ion-star'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Switch, {
            'on': '收起',
            'off': '展开',
            'width': '60',
            'trueValue': true,
            'falseValue': false,
            'ev-$model:value': function($v) {
                $this.set('collapse', $v);
            },
            'value': $this.get('collapse')
        }),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'value': 'light',
                    'children': 'light'
                }),
                _$cc(Button, {
                    'value': 'dark',
                    'children': 'dark'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('theme', $v);
            },
            'value': $this.get('theme')
        }),
        _$cc(Switch, {
            'on': 'horizontal',
            'off': 'vertical',
            'width': '100',
            'trueValue': 'horizontal',
            'falseValue': 'vertical',
            'ev-$model:value': function($v) {
                $this.set('type', $v);
            },
            'value': $this.get('type')
        }),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'value': 'large',
                    'children': 'large'
                }),
                _$cc(Button, {
                    'value': 'default',
                    'children': 'default'
                }),
                _$cc(Button, {
                    'value': 'small',
                    'children': 'small'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('size', $v);
            },
            'value': $this.get('size')
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Menu, {
            'collapse': this.get('collapse'),
            'theme': this.get('theme'),
            'size': this.get('size'),
            'type': this.get('type'),
            'children': [
                _$cc(MenuItem, {
                    'key': '1',
                    'children': [
                        _$cc(Icon, _$tmp0),
                        _$ct('menu 1')
                    ]
                }, '1'),
                _$cc(MenuItem, {
                    'key': '2',
                    'disabled': true,
                    'dot': true,
                    'children': 'menu 2'
                }, '2'),
                _$cc(MenuItem, {
                    'key': '3',
                    'children': [
                        _$cc(Icon, _$tmp1),
                        _$ct('menu 3\n            '),
                        _$cc(Menu, {
                            'children': [
                                _$cc(MenuItem, {
                                    'key': '3-1',
                                    'children': 'sub menu 1'
                                }, '3-1'),
                                _$cc(MenuItem, {
                                    'key': '3-2',
                                    'children': 'sub menu 2'
                                }, '3-2'),
                                _$cc(MenuItem, {
                                    'key': '3-3',
                                    'disabled': true,
                                    'children': 'sub menu 3'
                                }, '3-3'),
                                _$cc(MenuItem, {
                                    'key': '3-4',
                                    'children': [
                                        _$ct('\n                    sub menu 4\n                    '),
                                        _$cc(Menu, {
                                            'children': [
                                                _$cc(MenuItem, {
                                                    'key': '3-4-1',
                                                    'children': 'option 1'
                                                }, '3-4-1'),
                                                _$cc(MenuItem, {
                                                    'key': '3-4-2',
                                                    'children': 'option 2'
                                                }, '3-4-2')
                                            ]
                                        })
                                    ]
                                }, '3-4')
                            ]
                        })
                    ]
                }, '3'),
                _$cc(MenuItem, {
                    'key': '4',
                    'children': [
                        _$cc(Icon, _$tmp2),
                        _$ct('menu 4')
                    ]
                }, '4')
            ],
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['header'] = function($super) {
                            return [
                                _$cc(Icon, _$tmp3),
                                _$ct('Header')
                            ];
                        }),
                        (__$blocks['header'] = function($super, data) {
                            var block = $blocks['header'];
                            var callBlock = function() {
                                return _$blocks['header'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em),
            'ev-$model:expandedKeys': function($v) {
                $this.set('expandedKeys', $v);
            },
            'expandedKeys': $this.get('expandedKeys')
        })
    ], 4 /* HasNonKeyedChildren */);
};