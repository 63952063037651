import {
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Tip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$ma(this.get('types'), function($value, $key) {
            return _$cc(Tip, {
                'key': $value,
                'type': $value,
                'closable': true,
                'children': [
                    _$ct('\n        This is a '),
                    $value,
                    _$ct(' tip.\n    ')
                ],
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['title'] = function($super) {
                                return $value;
                            }),
                            (__$blocks['title'] = function($super, data) {
                                var block = $blocks['title'];
                                var callBlock = function() {
                                    return _$blocks['title'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, $value);
        }, $this)
    ), 8 /* HasKeyedChildren */);
};