import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Upload} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Upload, {
        'multiple': true,
        'beforeRemove': this.beforeRemove,
        'action': '//fakestoreapi.com/products',
        'accept': '.jpg, .png',
        'maxSize': 500,
        'ev-error': this.showError,
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['tip'] = function($super) {
                        return '只能上传JPG/PNG格式文件，且不超过500kb';
                    }),
                    (__$blocks['tip'] = function($super, data) {
                        var block = $blocks['tip'];
                        var callBlock = function() {
                            return _$blocks['tip'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    });
};