export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message} from 'kpc';

interface Props {
    data: DataItem
    nodes: Node[]
    lines: Line[]
}

type DataItem = {
    key: string
    children?: DataItem[]
    disabled?: boolean
}

type Node = {
    key: string
    label: string
    selectable: boolean
    data: DataItem
}

type Line = {
    from: string
    to: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: {
                key: 'Database',
                children: [
                    {
                        key: 'Table1',
                        children: [
                            {
                                key: 'Column1-1',
                            },
                            {
                                key: 'Column1-2',
                            }
                        ]
                     },
                     {
                        key: 'Table2',
                        disabled: true,
                        children: [
                            {
                                key: 'Column2-1',
                            },
                            {
                                key: 'Column2-2',
                            }
                        ]
                     },
                ] 
            },
            nodes: [],
            lines: [],
        } as Props;
    }

    init() {
        const nodes: Node[] = [];
        const lines: Line[] = [];
        const loop = (children: DataItem[], parent?: DataItem, disabled?: boolean) => {
            for (let i = 0; i < children.length; i++) {
                const data = children[i];

                // if parent is disabled, disable all children
                if (!disabled && data.disabled) disabled = true; 

                nodes.push({
                    key: data.key, 
                    label: data.key,
                    selectable: !disabled,
                    data: data
                });

                // add line if it has parent
                if (parent) {
                    lines.push({from: parent.key, to: data.key});
                }
                if (data.children) {
                    loop(data.children, data, disabled);
                }
            }
        };
        loop([this.get('data')]);

        this.set({nodes, lines});
    }

    onSelect(shapes: any[]) {
        if (!shapes.length) {
            Message.info('You selected nothing.');
        } else {
            const data = shapes[0].data;
            Message.info(`You selected ${data.key}.`);
        }
    }
}