export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    showSkeleton?: boolean
    rows?: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            showSkeleton: true,
            rows: 4
        };
    }
}