import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Card} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Card, {
            'children': [
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */),
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */)
            ]
        }),
        _$cc(Card, {
            'type': 'border',
            'children': [
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */),
                _$ce(2, 'div', '卡片内容', 16 /* HasTextChildren */)
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};