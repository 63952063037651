export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {range, bind} from 'kpc/components/utils';
import {nextTick} from 'intact';

interface Props {
    data: DataItem[]
    tab?: string
    size?: string
    selectedData?: DataItem
    checkedKeys?: number[]
}

type DataItem = {
    name: string
    ip: string
}

const data =  range(0, 10).map(item => {
    return {
        name: 'name ' + item,
        ip: '127.0.0.' + item
    };
});

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data,
            tab: 'detail',
            size: '0px',
            selectedData: undefined,
            checkedKeys: [],
        } as Props;
    }

    @bind
    _close() {
        this.set('size', '0px');
    }

    _open() {
        if (this.get('size') === '0px') {
            this.set('size', '200px');
        }
    }

    @bind
    _togglePanel(keys: number[] | undefined) {
        if (keys!.length === 1) {
            // 只选中一行时，展开详情面板
            const data = this.refs.table.getCheckedData()[0];
            this.set({
                selectedData: data,
                checkedKeys: keys,
            });
            this._open();
        } else {
            this._close();
        }
    }

    @bind
    _onClickRow(data: DataItem, index: number, key: number) {
        let checkedKeys = this.get('checkedKeys')!;
        if (checkedKeys.length === 1 && checkedKeys[0] === key) {
            // 如果只选中了一行，再次点击当前行，则取消选中
            checkedKeys = [];
        } else {
            // 否则只选中当前行
            checkedKeys = [key];
        }
        this.set('checkedKeys', checkedKeys);
        nextTick(() => {
            this._togglePanel(checkedKeys);
        });
    }
}