import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {getRestProps, mapChildren} from '../utils';
import {CarouselItem} from './item';
import {Button} from '../button';
import {Icon} from '../icon';
import {makeStyles} from './styles';
import {linkEvent} from 'intact';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, children, arrow, effect} = this.get();
    const {translate, shouldTransition, elementRef, isSlide} = this.slide;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-carousel`]: true,
        [className]: className,
        [`${k}-${arrow}`]: true,
        [`${k}-stop`]: !shouldTransition.value,
        [`${k}-${effect}`]: true,
        [makeStyles(k)]: true,
    };
    
    const {getItems, getItemsWithCloned} = this.items;
    const {stop, play} = this.autoplay;
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this),
        'ref': elementRef,
        'ev-mouseenter': stop,
        'ev-mouseleave': play
    }, [
        _$ce(2, 'div', getItemsWithCloned(), 0 /* UnknownChildren */, _$cn(`${k}-carousel-wrapper`), {
            'style': {
                transform: isSlide() ? `translate3d(${translate.value}px, 0, 0)` : undefined,
            }
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'type': 'none',
            'size': 'large',
            'className': _$cn(`${k}-carousel-btn ${k}-prev`),
            'ev-click': this.prev,
            'children': (
                _$cc(Icon, {
                    'className': _$cn(`${k}-icon-left`)
                })
            )
        }),
        _$cc(Button, {
            'icon': true,
            'circle': true,
            'type': 'none',
            'size': 'large',
            'className': _$cn(`${k}-carousel-btn ${k}-next`),
            'ev-click': this.next,
            'children': (
                _$cc(Icon, {
                    'className': _$cn(`${k}-icon-right`)
                })
            )
        }),
        _$ce(2, 'div', (
            _$ma(getItems(), function($value, $key) {
                return _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn({[`${k}-carousel-dot`]: true, [`${k}-active`]: this.isActive($value.props.value)}), {
                    'ev-click': linkEvent($key, this.setIndex)
                });
            }, $this)
        ), 4 /* HasNonKeyedChildren */, _$cn(`${k}-carousel-indicator c-clearfix`))
    ]);
};