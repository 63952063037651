export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    time?: string | null
    timeArray?: string[]
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            time: null,
            timeArray: []
        } as Props;
    };
}