import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    map as _$ma,
    noop as _$no,
} from 'intact';
import {makeStyles} from './styles';
import {getRestProps} from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {value, disabled, disable} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-scroll-select`]: true, 
        [`${k}-dragging`]: this.mouseEvents.dragging.value,
        [`${k}-disabled`]: disabled,
        [makeStyles(k)]: true,
    }
    
    const {onStart, onWheel, onClick} = this.mouseEvents;
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj)
    }, [
        _$ce(2, 'div', (
            _$ma(this.list.data.value, function($value, $key) {
                return _$ce(2, 'div', $value.label, 0 /* UnknownChildren */, _$cn({
                    [`${k}-scroll-select-item`]: true,
                    [`${k}-active`]: $value.value === value,
                    [`${k}-disabled`]: disabled || disable && disable.call(this, $value.value), 
                }), {
                    'ev-click': !disabled ? onClick.bind(this, $value, $key) : null
                });
            }, $this)
        ), 4 /* HasNonKeyedChildren */, _$cn(`${k}-scroll-select-wrapper`), {
            'style': {
                transform: `translateY(${this.translate.translate.value}px)`,
                marginTop: `${this.mouseEvents.marginTop.value}px`,
            },
            'ev-mousedown': !disabled ? onStart : null,
            'ev-wheel': !disabled ? onWheel : null
        }),
        ($blocks.append) ?
            _$ce(2, 'div', (
                (
                    (_$blocks['append'] = function($super) {
                        return null;
                    }),
                    (__$blocks['append'] = function($super, data) {
                        var block = $blocks['append'];
                        var callBlock = function() {
                            return _$blocks['append'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['append'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-scroll-select-append`)) :
            undefined,
        _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-scroll-select-border`))
    ]);
};