export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {MenuProps} from 'kpc';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            expandedKeys: ['3'],
            theme: 'light' as MenuProps['theme'],
        };
    }
}