import {
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tag} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ma(this.get('sizes'), function($value, $key) {
            return _$cc(Tag, {
                'size': $value,
                'children': $value
            });
        }, $this),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$ma(this.get('sizes'), function($value, $key) {
            return _$cc(Tag, {
                'size': $value,
                'type': this.get('types')[$key],
                'children': $value
            });
        }, $this),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$ma(this.get('tags'), function($value, $key) {
            return _$cc(Tag, {
                'type': 'primary',
                'size': $value,
                'ev-close': () => this.onClose($key),
                'closable': true,
                'children': $value
            });
        }, $this)
    ], 0 /* UnknownChildren */);
};