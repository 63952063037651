import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Dropdown, DropdownMenu, DropdownItem, Button, Tooltip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Dropdown, {
            'children': [
                _$cc(Button, {
                    'children': 'DropdownItem with Tooltip'
                }),
                _$cc(DropdownMenu, {
                    'children': [
                        _$cc(Tooltip, {
                            'content': 'item 1',
                            'children': (
                                _$cc(DropdownItem, {
                                    'children': 'item 1'
                                })
                            )
                        }),
                        _$cc(DropdownItem, {
                            'children': 'item 2'
                        }),
                        _$cc(Tooltip, {
                            'content': 'disabled',
                            'children': (
                                _$cc(DropdownItem, {
                                    'disabled': true,
                                    'children': 'item 3'
                                })
                            )
                        }),
                        _$cc(Dropdown, {
                            'position': {my: 'left top', at: 'right top'},
                            'trigger': 'click',
                            'children': [
                                _$cc(Tooltip, {
                                    'content': 'This is a nested Dropdown.',
                                    'children': (
                                        _$cc(DropdownItem, {
                                            'children': 'item 4'
                                        })
                                    )
                                }),
                                _$cc(DropdownMenu, {
                                    'children': [
                                        _$cc(DropdownItem, {
                                            'children': 'item 1'
                                        }),
                                        _$cc(Tooltip, {
                                            'content': 'nested DropdownItem',
                                            'children': (
                                                _$cc(DropdownItem, {
                                                    'children': 'item 2'
                                                })
                                            )
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};