import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Timeline, TimelineItem} from 'kpc';
import {Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-trophy'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Timeline, {
        'children': [
            _$cc(TimelineItem, {
                'type': 'success',
                'children': '\n        August\n    ',
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['dot'] = function($super) {
                                return (
                                    _$cc(Icon, _$tmp0)
                                );
                            }),
                            (__$blocks['dot'] = function($super, data) {
                                var block = $blocks['dot'];
                                var callBlock = function() {
                                    return _$blocks['dot'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }),
            _$cc(TimelineItem, {
                'children': 'July'
            }),
            _$cc(TimelineItem, {
                'children': 'June'
            }),
            _$cc(TimelineItem, {
                'children': 'May'
            })
        ]
    });
};