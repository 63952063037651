import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Input, Button, Icon} from 'kpc';

var _$tmp0 = {
    'size': 'large',
    'value': 'large'
};
var _$tmp1 = {
    'value': 'default'
};
var _$tmp2 = {
    'value': 'small',
    'size': 'small'
};
var _$tmp3 = {
    'className': 'k-icon k-icon-search',
    'size': 'small'
};
var _$tmp4 = {
    'value': 'mini',
    'size': 'mini'
};
var _$tmp5 = {
    'value': 'textarea auto mini',
    'size': 'mini',
    'type': 'textarea',
    'rows': 'auto'
};
var _$tmp6 = {
    'value': 'textarea auto small',
    'size': 'small',
    'type': 'textarea',
    'rows': 'auto'
};
var _$tmp7 = {
    'value': 'textarea auto default',
    'size': 'default',
    'type': 'textarea',
    'rows': 'auto'
};
var _$tmp8 = {
    'value': 'textarea auto large',
    'size': 'large',
    'type': 'textarea',
    'rows': 'auto'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Input, _$tmp0),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp1),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp2),
        _$ce(2, 'br'),
        _$cc(Input, {
            'size': 'small',
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['prepend'] = function($super) {
                            return 'http://';
                        }),
                        (__$blocks['prepend'] = function($super, data) {
                            var block = $blocks['prepend'];
                            var callBlock = function() {
                                return _$blocks['prepend'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['append'] = function($super) {
                            return '.com';
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'size': 'small',
            'placeholder': 'please enter',
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['prefix'] = function($super) {
                            return (
                                _$ce(2, 'i', null, 1 /* HasInvalidChildren */, 'ion-earth')
                            );
                        }),
                        (__$blocks['prefix'] = function($super, data) {
                            var block = $blocks['prefix'];
                            var callBlock = function() {
                                return _$blocks['prefix'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                _$cc(Button, {
                                    'size': 'small',
                                    'icon': true,
                                    'type': 'primary',
                                    'children': (
                                        _$cc(Icon, _$tmp3)
                                    )
                                })
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, {
            'size': 'small',
            'placeholder': 'please enter',
            'clearable': true,
            '$blocks': function($blocks) {
                var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                return (
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                _$cc(Button, {
                                    'type': 'primary',
                                    'size': 'small',
                                    'children': 'Search'
                                })
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        })
                    ),
                    __$blocks
                );
            }.call($this, _$em)
        }),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp4),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp5),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp6),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp7),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp8)
    ], 4 /* HasNonKeyedChildren */);
};