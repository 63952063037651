import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {makeItemStyles} from './styles';
import {getRestProps} from '../utils';

var _$tmp0 = {
    'd': 'M64 896V128h896v768H64z m64-128l192-192 116.352 116.352L640 448l256 307.2V192H128v576z m224-480a96 96 0 1 1-0.064 192.064A96 96 0 0 1 352 288z'
};
var _$tmp1 = {
    'viewBox': '0 0 1024 1024',
    'xmlns': 'http://www.w3.org/2000/svg'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {type} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-skeleton-item`]: true,
        [makeItemStyles(k)]: true
    };
    
    const ItemklsObj = {
        [`${k}-skeleton-item-box`]: true,
        [`${k}-skeleton-item-${type}`]: true
    };
    
    const skeletonItem = _$cv('div', {
        'className': _$cn(ItemklsObj),
        ...getRestProps(this)
    }, (
        (type === 'image') ?
            _$ce(512, 'svg', (
                _$ce(2, 'path', null, 1 /* HasInvalidChildren */, null, _$tmp0)
            ), 2 /* HasVNodeChildren */, null, _$tmp1) :
            undefined
    ));
    
    return _$ce(2, 'div', skeletonItem, 0 /* UnknownChildren */, _$cn(classNameObj));
};