import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    map as _$ma,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Select, Option} from '../select';
import {Icon} from '../icon';
import {_$} from '../../i18n';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, value, placeholder, ...rest} = this.get();
    const options = this.step.options.value;
    const { k } = this.config;
    
    return _$cc(Select, {
        ...rest,
        'placeholder': placeholder || _$('请选择时间'),
        'filterable': true,
        'className': _$cn(className),
        'children': (
            _$ma(options, function($value, $key) {
                return _$cc(Option, {
                    'value': $value.value,
                    'children': $value.label
                });
            }, $this)
        ),
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['suffix'] = function($super) {
                        return (
                            _$cc(Icon, {
                                'className': _$cn(`${k}-icon-time`)
                            })
                        );
                    }),
                    (__$blocks['suffix'] = function($super, data) {
                        var block = $blocks['suffix'];
                        var callBlock = function() {
                            return _$blocks['suffix'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em),
        'ev-$model:value': function($v) {
            $this.set('value', $v);
        },
        'value': $this.get('value')
    });
};