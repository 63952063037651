import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    noop as _$no,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {makeStyles} from './styles';
import {getRestProps} from '../utils';
import {Ellipsis} from '../ellipsis';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        children, className, span, label, content, append
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-description-item`]: true,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj)
    }, [
        _$ce(2, 'div', (
            (
                (_$blocks['label'] = function($super) {
                    return (
                        (label) ?
                            label :
                            undefined
                    );
                }),
                (__$blocks['label'] = function($super, data) {
                    var block = $blocks['label'];
                    var callBlock = function() {
                        return _$blocks['label'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                }),
                __$blocks['label'](_$no)
            )
        ), 0 /* UnknownChildren */, _$cn(`${k}-description-item-label`)),
        _$ce(2, 'div', [
            _$cc(Ellipsis, {
                'children': content || children
            }),
            ($blocks.append || append) ?
                _$ce(2, 'div', (
                    (
                        (_$blocks['append'] = function($super) {
                            return (
                                (append) ?
                                    append :
                                    undefined
                            );
                        }),
                        (__$blocks['append'] = function($super, data) {
                            var block = $blocks['append'];
                            var callBlock = function() {
                                return _$blocks['append'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        }),
                        __$blocks['append'](_$no)
                    )
                ), 0 /* UnknownChildren */, _$cn(`${k}-description-option`)) :
                undefined
        ], 0 /* UnknownChildren */, _$cn(`${k}-description-centent`))
    ]);
};