import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Input} from './';
import {Icon} from '../icon';
import {Button} from '../button';
import {getRestProps} from '../utils';
import {makeSearchStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        className, placeholder, value,
        type, size, open, 
    } = this.get();
    
    const { k } = this.config;
    const classNameObj = {
        [`${k}-search`]: true,
        [className]: className,
        [`${k}-open`]: open,
        [`${k}-hide`]: !open,
        [`${k}-${type}`]: type,
        [makeSearchStyles(k)]: true,
    };
    
    return _$cv('form', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'ev-submit': this.onSubmit,
        'ref': this.elementRef
    }, [
        _$cc(Input, {
            'clearable': true,
            'ref': this.inputRef,
            'placeholder': open ? placeholder : undefined,
            'value': open ? value : undefined,
            'ev-$change:value': this.onChangeValue,
            'readonly': !open,
            'size': size,
            'waveDisabled': type !== 'default'
        }, null, this.inputRef),
        _$cc(Button, {
            'icon': true,
            'type': 'none',
            'ev-click': this.onClickBtn,
            'size': size,
            'children': (
                _$cc(Icon, {
                    'className': _$cn(`${k}-icon-search ${k}-search-icon`)
                })
            )
        })
    ]);
};