export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {TagProps} from 'kpc';

interface Props {
    types: TagProps['type'][]
}

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            types: ['default', 'primary', 'success', 'warning', 'danger'],
        } as Props;
    }
}