export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    expandedKeys?: string[]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            expandedKeys: ['3']
        };
    }
}