import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Switch} from 'kpc';

var _$tmp0 = {
    'size': 'large'
};
var _$tmp1 = {
    'size': 'small'
};
var _$tmp2 = {
    'size': 'mini'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ct('\n    large: '),
        _$cc(Switch, _$tmp0),
        _$ct('\n    default: '),
        _$cc(Switch),
        _$ct('\n    small: '),
        _$cc(Switch, _$tmp1),
        _$ct('\n    mini: '),
        _$cc(Switch, _$tmp2)
    ], 4 /* HasNonKeyedChildren */);
};