import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'name',
    'title': 'Name',
    'width': '200'
};
var _$tmp1 = {
    'key': 'column1',
    'title': 'Column1',
    'width': '300'
};
var _$tmp2 = {
    'key': 'column2',
    'title': 'Column2',
    'width': '300'
};
var _$tmp3 = {
    'key': 'column3',
    'title': 'Column3',
    'width': '300'
};
var _$tmp4 = {
    'key': 'column4',
    'title': 'Column4',
    'width': '300'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': this.get('data'),
        'resizable': true,
        'stickScrollbar': true,
        'children': [
            _$cc(TableColumn, _$tmp0, 'name'),
            _$cc(TableColumn, _$tmp1, 'column1'),
            _$cc(TableColumn, _$tmp2, 'column2'),
            _$cc(TableColumn, _$tmp3, 'column3'),
            _$cc(TableColumn, _$tmp4, 'column4'),
            _$cc(TableColumn, {
                'key': 'action',
                'title': 'Action',
                'width': '200',
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['template'] = function($super, data) {
                                return (
                                    _$ce(2, 'a', 'action', 16 /* HasTextChildren */)
                                );
                            }),
                            (__$blocks['template'] = function($super, data) {
                                var block = $blocks['template'];
                                var callBlock = function() {
                                    return _$blocks['template'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, 'action')
        ]
    });
};