export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import type {MenuProps} from 'kpc';

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            theme: 'dark' as MenuProps['theme'],
        }
    }
}