export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message} from 'kpc';

export default class extends Component {
    static template = template;
    
    static defaults() {
        return {
            types: ['info', 'error', 'warning', 'success'] as const
        }
    }

    showMessage(type: 'info' | 'error' | 'warning' | 'success') {
        Message[type]({
            content: type,
            duration: 3000,
            closable: true,
            hideIcon: true,
        });
    }
}