import {
    superCall as _$su,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const classNameObj = {
        'design-page': true,
        [makeStyles()]: true
    };
    
    return _$su.call($this, {
        'navIndex': 'design',
        'className': _$cn(classNameObj)
    }, $blocks, $__proto__);
};