export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, TreeDataItem, TreeNode} from 'kpc';

interface Props {
    data: TreeDataItem<string | number>[]
}

let uniqueId = 0;
export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    label: 'First floor-1',
                    children: [
                        {
                            label: 'Second floor-1.1',
                            children: [
                                {
                                    label: 'Third floor-1.1.1'
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'First floor-2',
                    children: [
                        {
                            label: 'Second floor-2.1',
                            children: [
                                {
                                    label: 'Third floor-2.1.1',
                                    key: 'floor-2.1.1',
                                },
                                {
                                    label: 'Third floor-2.1.2'
                                }
                            ]
                        },
                        {
                            label: 'Second floor-2.2',
                            disabled: true,
                            children: [
                                {
                                    label: 'Third floor-2.2.1'
                                }
                            ]
                        }
                    ]
                }
            ],
        }
    }

    @bind
    append(data: TreeDataItem<string | number>, node: TreeNode<string>, index: number, e: Event) {
        e.stopPropagation();

        const children = data.children || (data.children = []);
        children.push({
            label: 'Appended node',
            key: uniqueId++,
        });
        this.set({data: [
            ...this.get('data')
        ]});

        // expand the node
        this.refs.tree.expand(node.key);
    }

    @bind
    remove(data: TreeDataItem<string>, node: TreeNode<string>, index: number, e: Event) {
        e.stopPropagation();

        const children = node.parent ? node.parent.data.children : this.get('data');
        children!.splice(index, 1);
        this.set({data: [
            ...this.get('data'),
        ]});
    }

    @bind
    appendToRoot() {
        const data = this.get('data');
        this.set({data: [
            ...data,
            {
                label: 'Appended root node',
                key: uniqueId++,
            }
        ]});
    }
}