import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Timepicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Timepicker, {
            'min': '01:34 am',
            'max': '03:02 pm',
            'format': 'hh:mm a',
            'ev-$model:value': function($v) {
                $this.set('time1', $v);
            },
            'value': $this.get('time1')
        }),
        _$ce(2, 'span', [
            _$ct('You selected: '),
            this.get('time1')
        ], 0 /* UnknownChildren */),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Timepicker, {
            'step': '00:30:00',
            'showFormat': 'HH:mm',
            'ev-$model:value': function($v) {
                $this.set('time2', $v);
            },
            'value': $this.get('time2')
        }),
        _$ce(2, 'span', [
            _$ct('You selected: '),
            this.get('time2')
        ], 0 /* UnknownChildren */),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Timepicker, {
            'step': '00:30:00',
            'range': true,
            'clearable': true,
            'valueFormat': 'HH:mm:ss.SSS',
            'showFormat': 'HH:mm',
            'ev-$model:value': function($v) {
                $this.set('time3', $v);
            },
            'value': $this.get('time3')
        }),
        _$ce(2, 'span', [
            _$ct('You selected: '),
            JSON.stringify(this.get('time3'))
        ], 0 /* UnknownChildren */)
    ], 4 /* HasNonKeyedChildren */);
};