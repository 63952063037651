import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Tags, Tag, Tooltip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tags, {
            'draggable': true,
            'children': [
                _$ma(this.get('tags1'), function($value, $key) {
                    return _$cc(Tag, {
                        'key': $value,
                        'type': $value,
                        'children': $value
                    }, $value);
                }, $this),
                _$cc(Tooltip, {
                    'content': 'test',
                    'key': 'test',
                    'children': (
                        _$cc(Tag, {
                            'children': 'with tooltip'
                        })
                    )
                }, 'test')
            ]
        }),
        _$ce(2, 'br'),
        _$cc(Tags, {
            'draggable': true,
            'nowrap': true,
            'style': 'width: 300px;',
            'children': [
                _$ma(this.get('tags2'), function($value, $key) {
                    return _$cc(Tag, {
                        'key': $value,
                        'type': $value,
                        'children': $value
                    }, $value);
                }, $this),
                _$cc(Tooltip, {
                    'content': 'test',
                    'key': 'test',
                    'children': (
                        _$cc(Tag, {
                            'children': 'with tooltip'
                        })
                    )
                }, 'test')
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};