import {
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, children} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-timeline`]: true,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, children);
};