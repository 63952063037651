import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button} from '../button';
import {Icon} from '../icon';
import {Input} from '../input';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, disabled, size, vertical, value, width} = this.get();
    const { k } = this.config;
    const classNameObj = {
        [`${k}-spinner`]: true,
        [`${k}-disabled`]: disabled,
        [`${k}-${size}`]: size !== 'default',
        [className]: className,
        [`${k}-vertical`]: vertical,
        [makeStyles(k)]: true,
    };
    const {decrease, increase, onChangeValue, onFocusin} = this.change;
    const {showValue, onInput} = this.value;
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        _$cc(Button, {
            'icon': true,
            'ev-click': decrease,
            'disabled': this.isDisabledDecrease(),
            'className': _$cn(`${k}-spinner-btn ${k}-left`),
            'size': size,
            'type': 'none',
            'tabindex': '-1',
            'children': (
                (!vertical) ?
                    _$cc(Icon, {
                        'className': _$cn(`${k}-spinner-icon ${k}-icon-minus`)
                    }) :
                    _$cc(Icon, {
                        'className': _$cn(`${k}-spinner-icon ${k}-icon-down`)
                    })
            )
        }),
        _$cc(Input, {
            'className': _$cn(`${k}-spinner-input`),
            'disabled': disabled,
            'ev-change': onChangeValue,
            'ev-focusin': onFocusin,
            'value': showValue.value,
            'ev-input': onInput,
            'size': size,
            'width': width
        }),
        _$cc(Button, {
            'icon': true,
            'ev-click': increase,
            'disabled': this.isDisabledIncrease(),
            'className': _$cn(`${k}-spinner-btn ${k}-right`),
            'size': size,
            'type': 'none',
            'tabindex': '-1',
            'children': (
                (!vertical) ?
                    _$cc(Icon, {
                        'className': _$cn(`${k}-spinner-icon ${k}-icon-add`)
                    }) :
                    _$cc(Icon, {
                        'className': _$cn(`${k}-spinner-icon ${k}-icon-up`)
                    })
            )
        })
    ]);
};