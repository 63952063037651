import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
    map as _$ma,
} from 'intact';
import {Diagram, DTreeLayout, DRectangle, DLine} from 'kpc/components/diagram';
import {Select, Option} from 'kpc';

var _$tmp0 = {
    'from': 'from',
    'to': 'to'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const cities = this.get('cities');
    
    return _$cc(Diagram, {
        'children': (
            _$cc(DTreeLayout, {
                'children': [
                    _$cc(DRectangle, {
                        'key': 'from',
                        'strokeStyle': 'dashed',
                        'height': '100',
                        'children': (
                            _$ce(2, 'div', [
                                _$ce(2, 'div', '始发站', 16 /* HasTextChildren */),
                                _$cc(Select, {
                                    'fluid': true,
                                    'children': (
                                        _$ma(cities, function($value, $key) {
                                            return _$cc(Option, {
                                                'value': $value.value,
                                                'children': $value.name
                                            });
                                        }, $this)
                                    ),
                                    'ev-$model:value': function($v) {
                                        $this.set('from', $v);
                                    },
                                    'value': $this.get('from')
                                })
                            ], 4 /* HasNonKeyedChildren */, 'container')
                        )
                    }, 'from'),
                    _$cc(DRectangle, {
                        'key': 'to',
                        'strokeStyle': 'dashed',
                        'height': '100',
                        'children': (
                            _$ce(2, 'div', [
                                _$ce(2, 'div', '终点站', 16 /* HasTextChildren */),
                                _$cc(Select, {
                                    'fluid': true,
                                    'children': (
                                        _$ma(cities, function($value, $key) {
                                            return _$cc(Option, {
                                                'value': $value.value,
                                                'children': $value.name
                                            });
                                        }, $this)
                                    ),
                                    'ev-$model:value': function($v) {
                                        $this.set('to', $v);
                                    },
                                    'value': $this.get('to')
                                })
                            ], 4 /* HasNonKeyedChildren */, 'container')
                        )
                    }, 'to'),
                    _$cc(DLine, _$tmp0)
                ]
            })
        )
    });
};