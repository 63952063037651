export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {MenuProps, bind} from 'kpc';

interface Props extends MenuProps { }

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            expandedKeys: ['3'],
            selectedKey: '3-2',
            collapse: true,
            theme: 'dark'
        } as MenuProps;
    }

    @bind
    setTheme(theme?: string) {
        this.set('theme', theme as MenuProps['theme']);
    }
}