import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Link} from '../link';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'blockquote', (
        _$ce(2, 'p', [
            _$ct('\n        默认事件没有在此注明，请参考 \n        '),
            _$cc(Link, {
                'href': '/docs/event/',
                'children': '默认事件'
            })
        ], 4 /* HasNonKeyedChildren */)
    ), 2 /* HasVNodeChildren */);
};