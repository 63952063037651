import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Input} from 'kpc';

var _$tmp0 = {
    'disabled': true,
    'placeholder': 'disabled'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___test = function(i) {_$refs['__test'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Input, {
            'placeholder': 'please enter',
            'ref': _$ref___test,
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }, null, _$ref___test),
        this.get('value'),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp0)
    ], 0 /* UnknownChildren */);
};