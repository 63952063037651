import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    createElementVNode as _$ce,
    map as _$ma,
    noop as _$no,
} from 'intact';
import {
    Layout, Header, Body, Icon, Tabs, Tab,
    Dropdown, DropdownMenu, DropdownItem,
    Button, Select, Option,
} from 'kpc';
import {makeHeaderStyles, makeLayoutStyles} from './styles';
import {Link} from '../components/link';
import {Transition} from 'intact';

var _$tmp0 = {
    'className': 'ion-navicon',
    'size': 'large'
};
var _$tmp1 = {
    'className': 'icon ion-checkmark-round'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const nav = [
        {
            title: '字体',
            href: '/font',
            index: 'font'
        },
        {
            title: '设计',
            href: '/docs/design/about/',
            index: 'design',
        },
        {
            title: '组件',
            href: '/docs/vue/',
            index: 'document'
        },
        {
            title: '资源',
            href: '/resources',
            index: 'resource'
        },
        {
            title: '解决方案',
            href: '/solutions',
            index: 'solution'
        }
    ];
    
    const {themeColor, colorList, curLang} = this.get();
    
    return _$cc(Layout, {
        'className': _$cn({[$props.className]: $props.className, [makeLayoutStyles(this.config.k)]: true}),
        'children': [
            _$cc(Header, {
                'fixed': true,
                'className': _$cn(makeHeaderStyles(this.config.k)),
                'children': [
                    _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'logo', {
                        'ev-click': () => Link.to('/')
                    }),
                    _$ce(2, 'div', [
                        _$cc(Tabs, {
                            'size': 'large',
                            'value': $props.navIndex,
                            'children': [
                                _$ma(nav, function($value, $key) {
                                    return _$cc(Tab, {
                                        'value': $value.index,
                                        'children': (
                                            _$cc(Link, {
                                                'href': $value.href,
                                                'children': $value.title
                                            })
                                        )
                                    });
                                }, $this),
                                null
                            ]
                        }),
                        _$cc(Dropdown, {
                            'trigger': 'click',
                            'container': dom => dom,
                            'position': {my: 'center top+17', at: 'center bottom', collision: 'flipfit'},
                            'children': [
                                _$cc(Button, {
                                    'type': 'none',
                                    'icon': true,
                                    'className': 'menu-btn',
                                    'children': (
                                        _$cc(Icon, _$tmp0)
                                    )
                                }),
                                _$cc(DropdownMenu, {
                                    'className': 'nav-menu-list',
                                    'children': (
                                        _$ma(nav, function($value, $key) {
                                            return _$cc(DropdownItem, {
                                                'className': _$cn({
                                                    active: $value.index === $props.navIndex
                                                }),
                                                'ev-select': () => Link.to($value.href),
                                                'children': $value.title
                                            });
                                        }, $this)
                                    )
                                })
                            ]
                        }),
                        _$cc(Select, {
                            'width': '70',
                            'container': dom => dom,
                            'value': 'v3',
                            'size': 'small',
                            'ev-$change:value': this.onChangeVersion,
                            'className': 'versions',
                            'children': [
                                _$cc(Option, {
                                    'value': 'v1',
                                    'children': 'v1'
                                }),
                                _$cc(Option, {
                                    'value': 'v2',
                                    'children': 'v2'
                                }),
                                _$cc(Option, {
                                    'value': 'v3',
                                    'children': 'v3'
                                })
                            ]
                        }),
                        _$ce(2, 'div', [
                            _$ce(2, 'div', '主题色', 16 /* HasTextChildren */),
                            _$ce(2, 'div', (
                                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'color-item', {
                                    'style': {background: themeColor}
                                })
                            ), 2 /* HasVNodeChildren */, 'cur-color'),
                            _$cc(Transition, {
                                'name': 'k-slidedown',
                                'children': (
                                    (this.get('showThemeColor')) ?
                                        _$ce(2, 'div', (
                                            _$ma(colorList, function($value, $key) {
                                                return _$ce(2, 'div', (
                                                    _$ce(2, 'div', (
                                                        (themeColor === $value) ?
                                                            _$cc(Icon, _$tmp1) :
                                                            undefined
                                                    ), 0 /* UnknownChildren */, 'color-item', {
                                                        'style': {background: $value},
                                                        'ev-click': this.handleColorChange.bind(this, $value)
                                                    })
                                                ), 2 /* HasVNodeChildren */);
                                            }, $this)
                                        ), 4 /* HasNonKeyedChildren */, 'theme-color-option') :
                                        undefined
                                )
                            })
                        ], 4 /* HasNonKeyedChildren */, 'theme-color-box', {
                            'ev-mouseenter': this.showColorList,
                            'ev-mouseleave': this.hideColorList
                        })
                    ], 4 /* HasNonKeyedChildren */, 'main')
                ]
            }),
            _$cc(Layout, {
                'children': [
                    (
                        (
                            (_$blocks['aside'] = function($super) {
                                return null;
                            }),
                            (__$blocks['aside'] = function($super, data) {
                                var block = $blocks['aside'];
                                var callBlock = function() {
                                    return _$blocks['aside'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            }),
                            __$blocks['aside'](_$no)
                        )
                    ),
                    _$cc(Body, {
                        'children': (
                            (
                                (
                                    (_$blocks['content'] = function($super) {
                                        return null;
                                    }),
                                    (__$blocks['content'] = function($super, data) {
                                        var block = $blocks['content'];
                                        var callBlock = function() {
                                            return _$blocks['content'].call($this, $super, data);
                                        };
                                        return block ?
                                            block.call($this, callBlock, data) :
                                            callBlock();
                                    }),
                                    __$blocks['content'](_$no)
                                )
                            )
                        )
                    })
                ]
            })
        ]
    });
};