import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button} from '../button';
import {Icon} from '../icon';
import {getRestProps} from '../utils';
import {makeMessageStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {type, content, children, closable, hideIcon, classNamePrefix} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-message`]: true,
        [`${k}-${type}`]: type,
        [makeMessageStyles(k)]: true,
    };
    
    const iconMap = {
        info: 'information-fill',
        warning: 'warning-fill',
        success: 'success-fill',
        error: 'error-fill',
    };
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj),
        'ev-mouseenter': this.delayClose.onMouseEnter,
        'ev-mouseleave': this.delayClose.onMouseLeave
    }, (
        _$ce(2, 'div', [
            _$ce(2, 'div', [
                (!hideIcon) ?
                    _$cc(Icon, {
                        'className': _$cn({
                            [`${k}-icon-${iconMap[type] || iconMap.info}`]: true,
                            [`${k}-message-icon`]: true,
                        })
                    }) :
                    undefined,
                (typeof content === 'string' || typeof content === 'number') ?
                    _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-message-content`), {
                        'innerHTML': content
                    }) :
                    _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-message-content`))
            ], 0 /* UnknownChildren */, _$cn({[`${k}-message-wrapper`]: true, [`${k}-with-icon`]: !hideIcon})),
            (closable) ?
                _$cc(Button, {
                    'type': 'none',
                    'icon': true,
                    'className': _$cn(`${k}-message-close`),
                    'ev-click': this.close,
                    'children': (
                        _$cc(Icon, {
                            'className': _$cn(`${k}-icon-close`),
                            'hoverable': true
                        })
                    )
                }) :
                undefined
        ], 0 /* UnknownChildren */, _$cn(`${k}-message-container`))
    ));
};