import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
} from 'intact';
import {getRestProps} from '../utils';
import { makeStyles } from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    let {
        className, children, text, disabled, max
    } = this.get();
    
    if (typeof text === 'number' && max && text > max) {
        text = `${max}+`;
    }
    
    const { cls, k } = this.config;
    
    const classNameObj = {
        [`${k}-badge`]: true,
        [className]: className,
        [`${k}-has-text`]: text != null,
        [`${k}-alone`]: !children,
        [makeStyles(k)]: true,
    };
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj)
    }, [
        children,
        (!disabled) ?
            _$ce(2, 'sup', text, 0 /* UnknownChildren */, _$cn(`${k}-badge-text`)) :
            undefined
    ]);
};