export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            value: 2018
        };
    }

    generateData(value: number) {
        const start = value - 5;
        return Array.apply(null, {length: 10} as unknown[])
            .map((v, i) => {
                const year = start + i; 
                return {
                    label: `${year}年`,
                    value: year
                };
            });
    }
}