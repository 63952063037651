import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {addStyle, getRestProps} from '../utils';
import {Spin} from '../spin';
import {makeStyles} from './styles';

var _$tmp0 = {
    'center': true,
    'overlay': true,
    'size': 'small'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    let {width, height, className, style} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-code`]: true,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    if (width || height) {
        const _style = {};
        if (width) {
            _style.width = width;
        }
        if (height) {
            _style.height = height;
        }
        style = addStyle(style, _style);
    }
    
    const {elementRef, loading} = this.editor;
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this),
        'style': style,
        'ref': elementRef
    }, (
        (loading.value) ?
            _$cc(Spin, _$tmp0) :
            undefined
    ));
};