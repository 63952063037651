import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Ellipsis} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Ellipsis, {
            'position': 'right',
            'style': {width: '90px'},
            'children': 'tooltip在右侧tooltip在右侧'
        }),
        _$cc(Ellipsis, {
            'position': 'bottom',
            'style': {width: '90px'},
            'children': 'tooltip在下方tooltip在下方'
        })
    ], 4 /* HasNonKeyedChildren */);
};