import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tabs, Tab} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tabs, {
            'size': 'large',
            'value': '1',
            'children': [
                _$cc(Tab, {
                    'value': '1',
                    'children': 'large1'
                }),
                _$cc(Tab, {
                    'value': '2',
                    'children': 'large2'
                })
            ]
        }),
        _$cc(Tabs, {
            'value': '1',
            'children': [
                _$cc(Tab, {
                    'value': '1',
                    'children': 'default1'
                }),
                _$cc(Tab, {
                    'value': '2',
                    'children': 'defalut2'
                })
            ]
        }),
        _$cc(Tabs, {
            'size': 'small',
            'value': '1',
            'children': [
                _$cc(Tab, {
                    'value': '1',
                    'children': 'small1'
                }),
                _$cc(Tab, {
                    'value': '2',
                    'children': 'small2'
                })
            ]
        }),
        _$cc(Tabs, {
            'size': 'mini',
            'value': '1',
            'children': [
                _$cc(Tab, {
                    'value': '1',
                    'children': 'mini1'
                }),
                _$cc(Tab, {
                    'value': '2',
                    'children': 'mini2'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};