import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
} from 'intact';
import { Icon } from '../icon';
import { getRestProps } from '../utils';
import { Virtual } from '../virtual';
import { Transition } from 'intact';
import { makeStyles } from './styles';
import { _$ } from '../../i18n';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const { children, className } = this.get();
    const { startCopy, success } = this.copy;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-copy`]: true,
        [makeStyles(k)]: true,
        [className]: className,
    }
    
    return _$cc(Transition, {
        'name': 'k-fade',
        'children': (
            _$cc(Virtual, {
                'ev-click': startCopy,
                'className': _$cn(classNameObj),
                ...getRestProps(this),
                'children': (
                    (children) ?
                        children :
                        !success.value ?
                            _$cc(Icon, {
                                'key': '1',
                                'className': _$cn(`${k}-icon-clone`),
                                'hoverable': true,
                                'title': _$('复制')
                            }, '1') :
                            _$cc(Icon, {
                                'key': '2',
                                'className': _$cn(`${k}-icon-check`),
                                'hoverable': true,
                                'color': 'success'
                            }, '2')
                )
            })
        )
    });
};