export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Message, bind, PaginationChangeData} from 'kpc';

interface Props {
    value1: number
    value2?: number
    limit?: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            value1: 1, 
            value2: 1,
            limit: 20
        } as Props;
    }

    @bind
    _fetch1(v?: number) {
        // fetch data
        this.set('value1', v!);
        Message.info(`value page: ${v!}`);
    }

    @bind
    _fetch2({value, limit}: PaginationChangeData) {
        Message.info(`value page: ${value}, limit: ${limit}`);
    }
}