export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import { Message } from 'kpc';
export default class extends Component {
    static template = template;
    static defaults() {
        return {
            value1: 0,
            value2: false,
        }
    }

    beforeChange(from: any, to: any) {
        return new Promise<boolean>(resolve => {
            Message.warning(`current value is ${from}, to value is ${to}`);
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    }
    beforeChangeFalse(from: any, to: any) {
        Message.warning(`current value is ${from}, to value is ${to}`);
        return new Promise<boolean>(resolve => {
            setTimeout(() => {
                resolve(false);
            }, 1000);
        });
    }
}