import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Diagram, DRectangle, DCircle, DDiamond, DHexagon, DLine} from 'kpc/components/diagram';
import {Button, ButtonGroup, Form, FormItem, Spinner, Checkbox} from 'kpc';

var _$tmp0 = {
    'key': '1',
    'rounded': '5'
};
var _$tmp1 = {
    'key': '2'
};
var _$tmp2 = {
    'key': '3'
};
var _$tmp3 = {
    'key': '4'
};
var _$tmp4 = {
    'key': '5'
};
var _$tmp5 = {
    'from': '1',
    'to': '2',
    'strokeStyle': 'dashed',
    'type': 'rounded'
};
var _$tmp6 = {
    'from': '1',
    'to': '3',
    'strokeStyle': 'dashed',
    'type': 'rounded'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const Layout = this.Layout;
    const currentLayout = this.get('layouts')[this.get('layout')];
    
    return _$ce(2, 'div', (
        _$cc(Form, {
            'labelWidth': '150',
            'children': [
                _$cc(FormItem, {
                    'label': 'Layout',
                    'children': (
                        _$cc(ButtonGroup, {
                            'checkType': 'radio',
                            'children': (
                                _$ma(this.get('layouts'), function($value, $key) {
                                    return _$cc(Button, {
                                        'value': $key,
                                        'children': $value.Layout.displayName
                                    });
                                }, $this)
                            ),
                            'ev-$model:value': function($v) {
                                $this.set('layout', $v);
                            },
                            'value': $this.get('layout')
                        })
                    )
                }),
                _$ma(currentLayout.props, function($value, $key) {
                    return _$cc(FormItem, {
                        'label': $key,
                        'children': (() => {
                            const type = typeof $value;
                            const model = `layouts.${this.get('layout')}.props.${$key}`;
                        
                            switch (type) {
                                case 'number':
                                    return _$cc(Spinner, {
                                        'ev-$model:value': function($v) {
                                            $this.set(model, $v);
                                        },
                                        'value': $this.get(model)
                                    });
                                case 'boolean':
                                    return _$cc(Checkbox, {
                                        'ev-$model:value': function($v) {
                                            $this.set(model, $v);
                                        },
                                        'value': $this.get(model)
                                    });
                                case 'string':
                                    return _$cc(ButtonGroup, {
                                        'checkType': 'radio',
                                        'children': (
                                            _$ma(currentLayout.Layout.typeDefs[$key], function($value, $key) {
                                                return _$cc(Button, {
                                                    'value': $value,
                                                    'children': $value
                                                });
                                            }, $this)
                                        ),
                                        'ev-$model:value': function($v) {
                                            $this.set(model, $v);
                                        },
                                        'value': $this.get(model)
                                    })
                            }
                        })()
                    });
                }, $this),
                _$cc(FormItem, {
                    'children': (
                        _$cc(Diagram, {
                            'children': (
                                _$cc(DRectangle, {
                                    'width': '300',
                                    'height': '200',
                                    'strokeStyle': 'dotted',
                                    'children': (
                                        _$cc(Layout, {
                                            'layout': currentLayout.Layout,
                                            'props': currentLayout.props,
                                            'children': [
                                                _$cc(DRectangle, _$tmp0, '1'),
                                                _$cc(DDiamond, _$tmp1, '2'),
                                                _$cc(DCircle, _$tmp2, '3'),
                                                _$cc(DCircle, _$tmp3, '4'),
                                                _$cc(DCircle, _$tmp4, '5'),
                                                _$cc(DLine, _$tmp5),
                                                _$cc(DLine, _$tmp6)
                                            ]
                                        })
                                    )
                                })
                            )
                        })
                    )
                })
            ]
        })
    ), 2 /* HasVNodeChildren */);
};