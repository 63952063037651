import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Row, {
            'children': [
                _$cc(Col, {
                    'span': '12',
                    'children': 'col-12'
                }),
                _$cc(Col, {
                    'span': '12',
                    'children': 'col-12'
                })
            ]
        }),
        _$cc(Row, {
            'children': [
                _$cc(Col, {
                    'span': '4',
                    'children': 'col-4'
                }),
                _$cc(Col, {
                    'span': '6',
                    'children': 'col-6'
                }),
                _$cc(Col, {
                    'span': '8',
                    'children': 'col-8'
                }),
                _$cc(Col, {
                    'span': '6',
                    'children': 'col-6'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};