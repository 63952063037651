export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

interface Props {
    color: string
    colors: Color[]
    percent: number
}

type Color = {
    color: string
    percent: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            color: '#ff9800',
            colors: [
                {color: '#9CD6FB', percent: 25},
                {color: '#60C2FF', percent: 50},
                {color: '#32ACF7', percent: 75},
                {color: '#0091EA', percent: 100}
            ],
            percent: 25
        }
    }

    @bind
    add() {
        if (this.get('percent') >= 100) return;

        this.set('percent', this.get('percent') + 25);
    }

    @bind
    minus() {
        if (this.get('percent') <= 0) return;

        this.set('percent', this.get('percent') - 25);
    }

    setColor(percent: number) {
        if (percent <= 50) {
            return '#D1FDD5';
        } else {
            return '#36B342';
        }
    }
}