import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tabs, Tab} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tabs, {
            'vertical': true,
            'children': [
                _$cc(Tab, {
                    'value': 'rulein',
                    'children': '入站规则'
                }),
                _$cc(Tab, {
                    'value': 'ruleout',
                    'children': '出站规则'
                }),
                _$cc(Tab, {
                    'value': 'relatedVM',
                    'children': '关联云主机'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('tab', $v);
            },
            'value': $this.get('tab')
        }),
        _$cc(Tabs, {
            'vertical': true,
            'type': 'card',
            'children': [
                _$cc(Tab, {
                    'value': 'rulein',
                    'children': '入站规则'
                }),
                _$cc(Tab, {
                    'value': 'ruleout',
                    'children': '出站规则'
                }),
                _$cc(Tab, {
                    'value': 'relatedVM',
                    'children': '关联云主机'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('tab', $v);
            },
            'value': $this.get('tab')
        }),
        _$cc(Tabs, {
            'vertical': true,
            'type': 'flat-card',
            'children': [
                _$cc(Tab, {
                    'value': 'rulein',
                    'children': '入站规则'
                }),
                _$cc(Tab, {
                    'value': 'ruleout',
                    'children': '出站规则'
                }),
                _$cc(Tab, {
                    'value': 'relatedVM',
                    'children': '关联云主机'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('tab', $v);
            },
            'value': $this.get('tab')
        })
    ], 4 /* HasNonKeyedChildren */);
};