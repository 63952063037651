import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Spinner} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Spinner, {
            'vertical': true,
            'formatter': this.formater,
            'parser': this.parser,
            'ev-$model:value': function($v) {
                $this.set('money', $v);
            },
            'value': $this.get('money')
        }),
        _$cc(Spinner, {
            'vertical': true,
            'prefix': '增长率 ',
            'suffix': '%',
            'ev-$model:value': function($v) {
                $this.set('percent', $v);
            },
            'value': $this.get('percent')
        })
    ], 4 /* HasNonKeyedChildren */);
};