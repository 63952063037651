export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {range, bind} from 'kpc/components/utils';

const data =  range(1, 100).map(item => {
    return {
        name: 'name ' + item,
        ip: '127.0.0.' + item
    };
});

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data: data
        }
    }

    @bind
    scrollToRowByIndex() {
        this.refs.table.scrollToRowByIndex(19);
    }

    @bind
    scrollToRowByKey() {
        this.refs.table.scrollToRowByKey('name 25');
    }
}