import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Spinner} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Spinner, {
            'max': 5,
            'min': -5,
            'vertical': true,
            'size': 'large'
        }),
        _$cc(Spinner, {
            'max': 5,
            'min': -5,
            'vertical': true
        }),
        _$cc(Spinner, {
            'max': 5,
            'min': -5,
            'vertical': true,
            'size': 'small'
        }),
        _$cc(Spinner, {
            'max': 5,
            'min': -5,
            'vertical': true,
            'size': 'mini'
        })
    ], 4 /* HasNonKeyedChildren */);
};