export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {Message, TreeDataItem, TreeNode, TreeDragEndData, TreeMode} from 'kpc';

interface Props {
    data?: TreeDataItem<string>[]
    expandedKeys?: string[]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    label: '总览',
                    key: 'summarization',
                    type: 'file',
                },
                {
                    label: '产品简介',
                    key: 'introduction',
                    type: 'file',
                },
                {
                    label: '技术实现',
                    key: 'implemention',
                    type: 'file',
                },
                {
                    label: '操作指南',
                    key: 'guide',
                    type: 'dir',
                    children: [
                        {
                            label: '产品安装',
                            key: 'installation',
                            type: 'file',
                        },
                    ]
                },
                {
                    label: '接入说明',
                    key: 'interaction',
                    type: 'dir',
                    children: [
                        {
                            label: '使用',
                            key: 'usage',
                            type: 'file',
                        },
                    ]
                },
                {
                    label: '禁用文件',
                    key: 'disabled',
                    type: 'file',
                    disabled: true,
                },
            ],
            expandedKeys: [],
        };
    }

    onDragEnd(data: TreeDragEndData<string>) {
        console.log(data);
    }

    allowDrag(node: TreeNode<string>) {
        return node.key !== 'summarization';
    }

    allowDrop(node: TreeNode<string>, srcNode: TreeNode<string>, mode: TreeMode) {
        // 能够将文件拖入文件夹，但是不能讲文件夹拖入文件夹
        if (mode === TreeMode.Inner) {
            // 文件拖入文件夹
            return srcNode.data.type === 'file' && node.data.type === 'dir';
        } else if (srcNode.data.type === 'dir' && node.parent) {
            // 此时插入模式是，前面或者后面插入
            // 如果拖动的是文件夹，但是目标节点存在父元素，代表目标节点也是文件夹，不能插入
            return false
        }

        return true;
    }

    onDenyDrag(node: TreeNode<string>) {
        Message.error(`Cannot drag node: ${node.data.label}.`);
    }

    onDenyDrop(node: TreeNode<string>) {
        Message.error(`Cannot drop to node: ${node.data.label}.`);
    }
}