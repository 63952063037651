import {
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    createElementVNode as _$ce,
} from 'intact';
import {Timeline, TimelineItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Timeline, {
        'children': [
            _$cc(TimelineItem, {
                'children': [
                    _$ct('\n        August\n        '),
                    _$ce(2, 'p', 'some descriptions', 16 /* HasTextChildren */)
                ]
            }),
            _$cc(TimelineItem, {
                'children': 'July'
            }),
            _$cc(TimelineItem, {
                'children': 'June'
            }),
            _$cc(TimelineItem, {
                'children': 'May'
            })
        ]
    });
};