import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Row, Col} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'h4', '指定每一个断点Col所占的栅格数', 16 /* HasTextChildren */),
        _$cc(Row, {
            'children': [
                _$cc(Col, {
                    'xs': '2',
                    'sm': '4',
                    'md': '6',
                    'lg': '8',
                    'xl': '10',
                    'xxl': '11',
                    'children': 'col'
                }),
                _$cc(Col, {
                    'xs': '20',
                    'sm': '16',
                    'md': '12',
                    'lg': '8',
                    'xl': '4',
                    'xxl': '2',
                    'children': 'col'
                }),
                _$cc(Col, {
                    'xs': '2',
                    'sm': '4',
                    'md': '6',
                    'lg': '8',
                    'xl': '10',
                    'xxl': '11',
                    'children': 'col'
                })
            ]
        }),
        _$ce(2, 'h4', '指定一个断点，当宽度小于该断点时，Col将以100%宽度纵向排列', 16 /* HasTextChildren */),
        _$cc(Row, {
            'children': [
                _$cc(Col, {
                    'md': '16',
                    'children': 'col-md-18'
                }),
                _$cc(Col, {
                    'md': '8',
                    'children': 'col-md-8'
                })
            ]
        }),
        _$ce(2, 'h4', '同时指定多个属性', 16 /* HasTextChildren */),
        _$cc(Row, {
            'children': [
                _$cc(Col, {
                    'md': {span: 4, offset: 4, order: 1},
                    'lg': {span: 8, offset: 8, order: 2},
                    'children': '1 col'
                }),
                _$cc(Col, {
                    'md': {span: 8, offset: 8, order: 2},
                    'lg': {span: 4, offset: 4, order: 1},
                    'children': '2 col'
                })
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};