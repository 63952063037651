export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    values?: [number, number]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            values: [50, 76],
        } as Props;
    }
}