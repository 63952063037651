export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    data1: DataItem1[]
    data2: DataItem2[]
    data3: DataItem1[]
}

type DataItem1 = {
    a: string
}

type DataItem2 = {
    text: string
    id: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data1: [{a: 'default checked by index'}, {a: 'unchecked'}],
            data2: [{text: 'default checked by rowKey', id: 'yes'}, {text: 'unchecked', id: 'no'}],
            data3: [{a: 'default checked by index'}, {a: 'unchecked'}, {a: 'default checked'}],
        }
    }

    setRowKey(value: DataItem2, index: number) {
        return value.id;
    }
}