export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

interface Props {
    values: DataItem[]
    keywords?: string
    data: DataItem[]
}

type DataItem = {
    name: string
    domain: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            values: [],
            keywords: '',
            data: [
                {name: 'King Design', domain: 'design.ksyun.com'},
                {name: 'KPC Theme', domain: 'kpc-theme.ksyun.com'},
                {name: 'King Cloud', domain: 'www.ksyun.com'},
                {name: 'Baidu', domain: 'www.baidu.com'},
                {name: 'Google', domain: 'www.google.com'},
                {name: 'Bing', domain: 'cn.bing.com'},
                {name: 'Github', domain: 'www.github.com'},
            ]
        } as Props;
    }

    text() {
        const valuesLength = this.get('values').length;
        const dataLength = this.get('data').length;

        return valuesLength ? `已选择${valuesLength}项 / 共${dataLength}项` : null;
    }

    checkedKeys() {
        return this.get('values').map(item => item.name);
    }

    filter() {
        const data = this.get('data');
        let keywords = this.get('keywords')!;
        keywords = keywords.trim().toLowerCase();

        if (!keywords) return data;

        return data.filter(item => {
            return item.name.toLowerCase().includes(keywords) || 
                item.domain.toLowerCase().includes(keywords);
        });
    }

    @bind
    confirm() {
        const data = this.refs.table.getCheckedData();
        this.set('values', data);
        this.hide();
    }

    @bind
    hide() {
        this.refs.select.hide();
    }
}