export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

interface Props {
    languages?: string[]
    options: string[]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            // 必须初始化为数组
            languages: [],
            options: ['Javascript', 'C++', 'PHP'],
        } as Props;
    };

    @bind
    toggleSelectAll(checked?: boolean) {
        if (checked) {
            this.set('languages', ['Javascript', 'C++', 'PHP']);
        } else {
            this.set('languages', []);
        }
    }

    getLength() {
        return this.get('languages')!.length;
    }
}