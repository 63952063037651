import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    noop as _$no,
} from 'intact';
import {getRestProps} from '../utils';
import {makeItemStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {className, children, type, size} = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-timeline-item`]: true,
        [className]: className,
        [`${k}-${type}`]: true,
        [`${k}-${size}`]: size !== 'default',
        [makeItemStyles(k)]: true,
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        _$ce(2, 'div', [
            _$ce(2, 'div', (
                (
                    (_$blocks['dot'] = function($super) {
                        return (
                            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-timeline-circle`))
                        );
                    }),
                    (__$blocks['dot'] = function($super, data) {
                        var block = $blocks['dot'];
                        var callBlock = function() {
                            return _$blocks['dot'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['dot'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-timeline-dot`)),
            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-timeline-line`))
        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-timeline-indicator`)),
        _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-timeline-content`))
    ]);
};