import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Ellipsis, Split, Button, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-heart'
};
var _$tmp1 = {
    'className': 'ion-heart'
};
var _$tmp2 = {
    'className': 'ion-heart'
};
var _$tmp3 = {
    'className': 'ion-heart'
};
var _$tmp4 = {
    'className': 'ion-heart'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Ellipsis, {
            'className': 'panel tooltip-class',
            'children': [
                _$cc(Icon, _$tmp0),
                _$cc(Button, {
                    'type': 'primary',
                    'children': '测试'
                }),
                _$cc(Icon, _$tmp1),
                _$cc(Button, {
                    'type': 'primary',
                    'children': '测试'
                }),
                _$cc(Icon, _$tmp2),
                _$cc(Button, {
                    'type': 'primary',
                    'children': '测试'
                }),
                _$cc(Icon, _$tmp3),
                _$cc(Button, {
                    'type': 'primary',
                    'children': '测试'
                }),
                _$cc(Icon, _$tmp4),
                _$cc(Button, {
                    'type': 'primary',
                    'children': '测试'
                })
            ]
        })
    ), 2 /* HasVNodeChildren */, null, {
        'style': {width: '200px'}
    });
};