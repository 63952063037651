import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'children': 'default'
        }),
        _$cc(Button, {
            'type': 'primary',
            'children': 'primary'
        }),
        _$cc(Button, {
            'type': 'secondary',
            'children': 'secondary'
        }),
        _$cc(Button, {
            'type': 'warning',
            'children': 'warning'
        }),
        _$cc(Button, {
            'type': 'danger',
            'children': 'danger'
        }),
        _$cc(Button, {
            'type': 'success',
            'children': 'success'
        }),
        _$cc(Button, {
            'type': 'none',
            'children': 'none'
        }),
        _$cc(Button, {
            'type': 'link',
            'children': 'link'
        }),
        _$cc(Button, {
            'type': 'flat',
            'children': 'flat'
        }),
        _$cc(Button, {
            'color': 'red',
            'children': 'custom'
        }),
        _$cc(Button, {
            'color': '#0000ea',
            'children': 'custom'
        }),
        _$cc(Button, {
            'color': 'rgb(183, 18, 193)',
            'children': 'custom'
        })
    ], 4 /* HasNonKeyedChildren */);
};