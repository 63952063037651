export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            data: [
                {a: 'Cell 1-1', b: 'Cell 1-2'},
                {a: 'Cell 2-1', b: 'Cell 2-2'}
            ],
            hideHeader: true,
        };
    }
}