import {
    createElementVNode as _$ce,
    className as _$cn,
} from 'intact';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(makeStyles()), null, null, this.bezierBoxRef);
};