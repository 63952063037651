export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    states: States
    selectedStates: (keyof States)[]
}

type States = {
    selectable: boolean
    movable: boolean
    connectable: boolean
    resizable: boolean
    rotable: boolean
    editable: boolean
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            states: {
                selectable: false,
                movable: false,
                connectable: false,
                resizable: false,
                rotatable: false,
                editable: false,
            },
            selectedStates: [],
        }
    }

    init() {
        this.on('$change:selectedStates', (v) => {
            const states = {} as States;
            for (let key in this.get('states')) {
                states[key as keyof States] = v.indexOf(key as keyof States) > -1;
            }
            this.set('states', states);
        });
    }
}