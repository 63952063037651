export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

interface Props {
    value1?: boolean 
    value2?: number | string
}

export default class extends Component<Props> {
    static template = template;
    static defaults() {
        return {
            value1: false,
            value2: '1'
        } as Props;
    }
}