import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn, Switch} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Title 1'
};
var _$tmp1 = {
    'key': 'b',
    'title': 'Title 2'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'p', [
            _$ct('hideHeader: '),
            _$cc(Switch, {
                'ev-$model:value': function($v) {
                    $this.set('hideHeader', $v);
                },
                'value': $this.get('hideHeader')
            })
        ], 4 /* HasNonKeyedChildren */),
        _$cc(Table, {
            'data': this.get('data'),
            'resizable': true,
            'hideHeader': this.get('hideHeader'),
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};