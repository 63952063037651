import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    isChecked as _$isc,
    linkEvent as _$le,
    setCheckboxModel as _$scm,
    noop as _$no,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {makeStyles} from './styles';
import {addStyle, getRestProps} from '../utils';
import {Icon} from '../icon';

var _$tmp0 = {
    'src': 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    let {
        className, style, name, on,
        off, value, trueValue, falseValue,
        width, height, size, disabled, loading
    } = this.get();
    
    const {start, dragging, barRef, barWidth} = this.draggable;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-switch`]: true,
        [className]: className,
        [`${k}-checked`]: value === trueValue, 
        [`${k}-${size}`]: true,
        [`${k}-disabled`]: disabled,
        [`${k}-dragging`]: dragging.value,
        [makeStyles(k)]: true,
    };
    
    
    let onStyle;
    let offStyle;
    let barStyle;
    let iconStyle;
    if (width || height) {
        const _style = {};
        onStyle = {};
        offStyle = {};
        if (width) {
            _style.width = `${width}px`;
            onStyle.width = _style.width;
            offStyle.width = _style.width;
        }
        if (height) {
            _style.height = `${height}px`;
    
            Object.assign(onStyle, {
                height: _style.height,
                lineHeight: _style.height,
                padding: `0 ${_style.height} 0 ${height / 3}px`,
            });
    
            Object.assign(offStyle, {
                height: _style.height,
                lineHeight: _style.height,
                padding: `0 ${height / 3}px 0 ${_style.height}`,
            });
    
            barStyle = {
                width: value === trueValue ? '100%' : `${height}px`,
            }
            iconStyle = {
                fontSize: `${height * 0.7}px`,
            }
        }
        style = addStyle(style, _style);
    }
    
    if (barWidth.value) {
        barStyle = addStyle(barStyle, {width: barWidth.value + 'px'});
    }
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this),
        'style': style,
        'ev-click': this.onClick,
        'tabindex': disabled ? "-1" : "0",
        'ev-keypress': this.onKeypress,
        'ref': this.elementRef
    }, [
        _$ce(64, 'input', null, 1 /* HasInvalidChildren */, null, {
            'type': 'checkbox',
            'name': name,
            'disabled': disabled,
            'trueValue': trueValue,
            'falseValue': falseValue,
            'tabindex': '-1',
            '$model:value': 'value',
            'checked': _$isc($this.get('value'), trueValue),
            'ev-$model:change': _$le($this, _$scm)
        }),
        (off || $blocks.off) ?
            _$ce(2, 'div', (
                (
                    (_$blocks['off'] = function($super) {
                        return off;
                    }),
                    (__$blocks['off'] = function($super, data) {
                        var block = $blocks['off'];
                        var callBlock = function() {
                            return _$blocks['off'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['off'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-switch-off`), {
                'style': offStyle
            }) :
            undefined,
        _$ce(2, 'div', [
            (on || $blocks.on) ?
                _$ce(2, 'div', (
                    (
                        (_$blocks['on'] = function($super) {
                            return on;
                        }),
                        (__$blocks['on'] = function($super, data) {
                            var block = $blocks['on'];
                            var callBlock = function() {
                                return _$blocks['on'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        }),
                        __$blocks['on'](_$no)
                    )
                ), 0 /* UnknownChildren */, _$cn(`${k}-switch-on`), {
                    'style': onStyle
                }) :
                undefined,
            _$ce(2, 'div', [
                (loading) ?
                    _$cc(Icon, {
                        'className': _$cn(`${k}-switch-icon ion-load-c`),
                        'rotate': true,
                        'style': iconStyle
                    }) :
                    undefined,
                _$ce(2, 'div', (
                    _$ce(2, 'img', null, 1 /* HasInvalidChildren */, null, _$tmp0)
                ), 2 /* HasVNodeChildren */, _$cn(`${k}-switch-handle`), {
                    'ev-click': this.onClickOnHandle,
                    'ev-mousedown': start
                })
            ], 0 /* UnknownChildren */, _$cn(`${k}-switch-wrapper`))
        ], 0 /* UnknownChildren */, _$cn(`${k}-switch-bar`), {
            'style': barStyle
        }, null, barRef)
    ]);
};