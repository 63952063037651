export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {Dialog, Message, bind, RequestError} from 'kpc';

export default class extends Component {
    static template = template;

    @bind
    _onError(err: Error | RequestError) {
        Message.error(err.message);
    }
}