export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            items: [
                {label:'label1', content:'测试1'},
                {label:'label2', content:'测试测试'},
                {label:'label3', content: '测试3'},
                {label:'label4', content:'测试4', append: 'option'},
            ]
        };
    };
}