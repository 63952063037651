export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    startArrow: string
    endArrow: string
    startPoint: [number, number]
    endPoint: [number, number]
    type: string
    strokeStyle: string
    from: string | null
    to: string | null
    exit: [number, number]
    entry: [number, number]
    useexit: boolean
    useentry: boolean
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            startArrow: 'none',
            endArrow: 'classic',
            startPoint: [10, 100],
            endPoint: [100, 150],
            type: 'straight',
            strokeStyle: 'solid',
            from: null,
            to: null,
            exit: [0, 0],
            entry: [0, 0],
            useexit: false,
            useentry: false,
        };
    }

    onSliderChange(name: {[K in keyof Props]: Props[K] extends unknown[] ? K : never}[keyof Props], index: number, value: number) {
        let propValue = this.get(name).slice(0) as [number, number];
        propValue[index] = value;
        this.set(name, propValue);
    }
}