import {
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
} from 'intact';
import {Dropdown, DropdownMenu, DropdownItem} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Dropdown, {
        'trigger': 'contextmenu',
        'children': [
            _$ce(2, 'div', '\n        right click this area to show context menu\n    ', 16 /* HasTextChildren */, 'contextmenu-area'),
            _$cc(DropdownMenu, {
                'children': [
                    _$cc(DropdownItem, {
                        'children': 'Option 1'
                    }),
                    _$cc(DropdownItem, {
                        'children': 'Option 2'
                    }),
                    _$cc(DropdownItem, {
                        'children': 'Option 3'
                    })
                ]
            })
        ]
    });
};