import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Input} from 'kpc';

var _$tmp0 = {
    'type': 'textarea',
    'placeholder': 'please enter',
    'fluid': true
};
var _$tmp1 = {
    'type': 'textarea',
    'rows': '5',
    'placeholder': 'please enter',
    'fluid': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Input, _$tmp0),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Input, _$tmp1)
    ], 4 /* HasNonKeyedChildren */);
};