export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {PaginationChangeData} from 'kpc';

interface Props {
    value1?: number
    value2?: number
    value3?: number
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            value1: 1,
            value2: 1,
            value3: 1,
        };
    }

    _onChange(v: PaginationChangeData) {
        console.log(v);
    }
}