import {
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Table, TableColumn, Tooltip, Icon} from 'kpc';

var _$tmp0 = {
    'title': '自定义表头内容'
};
var _$tmp1 = {
    'className': 'k-icon-question'
};
var _$tmp2 = {
    'key': 'b',
    'title': '表头2'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': this.get('data'),
        'children': [
            _$cc(TableColumn, {
                'key': 'a',
                'group': [{label: 'test', value: ''}],
                'sortable': true,
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['title'] = function($super) {
                                return [
                                    _$ce(2, 'span', '自定义表头内容', 16 /* HasTextChildren */, 'title', _$tmp0),
                                    _$cc(Tooltip, {
                                        'content': 'tooltip content',
                                        'children': (
                                            _$cc(Icon, _$tmp1)
                                        )
                                    })
                                ];
                            }),
                            (__$blocks['title'] = function($super, data) {
                                var block = $blocks['title'];
                                var callBlock = function() {
                                    return _$blocks['title'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, 'a'),
            _$cc(TableColumn, _$tmp2, 'b')
        ]
    });
};