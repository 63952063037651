import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': '100px'
};
var _$tmp1 = {
    'key': 'a',
    'title': '100px'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': [{a: '表头固定，但内容没有超出最高高度'}],
            'fixHeader': '100',
            'children': (
                _$cc(TableColumn, _$tmp0, 'a')
            )
        }),
        _$cc(Table, {
            'data': [{a: '表头固定啦'}, {a: '下拉'}, {a: 'yeah!'}],
            'fixHeader': '100',
            'children': (
                _$cc(TableColumn, _$tmp1, 'a')
            )
        })
    ], 4 /* HasNonKeyedChildren */, 'wrapper');
};