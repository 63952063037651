export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    city?: string
    cities?: string[]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            city: 'beijing',
            cities: []
        } as Props;
    } 
}