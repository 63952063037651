import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {makeStyles} from './styles';
import {Button, Icon} from 'kpc';
import {Footer} from '../../components/footer/';
import {NewFunction} from './NewFunction';
import {BestPractice} from './BestPractice';
import {ColorProcess} from './ColorProcess';
import {KingVersion} from './KingVersion';
import {Link} from '../../components/link';
import bannerVideo from '../../imgs/banner_video.mp4';

var _$tmp0 = {
    'width': '13',
    'height': '13',
    'viewBox': '0 0 13 13',
    'fill': 'none',
    'xmlns': 'http://www.w3.org/2000/svg'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {themeColor, videoLevel} = this.get();
    
    return _$su.call($this, {
        'className': _$cn(makeStyles())
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['content'] = function($super) {
                    return [
                        _$ce(2, 'div', [
                            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, 'nav-bg'),
                            _$ce(2, 'div', (
                                _$ce(2, 'video', null, 1 /* HasInvalidChildren */, 'nav-video-bg', {
                                    'src': bannerVideo,
                                    'muted': true,
                                    'autoPlay': true,
                                    'loop': true
                                }, null, this.bannerVideo)
                            ), 2 /* HasVNodeChildren */, 'nav-video-box', {
                                'style': {zIndex: videoLevel}
                            }),
                            _$ce(2, 'div', (
                                _$ce(2, 'div', [
                                    _$ce(2, 'div', 'Kingcloud Design 3.0', 16 /* HasTextChildren */, 'main-title web-title'),
                                    _$ce(2, 'div', '更专业，更具活力的设计系统', 16 /* HasTextChildren */, 'sub-title web-title'),
                                    _$ce(2, 'div', 'Kingcloud Design 3.0', 16 /* HasTextChildren */, 'main-title h5-title'),
                                    _$ce(2, 'div', '提高团队效率，赋能产品设计', 16 /* HasTextChildren */, 'sub-title h5-title'),
                                    _$ce(2, 'div', [
                                        _$cc(Button, {
                                            'type': 'primary',
                                            'size': 'large',
                                            'ev-click': () => Link.to("/docs/vue/"),
                                            'children': '\n                            开始使用\n                        '
                                        }),
                                        _$cc(Button, {
                                            'type': 'secondary',
                                            'size': 'large',
                                            'href': 'https://github.com/ksc-fe/kpc',
                                            'target': '_blank',
                                            'children': [
                                                _$cc(Icon, {
                                                    'children': (
                                                        _$ce(512, 'svg', (
                                                            _$ce(2, 'path', null, 1 /* HasInvalidChildren */, null, {
                                                                'd': 'M6.50002 0.541664C4.93648 0.525776 3.4306 1.13133 2.31333 2.22523C1.19607 3.31914 0.558836 4.81189 0.541687 6.37541C0.547712 7.61105 0.945198 8.81298 1.67705 9.80857C2.40891 10.8042 3.43748 11.5422 4.61502 11.9167C4.91294 11.9708 5.02127 11.7921 5.02127 11.635V10.6437C3.36377 10.9958 3.01169 9.86374 3.01169 9.86374C2.90135 9.50836 2.66677 9.20456 2.35085 9.00791C1.80919 8.65041 2.39419 8.65583 2.39419 8.65583C2.58141 8.68086 2.76055 8.74795 2.91815 8.85208C3.07575 8.95621 3.20773 9.09467 3.30419 9.25708C3.47228 9.54856 3.7481 9.76239 4.07228 9.85256C4.39645 9.94272 4.74307 9.902 5.03752 9.73916C5.0678 9.44254 5.20213 9.16621 5.41669 8.95916C4.09502 8.81291 2.70835 8.31458 2.70835 6.0775C2.69653 5.49395 2.91215 4.92868 3.3096 4.50125C3.12859 4.00024 3.14997 3.44842 3.36919 2.96291C3.36919 2.96291 3.87294 2.80583 4.99419 3.55875C5.97021 3.29866 6.99733 3.29866 7.97335 3.55875C9.11085 2.80583 9.59835 2.96291 9.59835 2.96291C9.81757 3.44842 9.83895 4.00024 9.65794 4.50125C10.0646 4.92085 10.2919 5.48232 10.2917 6.06666C10.2917 8.30916 8.89419 8.80208 7.58335 8.94833C7.72689 9.08768 7.83776 9.25711 7.908 9.44442C7.97825 9.63174 8.00611 9.83229 7.9896 10.0317V11.6296C7.9896 11.8192 8.09794 11.9708 8.39585 11.9112C9.57006 11.5345 10.5952 10.7963 11.3247 9.80207C12.0543 8.80788 12.4511 7.60856 12.4584 6.37541C12.4412 4.81189 11.804 3.31914 10.6867 2.22523C9.56944 1.13133 8.06356 0.525776 6.50002 0.541664Z',
                                                                'fill': themeColor
                                                            })
                                                        ), 2 /* HasVNodeChildren */, null, _$tmp0)
                                                    )
                                                }),
                                                _$ct('\n                            Github\n                        ')
                                            ]
                                        })
                                    ], 4 /* HasNonKeyedChildren */)
                                ], 4 /* HasNonKeyedChildren */, 'nav-content')
                            ), 2 /* HasVNodeChildren */, 'nav-content-box')
                        ], 4 /* HasNonKeyedChildren */, 'nav'),
                        _$cc(NewFunction, {
                            'themeColor': themeColor
                        }),
                        _$cc(BestPractice, {
                            'themeColor': themeColor,
                            'ref': this.bestPrac
                        }, null, this.bestPrac),
                        _$cc(ColorProcess),
                        _$cc(KingVersion),
                        _$cc(Footer)
                    ];
                }),
                (__$blocks['content'] = function($super, data) {
                    var block = $blocks['content'];
                    var callBlock = function() {
                        return _$blocks['content'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};