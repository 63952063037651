import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tree, Button} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_tree = function(i) {_$refs['tree'] = i};
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.getCheckedData,
            'children': 'get checked data'
        }),
        _$cc(Button, {
            'ev-click': this.getCheckedLeavesData,
            'children': 'get checked leaves data'
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tree, {
            'data': this.get('data'),
            'selectable': false,
            'checkbox': true,
            'ref': _$ref_tree,
            'ev-$model:checkedKeys': function($v) {
                $this.set('checkedKeys', $v);
            },
            'checkedKeys': $this.get('checkedKeys')
        }, null, _$ref_tree)
    ], 4 /* HasNonKeyedChildren */);
};