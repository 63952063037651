export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {Dialog} from 'kpc';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            tab: 'ruleout',
        }
    }

    beforeChange(value: string) {
        return new Promise<boolean>(resolve => {
            Dialog.confirm({
                content: `Are you sure to change to "${value}"`
            }).then(() => {
                resolve(true);
            }, () => {
                resolve(false);
            });
        });
    }
}