export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data: [
                {a: '第一行', b: '哈哈1', c: '自定义1'}, 
                {a: '第二行', b: '哈哈2', c: '自定义2'}
            ],
        }
    }

    @bind
    exportTable() {
        this.refs.table.exportTable();
    }

    @bind
    exportData() {
        this.refs.table.exportTable([
            {a: '第一行', b: '哈哈1', c: '自定义1'}, 
            {a: '第二行', b: '哈哈2', c: '自定义2'},
            {a: '第三行', b: '哈哈3', c: '自定义3'}
        ]);
    }
}