import {
    superCall as _$su,
    className as _$cn,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    noop as _$no,
} from 'intact';
import {Icon} from '../icon';
import {makeStyles} from "./styles";


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const { type, title, content } = this.get();
    const { k } = this.config;
    
    const iconMap = {
        info: 'information-fill',
        warning: 'warning-fill',
        success: 'success-fill',
        error: 'error-fill',
    };
    
    const classNameObj = {
        [makeStyles(k)]: true,
        [`${k}-popover`]: true,
    }
    
    return _$su.call($this, {
        'className': _$cn(classNameObj)
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['body'] = function($super) {
                    return [
                        (title || $blocks.title) ?
                            _$ce(2, 'div', [
                                (type) ?
                                    _$cc(Icon, {
                                        'className': _$cn(`${k}-icon-${iconMap[type]} ${k}-popover-icon`),
                                        'color': type === 'error' ? 'danger' : type,
                                        'size': 'large'
                                    }) :
                                    undefined,
                                (
                                    (_$blocks['title'] = function($super) {
                                        return title;
                                    }),
                                    (__$blocks['title'] = function($super, data) {
                                        var block = $blocks['title'];
                                        var callBlock = function() {
                                            return _$blocks['title'].call($this, $super, data);
                                        };
                                        return block ?
                                            block.call($this, callBlock, data) :
                                            callBlock();
                                    }),
                                    __$blocks['title'](_$no)
                                )
                            ], 0 /* UnknownChildren */, _$cn(`${k}-popover-title`)) :
                            undefined,
                        (content || $blocks.content) ?
                            _$ce(2, 'div', (
                                (
                                    (_$blocks['content'] = function($super) {
                                        return content;
                                    }),
                                    (__$blocks['content'] = function($super, data) {
                                        var block = $blocks['content'];
                                        var callBlock = function() {
                                            return _$blocks['content'].call($this, $super, data);
                                        };
                                        return block ?
                                            block.call($this, callBlock, data) :
                                            callBlock();
                                    }),
                                    __$blocks['content'](_$no)
                                )
                            ), 0 /* UnknownChildren */, _$cn(`${k}-popover-content`)) :
                            undefined
                    ];
                }),
                (__$blocks['body'] = function($super, data) {
                    var block = $blocks['body'];
                    var callBlock = function() {
                        return _$blocks['body'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};