import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Select, {
        'inline': true,
        'children': [
            _$cc(Option, {
                'value': 'default',
                'children': '默认主题'
            }),
            _$cc(Option, {
                'value': 'ksyun',
                'children': '金山云主题'
            })
        ]
    });
};