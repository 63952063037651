export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind} from 'kpc';
interface Props {
    data: NonNullable<any>
}
export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [
                {
                    name: 'Audios', 
                    size: 12,
                    children: []
                },
                {
                    name: 'Images', 
                    size: 14,
                    children: []
                },
                {
                    name: 'doc.pdf', 
                    size: 18,
                },
            ]
        }
    }

    @bind
    loadData(node: any) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                node.children = [
                    {name: 'child1', size: 17},
                    {name: 'child2', size: 15, loaded: true},
                ];
                this.set('data', [...this.get('data')]);

                resolve();
            }, 2000);
        });
    }
}