export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, TabsProps} from 'kpc';

interface Props {
    tab?: number
    tabs: TabItem[]
    size: TabsProps['size']
    types: TabsProps['type'][]
}

type TabItem = {
    value: number
    label: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            tab: 1,
            tabs: [
                { value: 1, label: 'Table 1'},
                { value: 2, label: 'Table 2'},
                { value: 3, label: 'Table 3'},
                { value: 4, label: 'Table 4'},
                { value: 5, label: 'Table 5'},
                { value: 6, label: 'Table 6'},
                { value: 7, label: 'Table 7'},
                { value: 8, label: 'Table 8'},
                { value: 9, label: 'Table 9'},
                { value: 10, label: 'Table 10'},
                { value: 11, label: 'Table 11'},
                { value: 12, label: 'Table 12'},
                { value: 13, label: 'Table 13'},
                { value: 14, label: 'Table 14'},
                { value: 15, label: 'Table 15'},
                { value: 16, label: 'Table 16'},
                { value: 17, label: 'Table 17'},
                { value: 18, label: 'Table 18'},
                { value: 19, label: 'Table 19'},
                { value: 20, label: 'Table 20'},
            ],
            size: 'default',
            types: ['default', 'card', 'flat-card']
        } as Props;
    }
}