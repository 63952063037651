import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Cascader} from 'kpc';

var _$tmp0 = {
    'loading': true
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Cascader, {
            'data': this.get('data'),
            'ev-$model:value': function($v) {
                $this.set('value', $v);
            },
            'value': $this.get('value')
        }),
        _$ct('\n    You selected: '),
        JSON.stringify(this.get('value')),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Cascader, _$tmp0)
    ], 0 /* UnknownChildren */);
};