export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';

import {bind, TreeDataItem} from 'kpc';

interface Props {
    expandedKeys?: string[]
    keywords?: string
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            expandedKeys: ['2', '2-1'],
            keywords: '',
            data: [
                {
                    label: 'First floor-1',
                    key: '1',
                    children: [
                        {
                            label: 'Second floor-1.1',
                            key: '1-1',
                            children: [
                                {
                                    label: 'Third floor-1.1.1',
                                    key: '1-1-1'
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'First floor-2',
                    key: '2',
                    children: [
                        {
                            label: 'Second floor-2.1',
                            key: '2-1',
                            children: [
                                {
                                    label: 'Third floor-2.1.1',
                                    key: '2-1-1' 
                                },
                                {
                                    label: 'Third floor-2.1.2',
                                    key: '2-1-2'
                                }
                            ]
                        },
                        {
                            label: 'Second floor-2.2',
                            disabled: true,
                            key: '2-2',
                            children: [
                                {
                                    label: 'Third floor-2.2.1',
                                    key: '2-2-1'
                                }
                            ]
                        }
                    ]
                }
            ]
        };
    }

    @bind
    filter(data: TreeDataItem<string>) {
        const keywords = this.get('keywords');
        if (!keywords) return true;
        return (data.label as string).toLowerCase().includes(keywords.trim().toLowerCase());
    }
}