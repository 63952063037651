import {
    createElementVNode as _$ce,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    map as _$ma,
} from 'intact';
import {Row, Col} from '../grid';
import {Slider} from '../slider';
import {Input} from '../input';
import {makePanelStyles, makeAlphaBgColor} from './styles';
import {linkEvent} from 'intact';
import {Mode} from './useInput';
import {ColorpickerDrag as Drag} from './drag';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {value, presets} = this.get();
    const { k } = this.config;
    const classNameObj = {
        [`${k}-colorpicker-panel`]: true,
        [makePanelStyles(k)]: true,
    }
    const {
        hex: {value: hex},
        hsv: {value: hsv},
        rgb: {value: rgb},
        hsl: {value: hsl},
        changeHue,
        changeAlpha,
        setValue
    } = this.value;
    const {start, areaRef} = this.pointer;
    const {
        onInputHex, mode, changeMode, changeColor,
        onStart, onEnd, dragging, setInputValue,
    } = this.input;
    const alpha = Math.round(rgb.a * 100);
    
    return _$ce(2, 'div', [
        _$ce(2, 'div', [
            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-colorpicker-white`)),
            _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-colorpicker-black`)),
            _$ce(2, 'div', (
                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-colorpicker-circle`))
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-colorpicker-pointer`), {
                'style': {
                    left: `${hsv.s * 100}%`,
                    top: `${-hsv.v * 100 + 100}%`,
                }
            })
        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-colorpicker-saturation`), {
            'style': {backgroundColor: `hsl(${hsv.h}, 100%, 50%)`},
            'ev-mousedown': start
        }, null, areaRef),
        _$ce(2, 'div', [
            _$ce(2, 'div', [
                _$cc(Slider, {
                    'className': _$cn(`${k}-colorpicker-hue`),
                    'showInput': false,
                    'showEnd': false,
                    'step': 0,
                    'max': 360,
                    'value': hsv.h,
                    'ev-$change:value': changeHue,
                    'animate': !dragging.value
                }),
                _$cc(Slider, {
                    'className': _$cn({
                        [`${k}-colorpicker-alpha ${k}-colorpicker-alpha-bg`]: true,
                        [makeAlphaBgColor(rgb, k)]: true,
                    }),
                    'showInput': false,
                    'showEnd': false,
                    'step': 0,
                    'max': 1,
                    'value': rgb.a,
                    'ev-$change:value': changeAlpha,
                    'animate': !dragging.value
                })
            ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-colorpicker-sliders`)),
            _$ce(2, 'div', (
                _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-colorpicker-color`), {
                    'style': {backgroundColor: value}
                })
            ), 2 /* HasVNodeChildren */, _$cn(`${k}-colorpicker-color-wrapper ${k}-colorpicker-alpha-bg`))
        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-colorpicker-controls`)),
        _$cc(Row, {
            'gutter': '6',
            'children': [
                _$cc(Col, {
                    'span': '8',
                    'children': [
                        _$cc(Input, {
                            'fluid': true,
                            'size': 'small',
                            'frozenOnInput': true,
                            'className': _$cn(`${k}-colorpicker-input`),
                            'value': hex,
                            'ev-input': onInputHex
                        }),
                        _$ce(2, 'div', 'Hex', 16 /* HasTextChildren */, _$cn(`${k}-colorpicker-text`))
                    ]
                }),
                (() => {
                    let params;
                    let color;
                    let method;
                    let max;
                    if (mode.value === Mode.RGB) {
                        params = ['r', 'g', 'b'];
                        color = rgb;
                        method = this._changeRgb;
                        max = 255;
                    } else {
                        params = ['h', 's', 'l'];
                        color = {h: hsl.h, s: hsl.s * 100, l: hsl.l * 100};
                        method = this._changeHsv;
                        max = 100;
                    }
                    return params.map(value => {
                        const v = Math.round(color[value]);
                        const realMax = value === 'h' ? 359 : max;
                        return (
                            _$cc(Col, {
                                'span': '4',
                                'children': [
                                    _$cc(Input, {
                                        'fluid': true,
                                        'size': 'small',
                                        'value': v,
                                        'className': _$cn(`${k}-colorpicker-input`),
                                        'ev-input': setInputValue.bind(null, value, realMax)
                                    }),
                                    _$cc(Drag, {
                                        'value': v,
                                        'max': realMax,
                                        'ev-$change:value': changeColor.bind(null, value),
                                        'ev-click': changeMode,
                                        'onStart': onStart,
                                        'onEnd': onEnd,
                                        'children': [
                                            value.toUpperCase(),
                                            _$ct('\n                            '),
                                            value === 's' || value === 'l' ? '%' : null
                                        ]
                                    })
                                ]
                            })
                        ); 
                    });
                })(),
                _$cc(Col, {
                    'span': '4',
                    'children': [
                        _$cc(Input, {
                            'fluid': true,
                            'size': 'small',
                            'value': alpha,
                            'className': _$cn(`${k}-colorpicker-input`),
                            'ev-input': setInputValue.bind(null, 'a', 100)
                        }),
                        _$cc(Drag, {
                            'value': alpha,
                            'max': 100,
                            'ev-$change:value': changeColor.bind(null, 'a'),
                            'onStart': onStart,
                            'onEnd': onEnd,
                            'children': 'A'
                        })
                    ]
                })
            ]
        }),
        (presets && presets.length) ?
            _$ce(2, 'div', (
                _$ma(presets, function($value, $key) {
                    return _$ce(2, 'div', (
                        _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-colorpicker-preset-color`), {
                            'style': {backgroundColor: $value}
                        })
                    ), 2 /* HasVNodeChildren */, _$cn(`${k}-colorpicker-preset-color-wrapper ${k}-colorpicker-alpha-bg`), {
                        'ev-click': linkEvent($value, setValue)
                    });
                }, $this)
            ), 4 /* HasNonKeyedChildren */, _$cn(`${k}-colorpicker-presets`)) :
            undefined
    ], 0 /* UnknownChildren */, _$cn(classNameObj));
};