import {
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import {Transition} from 'intact';
import {getRestProps} from '../utils';
import {makeItemStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {className, children, value, cloned} = this.get();
    const parent = this.$senior;
    const active = parent.isActive(value);
    const { k } = this.config;
    const classNameObj = {
        [`${k}-carousel-item`]: true,
        [className]: className,
        [`${k}-active`]: active,
        [`${k}-cloned`]: cloned,
        [makeItemStyles(k)]: true,
    };
    const isSlide = parent.get('effect') === 'slide';
    
    return _$cc(Transition, {
        'show': active || isSlide,
        'name': 'k-carousel-fade',
        'children': (
            _$cv('div', {
                'className': _$cn(classNameObj),
                ...getRestProps(this)
            }, children)
        )
    });
};