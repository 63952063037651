import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createElementVNode as _$ce,
} from 'intact';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {Wave} from '../wave';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        className, disabled, trueValue, style,
        children, value, ref, key, title, ...rest
    } = this.get();
    const isChecked = value === trueValue;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-radio`]: true,
        [`${k}-disabled`]: disabled,
        [`${k}-checked`]: isChecked,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    // let evClick;
    const events = [
        'ev-click', 'ev-change',
        'ev-mouseenter', 'ev-mouseleave'
    ].reduce((memo, name) => {
        if (rest[name]) {
            if (name === 'ev-click' || name === 'ev-change') {
                // don't bind click to label, otherwise it will trigger click event twice
                // don't bind change to input, use click instead of, #448
                // evClick = rest[name];
            } else {
                memo[name] = rest[name];
            }
            delete rest[name];
        }
        return memo;
    }, {});
    
    return _$cv('label', {
        ...events,
        'className': _$cn(classNameObj),
        'style': style,
        'title': title,
        'tabindex': disabled ? "-1" : "0",
        'ev-keypress': this.onKeypress,
        'ev-click': this.fixClick
    }, [
        _$cc(Wave, {
            'disabled': disabled,
            'inset': '-2px',
            'children': (
                _$ce(2, 'span', (
                    _$cv('input', {
                        ...getRestProps(this, rest),
                        'type': 'radio',
                        'disabled': disabled,
                        'value': trueValue,
                        'tabindex': '-1',
                        'ref': this.elementRef,
                        'checked': isChecked,
                        'ev-click': this.onClick
                    })
                ), 2 /* HasVNodeChildren */, _$cn(`${k}-radio-wrapper`))
            )
        }),
        (children) ?
            _$ce(2, 'span', children, 0 /* UnknownChildren */, _$cn(`${k}-radio-text`)) :
            undefined
    ]);
};