import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Checkbox} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Checkbox, {
            'name': 'languages',
            'trueValue': 'Javascript',
            'children': 'Javascript',
            'ev-$model:value': function($v) {
                $this.set('languages', $v);
            },
            'value': $this.get('languages')
        }),
        _$cc(Checkbox, {
            'name': 'languages',
            'trueValue': 'C++',
            'children': 'C++',
            'ev-$model:value': function($v) {
                $this.set('languages', $v);
            },
            'value': $this.get('languages')
        }),
        _$cc(Checkbox, {
            'name': 'languages',
            'trueValue': 'PHP',
            'children': 'PHP',
            'ev-$model:value': function($v) {
                $this.set('languages', $v);
            },
            'value': $this.get('languages')
        }),
        _$ct('\n    Your selected: '),
        JSON.stringify(this.get('languages'))
    ], 0 /* UnknownChildren */);
};