export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import type {MenuProps} from 'kpc';

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            expandedKeys: ['3'],
            size: 'large',
            theme: 'light',
            type: 'vertical',
            collapse: false,
        } as MenuProps;
    }
}