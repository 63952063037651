import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
} from 'intact';
import {Button, ButtonGroup, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'k-icon-left'
};
var _$tmp1 = {
    'className': 'k-icon-right'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref___radioShanghai = function(i) {_$refs['__radioShanghai'] = i};
    var _$ref___checkboxShanghai = function(i) {_$refs['__checkboxShanghai'] = i};
    
    return _$ce(2, 'div', [
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'children': '开机'
                }),
                _$cc(Button, {
                    'children': '关机'
                }),
                _$cc(Button, {
                    'children': '重启'
                })
            ]
        }),
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'type': 'danger',
                    'children': '开机'
                }),
                _$cc(Button, {
                    'type': 'danger',
                    'children': '关机'
                }),
                _$cc(Button, {
                    'type': 'danger',
                    'children': '重启'
                })
            ]
        }),
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'type': 'flat',
                    'children': '开机'
                }),
                _$cc(Button, {
                    'type': 'flat',
                    'children': '关机'
                }),
                _$cc(Button, {
                    'type': 'flat',
                    'children': '重启'
                })
            ]
        }),
        _$cc(ButtonGroup, {
            'children': [
                _$cc(Button, {
                    'type': 'primary',
                    'children': [
                        _$cc(Icon, _$tmp0),
                        _$ct('上一页\n        ')
                    ]
                }),
                _$cc(Button, {
                    'type': 'primary',
                    'children': [
                        _$ct('\n            下一页'),
                        _$cc(Icon, _$tmp1)
                    ]
                })
            ]
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$ce(2, 'p', '纵向按钮组', 16 /* HasTextChildren */),
        _$cc(ButtonGroup, {
            'vertical': true,
            'children': [
                _$cc(Button, {
                    'type': 'primary',
                    'children': '开机'
                }),
                _$cc(Button, {
                    'type': 'primary',
                    'children': '关机'
                }),
                _$cc(Button, {
                    'type': 'primary',
                    'children': '重启'
                })
            ]
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$ce(2, 'p', '单选按钮组', 16 /* HasTextChildren */),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'value': 'beijing',
                    'children': '北京'
                }),
                _$cc(Button, {
                    'value': 'shanghai',
                    'ref': _$ref___radioShanghai,
                    'children': '上海'
                }, null, _$ref___radioShanghai),
                _$cc(Button, {
                    'value': 'guangzhou',
                    'children': '广州'
                }),
                _$cc(Button, {
                    'value': 'shenzhen',
                    'children': '深圳'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('city', $v);
            },
            'value': $this.get('city')
        }),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'children': [
                _$cc(Button, {
                    'type': 'flat',
                    'value': 'beijing',
                    'children': '北京'
                }),
                _$cc(Button, {
                    'type': 'flat',
                    'value': 'shanghai',
                    'children': '上海'
                }),
                _$cc(Button, {
                    'type': 'flat',
                    'value': 'guangzhou',
                    'children': '广州'
                }),
                _$cc(Button, {
                    'type': 'flat',
                    'value': 'shenzhen',
                    'children': '深圳'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('city', $v);
            },
            'value': $this.get('city')
        }),
        _$ce(2, 'p', '多选按钮组', 16 /* HasTextChildren */),
        _$cc(ButtonGroup, {
            'checkType': 'checkbox',
            'children': [
                _$cc(Button, {
                    'value': 'beijing',
                    'size': 'small',
                    'children': '北京'
                }),
                _$cc(Button, {
                    'value': 'shanghai',
                    'size': 'small',
                    'ref': _$ref___checkboxShanghai,
                    'children': '上海'
                }, null, _$ref___checkboxShanghai),
                _$cc(Button, {
                    'value': 'guangzhou',
                    'size': 'small',
                    'children': '广州'
                }),
                _$cc(Button, {
                    'value': 'shenzhen',
                    'size': 'small',
                    'children': '深圳'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('cities', $v);
            },
            'value': $this.get('cities')
        }),
        _$ce(2, 'p', '有间隔的按钮组', 16 /* HasTextChildren */),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'separate': true,
            'children': [
                _$cc(Button, {
                    'value': 'beijing',
                    'children': '北京'
                }),
                _$cc(Button, {
                    'value': 'shanghai',
                    'children': '上海'
                }),
                _$cc(Button, {
                    'value': 'guangzhou',
                    'children': '广州'
                }),
                _$cc(Button, {
                    'value': 'shenzhen',
                    'children': '深圳'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('city', $v);
            },
            'value': $this.get('city')
        }),
        _$cc(ButtonGroup, {
            'separate': true,
            'children': [
                _$cc(Button, {
                    'value': 'beijing',
                    'type': 'primary',
                    'disabled': true,
                    'children': '北京'
                }),
                _$cc(Button, {
                    'value': 'shanghai',
                    'children': '上海'
                }),
                _$cc(Button, {
                    'value': 'guangzhou',
                    'children': '广州'
                }),
                _$cc(Button, {
                    'value': 'shenzhen',
                    'children': '深圳'
                })
            ]
        }),
        _$ce(2, 'p', '支持自定义ButtonGroup下的Button宽度', 16 /* HasTextChildren */),
        _$cc(ButtonGroup, {
            'checkType': 'radio',
            'btnWidth': '100px',
            'children': [
                _$cc(Button, {
                    'value': 'beijing',
                    'children': '北京'
                }),
                _$cc(Button, {
                    'value': 'shanghai',
                    'children': '上海'
                }),
                _$cc(Button, {
                    'value': 'guangzhou',
                    'children': '广州'
                }),
                _$cc(Button, {
                    'value': 'shenzhen',
                    'children': '深圳'
                })
            ],
            'ev-$model:value': function($v) {
                $this.set('city', $v);
            },
            'value': $this.get('city')
        })
    ], 4 /* HasNonKeyedChildren */);
};