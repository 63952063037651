import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Colorpicker} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Colorpicker, {
            'presets': ['transparent', 'red', '#d99123', 'rgb(255, 51, 33)', 'hsl(126, 54%, 46%)'],
            'ev-$model:value': function($v) {
                $this.set('color1', $v);
            },
            'value': $this.get('color1')
        }),
        _$cc(Colorpicker, {
            'presets': [],
            'ev-$model:value': function($v) {
                $this.set('color2', $v);
            },
            'value': $this.get('color2')
        })
    ], 4 /* HasNonKeyedChildren */);
};