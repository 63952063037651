import {
    createUnknownComponentVNode as _$cc,
    map as _$ma,
    createTextVNode as _$ct,
} from 'intact';
import {Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Select, {
        'multiple': true,
        'filterable': true,
        'filter': () => true,
        'ev-input': this.search,
        'children': (
            _$ma(this.get('users'), function($value, $key) {
                return _$cc(Option, {
                    'value': $value.login.username,
                    'children': [
                        $value.name.first,
                        _$ct(' '),
                        $value.name.last
                    ]
                });
            }, $this)
        )
    });
};