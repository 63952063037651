import {
    createVNode as _$cv,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
    noop as _$no,
} from 'intact';
import { TransitionGroup } from 'intact';
import { getRestProps } from '../utils';
import { makeTagsStyles } from './styles';
import { Tag } from './';
import { Tooltip } from '../tooltip';
import { Tags } from './tags';
import { Virtual } from '../virtual';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const { className, nowrap, size, border, draggable } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-tags`]: true,
        [`${k}-nowrap`]: nowrap,
        [`${k}-draggable`]: draggable,
        [makeTagsStyles(k)]: true,
        [className]: className,
    }
    
    const { containerRef, children, hiddenChildren } = this.nowrap;
    const vNodes = children.value;
    
    /**
 * We should always render Tooltip in nowrap mode, otherwise it will be removed while we close one tag.
 * Because we will render all tags to calculate the width
 */
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this),
        'ref': containerRef
    }, [
        (!nowrap) ?
            _$cc(TransitionGroup, {
                'name': 'k-fade',
                'children': vNodes
            }) :
            vNodes,
        (nowrap) ?
            _$cc(Tooltip, {
                'key': '$tooltip',
                'hoverable': true,
                'theme': 'light',
                'className': _$cn(`${k}-tags-tooltip`),
                'children': (
                    _$cc(Tag, {
                        'key': '$more',
                        'className': _$cn({[`${k}-tags-more`]: true, 'c-hidden': !hiddenChildren.value.length}),
                        'size': size,
                        'border': border,
                        'children': [
                            _$ct('\n            +'),
                            hiddenChildren.value.length
                        ]
                    }, '$more')
                ),
                '$blocks': function($blocks) {
                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                    return (
                        (
                            (_$blocks['content'] = function($super) {
                                return (
                                    _$ce(2, 'div', hiddenChildren.value, 0 /* UnknownChildren */, _$cn({ ...classNameObj, [`${k}-nowrap`]: false /* should not nowrap */ }))
                                );
                            }),
                            (__$blocks['content'] = function($super, data) {
                                var block = $blocks['content'];
                                var callBlock = function() {
                                    return _$blocks['content'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            })
                        ),
                        __$blocks
                    );
                }.call($this, _$em)
            }, '$tooltip') :
            undefined,
        (
            (_$blocks['append'] = function($super) {
                return null;
            }),
            (__$blocks['append'] = function($super, data) {
                var block = $blocks['append'];
                var callBlock = function() {
                    return _$blocks['append'].call($this, $super, data);
                };
                return block ?
                    block.call($this, callBlock, data) :
                    callBlock();
            }),
            __$blocks['append'](_$no)
        )
    ]);
};