export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind, ProgressProps} from 'kpc';

interface Props {
    percent: number
    status: ProgressProps['status'] 
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            percent: 40,
            status: 'active',
        } as Props;
    }

    @bind
    add() {
        if (this.get('percent') >= 100) return;

        this.set('percent', this.get('percent') + 10);
    }

    @bind
    minus() {
        if (this.get('percent') <= 0) return;

        this.set('percent', this.get('percent') - 10);
    }
}