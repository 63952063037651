import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Title 1',
    'minWidth': 200
};
var _$tmp1 = {
    'key': 'b',
    'title': 'Title 2',
    'minWidth': 300
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Table, {
        'data': this.get('data'),
        'resizable': true,
        'children': [
            _$cc(TableColumn, _$tmp0, 'a'),
            _$cc(TableColumn, _$tmp1, 'b')
        ]
    });
};